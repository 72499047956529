import { createGlobalStyle } from 'styled-components';
import {theme} from "./theme";

export const GlobalStyles = createGlobalStyle`

  * {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }

  body {
    line-height: 1.2;
  }
  
  html, body {
    height: 100%;
  }
  
  #root {
    height: 100%;
  }
  
  .tooltip {
    padding: 5px 10px !important;
    font-family: ${theme.fonts.mainRegular};
    font-size: ${theme.fontSizes.body};
    white-space: nowrap;
  }
  
  body.modal-open {
    overflow: hidden;
  }

  @media (max-height: 900px) {
    .grid-content-wrapper {
      overflow: unset;
      height: auto;
    }
  }
  
  
  //react-datepicker
  .react-datepicker {
    border: 1px solid ${theme.colors.primaryDark};
    
    &__header {
      background-color: ${theme.colors.neutral1};
      border-bottom: 1px solid ${theme.colors.neutral3};
    }

    &__current-month, 
    &-time__header, 
    &-year-header {
      font-family: ${theme.fonts.mainBold};
      font-size: ${theme.fontSizes.title};
      color: ${theme.colors.neutral10};
      font-weight: normal;
    }
    
    &-popper {
      z-index: 100;
      
      &[data-placement^=bottom] {
        padding-top: 3px;

        .react-datepicker__triangle {
          display: none;
        }
      }
    }

    &__month {
      .react-datepicker__month-text {
        font-family: ${theme.fonts.mainRegular};
        font-size: ${theme.fontSizes.body};
        width: 4.5rem;
        padding: 5px;
      }

      &-text--keyboard-selected {
        border-radius: 4px;
        background-color: ${theme.colors.primary};
        
        &:hover {
          background-color: ${theme.colors.primaryDark} !important;
        }
      }
    }

    &__day {
      font-family: ${theme.fonts.mainRegular};
      font-size: ${theme.fontSizes.body};
      color: ${theme.colors.neutral10};
      
      &.react-datepicker__day--in-selecting-range {
        color: ${theme.colors.neutral10} !important;
        background-color: ${theme.colors.primaryLight} !important;
      }
      
      &--in-range {
        background-color: ${theme.colors.primary};
        color: #ffffff;

        &:hover {
          background-color: ${theme.colors.primaryDark} !important;
        }
      }
      
      &-name {
        font-family: ${theme.fonts.mainRegular};
        font-size: ${theme.fontSizes.body};
        color: ${theme.colors.neutral10};
      }
      
      &--selected,
      &--keyboard-selected{
        background-color: ${theme.colors.primary};
        color: #ffffff;
        
        &:hover {
          background-color: ${theme.colors.primaryDark};
        }
      }
      
      &--outside-month {
        color: ${theme.colors.neutral7};
      }
    }
    
  }
  
  //charts
  
  .highcharts-credits {
    display: none ;
  }

  .funnel-label {
    span {
      font-size: 14px !important;
      font-family: ${theme.fonts.mainRegular} !important;
      font-weight: normal !important;
      text-align: center;
      left: 0 !important;
      top: 0 !important;
    }
  }
  
  .column-label {
    span {
      font-size: 14px !important;
      font-family: ${theme.fonts.mainRegular} !important;
      font-weight: normal !important;
      text-align: center;
      top: 0 !important;
    }
  }
  
  .chart-tooltip {
    padding: 5px 10px;
    span {
      position: relative !important;
      font-family: ${theme.fonts.mainRegular} !important;
      line-height: 20px;
      left: 0 !important;
      top: 0 !important;
    }
  }
  
  .highcharts-legend-navigation {
    font-family: ${theme.fonts.mainRegular};
    color: ${theme.colors.neutral8};
    font-size: 14px;
  }

  .highcharts-legend-item {
    span {
      font-family: ${theme.fonts.mainRegular} !important;
      color: ${theme.colors.neutral10} !important;
      font-size: 14px !important;
      top: 12px !important;
    }
    
    &:hover {
      span {
        color: ${theme.colors.primary} !important;
      }
      rect {
        fill: ${theme.colors.primary};
      }
    }
    
    &-hidden {
      span {
        color: ${theme.colors.neutral6} !important;
      }
    }
  }
  
`;