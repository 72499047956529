import {useMemo} from "react";
import initializeMultiselectValue from "../utils/initializeMultiselectValue";

export const useMemoFilteredRestaurants = (userData, restaurants) => {
  return useMemo(() => {
    if(userData.role?.name === "controller")
      return initializeMultiselectValue(userData.restaurants, "id");

    if(userData.role?.name === "hr_partner") {
      return initializeMultiselectValue(userData.partner_restaurants, "id");
    }

    return initializeMultiselectValue(restaurants, "id");
  }, [userData.role?.name, userData.restaurants, userData.partner_restaurants, restaurants])
}