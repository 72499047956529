import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import Header from "../../components/Header";
import { observer } from "mobx-react-lite";
import styled from "styled-components";
import ActionIcon from "../../components/ActionIcon";
import FilterIcon from "../../assets/icons/FilterIcon";
import Rangepicker from "../../components/Rangepicker";
import { INTERVIEW_FILTER_ID as FILTER_ID } from "../../config/filters";
import { HeaderElement as HeaderListElement } from "./ListView/HeaderElement";
import { HeaderElement as HeaderCalendarElement } from "./CalendarView/HeaderElement";
import Select from "../../components/Select";
import CalendarViewMonthIcon from "../../assets/icons/CalendarViewMonthIcon";
import CalendarViewWeekIcon from "../../assets/icons/CalendarViewWeekIcon";
import List from "./ListView/List";
import MonthView from "./CalendarView/MonthView";
import moment from "moment";
import WeekView from "./CalendarView/WeekView";
import { FiltersStoreContext } from "../../stores/filters";
import Filters from "../../components/Filters";
import { useFetching } from "../../hooks/useFetching";
import { getInterviews } from "../../actions/interviews";
import {DirectoryStoreContext} from "../../stores/directory";
import {privateRoutes} from "../../config/routes";
import {Input} from "../../components/Input";
import {AuthStoreContext} from "../../stores/auth";
import MultiSelect from "../../components/MultiSelect";
import {candidateFormsFiltersStoreContext} from "../../stores/candidateFormsFilters";
import cloneDeep from "lodash/cloneDeep";
import {PAGE_DEFAULT} from "../../config/consts";
import {useUpdateProfile} from "../../hooks/useUpdateProfile";
import {useUpdateForm} from "../../hooks/useUpdateForm";
import {useMemoFilteredRestaurants} from "../../hooks/useMemoFilteredRestaurants";
import {useMemoRestaurantOption} from "../../hooks/useMemoRestaurantOption";
import Loader from "../../components/Loader";
import initializeMultiselectOptions from "../../utils/initializeMultiselectOptions";
import initializeMultiselectValue from "../../utils/initializeMultiselectValue";

const schema = yup.object().shape({
  search: yup.string(),
  sortBy: yup.string(),
  sortOrder: yup.string(),
  filters: yup.object().shape({
    ageFromTo: yup.object(),
    dateRange: yup.object(),
    vacancy_id: yup.string(),
    restaurant_id: yup.string(),
    citizenship_id: yup.string(),
    candidate_profile_status_id: yup.string(),
  }),
});

const RightActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;

  @media (max-width: 1197px) {
    width: 100%;
    justify-content: flex-start;
  }
  
  @media (max-width: 720px) {
    align-items: flex-end;
  }
  
  @media (max-width: 500px) {
    justify-content: space-between;
  }
  
  @media (max-width: 425px) {
    flex-wrap: wrap;
  }
`;

const InputWrapper = styled.div`
  width: 180px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  @media (max-width: 413px) {
    width: 100%;
  }
`;

const ActionWrapper = styled.div<{ margin?: boolean }>`
  margin-right: ${({ margin }) => (margin ? 5 : 0)}px;
`;

const LeftColumnWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;

  @media (max-width: 413px) {
    width: 100%;  
  }
`;

const pageTypeOptions: IPageTypeOptions[] = [
  { label: "Список", value: "list" },
  { label: "Календарь", value: "calendar" },
];

const SelectWrapper = styled.div`
  &:not(:last-child) {
    margin-bottom: 15px;
  }
`;

const SelectAgeWrapper = styled.div`
  display: flex;
  &:not(:last-child) {
    margin-bottom: 15px;
  }
`;

const FiltersBtn = styled.div`
  position: relative;
`;

const CalendarViewAction = styled.div`
  display: flex;
`;

type pageTypeVariables = 'list' | 'calendar';
type calendarVariables = 'month' | 'week';
export interface IPageTypeOptions {
  label: string;
  value: pageTypeVariables;
}

const Interview = observer(() => {
  const { userData } = useContext(AuthStoreContext);

  useUpdateProfile();

  const [pageType, setPageType] = useState<pageTypeVariables>(pageTypeOptions[0].value);
  const [currentDate, setCurrentDate] = useState<moment.Moment>(null);
  const [calendarView, setCalendarView] = useState<calendarVariables>("month");
  const [searchValue, setSearchValue] = useState("");
  const calendarMonth = useRef(null);
  const filtersIconRef = useRef(null);
  const [interviewsData, setInterviewsData] = useState(null);
  const { filtersData, filtersIconClickHandle, closeFiltersHandle } = useContext(FiltersStoreContext);

  const [fetchInterviews, isLoading, error] = useFetching(async (data) => {
    const {limit, page, order, search, filters, pagination} = data;
    const isChk = window.location.pathname === privateRoutes.interviewChk.path;
    const exportParams = null;

    const filterOptionLengths = {
      partnerLength: filteredPartners.length,
      restaurantLength: filteredRestaurants.length,
      statusLength: checkedStatuses.length,
      citizenshipLength: checkedCitizenship.length,
    }

    const response = await getInterviews(
      limit,
      page,
      order,
      search,
      filters,
      filterOptionLengths,
      isChk,
      exportParams,
      pagination
    );
    setInterviewsData(response);
  });
  const {
    partners,
    citizenship,
    restaurants,
    candidateProfileStatuses,
    vacancies
  } = useContext(DirectoryStoreContext);
  const {
    limit,
    page,
    currentPath,
    sortBy,
    sortOrder,
    defaultFilterValues,
    filters,
    setLimit,
    setPage,
    setFilters,
    setAllDefault,
    setCurrentPath,
    setRestaurantFilters,
    setPartnersFilters,
    setStatusFilters,
    setCitizenshipFilters
  } = useContext(candidateFormsFiltersStoreContext);

  const filteredRestaurants = useMemoFilteredRestaurants(userData, restaurants);

  const { control, getValues, setValue, reset } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      search: "",
      sortBy: "",
      sortOrder: "ascending",
      filters: defaultFilterValues,
    },
  });

  useEffect(() => {
    if(currentPath !== window.location.pathname) {
      setCurrentPath(window.location.pathname);
      return;
    }

    if (currentDate) {
      const filters = getValues().filters;

      if (calendarView === "month") {
        filters.interviewDate = {
          from: moment(currentDate).startOf("month").toDate(),
          to: moment(currentDate).endOf("month").toDate()
        };
      } else {
        filters.interviewDate = {
          from: currentDate.toDate(),
          to: moment(currentDate).add(1, "week").toDate()
        };
      }

      fetchInterviews({pagination: "nolimit", order: null, search: "", filters});
    } else {
      let order = null;
      if (sortBy) {
        const orderValue = `${sortOrder === "ascending" ? "" : "-"}${sortBy}`
        order = [orderValue];
      }
      const search = searchValue;

      fetchInterviews({limit, page, order, search, filters});
    }
  }, [limit, page, searchValue, sortBy, sortOrder, filters, currentPath, currentDate, pageType, calendarView]);

  useUpdateForm(setValue, {searchValue, sortBy, sortOrder, filters});

  const restaurantOptions = useMemoRestaurantOption();

  const vacanciesOptions = vacancies.map(i => ({value: i.id, label: i.name}));

  const citizenshipOptions = useMemo(() => {
    return citizenship.map(i => ({value: i.id, label: i.name}));
  }, [citizenship]);
  const checkedCitizenship = useMemo(() => {
    return citizenship.map(i => i.id);
  }, [citizenship]);

  const statusesOptions = useMemo(() => {
    return candidateProfileStatuses.map(status => ({value: status.id, label: status.name}));
  },[candidateProfileStatuses]);
  const checkedStatuses = useMemo(() => {
    return candidateProfileStatuses.map(status => status.id)
  }, [candidateProfileStatuses])

  const resetFilters = useCallback(() => {
    const filters = cloneDeep(defaultFilterValues);
    filters.restaurant_id = filteredRestaurants;
    filters.partner = filteredPartners;
    filters.candidate_profile_status_id = checkedStatuses;
    filters.citizenship_id = checkedCitizenship;

    reset({
      search: getValues().search,
      sortBy: getValues().sortBy,
      sortOrder: getValues().sortOrder,
      filters: filters,
    });
  }, [defaultFilterValues, filteredRestaurants, getValues, reset]);

  const applyFilters = () => {
    const filters = getValues().filters;
    setFilters(filters);
    setPage(PAGE_DEFAULT);
    setSearchValue("");
  };

  const pageTypeSelectChangeHandle = (value: pageTypeVariables) => {
    if(value === "list") {
      setCurrentDate(null);
    } else {
      setCurrentDate(moment());
    }
    setPageType(value);
    setAllDefault();
    resetFilters();
  };

  const calendarViewSelectChangeHandle = (value: calendarVariables) => {
    setCalendarView(value);
    setCurrentDate(prevDate => moment(prevDate).startOf("month"))
  };

  const onChangeDate = (count: number) => {
    setCurrentDate(prevDate => moment(prevDate).add(count, calendarView))
  }

  const onClickToday = () => {
    setCurrentDate(moment());
  };

  const partnersOptions = initializeMultiselectOptions(partners, "id", "name", false);

  const filteredPartners = useMemo(() => {
    if(userData.role.name === "superadmin") {
      return initializeMultiselectValue(partners, "id");
    } else {
      return  [];
    }
  }, [partners])

  useEffect(() => {
    if(!filters.restaurant_id.length)
      setRestaurantFilters(filteredRestaurants);
    if(!filters.partner.length)
      setPartnersFilters(filteredPartners);
    if(!filters.candidate_profile_status_id.length)
      setStatusFilters(checkedStatuses)
    if(!filters.citizenship_id.length)
      setCitizenshipFilters(checkedCitizenship)
  }, [filteredRestaurants, filteredPartners, checkedStatuses, checkedCitizenship])

  return (
    <>
      <Header
        rightActions={
          <RightActions>
            {pageType === "list" ? (
              <HeaderListElement
                control={control}
                filteredRestaurants={filteredRestaurants}
                getValues={getValues}
                resetFilters={resetFilters}
                setSearchValue={setSearchValue}
              />
            ) : (
              <HeaderCalendarElement
                onChangeDate={onChangeDate}
                calendarView={calendarView}
                onClickToday={onClickToday}
                currentDate={currentDate}
              />
            )}
            <FiltersBtn ref={filtersIconRef}>
              <ActionIcon
                action={() => filtersIconClickHandle(FILTER_ID, filtersIconRef)}
                active={filtersData[FILTER_ID]?.open}
                disabled={
                  !citizenshipOptions.length ||
                  !checkedCitizenship.length ||
                  !statusesOptions.length ||
                  !checkedStatuses.length ||
                  !restaurantOptions.length ||
                  !filteredRestaurants.length ||
                  !partnersOptions.length
                }
                tooltip="Фильтры"
              >
                <FilterIcon />
              </ActionIcon>

              {filtersData[FILTER_ID]?.open && (
                <Filters
                  onReset={resetFilters}
                  btnRef={filtersIconRef}
                  onApply={() => {
                    applyFilters();
                    closeFiltersHandle(null, FILTER_ID, filtersIconRef);
                  }}
                  onClose={(event) =>
                    closeFiltersHandle(event, FILTER_ID, filtersIconRef)
                  }
                >
                  {userData.role.name === "superadmin" &&
                    <SelectWrapper>
                      <Controller
                        render={({ field, fieldState }) => (
                          <MultiSelect
                            {...field}
                            {...fieldState}
                            title="Партнер"
                            options={partnersOptions}
                            withSubOptions={false}
                            ref={null}
                          />
                        )}
                        name="filters.partner"
                        control={control}
                      />
                    </SelectWrapper>
                  }

                  {userData.role.name !== "restaurant" &&
                    <SelectWrapper>
                      <Controller
                        render={({ field, fieldState }) => (
                          <MultiSelect
                            {...field}
                            {...fieldState}
                            title="Ресторан"
                            options={restaurantOptions}
                            withSubOptions={true}
                            subOptionsKey="restaurants_minimal"
                            ref={null}
                          />
                        )}
                        name="filters.restaurant_id"
                        control={control}
                      />
                    </SelectWrapper>
                  }

                  <SelectWrapper>
                    <Controller
                        render={({ field, fieldState }) => (
                            <MultiSelect
                                {...field}
                                {...fieldState}
                                title="Статус анкеты"
                                withSubOptions={false}
                                options={statusesOptions}
                                ref={null}
                            />
                        )}
                        name="filters.candidate_profile_status_id"
                        control={control}
                    />
                  </SelectWrapper>
                  {
                    pageType === "list" &&
                    <SelectWrapper>
                      <Controller
                          render={({ field, fieldState }) => (
                            <Rangepicker
                              startDate={field.value.from
                                ? moment(field.value.from, "YYYY-MM-DD HH:mm:ss")
                                : null
                              }
                              endDate={field.value.to
                                ? moment(field.value.to, "YYYY-MM-DD HH:mm:ss")
                                : null
                              }
                              onChange={(from, to) =>
                                field.onChange({from, to})}
                              title="С - по"
                            />
                          )}
                          name="filters.dateRange"
                          control={control}
                      />
                    </SelectWrapper>
                  }
                  <SelectAgeWrapper>
                    <Controller
                        render={({ field, fieldState }) => (
                            <Input
                                {...field}
                                {...fieldState}
                                title="Возраст от"
                                placeholder="Возраст от"
                                type="number"
                                positive={true}
                                ref={null}
                            />
                        )}
                        name="filters.ageFromTo.from"
                        control={control}
                    />
                    <Controller
                        render={({ field, fieldState }) => (
                            <Input
                                {...field}
                                {...fieldState}
                                title="Возраст до"
                                placeholder="Возраст до"
                                type="number"
                                positive={true}
                                ref={null}
                            />
                        )}
                        name="filters.ageFromTo.to"
                        control={control}
                    />
                  </SelectAgeWrapper>
                  <SelectWrapper>
                    <Controller
                        render={({ field, fieldState }) => (
                            <MultiSelect
                                {...field}
                                {...fieldState}
                                title="Гражданство"
                                options={citizenshipOptions}
                                withSubOptions={false}
                                ref={null}
                            />
                        )}
                        name="filters.citizenship_id"
                        control={control}
                    />
                  </SelectWrapper>
                  <SelectWrapper>
                    <Controller
                        render={({ field, fieldState }) => (
                            <Select
                                {...field}
                                {...fieldState}
                                title="Вакансия"
                                options={vacanciesOptions}
                                ref={null}
                            />
                        )}
                        name="filters.vacancy"
                        control={control}
                    />
                  </SelectWrapper>
                  {
                    pageType === "list" &&
                    <SelectWrapper>
                      <Controller
                        render={({field, fieldState}) => (
                          <Rangepicker
                            startDate={field.value.from
                              ? moment(field.value.from, "YYYY-MM-DD HH:mm:ss")
                              : null
                            }
                            endDate={field.value.to
                              ? moment(field.value.to, "YYYY-MM-DD HH:mm:ss")
                              : null
                            }
                            onChange={(from, to) =>
                              field.onChange({from, to})}
                            title="Дата собеседования"
                          />
                        )}
                        name="filters.interviewDate"
                        control={control}
                      />
                    </SelectWrapper>
                  }
                </Filters>
              )}
            </FiltersBtn>
          </RightActions>
        }
        leftActions={
          <LeftColumnWrapper>
            <InputWrapper>
              <Select
                options={pageTypeOptions}
                value={pageType}
                onChange={pageTypeSelectChangeHandle}
              />
            </InputWrapper>
            {pageType === "calendar" && (
              <CalendarViewAction>
                <ActionWrapper margin>
                  <ActionIcon
                    action={() => calendarViewSelectChangeHandle("month")}
                    active={calendarView === "month"}
                  >
                    <CalendarViewMonthIcon />
                  </ActionIcon>
                </ActionWrapper>
                <ActionWrapper>
                  <ActionIcon
                    action={() => calendarViewSelectChangeHandle("week")}
                    active={calendarView === "week"}
                  >
                    <CalendarViewWeekIcon />
                  </ActionIcon>
                </ActionWrapper>
              </CalendarViewAction>
            )}
          </LeftColumnWrapper>
        }
        title={
          window.location.pathname === privateRoutes.interviewChk.path
              ? "Собеседования ЧК"
              : "Собеседования МС"
        }
      />
        {isLoading
            ?
          <Loader width={70} height={70}/>
            :
          <>
            {pageType === "list" ?
              <List data={interviewsData}
                    setPage={setPage}
                    setLimit={setLimit} />
            : calendarView === "month" ?
              <MonthView data={interviewsData}
                         currentDate={currentDate}
                         ref={calendarMonth}
                         onChangeDate={onChangeDate}
              />
             :
              <WeekView date={currentDate}
                        data={interviewsData}/>
            }
          </>
        }
    </>
  );
});

export default Interview;
