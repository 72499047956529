import React, {FC} from 'react';
import ActionIcon from "../../../components/ActionIcon";
import Reset from "../../../assets/icons/Reset";
import {useFetching} from "../../../hooks/useFetching";
import {putCandidateFormsOrder} from "../../../actions/candidateFormsOrder";
import styled from "styled-components";

interface Props {
  row: any;
  value: boolean;
  onRequestFulfilled?: () => void;
}

const TableActionIcon:FC<Props> = ({
  row,
  onRequestFulfilled,
}) => {
  const [putCandidateFormsOrderHandle, isLoading, error] = useFetching(async (data) => {
    const {id, requestData} = data;
    await putCandidateFormsOrder(id, requestData);
    onRequestFulfilled();
  });

  const clickHandle = () => {
    const requestData = {
      restaurant_id: row.id,
      needed_for_month: row?.candidate_orders_current_month?.needed_for_month,
      requirement_la_re: 0,
      requirement_la_sm: 0,
    }
    putCandidateFormsOrderHandle({id: row?.candidate_orders_current_month?.id, requestData});
  }

  let disabled = false;
  if(!(row?.candidate_orders_current_month?.requirement_la_re ||
    row?.candidate_orders_current_month?.requirement_la_sm)) {
    disabled = true;
  }

  return (
    <IconWrapper>
      <ActionIcon
        size={32}
        disabled={disabled}
        action={clickHandle}
      >
        <Reset />
      </ActionIcon>
    </IconWrapper>
  );
};

const IconWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export default TableActionIcon;