const DEFAULT_VALUE = 0;

export class Rates {
    id: number;
    restaurant_id: number;
    current_employees_count_fourth: number;
    current_employees_count_half: number;
    current_employees_count_one_point_five: number;
    current_employees_count_one_point_twenty_five: number;
    current_employees_count_three_quarters: number;
    current_employees_count_whole: number;
    current_employees_total_count: number;
    current_employees_total_hour: number;
    personnel_reserve_count_fourth: number;
    personnel_reserve_count_half: number;
    personnel_reserve_count_one_point_five: number;
    personnel_reserve_count_one_point_twenty_five: number;
    personnel_reserve_count_three_quarters: number;
    personnel_reserve_count_whole: number;
    personnel_reserve_total_count: number;
    personnel_reserve_total_hour: number;
    planned_turnover_count_fourth: number;
    planned_turnover_count_half: number;
    planned_turnover_count_one_point_five: number;
    planned_turnover_count_one_point_twenty_five: number;
    planned_turnover_count_three_quarters: number;
    planned_turnover_count_whole: number;
    planned_turnover_total_count: number;
    planned_turnover_total_hour: number;

    constructor(rates) {
        this.id = rates?.id;
        this.restaurant_id = rates?.restaurant_id;
        this.current_employees_count_fourth = rates?.current_employees_count_fourth || DEFAULT_VALUE;
        this.current_employees_count_half = rates?.current_employees_count_half || DEFAULT_VALUE;
        this.current_employees_count_one_point_five = rates?.current_employees_count_one_point_five || DEFAULT_VALUE;
        this.current_employees_count_one_point_twenty_five = rates?.current_employees_count_one_point_twenty_five || DEFAULT_VALUE;
        this.current_employees_count_three_quarters = rates?.current_employees_count_three_quarters || DEFAULT_VALUE;
        this.current_employees_count_whole = rates?.current_employees_count_whole || DEFAULT_VALUE;
        this.current_employees_total_count = rates?.current_employees_total_count || DEFAULT_VALUE;
        this.current_employees_total_hour = rates?.current_employees_total_hour || DEFAULT_VALUE;
        this.personnel_reserve_count_fourth = rates?.personnel_reserve_count_fourth || DEFAULT_VALUE;
        this.personnel_reserve_count_half = rates?.personnel_reserve_count_half || DEFAULT_VALUE;
        this.personnel_reserve_count_one_point_five = rates?.personnel_reserve_count_one_point_five || DEFAULT_VALUE;
        this.personnel_reserve_count_one_point_twenty_five = rates?.personnel_reserve_count_one_point_twenty_five || DEFAULT_VALUE;
        this.personnel_reserve_count_three_quarters = rates?.personnel_reserve_count_three_quarters || DEFAULT_VALUE;
        this.personnel_reserve_count_whole = rates?.personnel_reserve_count_whole || DEFAULT_VALUE;
        this.personnel_reserve_total_count = rates?.personnel_reserve_total_count || DEFAULT_VALUE;
        this.personnel_reserve_total_hour = rates?.personnel_reserve_total_hour || DEFAULT_VALUE;
        this.planned_turnover_count_fourth = rates?.planned_turnover_count_fourth || DEFAULT_VALUE;
        this.planned_turnover_count_half = rates?.planned_turnover_count_half || DEFAULT_VALUE;
        this.planned_turnover_count_one_point_five = rates?.planned_turnover_count_one_point_five || DEFAULT_VALUE;
        this.planned_turnover_count_one_point_twenty_five = rates?.planned_turnover_count_one_point_twenty_five || DEFAULT_VALUE;
        this.planned_turnover_count_three_quarters = rates?.planned_turnover_count_three_quarters || DEFAULT_VALUE;
        this.planned_turnover_count_whole = rates?.planned_turnover_count_whole || DEFAULT_VALUE;
        this.planned_turnover_total_count = rates?.planned_turnover_total_count || DEFAULT_VALUE;
        this.planned_turnover_total_hour = rates?.planned_turnover_total_hour || DEFAULT_VALUE;
    }
}

export const IndexToCurrentKey = {
    0: "current_employees_count_whole",
    1: "current_employees_count_fourth",
    2: "current_employees_count_half",
    3: "current_employees_count_three_quarters",
    4: "current_employees_count_one_point_twenty_five",
    5: "current_employees_count_one_point_five",
};

export const IndexToPlannedKey = {
    0: "planned_turnover_count_whole",
    1: "planned_turnover_count_fourth",
    2: "planned_turnover_count_half",
    3: "planned_turnover_count_three_quarters",
    4: "planned_turnover_count_one_point_twenty_five",
    5: "planned_turnover_count_one_point_five",
};

export const IndexToPersonnelKey = {
    0: "personnel_reserve_count_whole",
    1: "personnel_reserve_count_fourth",
    2: "personnel_reserve_count_half",
    3: "personnel_reserve_count_three_quarters",
    4: "personnel_reserve_count_one_point_twenty_five",
    5: "personnel_reserve_count_one_point_five",
};
