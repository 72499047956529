import React, {
  ChangeEvent,
  FC,
  memo,
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import { withError } from "../../hocs/Error";
import { theme } from "../../styles/theme";
import SearchIcon from "../../assets/icons/SearchIcon";
import CloseIcon from "../../assets/icons/CloseIcon";
import ActionIcon from "../ActionIcon";
import { IMaskMixin } from "react-imask";
import DOMPurify from "dompurify";

interface Props {
  mask?: string;
  type?: "text" | "password" | "number";
  positive?: boolean;
  inputId?: string;
  placeholder?: string;
  onBlur?: (event?, value?) => void;
  autoFocus?: boolean;
  onChange: (value: string | number | any, isClear?: boolean) => void;
  onKeyUp?: (event) => void;
  value?: string | number;
  width?: string;
  search?: boolean;
  disabled?: boolean;
  height?: string;
  withCloseIcon?: boolean;
  rightIcon?: ReactElement;
  rightIconAction?: () => void;
  rightActionDisabled?: string;
  padding?: string;
  error?: string;
  maxLength?: number;
  required?: boolean;
  isTopPlaceholder?: boolean;
}

const InputComponent: FC<Props> = ({
  mask,
  type = "text",
  positive = false,
  inputId,
  padding,
  placeholder = "",
  onBlur = () => null,
  autoFocus = false,
  onChange,
  onKeyUp,
  value,
  width = "100%",
  search = false,
  disabled = false,
  height = "40px",
  withCloseIcon = true,
  rightIcon,
  rightIconAction = () => null,
  rightActionDisabled,
  error,
  maxLength = null,
  required = false,
  isTopPlaceholder
}) => {
  const inputRef = useRef(null);
  const [inputType, setInputType] = useState();
  const [topPlaceholder, setTopPlaceholder] = useState(!!value);
  const [focusInput, setFocusInput] = useState(false);

  const focusToInput = useCallback(() => {
    inputRef.current.focus();
  }, []);

  useEffect(() => {
    if(isTopPlaceholder) {
      let timerId = setInterval(() => {
        const styles = window.getComputedStyle(document.getElementById(inputId))
        if (styles && styles.backgroundColor !== theme.colors.inputBackgroundNormalState) {
          setTopPlaceholder(true);
        }
      }, 200)

      setTimeout(() => {
        clearInterval(timerId)
      }, 7000);
    }
  }, [])

  useEffect(() => {
    if (value) {
      setTopPlaceholder(true);
    }
  }, [value]);

  useEffect(() => {
    if (autoFocus) {
      focusToInput();
      setFocusInput(true);
    }
  }, [autoFocus, focusToInput]);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      try {
        let newValue = DOMPurify.sanitize(e.target.value);

        if (positive && type === "number") {
          if (newValue.length > 1 && newValue.startsWith("0")) return;

          if (+newValue < 0 || newValue === "") {
            onChange(+value < 10 ? "" : value);
            return;
          } else {
            onChange(parseInt(newValue).toString());
            return;
          }
        }

        if (maxLength) {
          if (newValue.length <= maxLength) {
            onChange(newValue);
          }
        } else {
          onChange(newValue);
        }
      } catch (error) {
        console.error(error);
      }
    },
    [maxLength, onChange, positive, type, value]
  );

  const handleBlur = useCallback(
    (event) => {
      !inputRef.current.value && setTopPlaceholder(false);
      setFocusInput(false);
      onBlur(event, value);
    },
    [onBlur, value]
  );

  const handleClear = () => {
    if (!disabled) {
      const input = inputRef.current.element
        ? inputRef.current.element
        : inputRef.current;
      onChange("", true);
      input.focus();
    }
  };

  const handleFocus = () => {
    setTopPlaceholder(true);
    setFocusInput(true);
  };

  const handleKeyboardEvents = useCallback(
    (event) => {
      if (onKeyUp) onKeyUp(event);
    },
    [onKeyUp]
  );

  const rightIconDisabled = () => {};

  return (
    <Wrapper>
      <Placeholder
        topPlaceholder={topPlaceholder}
        search={search}
        focusInput={focusInput}
        disabled={disabled}
      >
        <span>
          {placeholder}
          {required && <StarWrapper> *</StarWrapper>}
        </span>
      </Placeholder>
      {search && (
        <IconSearchWrapper>
          <SearchIcon />
        </IconSearchWrapper>
      )}
      {!!mask ? (
        <MaskedInputElement
          mask={mask}
          ref={inputRef}
          unmask={true}
          value={value}
          onFocus={handleFocus}
          onBlur={() => {
            !value && setTopPlaceholder(false);
            setFocusInput(false);
          }}
          onAccept={(value, mask) => onChange(value)}
          type={inputType ? inputType : type}
          field={type}
          width={width}
          search={search}
          disabled={disabled}
          height={height}
          rightIcon={rightIcon}
          error={error}
        />
      ) : (
        <InputElement
          id={inputId}
          ref={inputRef}
          padding={padding}
          type={inputType ? inputType : type}
          onBlur={handleBlur}
          onFocus={handleFocus}
          value={value === null ? "" : value}
          onChange={handleChange}
          onKeyUp={handleKeyboardEvents}
          field={type}
          width={width}
          search={search}
          disabled={disabled}
          height={height}
          rightIcon={rightIcon}
          error={error}
        />
      )}
      {/*{inputValue && withCloseIcon && (*/}
      {/*{value && withCloseIcon && (*/}
      <Actions>
        {value !== 0 && value && withCloseIcon && (
          <ActionIcon action={handleClear} size={24}>
            <CloseIcon />
          </ActionIcon>
        )}
        {value !== 0 && value && withCloseIcon && rightIcon && <Separator />}
        {rightIcon && (
          <ActionIcon
            action={rightIconAction ? rightIconAction : () => {}}
            disabled={!!rightActionDisabled}
            size={24}
          >
            {rightIcon}
          </ActionIcon>
        )}
      </Actions>

      {/*{type === 'password' && (*/}
      {/*  <IconWrapper onClick={togglePassword}>*/}
      {/*    <Icon color={'#fff'} opacity={0.7}>*/}
      {/*      {inputType === 'text' ? <EyeOffIcon /> : <EyeIcon />}*/}
      {/*    </Icon>*/}
      {/*  </IconWrapper>*/}
      {/*)}*/}
    </Wrapper>
  );
};

const MaskedInputElement: any = IMaskMixin(({ inputRef, ...props }) => (
  <InputElement {...props} ref={inputRef} />
));

const Separator = styled.div`
  width: 1px;
  height: 24px;
  background-color: ${theme.colors.neutral5};
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  position: absolute;
  top: 8px;
  right: 5px;
`;

const IconSearchWrapper = styled.div`
  width: 24px;
  height: 24px;
  position: absolute;
  top: 8px;
  left: 5px;
`;

const placeholderPosition = (top, search) => {
  switch (top) {
    case true:
      return "line-height: 12px; top: -6px; left: 4px; font-size: 12px;";
    default:
      return (
        "line-height: 20px; top: 10px; left: " + (!search ? "7px;" : "31px;")
      );
  }
};

const Placeholder = styled.div<{
  topPlaceholder: boolean;
  search: boolean;
  focusInput: boolean;
  disabled: boolean;
}>`
  position: absolute;
  pointer-events: none;
  color: ${({ focusInput }) =>
    !focusInput ? theme.colors.neutral6 : theme.colors.primaryDark};
  padding: 0 3px;
  ${({ topPlaceholder, search }) =>
    placeholderPosition(topPlaceholder, search)};
  transition: all 0.1s ease;
  white-space: nowrap;
  overflow: hidden;
  max-width: calc(100% - 38px);

  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    bottom: 0;
    background-color: ${({ disabled }) =>
      disabled ? theme.colors.neutral1 : theme.colors.inputBackgroundNormalState};
  }

  span {
    position: relative;
  }
`;

const Wrapper = styled.div`
  position: relative;
  font-family: ${theme.fonts.mainRegular};
  font-size: ${theme.fontSizes.body};

  path[fill] {
    fill: ${theme.colors.neutral7};
  }
`;

const InputElement: any = styled.input<{
  field: string;
  width: string;
  search: boolean;
  disabled: boolean;
  height: string;
  rightIcon: ReactElement;
  error?: string;
  padding?: string;
}>`
  border: ${({ error }) =>
    "1px solid " + (!error ? theme.colors.neutral7 : theme.colors.primary)};
  height: ${(props) => props.height};
  border-radius: 4px;
  outline: none;
  width: ${({ width }) => width};
  color: ${theme.colors.bodyText};
  background-color: ${({ disabled }) =>
    disabled ? theme.colors.neutral1 : theme.colors.inputBackgroundNormalState};
  padding: ${({ search, rightIcon, padding }) =>
    padding
      ? padding
      : !search
      ? "0 32px 0 10px"
      : !rightIcon
      ? "0 32px 0 34px"
      : "0 65px 0 34px"};

  &:hover {
    ${({ disabled }) =>
      !disabled && "border: 1px solid " + theme.colors.neutral10 + ";"}
  }

  &:focus {
    border: 1px solid ${theme.colors.primaryDark};
  }
`;

const StarWrapper = styled.span`
  color: ${theme.colors.primary};
`;

export const Input = withError(memo(InputComponent));
