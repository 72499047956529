import axios from "../middlewares/axios";
import dayjs from "dayjs";
import {BLOGGERS} from "../config/paths";
import moment from "moment/moment";

export async function getBloggers(limit, page, search, order, filters, exportParams) {
    const data = {
        limit,
        page,
        with: {
            relationships: ["restaurant", "partner", "city"]
        }
    };
    
    const config = {};
    
    if (exportParams) {
        data.export = {
            export_type: "xlsx",
            file_name: `Блогеры ${moment().format("YYYY-MM-DD HH:mm:ss")}`,
            fields: exportParams
        };
        data.return_type = "export";
        config.responseType = "arraybuffer";
    }
    
    if (order) {
        data.order = [order];
    }
    
    const filtersList = [];
    
    const filtersGroup = {
        type: "group",
        group: []
    };
    const searchGroup = {
        type: "group",
        group: []
    };
    
    if (filters) {
        if (filters.city && filters.city.length > 0) {
            filtersGroup.group.push({
                column: "cities.id",
                operator: "=",
                value: filters.city
            });
        }
        if (filters.restaurant && filters.restaurant.length > 0) {
            filtersGroup.group.push({
                column: "restaurant_id",
                operator: "=",
                value: filters.restaurant
            });
        }
        if (filters.partner && filters.partner.length > 0) {
            filtersGroup.group.push({
                column: "partner_id",
                operator: "=",
                value: filters.partner
            });
        }
        if (filters.dateRange.from && filters.dateRange.to) {
            filtersGroup.group.push({
                column: "created_at",
                operator: ">=",
                value: dayjs(filters.dateRange.from).format("YYYY-MM-DD HH:mm:ss")
            });
            filtersGroup.group.push({
                column: "created_at",
                operator: "<=",
                value: dayjs(filters.dateRange.to).format("YYYY-MM-DD HH:mm:ss"),
            });
        }
    
        filtersGroup.group.map((item, index) => {
            if (index !== 0) {
                item.boolean = "and";
            }
        });
    
        if (filtersGroup.group.length !== 0) {
            filtersList.push(filtersGroup);
        }
    }
    
    if (search) {
        const searchColumns = ["name", "cities.name_ru", "restaurants.name_ru"];
        searchColumns.map((column, index) => {
            const searchObject = {
                column,
                operator: "ilike",
                value: "%" + search + "%"
            };
            if (index !== 0) {
                searchObject.boolean = "or";
            }
            searchGroup.group.push(searchObject);
        });
    
        if (searchGroup.group.length !== 0) {
            filtersList.push(searchGroup);
        }
    }
    
    filtersList.map((item, index) => {
        if (index !== 0) {
            item.boolean = "and";
        }
    });
    
    if (filtersList.length !== 0) {
        data.filter = filtersList;
    }
    
    const response = await axios.post(BLOGGERS + "/search", data, config);
    return response.data;
}