import React, {FC, memo} from "react";
import styled from "styled-components";
import {theme} from "../../styles/theme";

interface Props {
    textAlign?: string;
    data: {
        [key: string]: string | number | boolean;
        textBold?: boolean;
        needBorderRight?: boolean;
    }[][];
}

const TableSummary: FC<Props> = memo(({
    data,
    textAlign = "left",
}) => {
    return (
        <>
            {data.map((row, rowIndex) => (
                <RowWrapper key={rowIndex}>
                    {row.map((element, elementIndex) => {
                        return (
                          <TableTD
                            key={elementIndex}
                            colSpan={element.colSpan}
                            textAlign={textAlign}
                            color={element.color}
                            textBold={element.textBold}
                            needBorderRight={element.needBorderRight}
                          >
                              {element.title}
                          </TableTD>
                        )
                    })}
                </RowWrapper>
            ))}
        </>
    );
});

const TableTD = styled.td<{
    textAlign?: string,
    colSpan: any,
    color: any,
    textBold?: boolean,
    needBorderRight?: boolean,
}>`
  text-align: ${props => props.textAlign};
  padding: 8px 10px;
  font-family: ${({textBold}) => textBold ? theme.fonts.mainBold : theme.fonts.mainRegular};
  ${props => props.color ? `color: ${props.color} !important;` : ""};
  ${({needBorderRight}) => needBorderRight && 'border-right: 1px solid ' + theme.colors.neutral3};
`;

const RowWrapper = styled.tr`
  td {
    font-size: ${theme.fontSizes.body};
    color: ${theme.colors.bodyText};
    border-bottom: 1px solid ${theme.colors.neutral3};

    &:not(.icons) {
      padding: 10px;
    }

    &.icons {
      & > div {
        opacity: 0;
      }
    }
  }

  &:hover {
    background: ${theme.colors.neutral2};

    td.icons {
      & > div {
        opacity: 1;
      }
    }
  }
`;

export default TableSummary;
