import moment from "moment/moment";
import React, {FC, memo, useCallback} from 'react';
import styled from "styled-components";
import CloseIcon from "../../assets/icons/CloseIcon";
import DoneIcon from "../../assets/icons/DoneIcon";
import {
    Cell,
    GridRowWrapper,
    FieldLabelWrapper,
    FieldValueWrapper,
    FieldValueBoldWrapper,
    RowIdWrapper,
    Block,
} from "../../styles/gridRowDefaults";
import {theme} from "../../styles/theme";

interface Props {
    data: {
        id: string,
        created_at: string,
        name: string,
        city: {
            id: string,
            name_ru: string
        },
        restaurant: {
            id: string,
            name_ru: string
        },
        phone: string,
        email: string,
        partner: {
            id: string,
            name: string
        },
        url: string,
        agreement: boolean
    }
}

const GridRow: FC<Props> = memo(({data}) => {
    const getIcon = useCallback((dataProperty) => {
        if (dataProperty) {
            return (
                <DoneIcon
                    width="16"
                    height="16"
                    fill={theme.colors.green}
                />
            );
        } else {
            return (
                <CloseIcon
                    width="16"
                    height="16"
                    fill={theme.colors.primary}
                />
            );
        }
    }, []);
    
    return (
        <GridRowWrapper noCursorPointer={true}>
            <Block>
                <FirstCellTopRowWrapper className="cell-row">
                    <RowIdWrapper>ID {data.id}</RowIdWrapper>
                    <FieldValueBoldWrapper>{moment(data.created_at).format("DD.MM.YYYY")}</FieldValueBoldWrapper>
                    <FieldValueBoldWrapper>{data.name}</FieldValueBoldWrapper>
                </FirstCellTopRowWrapper>
            </Block>

            <Row>
                <Cell setFlex={'0 0 10%'}>
                    <div className="cell-row">
                        <FieldLabelWrapper>Город</FieldLabelWrapper>
                        <FieldValueWrapper>{data?.city?.name_ru}</FieldValueWrapper>
                    </div>
                </Cell>

                <Cell setFlex={'0 0 15%'}>
                    <div className="cell-row">
                        <FieldLabelWrapper>Ресторан</FieldLabelWrapper>
                        <FieldValueWrapper>{data?.restaurant?.name_ru}</FieldValueWrapper>
                    </div>
                </Cell>

                <Cell setFlex={'0 0 140px'}>
                    <div className="cell-row">
                        <FieldLabelWrapper>Телефон</FieldLabelWrapper>
                        <FieldValueWrapper>+{data.phone}</FieldValueWrapper>
                    </div>
                </Cell>

                <Cell setFlex={'0 0 300px'}>
                    <div className="cell-row">
                        <FieldLabelWrapper>Email</FieldLabelWrapper>
                        <FieldValueWrapper>{data.email}</FieldValueWrapper>
                    </div>
                </Cell>

                <Cell setFlex={'0 0 150px'}>
                    <div className="cell-row">
                        <FieldLabelWrapper>Партнер</FieldLabelWrapper>
                        <FieldValueWrapper>{data?.partner?.name}</FieldValueWrapper>
                    </div>
                </Cell>

                <Cell setFlex={'1 1 auto'}>
                    <div className="cell-row">
                        <FieldLabelWrapper>URL</FieldLabelWrapper>
                        <FieldValueWrapper>{data.url}</FieldValueWrapper>
                    </div>
                </Cell>

                <Cell setFlex={'0 0 54px'}>
                    <IconCellRowWrapper className="cell-row">
                        <FieldLabelWrapper>Согласие</FieldLabelWrapper>
                        {getIcon(data.agreement)}
                    </IconCellRowWrapper>
                </Cell>
            </Row>
        </GridRowWrapper>
    );
});

const Row = styled(Block)`
  @media (max-width: 1230px) {
      flex-wrap: wrap;
      
      &>div {
          &:nth-child(1),
          &:nth-child(2){
              min-width: 200px;
              flex: unset;
          }
          &:nth-child(6) {
              min-width: 410px;
              flex-grow: 0;
          }
      }
  }
    
  @media (max-width: 1042px) {
      &>div {
          &:nth-child(5) {
              min-width: 200px;
          }
          &:nth-child(6) {
              min-width: 350px;
          }
      }
  }
    
    @media (max-width: 920px) {
        &>div {
            &:nth-child(6) {
                max-width: 305px;
            }
        }
    }
    
    @media (max-width: 882px) {
        &>div {
            &:nth-child(4) {
                min-width: 200px;
            }

            &:nth-child(6) {
                min-width: 300px;
            }
        }
    }
    
    @media (max-width: 773px) {
        &>div {
            &:nth-child(2),
            &:nth-child(6){
                min-width: 200px;
            }
        }
    }
    
    @media (max-width: 639px) {
        &>div {
            &:nth-child(3) {
                min-width: 200px;
            }
        }
    }
    
    @media (max-width: 461px) {
        &>div {
            min-width: 100%;
            &:last-child {
                &>div {
                    align-items: flex-start;
                }
            }
        }
    }
    
`;

const FirstCellTopRowWrapper = styled.div`
  display: flex;
  gap: 15px;
`;

const IconCellRowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default GridRow;