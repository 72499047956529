import { makeAutoObservable } from "mobx";
import { createContext } from "react";
import {
    captchaCheck,
    loginKeycloak,
    getCaptcha,
    getUserData,
    logoutSso,
    refreshTokenRequest,
    logoutKeycloak,
} from "../actions/auth";
import axios from "../middlewares/axios";
import { history } from "../routes/history";
import {getCookie} from "../utils/getCookie";

class Auth {
    constructor() {
        makeAutoObservable(this);
    }

    isAuth = false;
    authPending = true;
    userData = {};
    captchaImg = "";
    captchaKey = "";
    isRemember = getCookie("is_remember");
    refreshTimerId;

    login = async (email, password, is_remember) => {
        this.isRemember = is_remember;

        const response = await loginKeycloak(email, password);
        await this.handleAuthResponse(response);
        if(response.access_token) {
            history.push("/");
        }
        return response;
    };

    getCaptchaImg = async () => {
        return  await getCaptcha();
    }

    checkCaptcha = async (value, key) => {
        return await captchaCheck(value, key)
    }

    setCaptchaImg = (img) => this.captchaImg = img;

    setCaptchaKey = (key) => this.captchaKey = key;
    
    handleAuthResponse = async (response, fromSetTimeout = false) => {
        if (response?.access_token) {
            this.runTokenUpdate(response);

            const token = "Bearer " + response.access_token;
            this.setCookie(response);
            axios.defaults.headers["authorization"] = token;
            axios.defaults.headers["x-token-expires-at"] = Math.round(Date.now() / 1000) + response.expires_in;

            if(!fromSetTimeout) {
                await this.checkAuth();
            }
        } else {
            this.isAuth = false;
            this.userData = {};
            this.authPending = false;
        }
    };

    runTokenUpdate = (response) => {
        this.refreshTimerId = setTimeout(() => {
            this.checkAuthKeycloak(true)
        }, response.expires_in * 950)
    }

    setCookie = (response) => {
        const actualDate = new Date();
        let endOfDayDate = new Date(actualDate.getFullYear()
                                          ,actualDate.getMonth()
                                          ,actualDate.getDate()
                                          ,23,59,59);
        endOfDayDate = endOfDayDate.toUTCString()

        if(this.isRemember) {
            document.cookie = `is_remember=true; max-age=${response.refresh_expires_in}; path=/`;
            document.cookie = `refresh_token=${response.refresh_token}; max-age=${response.refresh_expires_in}; path=/`;
        } else {
            document.cookie = `is_remember=false; max-age=0; path=/`;
            document.cookie = `refresh_token=${response.refresh_token}; expires=${endOfDayDate}; path=/`;
        }
    }

    checkAuth = async () => {
        this.authPending = true;
        const response = await getUserData();

        if (response.data) {
            this.isAuth = true;
            this.userData = response.data.data;
        } else {
            this.isAuth = false;
            this.userData = {};
        }

        this.authPending = false;
    };

    checkAuthKeycloak = async (fromSetTimeout = false) => {
        const response = await refreshTokenRequest();
        this.handleAuthResponse(response, fromSetTimeout);
    }

    updateUserData = (newUserData) => {
        this.userData = newUserData;
    }

    logout = async () => {
        this.authPending = true;
        const response = await logoutKeycloak();

        if(response.status === 204) {
            logoutSso();
            this.isAuth = false;
            this.userData = {};
            clearTimeout(this.refreshTimerId);
        }

        this.authPending = false;
    };

    get userRestaurants() {
        if (this.isHrPartner) {
            return this.userData.partner_restaurants;
        }
        return this.userData.restaurants;
    }

    get isSuperAdmin() {
        return this.userData.role.name === "superadmin";
    }

    get isHrPartner() {
        return this.userData.role.name === "hr_partner";
    }

    get isCallCenterOperator() {
        return this.userData.role.name === "call_center_operator";
    }
}

export const AuthStore = new Auth();
export const AuthStoreContext = createContext(AuthStore);
