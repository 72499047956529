import React, {FC, memo, useCallback, useRef, useState} from "react";
import styled from "styled-components";
import ChevronIcon from "../../../../assets/icons/ChevronIcon";
import {theme} from "../../../../styles/theme";
import Options from "./Options";

interface Props {
    menuOpen: boolean;
    title: string;
    path: string;
    icon: any;
    options: {
        exact: boolean;
        path: string;
        title: string;
    }[];
    isAdaptive?: boolean;
    toggleOpen?: () => void;
}

const MenuItemOptions: FC<Props> = memo(({
    menuOpen,
    title,
    path,
    icon,
    options,
    isAdaptive,
    toggleOpen
}) => {
    const [optionsOpen, setOptionsOpen] = useState(false);
    const [optionsPosition, setOptionsPosition] = useState({left: 0, top: 0});
    const itemRef = useRef(null);
    const IconComponent = icon;
    
    const itemClickHandle = useCallback(() => {
        setOptionsOpen(prevState => {
            return !prevState;
        });
        const rect = itemRef.current.getBoundingClientRect();
        setOptionsPosition({left: rect.right + 10, top: rect.top});
    }, []);

    const handleChevronDirection = () => {
        if(!isAdaptive) {
            if(optionsOpen) {
                return "left"
            } else {
                return "right"
            }
        } else {
            if(optionsOpen) {
                return "top"
            } else {
                return "bottom"
            }
        }
    }
    
    return (
        <>
            <ItemWrapper
                ref={itemRef}
                menuOpen={menuOpen}
                className={window.location.href.includes(path) ? "active-menu-item" : ""}
                onClick={itemClickHandle}
            >
                <LeftSideWrapper>
                    <IconWrapper>
                        <IconComponent
                            width="24"
                            height="24"
                            fill={theme.colors.neutral7}
                        />
                    </IconWrapper>
                    <Title>{title}</Title>
                </LeftSideWrapper>
                <ChevronIcon
                    direction={handleChevronDirection()}
                    fill={window.location.pathname.includes(path) ? "white" : theme.colors.neutral7}
                />
            </ItemWrapper>
    
            {optionsOpen &&
                <Options
                    options={options}
                    itemRef={itemRef}
                    position={optionsPosition}
                    onClick={() => isAdaptive && toggleOpen()}
                    onClose={() => setOptionsOpen(false)}
                />
            }
        </>
    );
});

const Title = styled.div`
  font-size: 13px;
  ${({theme}) => `
    font-family: ${theme.fonts.mainBlack};
    color: ${theme.colors.neutral7};
  `}
`;

const IconWrapper = styled.div`
  margin-right: 16px;

  svg path {
    fill: ${({theme}) => theme.colors.neutral7};
  }
`;

const ItemWrapper = styled.div<{menuOpen: boolean}>`
  justify-content: space-between;
  margin-bottom: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 4px;
  overflow: hidden;
  text-decoration: none;

  ${({menuOpen}) => !menuOpen && 'height: 44px;'}
  &:hover {
    background-color: ${({theme}) => theme.colors.neutral2};
  }

  &.active-menu-item {
    background-color: ${({theme}) => theme.colors.primary};

    ${Title} {
      color: #ffffff;
    }

    ${IconWrapper} {
      svg path {
        fill: #ffffff;
      }
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

const LeftSideWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export default MenuItemOptions;