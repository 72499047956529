import React, {FC, memo} from "react";
import styled from "styled-components";
import {theme} from "../../styles/theme";

interface Props {
    textAlign?: string;
    fields: {
        label: string;
        field?: string;
        type: string;
        component?: FC;
        needBorderRight?: boolean;
        font?: string;
        textAlign?: string;
    }[];
    headerSubtitles?: {
        title: string,
        colSpan: number,
        font?: string,
    }[];
}

const TableHeader: FC<Props> = memo(({
    fields,
    headerSubtitles,
    textAlign,
}) => {
    return (
        <>
            {headerSubtitles &&
                <tr>
                    {headerSubtitles.map((subtitle, index) =>
                        <TableTD
                            key={index}
                            colSpan={subtitle.colSpan}
                            textAlign="center"
                            needBorderRight={true}
                            font={subtitle.font}
                        >
                            {subtitle.title}
                        </TableTD>
                    )}
                </tr>
            }
            <tr>
                {fields.map((field, index) => (
                    <TableTD
                      key={index}
                      needBorderRight={field.needBorderRight}
                      textAlign={textAlign || field.textAlign}
                      font={field.font}
                    >
                        {field.label}
                    </TableTD>
                ))}
            </tr>
        </>
    );
});

const TableTD = styled.th<{
    textAlign?: string,
    needBorderRight: boolean,
    font?: string,
}>`
  text-align: ${props => props.textAlign ? props.textAlign : "left"};
  padding: 8px 10px;
  ${({needBorderRight}) => needBorderRight && 'border-right: 1px solid ' + theme.colors.neutral3};
  ${({font}) => font && 'font-family: ' + theme.fonts.condensedRegular};
`;

export default TableHeader;