import TableInput from "../../../pages/Plr/TableInput";


const tableFields = [
    {
        label: "Смены",
        field: "name",
        type: "string"
    },
    {
        label: "Потребность в часах",
        field: "need_hour",
        type: "component-input",
        component: TableInput
    },
    {
        label: "Потребность, чел.",
        field: "need_people",
        type: "string"
    }
];

export default tableFields;
