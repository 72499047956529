import { Controller } from "react-hook-form";
import { Input } from "../../../../../components/Input";
import styled from "styled-components";
import React, {FC} from "react";
import Select from "../../../../../components/Select";
import {TextArea} from "../../../../../components/TextArea";
import {ISelectOption, MATERIALS_VALUE} from "../../const";

interface Props {
  printedProduct: ISelectOption[],
  posterSize: ISelectOption[],
  control: any;
}

const MaterialFields: FC<Props> = ({
  printedProduct,
  posterSize,
  control
}) => {
    return (
        <>
            <Controller
                render={({ field, fieldState }) => {
                    return (
                        <Select
                          title="Печатная продукция"
                          options={printedProduct}
                          {...field}
                          {...fieldState}
                          ref={null}
                        />
                    );
                }}
                name={`${MATERIALS_VALUE}.type`}
                control={control}
            />
            <Row>
                <Cell width={"50%"}>
                    <Controller
                        render={({ field, fieldState }) => {
                            return (
                                <Select
                                    title="Размер"
                                    options={posterSize}
                                    {...field}
                                    {...fieldState}
                                    ref={null}
                                />
                            );
                        }}
                        name={`${MATERIALS_VALUE}.size`}
                        control={control}
                    />
                </Cell>
                <Cell width={"50%"}>
                    <Controller
                        render={({ field, fieldState }) => {
                            return (
                                <Input
                                    placeholder="Количество"
                                    type="number"
                                    positive={true}
                                    {...field}
                                    {...fieldState}
                                    onChange={(value) => {
                                      if(Number(value) > 100000000) return;
                                      field.onChange(value);
                                    }}
                                    ref={null}
                                />
                            );
                        }}
                        name={`${MATERIALS_VALUE}.count`}
                        control={control}
                    />
                </Cell>
            </Row>
            <Controller
                render={({ field, fieldState }) => {
                    return (
                        <TextArea
                            placeholder="Комментарий"
                            {...field}
                            {...fieldState}
                            ref={null}
                        />
                    );
                }}
                name={`${MATERIALS_VALUE}.comment`}
                control={control}
            />
        </>
    );
};

const Cell = styled.div<{margin?: string, width?: string,}>`
  width: ${({width}) => width ? width : '100%'};
  margin: ${({margin}) => margin ? margin : 0};
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 16px;
`;

export default MaterialFields;
