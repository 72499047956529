import React, {FC, memo, useCallback, useContext} from "react";
import styled from "styled-components";
import _get from "lodash/get";
import {
  Cell,
  GridRowWrapper,
  FieldLabelWrapper,
  FieldValueWrapper,
  FieldValueBoldWrapper,
  RowIdWrapper,
  Block,
  FieldValueGreenWrapper,
  FieldValueRedWrapper,
} from "../../../../styles/gridRowDefaults";
import moment from "moment";
import {formatPhoneNumber} from "../../../../utils/formatPhoneNumber";
import {AuthStoreContext} from "../../../../stores/auth";

interface Props {
  data: {
    id: string;
    first_name: string;
    second_name: string;
    candidate_profile_status: {
      comment: string;
      name: string;
    };
    candidate_interview: {
      created_at: string;
      created_by: string;
      name: string;
      id: string;
    };
    is_auto_dialing: boolean;
    city: {
      name_ru: string;
    };
    source: {
      name: string;
    };
    restaurant: {
      name: string;
      tariff_id: number;
    };
    vacancy: {
      id: number;
      name: string;
    };
    is_interrogation: string;
    age: string;
    citizenship: {
      name: string;
    };
  };
  onRowClick?: (data: object) => void;
  onInterviewIconClick?: (data: object) => void;
}

const GridRow: FC<Props> = memo(
  ({ data, onRowClick, onInterviewIconClick }) => {
    const {userData} = useContext(AuthStoreContext);

    const getColoredStatusValue = useCallback((data) => {
      if (_get(data, "candidate_interview")) {
        return (
          <FieldValueGreenWrapper>
            {_get(data, "candidate_profile_status.name", "")}
          </FieldValueGreenWrapper>
        );
      } else {
        return (
          <FieldValueRedWrapper>
            {_get(data, "candidate_profile_status.name", "")}
          </FieldValueRedWrapper>
        );
      }
    }, []);

    const getColoredSurveyValue = useCallback((data) => {
      const survey = data.count_tmri;
      if (survey && survey[0] !== '0') {
        return <FieldValueGreenWrapper>Пройден</FieldValueGreenWrapper>;
      } else {
        return <FieldValueRedWrapper>Не пройден</FieldValueRedWrapper>;
      }
    }, []);

    const getAutoCallText = (data) => {
      return !!data.auto_redial_status
        ? data.auto_redial_status
        : "-";
    };

    const convertDate = (date) => {
      if (date) {
        return moment(date, "YYYY-MM-DD HH:mm:ss").format("DD.MM.YYYY HH:mm");
      }
      return "-";
    };

    return (
      <GridRowWrapper onClick={() => onRowClick(data)}>
        <Block>
          <FirstCellTopRowWrapper className="cell-row">
            <RowIdWrapper>ID{data.id}</RowIdWrapper>
            <FieldValueBoldWrapper>
              {convertDate(_get(data, "created_at"))}
            </FieldValueBoldWrapper>
            <FieldValueBoldWrapper>
              {data.first_name + " " + (data.second_name || "")}
            </FieldValueBoldWrapper>
          </FirstCellTopRowWrapper>
        </Block>
        <BlockRow>
          <Cell setFlex={"0 0 30%"} minWidth={"268px"}>
            <div className="cell-row">
              <FieldLabelWrapper>Статус</FieldLabelWrapper>
              {getColoredStatusValue(data)}
            </div>

            {(userData.is_access_to_auto_call_status ||
              data.restaurant.tariff_id === 2 ||
              data.restaurant.tariff_id === 3) &&
              <div className="cell-row">
                <FieldLabelWrapper>Статус авто-обзвона</FieldLabelWrapper>
                <FieldValueWrapper>{getAutoCallText(data)}</FieldValueWrapper>
              </div>
            }
          </Cell>

          <Cell setFlex={"0 0 15%"}>
            <div className="cell-row">
              <FieldLabelWrapper>Город</FieldLabelWrapper>
              <FieldValueWrapper>
                {_get(data, "city.name_ru", "-")}
              </FieldValueWrapper>
            </div>

            <div className="cell-row">
              <FieldLabelWrapper>Телефон</FieldLabelWrapper>
              <FieldValueWrapper>{formatPhoneNumber(data, "phone")}</FieldValueWrapper>
            </div>
          </Cell>

          <Cell setFlex={"0 0 20%"}>
            <div className="cell-row">
              <FieldLabelWrapper>Ресторан</FieldLabelWrapper>
              <FieldValueWrapper>
                {_get(data, "restaurant.name_ru", "-")}
              </FieldValueWrapper>
            </div>

            <div className="cell-row">
              <FieldLabelWrapper>Email</FieldLabelWrapper>
              <FieldValueWrapper>{_get(data, "email", "-")}</FieldValueWrapper>
            </div>
          </Cell>

          <Cell setFlex={"0 0 15%"}>
            <div className="cell-row">
              <FieldLabelWrapper>Вакансия</FieldLabelWrapper>
              <FieldValueWrapper>
                {data.vacancy
                  ? data.vacancy.name
                  : "-"}
              </FieldValueWrapper>
            </div>

            <div className="cell-row">
              <FieldLabelWrapper>Дата собеседования</FieldLabelWrapper>
              <FieldValueWrapper>
                {convertDate(_get(data, "interview.interview_date"))}
              </FieldValueWrapper>
            </div>
          </Cell>

          <Cell>
            {/*<LastCellTopRow className="icon">*/}
            {/*  <ActionIcon*/}
            {/*    action={() => console.log("DeleteIcon click", data)}*/}
            {/*    tooltip="Удалить анкету"*/}
            {/*    size={24}*/}
            {/*  >*/}
            {/*    <DeleteIcon*/}
            {/*      width="18"*/}
            {/*      height="18"*/}
            {/*      fill={theme.colors.neutral7}*/}
            {/*    />*/}
            {/*  </ActionIcon>*/}
            {/*</LastCellTopRow>*/}

            <LastCellMiddleRowWrapper>
              <div className="cell-row">
                <FieldLabelWrapper>Возраст</FieldLabelWrapper>
                <FieldValueWrapper>{data.age}</FieldValueWrapper>
              </div>

              <div className="cell-row">
                <FieldLabelWrapper>Гражданство</FieldLabelWrapper>
                <FieldValueWrapper>
                  {_get(data, "citizenship.name", "-")}
                </FieldValueWrapper>
              </div>
            </LastCellMiddleRowWrapper>

            <div className="cell-row">
              <SurveyRowWrapper>
                <FieldLabelWrapper style={{ marginBottom: 0 }}>
                  Опрос
                </FieldLabelWrapper>
              </SurveyRowWrapper>
              {getColoredSurveyValue(data)}
            </div>
          </Cell>
        </BlockRow>
      </GridRowWrapper>
    );
  }
);

const BlockRow = styled(Block)`

  @media (max-width: 1023px) {
    flex-wrap: wrap;
    &>div {
      flex-shrink: 0;
      &:first-child {
        flex-grow: 1;
        
      }
      &:nth-child(2) {
        min-width: 120px;
      }
      &:nth-child(4) {
        min-width: 124px;
      }
    }
  }

  @media (max-width: 800px) {
    &>div {
      &:first-child,
      &:nth-child(2),
      &:nth-child(3){
        min-width: calc((100% - 10px) / 2);
      }
  }
`;

const FirstCellTopRowWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

const SurveyRowWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  gap: 3px;
`;

const LastCellTopRow = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const LastCellMiddleRowWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

export default GridRow;
