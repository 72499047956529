import dayjs from "dayjs";
import React, {useState} from 'react';
import styled from "styled-components";
import Datepicker from "../../components/Datepicker";
import InputAutocomplete from "../../components/InputAutocomplete";
import {getCitizenshipList} from "../../actions/directory";
import Rangepicker from "../../components/Rangepicker";
import moment from "moment";

const Playground = () => {
    const [fieldValue, setFieldValue] = useState("");
    const [searchField, setSearchField] = useState("name"); // поле, по которому будет поиск

    const [datepickerValue, setDatepickerValue] = useState(moment());

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const _onChange = (start, end) => {
    setStartDate(start);
    setEndDate(end);
  };

    return (
    <div>
      <InputAutocomplete
        value={fieldValue}
        searchField={searchField}
        setAutocompletedValue={(value) => setFieldValue(value)}
        getData={getCitizenshipList}
      />
    {/*<MultiSelect*/}
            {/*    title="Рестораны"*/}
            {/*    options={multiSelectSubOptions}*/}
            {/*    withSubOptions={true}*/}
            {/*    value={multiSelectValue}*/}
            {/*    onChange={setMultiSelectValue}*/}
            {/*/>*/}

            <DatepickerWrapper>
                <Datepicker
                    title="Выбрать дату"
                    format={"MM.yyyy"}
                    value={datepickerValue}
                    onChange={(date) => setDatepickerValue(date)}
                />
            </DatepickerWrapper>

      {/*<>*/}
      {/*  <Datepicker*/}
      {/*    selected={startDate}*/}
      {/*    onChange={(date) => setStartDate(date)}*/}
      {/*    selectsStart*/}
      {/*    startDate={startDate}*/}
      {/*    endDate={endDate}*/}
      {/*  />*/}
      {/*  <Datepicker*/}
      {/*    selected={endDate}*/}
      {/*    onChange={(date) => setEndDate(date)}*/}
      {/*    selectsEnd*/}
      {/*    startDate={startDate}*/}
      {/*    endDate={endDate}*/}
      {/*    minDate={startDate}*/}
      {/*  />*/}
      {/*</>*/}

      {/*<DatepickerWrapper>*/}
      {/*  <Datepicker*/}
      {/*    value={startDate}*/}
      {/*    onChange={onChange}*/}
      {/*    startDate={startDate}*/}
      {/*    endDate={endDate}*/}
      {/*    selectsRange*/}
      {/*    inline*/}
      {/*  />*/}
      {/*</DatepickerWrapper>*/}

            <RangePickerWrapper>
              <Rangepicker
                startDate={startDate
                  ? moment(startDate, "YYYY-MM-DD HH:mm:ss")
                  : null
                }
                endDate={endDate
                  ? moment(endDate, "YYYY-MM-DD HH:mm:ss")
                  : null
                }
                onChange={(from, to) => _onChange(from, to)}
                title="С - по"
              />
            </RangePickerWrapper>
        </div>
    );
};

const DatepickerWrapper = styled.div`
  margin-bottom: 20px;
  width: 268px;
`;

const RangePickerWrapper = styled.div`
  margin-bottom: 20px;
  width: 268px;
`;

export default Playground;