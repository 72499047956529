import React, {FC, memo} from "react";
import styled from 'styled-components';
import ListItem from "./ListItem";
import ContentWrapper from "../../../../components/ContentWrapper";
import PaginationGroup from "../../../../components/PaginationGroup";

interface Props {
  data: any;
  setPage: (string) => void;
  setLimit: (string) => void;
}

const List: FC<Props> = memo(({data, setLimit, setPage}) => {

  return (
    <Wrapper>
      {data &&
        <>
          <ListWrapper>
            {
              data?.data?.map((item, i) => {
                return <ListItem key={i} item={item}/>
              })
            }
          </ListWrapper>
          <PaginationGroup
            meta={data?.meta}
            setLimit={setLimit}
            setPage={setPage}
          />
        </>
      }
    </Wrapper>
  );
});

const ListWrapper = styled(ContentWrapper)`
  margin-bottom: 16px;
`;

const Wrapper = styled.div`
  width: 100%;
  height: calc(100% - 56px);
  display: flex;
  flex-direction: column;
`;

export default List;
