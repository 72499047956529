import { MAX_LIMIT } from "../config/consts";
import axios from "../middlewares/axios";
import {
  GET_CITIZENSHIP_LIST,
  GET_CANDIDATE_PROFILE_STATUSES_LIST,
  GET_PARTNERS_LIST,
  GET_SOURCES_LIST,
  GET_TARIFFS_LIST,
  GET_ROLES_LIST,
  GET_VACANCIES_LIST,
  GET_ALL_RESTAURANTS_LIST,
  GET_ALL_CITIES_LIST, GET_COUNTRIES_LIST, GET_REJECTS_LIST, GET_STATUS_DETAILS_LIST
} from "../config/paths";

export async function getCitizenshipList() {
  const response = await axios.post(GET_CITIZENSHIP_LIST, {
    with: {
      relationships: [],
    },
    limit: MAX_LIMIT,
  });

  return response.data;
}

export async function getCandidateProfileStatusesList() {
  const response = await axios.post(GET_CANDIDATE_PROFILE_STATUSES_LIST, {
    with: {
      relationships: [],
    },
    limit: MAX_LIMIT,
  });

  return response.data;
}

export async function getCitiesList() {
  const response = await axios.get(GET_ALL_CITIES_LIST);

  return response.data;
}

export async function getPartnersList() {
  const response = await axios.post(GET_PARTNERS_LIST, {
    with: {
      relationships: [],
    },
    limit: MAX_LIMIT,
  });

  return response.data;
}

export async function getRestaurantsList() {
  const response = await axios.get(GET_ALL_RESTAURANTS_LIST);

  return response.data;
}

export async function getCountriesList() {
  const response = await axios.get(GET_COUNTRIES_LIST+ "?limit=" + MAX_LIMIT);

  return response.data;
}

export async function getVacanciesList() {
  const response = await axios.get(GET_VACANCIES_LIST + "?limit=" + MAX_LIMIT);

  return response.data;
}

export async function getSourcesList() {
  const response = await axios.post(GET_SOURCES_LIST, {
    with: {
      relationships: ["restaurants", "tariffs", "source", "sourceResources"],
      properties: []
    },
    limit: MAX_LIMIT,
  });

  return response.data;
}

export async function getTariffsList() {
  const response = await axios.post(GET_TARIFFS_LIST, {
    with: {
      relationships: ["restaurants", "roles"],
    },
    limit: MAX_LIMIT,
  });

  return response.data;
}

export async function getRolesList() {
  const response = await axios.post(GET_ROLES_LIST, {
    limit: MAX_LIMIT,
  });

  return response.data;
}

export async function getRejectsList() {
  const response = await axios.post(GET_REJECTS_LIST, {
    limit: MAX_LIMIT,
  });

  return response.data;
}

export async function getStatusDetailsList() {
  const response = await axios.post(GET_STATUS_DETAILS_LIST, {
    limit: MAX_LIMIT,
  });

  return response.data;
}
