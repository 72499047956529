import React, {FC, useCallback, useEffect, useRef, useState} from "react";
import styled from "styled-components";
import {theme} from "../../styles/theme";
import ChevronIcon from "../../assets/icons/ChevronIcon";
import CloseIcon from "../../assets/icons/CloseIcon";
import InfoIcon from "../../assets/icons/InfoIcon";
import ReactTooltip from "react-tooltip";
import sortOptions from "../../utils/sortOptions";
import {withError} from "../../hocs/Error";
import OptionsBlock from "./OptionsBlock";

interface Props {
    title?: string;
    options: {
        label: string;
        value: string | number;
    }[];
    value?: string;
    closeIcon?: boolean;
    onChange?: (string: string) => void;
    disabled?: boolean;
    size?: "primary" | "small";
    optionsDirection?: "top" | "bottom";
    width?: string;
    help?: string;
    ref?: any;
    noBorder?: boolean;
    withSearch?: boolean;
    withSorting?: boolean;
    required?: boolean;
    error?: string;
}

const Select: FC<Props> = ({
    title,
    options,
    value,
    closeIcon = false,
    onChange,
    disabled = false,
    size = "primary",
    optionsDirection = "bottom",
    width,
    help,
    noBorder = false,
    withSearch = false,
    withSorting = false,
    required = false,
    error
}) => {
    const selectWrapperRef = useRef();
    const [optionsOpen, setOptionsOpen] = useState(false);
    const [filteredOptions, setFilteredOptions] = useState(
        withSorting ? sortOptions(options, "label", false) : options
    );
    const [filterInputValue, setFilterInputValue] = useState("");

    useEffect(() => {
        const newOptions = options.filter(item => {
            const labelLower = item.label?.toLowerCase();
            const searchLower = filterInputValue?.toLowerCase();
            return labelLower.includes(searchLower);
        });

        setFilteredOptions(
            withSorting ? sortOptions(newOptions, "label", false) : newOptions
        );
    }, [filterInputValue, options]);

    const headerClickHandle = useCallback(() => {
        if (!disabled) {
            setOptionsOpen((prevState) => {
                return !prevState;
            });
        }
    }, [disabled]);

    const optionSelectHandle = useCallback((value) => {
        if (!disabled) {
            onChange(value);
            setOptionsOpen(false);
        }
    }, [onChange, disabled]);

    const clickCloseHandle = useCallback((event) => {
        event.stopPropagation();
        onChange("");
    }, [onChange]);

    const onChangeInputHandle = (value) => {
        setFilterInputValue(value);
        onChange("");
    }

    return (
        <SelectWrapper ref={selectWrapperRef} size={size} width={width}>
            {title &&
              <SelectTitle optionsOpen={optionsOpen} disabled={disabled}>
                  <span>{title}{required && <StarWrapper> *</StarWrapper>}</span>
              </SelectTitle>}

            <SelectHeader
                noBorder={noBorder}
                onClick={() => headerClickHandle()}
                value={
                    filteredOptions.find((item) => item.value === value)
                        ? filteredOptions.find((item) => item.value === value).value
                        : undefined
                }
                disabled={disabled}
                optionsOpen={optionsOpen}
                size={size}
                error={error}
            >
                <SelectValueWrapper>
                    {filteredOptions.find((item) => item.value === value)
                        ? filteredOptions.find((item) => item.value === value).label
                        : value}
                </SelectValueWrapper>

                {!noBorder && (
                    <IconsWrapper>
                        {closeIcon && <CloseIcon onClick={clickCloseHandle}/>}
                        {help && (
                            <>
                                <IconHelpWrapper data-tip data-for={"help-tooltip"}>
                                    <InfoIcon fill={theme.colors.primary}/>
                                </IconHelpWrapper>
                                <ReactTooltip id={"help-tooltip"}>
                                    <HelpTextWrapper>{help}</HelpTextWrapper>
                                </ReactTooltip>
                            </>
                        )}
                        <ChevronIcon direction={optionsOpen ? "top" : "bottom"}/>
                    </IconsWrapper>
                )}
            </SelectHeader>

            {optionsOpen &&
              <OptionsBlock
                filterInputValue={filterInputValue}
                selectWrapperRef={selectWrapperRef}
                value={value}
                optionsDirection={optionsDirection}
                size={size}
                withSearch={withSearch}
                filteredOptions={filteredOptions}
                onChangeInputHandle={onChangeInputHandle}
                optionSelectHandle={optionSelectHandle}
                setOptionsOpen={setOptionsOpen}
              />
            }
        </SelectWrapper>
    );
};

const SelectWrapper = styled.div<{size: string; width: string}>`
  position: relative;
  width: ${({width}) => (width ? width : "100%")};
  user-select: none;
  height: ${({size}) => (size === "primary" ? 40 : 32)}px;
`;

const IconHelpWrapper = styled.div`
  width: 24px;
  height: 24px;

  svg > g > path {
    fill: ${({theme}) => theme.colors.primary};
  }
`;

const HelpTextWrapper = styled.div`
  max-width: 320px;
`;

const SelectTitle = styled.div<{optionsOpen: boolean, disabled: boolean,}>`
  position: absolute;
  top: -9px;
  left: 10px;
  padding: 0 3px;
  z-index: 1;
  font-size: ${({theme}) => theme.fontSizes.small};
  font-family: ${({theme}) => theme.fonts.mainRegular};
  color: ${({optionsOpen, theme}) =>
          !optionsOpen ? theme.colors.neutral6 : theme.colors.primaryDark};
  &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      bottom: 0;
      background-color: ${({disabled}) => disabled ? theme.colors.neutral1 : "#ffffff"};
  }
  span {
      position: relative;
  }
`;

const SelectHeader = styled.div<{
    value: string;
    disabled: boolean;
    optionsOpen: boolean;
    noBorder: boolean;
    size: string;
    error: string;
}>`
  display: flex;
  background-color: ${(props) => props.disabled ? "#F8F9FA" : ""};
  justify-content: ${(props) =>
          props.value === "" ? "flex-end" : "space-between"};
  align-items: center;
  box-sizing: border-box;
  padding: 10px;
  border: ${({noBorder, optionsOpen, theme}) =>
          noBorder
                  ? "none"
                  : `1px solid ${
                          !optionsOpen ? theme.colors.neutral7 : theme.colors.primaryDark
                  } `};
  font-size: ${({theme}) => theme.fontSizes.body};
  font-family: ${({theme}) => theme.fonts.mainRegular};
  border-radius: 4px;
  line-height: 20px;
  height: ${({size}) => (size === "primary" ? 40 : 32)}px;
  background-color: ${({disabled}) => disabled ? theme.colors.neutral1 : "#ffffff"};

  &:hover {
    ${({disabled, theme, optionsOpen}) =>
            !disabled &&
            !optionsOpen &&
            `
        border: 1px solid` +
            theme.colors.neutral10 +
            `;`}
  }

  ${({disabled}) =>
          !disabled &&
          `
    cursor: pointer;
  `}
`;

const SelectValueWrapper = styled.div`
  width: calc(100% - 24px);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const IconsWrapper = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: 5px;
  cursor: pointer;

  path {
    fill: ${({theme}) => theme.colors.neutral7};
  }
`;

const StarWrapper = styled.span`
  color: ${theme.colors.primary};
`;

export default withError(Select);
