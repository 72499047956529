import {useMemo} from "react";
import {rejectCandidateProfileStatuses} from "../assets/CandidateForms/RowsSection/Enums";

export const useMemoStatusDetailOptions = (statusDetails, statusSelectValue) => {
  const statusDetailAllOptions = useMemo(() => {
    return statusDetails.map(detail => ({value: detail.id, label: detail.name}))
  }, [statusDetails])

  return useMemo(() => {
    if(statusSelectValue === 2) // Неактивная
      return statusDetailAllOptions.filter(detail => [1, 2, 3, 4, 5, 11].includes(detail.value));

    if(statusSelectValue === 8) // В обработке
      return statusDetailAllOptions.filter(detail => [6, 7, 8].includes(detail.value));

    if(statusSelectValue === 6 || statusSelectValue === 22) // Отказ до собеседования Отказ после собеседования
      return statusDetailAllOptions.filter(detail => rejectCandidateProfileStatuses.includes(detail.value));

    return [];
  }, [statusDetailAllOptions, statusSelectValue]);
}