import axios from "../middlewares/axios";
import { INTERVIEWS } from "../config/paths";

export const getCandidateInterviews = async (filters = null) => {
  const response = await axios.post(INTERVIEWS + "/period", filters);

  return response.data;
};

export const createCandidateInterviews = async (data = null) => {
  const response = await axios.post(INTERVIEWS, data);

  return response.data;
};
