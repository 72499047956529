import styled from "styled-components";
import Icon from "../../../../components/Icon";
import { FC } from "react";
import { NavLink } from "react-router-dom";

interface Props {
  title: string;
  path: string;
  icon: any;
  exact?: boolean;
  menuOpen: boolean;
  onClick?: () => void;
}

const MenuItemDefault: FC<Props> = ({
                               title,
                               path,
                               icon,
                               exact ,
                               menuOpen,
                               onClick
}) => {
  const IconComponent = icon;

  return (
    <ItemWrapper menuOpen={menuOpen} onClick={onClick}>
      <NavLink
        exact={exact}
        to={path}
        activeClassName={'active-menu-item'}
      >
        <IconWrapper>
          <Icon color={"#fff"} opacity={1}>
            <IconComponent />
          </Icon>
        </IconWrapper>
        <Title>{title}</Title>
      </NavLink>
    </ItemWrapper>
  );
};

const Title = styled.div`
  font-size: 13px;
  ${({theme}) => `
    font-family: ${theme.fonts.mainBlack};
    color: ${theme.colors.neutral7};
  `}
`;

const IconWrapper = styled.div`
  margin-right: 16px;
  
  svg path {
    fill: ${({theme}) => theme.colors.neutral7};
  }
`;

const ItemWrapper = styled.div<{menuOpen:boolean,}>`
  a {
    margin-bottom: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 10px;
    border-radius: 4px;
    overflow: hidden;
    text-decoration: none;
    ${({menuOpen}) => !menuOpen && 'height: 44px;'}
    &:hover {
      background-color: ${({theme}) => theme.colors.neutral2};
    }
    &.active-menu-item {
      background-color: ${({theme}) => theme.colors.primary};
      
      ${Title} {
        color: #ffffff;
      }
      ${IconWrapper} {
        svg path {
          fill: #ffffff;
        }
      }
    }
  }
  &:last-child {
    margin-bottom: 0;
  }
`;

export default MenuItemDefault;
