import React, {FC} from 'react';

interface Props {
    width?: string,
    height?: string,
    fill?: string,
    onClick?: () => void
}

const AddIcon: FC<Props> = ({
    width,
    height,
    fill,
    onClick
}) => {
    return (
        <svg
            width={width || "24"}
            height={height || "24"}
            fill="none"
            onClick={onClick}
        >
            <g clipPath="url(#a)">
                <path
                    d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2Z"
                    fill={fill || "#000"}
                />
            </g>
            <defs>
                <clipPath id="a">
                    <path fill="#fff" d="M0 0h24v24H0z"/>
                </clipPath>
            </defs>
        </svg>
    );
};

export default AddIcon;