import React, {FC} from 'react';

interface Props {
    width?: string,
    height?: string,
    fill?: string,
    onClick?: () => void
}

const QuestionnaireIcon: FC<Props> = ({
    width,
    height,
    fill,
    onClick
}) => {
    return (
        <svg
            width={width || "24"}
            height={height || "24"}
            fill="none"
            onClick={onClick}
        >
            <g clipPath="url(#a)">
                <path
                    d="M13.17 4 18 8.83V20H6V4h7.17ZM14 2H6c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V8l-6-6Zm-2 12c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2Zm4 3.43c0-.81-.48-1.53-1.22-1.85a6.95 6.95 0 0 0-5.56 0A2.01 2.01 0 0 0 8 17.43V18h8v-.57Z"
                    fill={fill || "#fff"}
                />
            </g>
            <defs>
                <clipPath id="a">
                    <path
                        fill={fill || "#fff"}
                        d="M0 0h24v24H0z"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};

export default QuestionnaireIcon;