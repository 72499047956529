import React, { FC, memo } from "react";
import styled from "styled-components";
import { theme } from "../../../../styles/theme";
import Link from "../../../../components/Link";
import ACVModal from "../QuestionnairesModal/ACV";
import RJPModal from "../QuestionnairesModal/RJP";
import SIGModal from "../QuestionnairesModal/SIG";
import RecommendationModal from "../RecommendationModal";
import { ModalsStore } from "../../../../stores/modal";
import QuestionnaireModal from "../QuestionnairesModal";
import {
    downloadQuestionnaire,
    downloadQuestionnaireBlank,
    downloadQuestionnaireResults
} from "../../../../actions/candidates";

interface Props {
    candidateProfile: any;
  updateAfterSave: (type:string, value: string) => void;
  changeRecommendation: (value: string) => void;
}

const Operations: FC<Props> = memo(({ candidateProfile, updateAfterSave, changeRecommendation }) => {

  /**
   * Можно ли скачать опросник или его надо заполнить
   */
  const canDownload = type => {
    const value = candidateProfile[`count_${type}`];
    if (value) {
      if (typeof value === "string"){
        if (value[0] !== '0'){
          return true;
        }
      }
    }
    return false;
  };

  /**
   * Загрузка опросника
   */
  const download = (type) => {
      downloadQuestionnaire(candidateProfile.id, type);
  };

  /**
   * Загрузка итогового бланка
   */
  const downloadResults = () => {
      downloadQuestionnaireResults(candidateProfile.id);
  };

  /**
   * Загрузка пустого бланка по типу
   */
  const downloadBlank = (type) => {
      downloadQuestionnaireBlank(type);
  };

  return (
    <>
      <H2>Операции</H2>

      <OperationsListWrapper>
        {
          canDownload("tmri") ?
              <Operation onClick={() => download("tmri")}>Скачать ACV ответы</Operation>
          :
              <Operation
                  onClick={() =>
                      ModalsStore.showModal("questionnaire-modal",
                          {
                            type: "tmri",
                            candidate_profile_id: candidateProfile.id,
                            updateAfterSave
                          })
                  }
              >
                Заполнить ACV опросник
              </Operation>
        }
        {
          canDownload("sig") ?
              <Operation onClick={() => download("sig")}>Скачать SIG ответы</Operation>
          :
              <Operation
                  onClick={() =>
                      ModalsStore.showModal("questionnaire-modal",
                          {
                            type: "sig",
                            candidate_profile_id: candidateProfile.id,
                            updateAfterSave
                          })
                  }
              >
                Заполнить SIG опросник
              </Operation>
        }
        {
          canDownload("rjp") ?
              <Operation onClick={() => download("rjp")}>Скачать RJP ответы</Operation>
          :
              <Operation
                  onClick={() =>
                      ModalsStore.showModal("questionnaire-modal",
                          {
                            type: "rjp",
                            candidate_profile_id: candidateProfile.id,
                            updateAfterSave
                          })
                  }
              >
                Заполнить RJP опросник
              </Operation>
        }
        <Operation
            onClick={() =>
                ModalsStore.showModal("recommendation-modal", {
                  recommendation: candidateProfile.recommendation,
                  changeRecommendation
                })
            }
        >
          {
              candidateProfile.recommendation ?
                "Изменить рекомендацию"
              :
                "Внести рекомендацию"
          }
        </Operation>
        <Operation onClick={downloadResults}>
            Скачать итоговый бланк этапов отбора
        </Operation>
        <Operation onClick={() => downloadBlank("tmri")}>
            Скачать пустой бланк ACV
        </Operation>

        <QuestionnaireModal />
        <ACVModal />
        <RJPModal />
        <SIGModal />
        <RecommendationModal/>
      </OperationsListWrapper>
    </>
  );
});

const Operation = styled.div`
  color: ${theme.colors.primary};
  font-size: ${theme.fontSizes.body};
  cursor: pointer;

  &:hover {
    color: ${theme.colors.primaryDark};
  }
`;

const H2 = styled.div`
  font-size: ${theme.fontSizes.h2};
  color: ${theme.colors.neutral10};
  font-family: ${theme.fonts.mainBlack};
`;

const OperationsListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;

  a {
    font-size: ${theme.fontSizes.body};
  }
`;

export default Operations;
