import React, {FC, memo} from "react";
import isEqual from "lodash/isEqual";
import styled from "styled-components";
import {theme} from "../../styles/theme";
import TableBody from "./TableBody";
import TableHeader from "./TableHeader";
import TableSummary from "./TableSummary";
import {observer} from "mobx-react-lite";

interface Props {
    margin?: string,
    data: {
        [key: string]: string | number | boolean;
    }[];
    fields: {
        label: string;
        field?: string;
        type: string;
        component?: FC;
    }[];
    headerSubtitles?: {
        title: string,
        colSpan: number
    }[];
    summaryRows?: {
        title: string | number,
        colSpan: number
    }[][];
    onClick?: (id: string | number | boolean) => void;
    onRequestFulfilled?: () => void;
    onInputTableChange?: (value, field, rowIndex, row) => void;
    textAlignBody?: string;
    textAlignHeader?: string;
    needBorderRight?: boolean;
    inputTextAlign?: string;
    textAlignBodyTitle?: string;
    classNameBody?: string;
}

const Table: FC<Props> = observer(({
    data,
    fields,
    headerSubtitles,
    summaryRows,
    margin,
    onClick = () => null,
    onRequestFulfilled,
    onInputTableChange,
    textAlignBody,
    textAlignHeader,
    needBorderRight,
    inputTextAlign,
    textAlignBodyTitle,
    classNameBody,
}) => {
    return (
        <TableWrapper margin={margin}>
            <TableHeaderWrapper
              textAlignBodyTitle={textAlignBodyTitle}
            >
                <TableHeader
                    fields={fields}
                    headerSubtitles={headerSubtitles}
                    textAlign={textAlignHeader}
                />
            </TableHeaderWrapper>

            <TableBodyWrapper
              inputTextAlign={inputTextAlign}
              textAlignBodyTitle={textAlignBodyTitle}
              needBorderRight={needBorderRight}
            >
                <TableBody
                    data={data}
                    fields={fields}
                    onClick={onClick}
                    onRequestFulfilled={onRequestFulfilled}
                    onInputTableChange={onInputTableChange}
                    textAlign={textAlignBody}
                    className={classNameBody}
                />
                {
                    summaryRows &&
                    <TableSummary
                      data={summaryRows}
                      textAlign={'center'}
                    />
                }
            </TableBodyWrapper>
        </TableWrapper>
    );
});

const TableWrapper = styled.div<{margin?: string}>`
  display: table;
  width: 100%;
  margin: ${({margin}) => margin};
`;

const TableHeaderWrapper = styled.thead<{textAlignBodyTitle?: string,}>`
  background-color: #F8F9FA;
  font-family: ${theme.fonts.mainRegular};
  font-size: 12px;
  color: ${theme.colors.neutral8};
    
  td {
      &:first-child {
          ${({textAlignBodyTitle}) => textAlignBodyTitle && 'text-align: ' + textAlignBodyTitle};
      }
  }
`;

const TableBodyWrapper = styled.tbody<{
    inputTextAlign?: string,
    textAlignBodyTitle?: string,
    needBorderRight?: boolean,
}>`
  
  input {
      ${({inputTextAlign}) => inputTextAlign && 'text-align: ' + inputTextAlign};
      padding: 0 0 0 15px;
  }
    
  .body-row td{
      &:nth-child(4),
      &:nth-child(8) {
          border-right: 1px solid ${theme.colors.neutral3};
      }
  }
    
  td {
      &:not(:last-child) {
          ${({needBorderRight}) => needBorderRight && 'border-right: 1px solid ' + theme.colors.neutral3};
      }
      &:first-child {
          ${({textAlignBodyTitle}) => textAlignBodyTitle && 'text-align: ' + textAlignBodyTitle + ' !important'};
      }
  }
`;

export default Table;
