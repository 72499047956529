import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {observer} from "mobx-react-lite";
import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {Controller, useForm, useWatch} from "react-hook-form";
import styled from "styled-components";
import FilterIcon from "../../assets/icons/FilterIcon";
import ActionIcon from "../../components/ActionIcon";
import Filters from "../../components/Filters";
import Header from "../../components/Header";
import {Input} from "../../components/Input";
import Select from "../../components/Select";
import {RESTAURANTS_FILTER_ID as FILTER_ID} from "../../config/filters";
import {FiltersStoreContext} from "../../stores/filters";
import {ModalsStore} from "../../stores/modal";
import FormModal from "./FormModal";
import GridRow from "./GridRow";
import {useFetching} from "../../hooks/useFetching";
import {getRestaurants} from "../../actions/restaurants";
import Grid from "../../components/Grid";
import * as yup from "yup";
import sortBySelectOptions from "../../assets/Restaurants/sortBySelectOptions.json";
import sortOrderSelectOptions from "../../assets/sortOrderSelectOptions.json";
import blockingSelectOptions from "../../assets/Restaurants/filters/blockingSelectOptions.json";
import forwardingSelectOptions from "../../assets/Restaurants/filters/forwardingSelectOptions.json"
import {LIMIT_DEFAULT, PAGE_DEFAULT} from "../../config/consts";
import MultiSelect from "../../components/MultiSelect";
import {DirectoryStoreContext} from "../../stores/directory";
import radioButtonsData from "../../assets/Restaurants/formMain/radioButtonsData.json";
import initializeMultiselectOptions from "../../utils/initializeMultiselectOptions";
import initializeMultiselectValue from "../../utils/initializeMultiselectValue";
import debounce from "lodash/debounce";

const schema = yup.object().shape({
    search: yup.string(),
    sortBy: yup.string(),
    sortOrder: yup.string(),
    filters: yup.object().shape({
        partner: yup.string(),
        city: yup.string(),
        restaurant: yup.string(),
        tariff: yup.string(),
        forwarding: yup.string(),
        minorsRecruitment: yup.string(),
        blocking: yup.string()
    })
});

const Restaurants = observer(() => {
    const [restaurantsData, setRestaurantsData] = useState(null);
    const [limit, setLimit] = useState(LIMIT_DEFAULT);
    const [page, setPage] = useState(PAGE_DEFAULT);
    const [searchValue, setSearchValue] = useState("");
    const [sortBy, setSortBy] = useState("");
    const [sortOrder, setSortOrder] = useState("descending");
    const [filterValues, setFilterValues] = useState(null);
    const filtersIconRef = useRef(null);
    const {partners, cities, restaurants, tariffs} = useContext(DirectoryStoreContext);
    const partnersOptions = initializeMultiselectOptions(partners, "id", "name", false);
    const citiesOptions = initializeMultiselectOptions(cities, "id", "name_ru", false);
    const tariffsOptions = initializeMultiselectOptions(tariffs, "id", "name", false);
    const {filtersData, filtersIconClickHandle, closeFiltersHandle} = useContext(FiltersStoreContext);
    const {control, getValues, setValue, reset} = useForm({
        mode: "onChange",
        resolver: yupResolver(schema),
        defaultValues: {
            search: "",
            sortBy: "",
            sortOrder: "descending",
            filters: {
                partner: [],
                city: [],
                restaurant: [],
                tariff: [],
                forwarding: "all",
                minorsRecruitment: "all",
                blocking: "all"
            }
        }
    });
    
    const citySelectValue = useWatch({
        control,
        name: "filters.city"
    });
    
    const [fetchRestaurants, isLoading, error] = useFetching(async () => {
        let order = null;
        if(sortBy) {
            order = sortOrder === "ascending" ? sortBy : "-" + sortBy;
        }

        const response = await getRestaurants(limit, page, searchValue, order, filterValues);
        setRestaurantsData(response);
    });
    
    useEffect(() => {
        setValue("filters.partner", initializeMultiselectValue(partners, "id"));
    }, [partners]);
    useEffect(() => {
        setValue("filters.restaurant", initializeMultiselectValue(restaurants, "id"));
    }, [restaurants]);
    useEffect(() => {
        setValue("filters.city", initializeMultiselectValue(cities, "id"));
    }, [cities]);
    useEffect(() => {
        setValue("filters.tariff", initializeMultiselectValue(tariffs, "id"));
    }, [tariffs]);
    
    useEffect(() => {
        fetchRestaurants();
    }, [limit, page, searchValue, sortBy, sortOrder, filterValues]);
    
    useEffect(() => {
        const filteredRestaurants = restaurants.filter(restaurant => {
            return citySelectValue.indexOf(restaurant.city_id) !== -1;
        });
        const newRestaurantsValue = initializeMultiselectValue(filteredRestaurants, "id");
        setValue("filters.restaurant", newRestaurantsValue);
    }, [citySelectValue]);
    
    const searchChangeHandle = debounce((value) => {
        setSearchValue(value);
        setPage(1);
    }, 400);
    
    const sortBySelectChangeHandle = useCallback((value) => {
        setSortBy(value);
    }, []);
    
    const sortOrderSelectChangeHandle = useCallback((value) => {
        setSortOrder(value);
    }, []);
    
    const resetFilters = useCallback(() => {
        reset({
            search: getValues().search,
            sortBy: getValues().sortBy,
            sortOrder: getValues().sortOrder,
            filters: {
                partner: initializeMultiselectValue(partners, "id"),
                city: initializeMultiselectValue(cities, "id"),
                restaurant: initializeMultiselectValue(restaurants, "id"),
                tariff: initializeMultiselectValue(tariffs, "id"),
                forwarding: "all",
                minorsRecruitment: "all",
                blocking: "all"
            }
            // filters: control._defaultValues.filters
        });
    }, [partners, cities, restaurants, tariffs]);
    
    const applyFilters = useCallback(() => {
        const filters = {...getValues().filters};
        
        if (filters.partner.length === partners.length) {
            delete filters.partner;
        }
        if (filters.city.length === cities.length) {
            delete filters.city;
        }
        if (filters.restaurant.length === restaurants.length) {
            delete filters.restaurant;
        }
        if (filters.tariff.length === tariffs.length) {
            delete filters.tariff;
        }
        if (filters.forwarding === "all") {
            delete filters.forwarding;
        }
        if (filters.minorsRecruitment === "all") {
            delete filters.minorsRecruitment;
        }
        if (filters.blocking === "all") {
            delete filters.blocking;
        }
        setPage(1);

        if(Object.keys(filters).length) {
            setFilterValues(filters);
        } else {
            setFilterValues(null);
        }

    }, [partners, cities, restaurants, tariffs, limit, page]);
    
    const onOpenFormModal = useCallback((data) => {
        ModalsStore.showModal("restaurants-modal", {data});
    }, []);
    
    const getRestaurantsOptions = useCallback(() => {
        const restaurantsOptions = initializeMultiselectOptions(
            cities, "id", "name_ru", true, "restaurants_minimal"
        );
        
        return restaurantsOptions.filter(item => {
            return citySelectValue.indexOf(item.value) !== -1;
        });
    }, [cities, citySelectValue]);
    
    return (
        <>
            <Header
                rightActions={
                    <RightActions>
                        <SearchWrapper>
                            <Controller
                                render={({field, fieldState}) =>
                                    <Input
                                        {...field}
                                        {...fieldState}
                                        onChange={(value) => {
                                            searchChangeHandle(value);
                                            field.onChange(value);
                                        }}
                                        placeholder="Поиск..."
                                        search={true}
                                        maxLength={200}
                                        ref={null}
                                    />
                                }
                                name="search"
                                control={control}
                            />
                        </SearchWrapper>
                        
                        <HeaderSelectWrapper>
                            <Controller
                                render={({field, fieldState}) =>
                                    <Select
                                        {...field}
                                        {...fieldState}
                                        onChange={(value) => {
                                            sortBySelectChangeHandle(value);
                                            field.onChange(value);
                                        }}
                                        title="Сортировать по..."
                                        options={sortBySelectOptions}
                                        ref={null}
                                    />
                                }
                                name="sortBy"
                                control={control}
                            />
                        </HeaderSelectWrapper>
                        
                        <HeaderSelectWrapper>
                            <Controller
                                render={({field, fieldState}) =>
                                    <Select
                                        {...field}
                                        {...fieldState}
                                        onChange={(value) => {
                                            sortOrderSelectChangeHandle(value);
                                            field.onChange(value);
                                        }}
                                        title="Порядок сортировки"
                                        options={sortOrderSelectOptions}
                                        ref={null}
                                    />
                                }
                                name="sortOrder"
                                control={control}
                            />
                        </HeaderSelectWrapper>
                        
                        <FiltersBtn ref={filtersIconRef}>
                            <ActionIcon
                                action={() => filtersIconClickHandle(FILTER_ID, filtersIconRef)}
                                active={filtersData[FILTER_ID]?.open}
                                tooltip="Фильтры"
                            >
                                <FilterIcon/>
                            </ActionIcon>
                            
                            {filtersData[FILTER_ID]?.open &&
                                <Filters
                                    onReset={resetFilters}
                                    btnRef={filtersIconRef}
                                    onApply={applyFilters}
                                    onClose={(event) => {closeFiltersHandle(event, FILTER_ID, filtersIconRef)}}
                                >
                                    <FilterRowWrapper>
                                        <Controller
                                            render={({field, fieldState}) =>
                                                <MultiSelect
                                                    {...field}
                                                    {...fieldState}
                                                    title="Партнер"
                                                    options={partnersOptions}
                                                    withSubOptions={false}
                                                    ref={null}
                                                />
                                            }
                                            name="filters.partner"
                                            control={control}
                                        />
                                    </FilterRowWrapper>
                                    
                                    <FilterRowWrapper>
                                        <Controller
                                            render={({field, fieldState}) =>
                                                <MultiSelect
                                                    {...field}
                                                    {...fieldState}
                                                    title="Город"
                                                    options={citiesOptions}
                                                    withSubOptions={false}
                                                    ref={null}
                                                />
                                            }
                                            name="filters.city"
                                            control={control}
                                        />
                                    </FilterRowWrapper>
                                    
                                    <FilterRowWrapper>
                                        <Controller
                                            render={({field, fieldState}) =>
                                                <MultiSelect
                                                    {...field}
                                                    {...fieldState}
                                                    title="Ресторан"
                                                    options={getRestaurantsOptions()}
                                                    withSubOptions={true}
                                                    subOptionsKey="restaurants_minimal"
                                                    ref={null}
                                                />
                                            }
                                            name="filters.restaurant"
                                            control={control}
                                        />
                                    </FilterRowWrapper>
                                    
                                    <FilterRowWrapper>
                                        <Controller
                                            render={({field, fieldState}) =>
                                                <MultiSelect
                                                    {...field}
                                                    {...fieldState}
                                                    title="Тариф"
                                                    options={tariffsOptions}
                                                    withSubOptions={false}
                                                    ref={null}
                                                />
                                            }
                                            name="filters.tariff"
                                            control={control}
                                        />
                                    </FilterRowWrapper>
                                    
                                    
                                    <FilterRowWrapper>
                                        <Controller
                                            render={({field, fieldState}) =>
                                                <Select
                                                    {...field}
                                                    {...fieldState}
                                                    title="Переадресация"
                                                    options={forwardingSelectOptions}
                                                    ref={null}
                                                />
                                            }
                                            name="filters.forwarding"
                                            control={control}
                                        />
                                    </FilterRowWrapper>
                                    
                                    <FilterRowWrapper>
                                        <Controller
                                            render={({field, fieldState}) =>
                                                <Select
                                                    {...field}
                                                    {...fieldState}
                                                    title="Набор несовершеннолетних"
                                                    options={radioButtonsData}
                                                    ref={null}
                                                />
                                            }
                                            name="filters.minorsRecruitment"
                                            control={control}
                                        />
                                    </FilterRowWrapper>
                                    
                                    <FilterRowWrapper>
                                        <Controller
                                            render={({field, fieldState}) =>
                                                <Select
                                                    {...field}
                                                    {...fieldState}
                                                    title="Блокировка"
                                                    options={blockingSelectOptions}
                                                    ref={null}
                                                />
                                            }
                                            name="filters.blocking"
                                            control={control}
                                        />
                                    </FilterRowWrapper>
                                </Filters>
                            }
                        </FiltersBtn>
                    </RightActions>
                }
                title="Рестораны"
            />
            
            <Grid
                data={restaurantsData}
                isLoading={isLoading}
                error={error}
                gridRow={GridRow}
                onRowClick={(data) => onOpenFormModal(data)}
                setPage={setPage}
                setLimit={setLimit}
            />

            <FormModal onRequestFulfilled={fetchRestaurants}/>
        </>
    );
});

const FiltersBtn = styled.div`
  position: relative;
`;

const RightActions = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: flex-end;
  flex-grow: 1;

  @media (max-width: 630px) {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
`;

const SearchWrapper = styled.div`
  max-width: 200px;

  @media (max-width: 1122px) {
    max-width: 100%;
  }

  @media (max-width: 630px) {
    flex-grow: 1;
  }
`;

const HeaderSelectWrapper = styled.div`
  width: 180px;

  @media (max-width: 430px) {
    width: 100%;
  }
`;

const FilterRowWrapper = styled.div`
  &:not(:last-child) {
    margin-bottom: 15px;
  }
`;

export default Restaurants;