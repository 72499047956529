import {FC} from "react";

interface Props {
    width?: string,
    height?: string,
    fill?: string,
    onClick?: () => void
}

const DeleteIcon: FC<Props> = ({
    width,
    height,
    fill,
    onClick
}) => {
    return (
        <svg
            width={width || "24"}
            height={height || "24"}
            fill="none"
            onClick={onClick}
            viewBox="0 0 24 24"
        >
            <g clipPath="url(#a)">
                <path
                    d="M16 9v10H8V9h8Zm-1.5-6h-5l-1 1H5v2h14V4h-3.5l-1-1ZM18 7H6v12c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7Z"
                    fill={fill || "black"}
                />
            </g>
            <defs>
                <clipPath id="a">
                    <path fill="#fff" d="M0 0h24v24H0z" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default DeleteIcon;