import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import Button from "../../../../components/Button";
import styled from "styled-components";
import React, {FC, useCallback, useContext, useEffect, useMemo} from "react";
import Select from "../../../../components/Select";
import MaterialFields from "./FieldComponents/MaterialFields";
import MagazineFields from "./FieldComponents/MagazineFields";
import TransportFields from "./FieldComponents/TransportFields";
import PostFields from "./FieldComponents/PostFields";
import PromoterFields from "./FieldComponents/PromoterFields";
import OtherFields from "./FieldComponents/OtherFields";
import { v4 as uuidv4 } from 'uuid';
import {
  BUS_STOP_VALUE,
  DEFAULT_FROM_VALUE, DEFAULT_RESULT_VALUE,
  ELEVATORS_VALUE,
  ENTRANCES_VALUE,
  ISelectOption,
  ItemOfflineAdv,
  MAGAZINE_VALUE,
  MATERIALS_VALUE,
  OTHER_VALUE,
  POST_BOX_VALUE,
  PROMOTER_VALUE,
  TRANSPORT_VALUE
} from "../const";
import {AuthStoreContext} from "../../../../stores/auth";
import initializeMultiselectOptions from "../../../../utils/initializeMultiselectOptions";
import {DirectoryStoreContext} from "../../../../stores/directory";
import Datepicker from "../../../../components/Datepicker";
import moment from "moment";
import Loader from "../../../../components/Loader";
import EntranceFields from "./FieldComponents/EntranceFields";
import BusStopFields from "./FieldComponents/BusStopFields";
import ElevatorFields from "./FieldComponents/ElevatorFields";

const schema = yup.object().shape({
  type: yup.string().required(),
});

interface Props {
  offlineAdvertisingType: ISelectOption[];
  printedProduct: ISelectOption[];
  posterSize: ISelectOption[];
  isLoading: boolean;
  handleHide: () => {};
  deleteItem: () => void;
  addNewItem: (type: string, item: ItemOfflineAdv) => void;
  editOldItem: (type: string, item: ItemOfflineAdv) => void;
  resultIsFill: boolean;
  editItem?: ItemOfflineAdv | any;
}

const FormComponent: FC<Props> = ({
  offlineAdvertisingType,
  printedProduct,
  posterSize,
  isLoading,
  handleHide,
  deleteItem,
  resultIsFill,
  addNewItem,
  editItem,
  editOldItem,
}) => {
  const {
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: DEFAULT_FROM_VALUE,
  });

  const {userData} = useContext(AuthStoreContext);
  const {restaurants} = useContext(DirectoryStoreContext);

  const restaurantOptions = useMemo(() => {
    if(userData.role?.name === "superadmin") {
      return initializeMultiselectOptions(restaurants, "id", "name_ru", false);
    } else if(userData.role?.name === "hr_partner") {
      return initializeMultiselectOptions(userData.partner_restaurants, "id", "name_ru", false);
    } else {
      return initializeMultiselectOptions(userData.restaurants, "id", "name_ru", false);
    }
  }, [userData.role?.name, userData.restaurants, userData.partner_restaurants, restaurants])

  useEffect(() => {
    if(editItem) {
      setValue("restaurant", editItem?.restaurant?.value);
      setValue("type", editItem?.type?.value);
      setValue("month", editItem?.advertisingMonth);

      setValue(`${MATERIALS_VALUE}.comment`, editItem[MATERIALS_VALUE].comment);
      setValue(`${MATERIALS_VALUE}.type`, editItem[MATERIALS_VALUE].type?.value);
      setValue(`${MATERIALS_VALUE}.size`, editItem[MATERIALS_VALUE].size?.value);
      setValue(`${MATERIALS_VALUE}.count`, editItem[MATERIALS_VALUE].count);

      setValue(`${BUS_STOP_VALUE}.comment`, editItem[BUS_STOP_VALUE].comment);
      setValue(`${BUS_STOP_VALUE}.address`, editItem[BUS_STOP_VALUE].address);

      setValue(`${ELEVATORS_VALUE}.comment`, editItem[ELEVATORS_VALUE].comment);
      setValue(`${ELEVATORS_VALUE}.address`, editItem[ELEVATORS_VALUE].address);

      setValue(`${TRANSPORT_VALUE}.numbers`, editItem[TRANSPORT_VALUE].numbers);
      setValue(`${TRANSPORT_VALUE}.comment`, editItem[TRANSPORT_VALUE].comment);

      setValue(`${POST_BOX_VALUE}.address`, editItem[POST_BOX_VALUE].address);
      setValue(`${POST_BOX_VALUE}.comment`, editItem[POST_BOX_VALUE].comment);

      setValue(`${ENTRANCES_VALUE}.address`, editItem[ENTRANCES_VALUE].address);
      setValue(`${ENTRANCES_VALUE}.comment`, editItem[ENTRANCES_VALUE].comment);

      setValue(`${PROMOTER_VALUE}.dotes`, editItem[PROMOTER_VALUE].dotes);
      setValue(`${PROMOTER_VALUE}.comment`, editItem[PROMOTER_VALUE].comment);

      setValue(`${MAGAZINE_VALUE}.name`, editItem[MAGAZINE_VALUE].name);
      setValue(`${MAGAZINE_VALUE}.comment`, editItem[MAGAZINE_VALUE].comment);

      setValue(`${OTHER_VALUE}.comment`, editItem[OTHER_VALUE].comment);
    } else {
      resetForm();
    }
  }, [editItem, restaurantOptions]);

  const resetForm = () => {
    reset(DEFAULT_FROM_VALUE);
    if(restaurantOptions.length === 1) {
      setValue("restaurant", restaurantOptions[0].value);
    }
  }

  const onSubmit = async (data) => {
    let item = data[data.type];
    item.type = printedProduct.find(el => el.value === item.type);
    item.size = posterSize.find(el => el.value === item.size);
    item.restaurant = restaurantOptions.length > 1
      ? restaurantOptions.find(el => el.value === data.restaurant)
      : restaurantOptions[0];
    item.advertisingMonth = data.month;

    if (!!editItem) {
      item = {...editItem[data.type], ...item};
      editOldItem(data.type, item);
    }
    else {
      item.id = uuidv4();
      addNewItem(watch("type"), item);
    }
    resetForm();
  };

  const onDelete = useCallback(e => {
    e.preventDefault();
    deleteItem();
  }, [editItem]);

  const getDisabledBtnAdd = () => {
    return !watch("restaurant") ||
      !watch("month") ||
      getDisabledBtnType();
  }

  const getDisabledBtnType = () => {
    switch(watch("type")) {
      case MATERIALS_VALUE:
        return !watch(`${MATERIALS_VALUE}.type`) ||
          !watch(`${MATERIALS_VALUE}.size`) ||
          !watch(`${MATERIALS_VALUE}.count`);
      case BUS_STOP_VALUE:
        return !watch(`${BUS_STOP_VALUE}.address`);
      case ELEVATORS_VALUE:
        return !watch(`${ELEVATORS_VALUE}.address`);
      case MAGAZINE_VALUE:
        return !watch(`${MAGAZINE_VALUE}.name`);
      case TRANSPORT_VALUE:
        return !watch(`${TRANSPORT_VALUE}.numbers`);
      case POST_BOX_VALUE:
        return !watch(`${POST_BOX_VALUE}.address`);
      case ENTRANCES_VALUE:
        return !watch(`${ENTRANCES_VALUE}.address`);
      case PROMOTER_VALUE:
        return !watch(`${PROMOTER_VALUE}.dotes`);
      case OTHER_VALUE:
        return !watch(`${OTHER_VALUE}.comment`);
    }
  };

  return (
      <>
        {isLoading || !restaurantOptions.length
            ?
          <Loader width={100} height={100}/>
            :
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Content>
              <Controller
                render={({field, fieldState}) => {
                  return (
                    <Select
                      title="Ресторан"
                      options={restaurantOptions}
                      withSearch={true}
                      withSorting={true}
                      disabled={restaurantOptions.length === 1}
                      {...field}
                      {...fieldState}
                      ref={null}
                    />
                  );
                }}
                name="restaurant"
                control={control}
              />
              <Row>
                <Cell width={"167px"}>
                  <Controller
                    render={({field, fieldState}) => {
                      return (
                        <Datepicker
                          value={field.value ? moment(field.value, "YYYY-MM") : null}
                          format={"MM.yyyy"}
                          minDate={new Date(moment().format("YYYY-MM"))}
                          title={"Месяц и год"}
                          monthSelect
                          onChange={(date) => field.onChange(date.format("YYYY-MM"))}
                        />
                      );
                    }}
                    name={"month"}
                    control={control}
                  />
                </Cell>
                <Cell width={"319px"}>
                  <Controller
                    render={({field, fieldState}) => {
                      return (
                        <Select
                          title="Тип"
                          options={offlineAdvertisingType}
                          disabled={!!editItem}
                          {...field}
                          {...fieldState}
                          ref={null}
                        />
                      );
                    }}
                    name={"type"}
                    control={control}
                  />
                </Cell>
              </Row>
              {
                watch("type") === MATERIALS_VALUE &&
                <MaterialFields
                  printedProduct={printedProduct}
                  posterSize={posterSize}
                  control={control}
                />
              }
              {
                watch("type") === BUS_STOP_VALUE &&
                <BusStopFields
                  control={control}
                />
              }
              {
                watch("type") === ELEVATORS_VALUE &&
                <ElevatorFields
                  control={control}
                />
              }
              {
                watch("type") === MAGAZINE_VALUE &&
                <MagazineFields control={control}/>
              }
              {
                watch("type") === TRANSPORT_VALUE &&
                <TransportFields control={control}/>
              }
              {
                watch("type") === POST_BOX_VALUE &&
                <PostFields control={control}/>
              }
              {
                watch("type") === PROMOTER_VALUE &&
                <PromoterFields control={control}/>
              }
              {
                watch("type") === ENTRANCES_VALUE &&
                <EntranceFields control={control}/>
              }
              {
                (
                  watch("type") === OTHER_VALUE ||
                  (!Object.keys(DEFAULT_RESULT_VALUE).includes(watch("type")) && watch("type") !== null)
                ) &&
                <OtherFields control={control}/>
              }
              {
                watch("type") &&
                <ButtonWrapper justifyContent={"flex-start"}>
                  {
                    !editItem ?
                      <Button type="submit"
                              disabled={getDisabledBtnAdd()}
                              btnStyle="primary"
                              title="Добавить"/>
                      :
                      <>
                        <Button btnStyle={'secondary'}
                                onClick={onDelete}
                                title={"Удалить"}/>
                        <Button type="submit"
                                disabled={getDisabledBtnAdd()}
                                btnStyle="primary"
                                title="Сохранить"/>
                      </>
                  }
                </ButtonWrapper>
              }
            </Content>
            <ButtonWrapper>
              <Button onClick={handleHide} btnStyle={'cancel'} title={"Отмена"}/>
              {
                !resultIsFill &&
                <Button disabled={true} btnStyle={'primary'} title={"Сохранить"}/>
              }
            </ButtonWrapper>
          </Form>
        }
      </>
  );
};

const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 16px;
  overflow: auto;
  padding-top: 16px;
  margin-top: -16px;
`;

const Form = styled.form`
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 16px;
  
  @media (max-width: 1100px) {
    &>div:nth-child(2) {
      flex-grow: 1;
    }
  }
  
  @media (max-width: 500px) {
    flex-direction: column;
    &>div {
      width: 100%;
    }
  }
`;

const Cell = styled.div<{margin?: string, width?: string,}>`
  width: ${({width}) => width ? width : '100%'};
  margin: ${({margin}) => margin ? margin : 0};
`;

const ButtonWrapper = styled.div<{justifyContent?: string}>`
  margin-top: 8px;
  width: 100%;
  display: flex;
  justify-content: ${({justifyContent}) => justifyContent || "flex-end"};
  gap: 16px;
  flex-shrink: 0;
  background-color: #ffffff;
`;

export default FormComponent;
