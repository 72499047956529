import {FC} from "react";

interface Props {
    width?: string,
    height?: string,
    fill?: string
}

const DoneIcon: FC<Props> = ({
    width,
    height,
    fill
}) => {
  return (
      <svg
          width={width || "12"}
          height={height || "12"}
          viewBox="0 0 24 24"
          fill="none"
      >
          <g clipPath="url(#clip0_1196_26673)">
              <path
                  d="M8.9999 16.2L4.7999 12L3.3999 13.4L8.9999 19L20.9999 6.99998L19.5999 5.59998L8.9999 16.2Z"
                  fill={fill || "black"}
              />
          </g>
          <defs>
              <clipPath id="clip0_1196_26673">
                  <rect
                      width="24"
                      height="24"
                      fill="white"
                  />
              </clipPath>
          </defs>
      </svg>
  );
};

export default DoneIcon;
