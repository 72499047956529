import { Controller } from "react-hook-form";
import { Input } from "../../../../../components/Input";
import React, {FC} from "react";
import {TextArea} from "../../../../../components/TextArea";
import {TRANSPORT_VALUE} from "../../const";

interface Props {
    control: any;
}

const TransportFields: FC<Props> = ({control}) => {

    return (
        <>
            <Controller
                render={({ field, fieldState }) => {
                    return (
                        <Input
                            placeholder="Желаемые номера маршрутов"
                            {...field}
                            {...fieldState}
                            ref={null}
                        />
                    );
                }}
                name={`${TRANSPORT_VALUE}.numbers`}
                control={control}
            />
            <Controller
                render={({ field, fieldState }) => {
                    return (
                        <TextArea
                            placeholder="Комментарий"
                            {...field}
                            {...fieldState}
                            ref={null}
                        />
                    );
                }}
                name={`${TRANSPORT_VALUE}.comment`}
                control={control}
            />
        </>
    );
};

export default TransportFields;
