import React, {FC} from 'react';
import styled from "styled-components";
import {theme} from "../../styles/theme";


interface Props {
  icon: React.JSXElementConstructor<any>;
  title: string;
  text: string;
  width?: string;
  adaptiveWidth?: string;
}

const InfoCard:FC<Props> = ({
                              icon,
                              title,
                              text,
                              width = '100%',
                              adaptiveWidth,
                            }) => {
  const Icon = icon;

  return (
    <Wrapper
      width={width}
      adaptiveWidth={adaptiveWidth}
    >
      <IconWrapper>
        <Icon/>
      </IconWrapper>
      <Content>
        <Title> {title} </Title>
        <Text> {text} </Text>
      </Content>
    </Wrapper>
  );
};

const Text = styled.div`
  font-family: ${theme.fonts.mainBlack};
  font-size: ${theme.fontSizes.h3};
  color: ${theme.colors.bodyText};
`;

const Title = styled.div`
  font-family: ${theme.fonts.mainRegular};
  font-size: ${theme.fontSizes.small};
  color: ${theme.colors.neutral7};
  margin-bottom: 5px;
`;

const Content = styled.div``;

const IconWrapper = styled.div`
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  background-color: ${theme.colors.primary};
  display: flex;
  align-items: center;
  justify-content: center;
  
  path[fill] {
    fill-opacity: 1;
    fill: #ffffff;
  }
`;

const Wrapper = styled.div<{
  width: string,
  adaptiveWidth?: string,
}>`
  flex: 0 1 ${({width}) => width};
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 30px 24px;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  
  @media (max-width: 820px) {
    flex: ${({adaptiveWidth}) => '0 1 ' + (adaptiveWidth ? adaptiveWidth : '100%')};
  }
  
  @media (max-width: 600px) {
    flex: 0 1 100%;
  }
`;

export default InfoCard;