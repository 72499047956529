import TableInput from "../../../pages/Plr/TableInput";


const tableFields = [
    {
        label: "Ставка",
        field: "rateNow",
        type: "number"
    },
    {
        label: "Часы",
        field: "hourNow",
        type: "number"
    },
    {
        label: "Кол-во чел.",
        field: "countNow",
        type: "component-input",
        component: TableInput
    },
    {
        label: "Итого, ч.",
        field: "summaryNow",
        type: "number",
        needBorderRight: true,
    },
    {
        label: "Ставка",
        field: "ratePlan",
        type: "number"
    },
    {
        label: "Часы",
        field: "hourPlan",
        type: "number"
    },
    {
        label: "Кол-во чел.",
        field: "countPlan",
        type: "component-input",
        component: TableInput
    },
    {
        label: "Итого, ч.",
        field: "summaryPlan",
        type: "number",
        needBorderRight: true,
    },
    {
        label: "Ставка",
        field: "rateReserve",
        type: "number"
    },
    {
        label: "Часы",
        field: "hourReserve",
        type: "number"
    },
    {
        label: "Кол-во чел.",
        field: "countReserve",
        type: "component-input",
        component: TableInput
    },
    {
        label: "Итого, ч.",
        field: "summaryReserve",
        type: "number"
    }
];

export default tableFields;
