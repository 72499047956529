import {observer} from "mobx-react-lite";
import moment from "moment";
import {useContext} from "react";
import InfoCard from "../../components/InfoCard";
import PersonIcon from "../../assets/icons/PersonIcon";
import EmailIcon from "../../assets/icons/EmailIcon";
import LoginIcon from "../../assets/icons/LoginIcon";
import CalendarIcon from "../../assets/icons/CalendarIcon";
import LanguageIcon from "../../assets/icons/LanguageIcon";
import Chart from "../../components/Chart";
import styled from "styled-components";
import {AuthStoreContext} from "../../stores/auth";
import {theme} from "../../styles/theme";
import UpcomingInterviews from "./UpcomingInterviews";

const MainPage = observer(() => {
    const {userData} = useContext(AuthStoreContext);
    
    return (
        <>
            <UserInfo>
                <Row>
                    <InfoCard
                        icon={PersonIcon}
                        title={"Текущий пользователь"}
                        text={userData.full_name}
                    />
                    <InfoCard
                        icon={EmailIcon}
                        title={"E-mail"}
                        text={userData.email}
                    />
                </Row>
                <Row>
                    <InfoCard
                        icon={LoginIcon}
                        title={"Кол-во входов"}
                        text={userData.logins}
                        width={"calc(25% - 12px)"}
                        adaptiveWidth={"calc(50% - 8px)"}
                    />
                    <InfoCard
                        icon={CalendarIcon}
                        title={"Последний вход"}
                        text={moment(userData.last_visit).format("DD.MM.YYYY HH:mm")}
                        width={"calc(25% - 12px)"}
                        adaptiveWidth={"calc(50% - 8px)"}
                    />
                    <InfoCard
                        icon={LanguageIcon}
                        title={"Язык системы"}
                        text={"Русский"}
                        width={"calc(25% - 12px)"}
                        adaptiveWidth={"calc(50% - 8px)"}
                    />
                </Row>
            </UserInfo>
            <Separator/>
            <UpcomingInterviews/>
            <Separator/>
            <Chart data={userData.seven_days_profile_statistics}/>
        </>
    );
});

const Row = styled.div`
  display: flex;
  gap: 16px;
  
  @media (max-width: 820px) {
    flex-wrap: wrap;
  }
`;

const Separator = styled.div`
  margin: 32px 0;
  width: 100%;
  height: 1px;
  background-color: ${theme.colors.neutral4};
  flex-shrink: 0;
`;

const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export default MainPage;