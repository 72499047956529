import {FC} from "react";

interface Props {
    onClick?: () => void
}

const FilterIcon: FC<Props> = ({onClick}) => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            onClick={onClick}
        >
            <g clipPath="url(#clip0_53_1693)">
                <path
                    d="M7.00018 6H17.0002L11.9902 12.3L7.00018 6ZM4.25018 5.61C6.27018 8.2 10.0002 13 10.0002 13V19C10.0002 19.55 10.4502 20 11.0002 20H13.0002C13.5502 20 14.0002 19.55 14.0002 19V13C14.0002 13 17.7202 8.2 19.7402 5.61C20.2502 4.95 19.7802 4 18.9502 4H5.04018C4.21018 4 3.74018 4.95 4.25018 5.61Z"
                    fill="black"/>
            </g>
            <defs>
                <clipPath id="clip0_53_1693">
                    <rect width="24" height="24" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    );
};

export default FilterIcon;
