import React, {FC, useCallback, useState} from "react";
import {createCandidateFormsOrder, putCandidateFormsOrder} from "../../../actions/candidateFormsOrder";
import {Input} from "../../../components/Input";
import {useFetching} from "../../../hooks/useFetching";
import moment from "moment";

const MAX_LENGTH = 5;
const MAX_NUMBER = 10000;

interface Props {
    row: any;
    field: string;
    value: string | number;
    onRequestFulfilled?: () => void;
}

const TableInput: FC<Props> = ({
    row,
    field,
    value,
    onRequestFulfilled
}) => {
    const [inputValue, setInputValue] = useState(value);
    const inputValueHandler = (newValue) => {
      const countZero = newValue.slice(0, 2);
      if(newValue.length > MAX_LENGTH || isNaN(+newValue) || +newValue > MAX_NUMBER || countZero === "00") return;
      setInputValue(newValue);
    }
    
    const [putCandidateFormsOrderHandle, isLoading, error] = useFetching(async (data) => {
        const {id, requestData} = data;
        await putCandidateFormsOrder(id, requestData);
        onRequestFulfilled();
    });

  const [createCandidateFormsOrderHandle, isLoadingCreate, errorCreate] = useFetching(async (requestData) => {
    await createCandidateFormsOrder(requestData);
    onRequestFulfilled();
  });

    const inputBlurHandle = useCallback((newValue) => {
      if (newValue !== inputValue) {
          const month = field.split('.')[0];
          const property = field.split('.')[1];
          const candidateOrder = row[month];
          const neededForMonth = month === 'candidate_orders_current_month'
            ? moment().format("YYYY-MM-01")
            : moment().add(1, 'month').format("YYYY-MM-01");

          const requestData = {
            restaurant_id: row.id,
            needed_for_month: neededForMonth,
          }
          requestData[property] = +newValue;
          candidateOrder ?
            putCandidateFormsOrderHandle({id: candidateOrder.id, requestData}) :
            createCandidateFormsOrderHandle(requestData);
        }
    }, []);

    const onKeyUp = (event) => {
      if(event.code === "Enter") inputBlurHandle(event.target.value);
    }

    return (
        <>
            <Input
                disabled={isLoading || isLoadingCreate}
                value={inputValue}
                withCloseIcon={false}
                height="24px"
                onChange={(newValue) => inputValueHandler(newValue)}
                onBlur={(event, value) => inputBlurHandle(value)}
                onKeyUp={onKeyUp}
            />
        </>
    );
};

export default TableInput;