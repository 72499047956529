import React, { Fragment, useContext, useMemo } from "react";
import { Switch, Route } from "react-router-dom";
import { privateRoutes } from "../config/routes";
import Page404 from "../pages/Page404";
import { AuthStoreContext } from "../stores/auth";

const PrivateRoutes = () => {
  const { userData } = useContext(AuthStoreContext);
  const {
    is_access_to_ordering_offline_advertising,
    is_candidate_profile_order_access,
    is_plr_access,
    role,
  } = userData;

  const filteredRoutes = useMemo(() => {
    if (userData.role) {
      return Object.keys(privateRoutes).filter((routeKey) => {
        const route = privateRoutes[routeKey];
        if (route.roles.length === 0 || route.roles.includes(role.name)) {
          return true;
        } else {
          if (routeKey === "offline_advertising") {
            return is_access_to_ordering_offline_advertising;
          }
          if (routeKey === "candidateFormsOrders") {
            return is_candidate_profile_order_access;
          }
          if (routeKey === "plr") {
            return is_plr_access;
          }
        }
      });
    } else {
      return [];
    }
  }, [privateRoutes, userData]);

  return (
    <Switch>
      {filteredRoutes.map((routeKey) => {
        const route = privateRoutes[routeKey];
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component: any = route.component;

        return (
          <Route
            key={`route-${route.path}`}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  <Component {...props} />
                </Layout>
              </Guard>
            )}
          />
        );
      })}
      <Route path="*" render={(props) => <Page404/>} />
    </Switch>
  );
};

export default PrivateRoutes;
