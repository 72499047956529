import React from "react";
import styled from "styled-components";
import Button from "../../../../../components/Button";
import { Modal } from "../../../../../components/Modal";
import { connectModal } from "../../../../../hocs/Modal";
import {Data} from "./data";
import {
  Answers,
  AnswerItem,
  AnswerTitle,
  FormActions,
  QuestionItem,
  Block,
  QuestionNumber,
  QuestionText,
  QuestionsList,
} from "../styles";
import Radio from "../../../../../components/Radio";

const SIGModal = connectModal('sig-modal')(
  ({
     handleHide
   }) => {
    return (
      <Modal
        title={"SIG-опросник"}
        onClose={handleHide}
        width={"900px"}
        footer={<FormActions><Button title={"Отправить"} btnStyle={'primary'}/></FormActions>}
        closeDataTip={"После закрытия ответы не сохрантся"}
      >
        <Block flexDirection={'column'} gap={16}>
          {
            Data.map((item, index) => {
              const num = index + 1;
              return (
                <Block flexDirection={'column'}>
                  <Answers>
                    {
                      item.answers.map((item, index) => {
                        return (
                          <AnswerItem
                            key={'answer' + index}
                            width={index === 2 ? "100px" : "100%"}
                            bold={index === 2}
                          >
                            <AnswerTitle>{item.title}</AnswerTitle>
                            <ul>
                              {
                                item.textList.map((item, index) => {
                                  return (
                                    <li>{item.text}</li>
                                  )
                                })
                              }
                            </ul>
                          </AnswerItem>
                        )
                      })
                    }
                  </Answers>
                  <QuestionItem>
                    <Block gap={4}>
                      <QuestionNumber>{num}</QuestionNumber>
                      <QuestionText>{item.text}</QuestionText>
                    </Block>
                    <Block padding={"0 0 0 28px"} gap={16}>
                      <Radio checked={false} value={"A"} name={'question' + num} label={"A"} onChange={()=>{}}/>
                      <Radio checked={false} value={"B"} name={'question' + num} label={"B"} onChange={()=>{}}/>
                      <Radio checked={false} value={"C"} name={'question' + num} label={"C"} onChange={()=>{}}/>
                    </Block>
                  </QuestionItem>
                </Block>
              )
            })
          }
        </Block>
      </Modal>
    )
  })

export default SIGModal;