const CalendarViewMonthIcon = () => {
  return (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
          <g clipPath="url(#clip0_153_626)">
              <path d="M20 4H4C2.9 4 2 4.9 2 6V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6C22 4.9 21.1 4 20 4ZM8 11H4V6H8V11ZM14 11H10V6H14V11ZM20 11H16V6H20V11ZM8 18H4V13H8V18ZM14 18H10V13H14V18ZM20 18H16V13H20V18Z" fill="black"/>
          </g>
          <defs>
              <clipPath id="clip0_153_626">
                  <rect width="24" height="24" fill="white"/>
              </clipPath>
          </defs>
      </svg>
  );
};

export default CalendarViewMonthIcon;
