export const Data = [
  {
    answers: [
      {
        title: 'A',
        textList: [
          {
            text: 'Дал ясный пример отличного обслуживания.'
          },
          {
            text: 'Выявил причины отличного обслуживания.'
          },
          {
            text: 'Отменил важность отличного обслуживания.'
          },
          {
            text: 'Приводил ясный пример того, как важно представлять великолепное обслуживание.'
          },
        ],
      },
      {
        title: 'B',
        textList: [
          {
            text: 'У кандидата нет примеров.'
          },
          {
            text: 'Не приводит примеры отличного обслуживания.'
          },
          {
            text: 'Раздражается на вопрос об идеальном обслуживании.'
          },
          {
            text: 'Не сделал выводы\\ не посчитал важным обращать внимание на обслуживание.'
          },
        ],
      },
      {
        title: 'C',
        textList: [
          {
            text: 'A+B'
          },
        ],
      },
    ],
    text: '( Гостемания ) Расскажите мне об одном из случаев, когда вы столкнулись с идеальным обслуживанием.'
  },
  {
    answers: [
      {
        title: 'A',
        textList: [
          {
            text: 'Дал ясный пример плохого обслуживания.'
          },
          {
            text: 'Выявил причины плохого обслуживания.'
          },
          {
            text: 'Отметил, что…'
          },
          {
            text: 'Приводил ясный пример того, как важно представлять великолепное обслуживание.'
          },
        ],
      },
      {
        title: 'B',
        textList: [
          {
            text: 'У кандидата нет примеров.'
          },
          {
            text: 'Не приводит примеры плохо обслуживания.'
          },
          {
            text: 'Раздражался и вступал в конфликт при плохом обслуживании'
          },
          {
            text: 'Не сделал выводы\\ не посчитал важным обращать внимание на обслуживание.'
          },
        ],
      },
      {
        title: 'C',
        textList: [
          {
            text: 'A+B'
          },
        ],
      },
    ],
    text: '( Гостемания ) Вспомните случай, когда вы столкнулись с плохим обслуживанием.'
  },
  {
    answers: [
      {
        title: 'A',
        textList: [
          {
            text: 'Приводил причины, когда проявлял услужливость.'
          },
          {
            text: 'Любит помогать другим...'
          },
          {
            text: 'Ориентирован на предвосхищение ожидания людей, помогает при просьбах.'
          },
        ],
      },
      {
        title: 'B',
        textList: [
          {
            text: 'Требовалось усилие, чтобы объяснить, что значит услужливость.'
          },
          {
            text: 'Не любит помогать другим\\быть услужливым.'
          },
          {
            text: 'Раздражался на вопрос об услужливости.'
          },
        ],
      },
      {
        title: 'C',
        textList: [
          {
            text: 'A+B'
          },
        ],
      },
    ],
    text: '( Гостемания ) Вспомните ситуацию, где вы проявили услужливость?'
  },
  {
    answers: [
      {
        title: 'A',
        textList: [
          {
            text: 'Оставался спокойным и позитивным.'
          },
          {
            text: 'Решил проблему.'
          },
          {
            text: 'Справился с ней быстро.'
          },
          {
            text: 'Стресс не повлиял на его или ее работу.'
          },
          {
            text: 'Дал пример того, как он или она справились со стрессовой ситуацией.'
          },
        ],
      },
      {
        title: 'B',
        textList: [
          {
            text: 'Фокусировался на негативном.'
          },
          {
            text: 'Не выявил причину стресса.'
          },
          {
            text: 'Не мог бы предотвратить повторения стрессовой ситуации.'
          },
          {
            text: 'Был в плохом настроении.'
          },
          {
            text: 'Не знал, как справиться со стрессовой ситуацией.'
          },
        ],
      },
      {
        title: 'C',
        textList: [
          {
            text: 'A+B'
          },
        ],
      },
    ],
    text: '( Позитивная энергия ) У каждого человека бывают конфликтные ситуации в школе или на работе. Расскажите об одной из них.'
  },
  {
    answers: [
      {
        title: 'A',
        textList: [
          {
            text: 'Хороший командный игрок. Был лидером в команде.'
          },
          {
            text: 'Предлагает помощь без просьб о ней.'
          },
          {
            text: 'Помогал работать над достижением целей команды. Поощрял команду.'
          },
          {
            text: 'Помогал команде принимать решения.'
          },
          {
            text: 'По-настоящему получал удовольствие от работы в команде.'
          },
        ],
      },
      {
        title: 'B',
        textList: [
          {
            text: 'Позволял команде выполнять большую часть работы.'
          },
          {
            text: 'Обычно помогает только тогда, когда его просят.'
          },
          {
            text: 'Не хотел быть вовлеченным.'
          },
          {
            text: 'Продвигает выполнение его или ее личных целей.'
          },
          {
            text: 'Предпочитал индивидуальную работу работе в команде.'
          },
        ],
      },
      {
        title: 'C',
        textList: [
          {
            text: 'A+B'
          },
        ],
      },
    ],
    text: '( Работа в команде ) Что для Вас командная работа. Приведите примеры, когда Вы выполняли какую-либо работу (задание) работая в команде. Какую работу нужно выполнять, работая в команде? Почему?'
  },
  {
    answers: [
      {
        title: 'A',
        textList: [
          {
            text: 'Был самомотивирован и уверен.'
          },
          {
            text: 'Ставил цели и работал усиленно над их достижением.'
          },
          {
            text: 'Показал гордость и удовлетворение его или ее достижением..'
          },
          {
            text: 'Усиленно работал и прилагал дополнительные усилия.'
          },
          {
            text: 'Приводил детально, что он или она узнал.'
          },
        ],
      },
      {
        title: 'B',
        textList: [
          {
            text: 'Не знал, что его или ее делает успешным.'
          },
          {
            text: 'Не ставил цели.'
          },
          {
            text: 'Не заботился о том, успешный ли он или она.'
          },
          {
            text: 'Не прилагал много усилий.'
          },
          {
            text: 'Не получил много опыта.'
          },
        ],
      },
      {
        title: 'C',
        textList: [
          {
            text: 'A+B'
          },
        ],
      },
    ],
    text: '( Ответственность ) Каким своим достижением или действием вы гордитесь больше всего? (соревнования, знания, работа, дом)'
  },
  {
    answers: [
      {
        title: 'A',
        textList: [
          {
            text: 'Показал хорошую рассудительность. Поступил ответственно.'
          },
          {
            text: 'Сообщил о нечестных действиях немедленно.'
          },
          {
            text: 'Много уяснил.'
          },
          {
            text: 'Привел пример того, как он или она справился с ситуацией.'
          },
        ],
      },
      {
        title: 'B',
        textList: [
          {
            text: 'Не показал рассудительность.'
          },
          {
            text: 'Признал действия нечестными, но не был обеспокоен этим.'
          },
          {
            text: 'Ничего не уяснил.'
          },
          {
            text: 'Не смог объяснить, как бы он или она справился с ситуацией.'
          },
        ],
      },
      {
        title: 'C',
        textList: [
          {
            text: 'A+B'
          },
        ],
      },
    ],
    text: '( Вера во ВСЕХ людей ) Расскажите мне о случае, когда вы видели кого-нибудь, кто поступал нечестно.'
  },
  {
    answers: [
      {
        title: 'A',
        textList: [
          {
            text: 'Чувствовал себя удобно в начале разговора.'
          },
          {
            text: 'Его легко было понять.'
          },
          {
            text: 'Давал хорошие, четкие ответы.'
          },
          {
            text: 'Задавал хорошие вопросы.'
          },
          {
            text: 'Хорошо слушал и отвечал.'
          },
          {
            text: 'Уделял внимание.'
          },
        ],
      },
      {
        title: 'B',
        textList: [
          {
            text: 'Был неуклюж.'
          },
          {
            text: 'Его было трудно понять.'
          },
          {
            text: 'Давал короткие, неясные ответы.'
          },
          {
            text: 'Не показывал достаточного интереса.'
          },
          {
            text: 'Недостаточно слушал.'
          },
          {
            text: 'С трудом фокусировался.'
          },
        ],
      },
      {
        title: 'C',
        textList: [
          {
            text: 'A+B'
          },
        ],
      },
    ],
    text: '( Оценка Коммуникации ) Пожалуйста, оцените все навыки общения кандидата.'
  },
  {
    answers: [
      {
        title: 'A',
        textList: [
          {
            text: 'Улыбался и поддерживал зрительный контакт.'
          },
          {
            text: 'Показывал заинтересованность.'
          },
          {
            text: 'Оптимист.'
          },
          {
            text: 'Уверен в себе.'
          },
        ],
      },
      {
        title: 'B',
        textList: [
          {
            text: 'Улыбался не много. Не поддерживал зрительный контакт.'
          },
          {
            text: 'Не являлся энтузиастом.'
          },
          {
            text: 'Фокусировался на негативном.'
          },
          {
            text: 'Выглядел неуверенным.'
          },
        ],
      },
      {
        title: 'C',
        textList: [
          {
            text: 'A+B'
          },
        ],
      },
    ],
    text: '( Оценка по Позитивной Энергии ) Пожалуйста, оцените поведение кандидата в течение собеседования.'
  }
]