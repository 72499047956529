import React, {FC, memo, useCallback, useRef, useState} from "react";
import styled from "styled-components";
import {useOutsideClick} from "../../../../../hooks/useOutsideClick";
import {theme} from "../../../../../styles/theme";

interface Props {
    dayWeek?: string;
    dayMonth?: string;
    currentDay?: number,
    reservedSlots?: any[];
    pickedTime?: string;
    slotsTotal?: number;
    disabled?: any;
    onSetContextMenuPosition?: (position: {left: number; top: number}) => void;
    onSetContextMenuOpen?: (open: boolean) => void;
    onSetCurrentCellRef?: (ref: any) => void;
    onSetPickedDate?: (day: string) => void;
}

const getContextMenuPosition = (ref) => {
    if (ref && ref.current) {
        const rect = ref.current.getBoundingClientRect();
        return {
            left: rect.left,
            top: rect.top + rect.height + 60,
        };
    } else {
        return {
            left: 0,
            top: 0,
        };
    }
};

const getSlotBackground = (active, picked, disabled) => {
    if (disabled) {
        return theme.colors.neutral5;
    }
    if (picked) {
        return theme.colors.green;
    }
    if (active) {
        return theme.colors.primary;
    }
    return theme.colors.neutral2;
};

const getSlotColor = (active, picked) => {
    if (picked || active) {
        return "white";
    }
    return theme.colors.neutral10;
};

const Cell: FC<Props> = memo(({
    dayWeek,
    dayMonth,
    reservedSlots,
    pickedTime,
    slotsTotal,
    disabled = false,
    onSetContextMenuPosition,
    onSetContextMenuOpen,
    onSetCurrentCellRef,
    onSetPickedDate,
    currentDay,
}) => {
    const [active, setActive] = useState(false);
    const ref = useRef(null);
    
    useOutsideClick(ref, () => {
        setActive(false);
    });
    
    const togglePicked = useCallback(() => {
        if (disabled) {
            return;
        }
        
        setActive((prevState) => {
            onSetContextMenuOpen(!prevState);
            return !prevState;
        });
    }, [onSetContextMenuOpen]);
    
    return (
        <CellWrapper
          ref={ref}
          disabled={disabled}
        >
            <CellTopRowWrapper>
                <CellDayMonthWrapper
                  currentDay={currentDay == parseInt(dayMonth)}
                >
                    {dayMonth}
                </CellDayMonthWrapper>
                <CellDayWeekWrapper>{dayWeek}</CellDayWeekWrapper>
            </CellTopRowWrapper>
            
            {dayMonth && !disabled && (
                <FreeSlotsWrapper
                    active={active}
                    picked={!!pickedTime}
                    disabled={disabled}
                    onClick={() => {
                        togglePicked();
                        onSetContextMenuPosition(getContextMenuPosition(ref));
                        onSetCurrentCellRef(ref);
                        onSetPickedDate(dayMonth);
                    }}
                >
                    {pickedTime && <>{pickedTime}</>}
                    {!pickedTime && (
                        <>
                            {
                                document.getElementsByTagName('body')[0].getBoundingClientRect().width > 555 &&
                              <>
                                  Свободно {" "}
                              </>
                            }
                            {slotsTotal - reservedSlots.length}
                            {" "} слотов
                        </>
                    )}
                </FreeSlotsWrapper>
            )}
        </CellWrapper>
    );
});

const CellWrapper = styled.div<{disabled: boolean,}>`
  padding: 5px;
  width: calc(100% / 7);
  height: 63px;
  aspect-ratio: 1;
  display: flex;
  flex-direction: column;
  gap: 5px;
  border-right: 1px solid ${theme.colors.neutral2};
  ${({disabled}) => disabled && "background-color: rgba(228, 0, 43, 0.02)"};
    
  &:last-child {
      border-right: 0;
  }
`;

const CellTopRowWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CellDayMonthWrapper = styled.div<{
    currentDay: boolean,
}>`
  color: ${({currentDay}) => currentDay ? theme.colors.primary : theme.colors.neutral10};
  font-size: ${theme.fontSizes.body};
`;

const CellDayWeekWrapper = styled.div`
  color: ${theme.colors.neutral6};
  font-size: ${theme.fontSizes.body};
`;

const FreeSlotsWrapper = styled.div<{active: boolean; picked: boolean; disabled: boolean}>`
  cursor: ${props => props.disabled ? "not-allowed" : "pointer"};
  padding: 3px;
  word-break: break-word;
  font-size: 11px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => getSlotBackground(props.active, props.picked, props.disabled)};
  color: ${(props) => getSlotColor(props.active, props.picked)};
    
  &:hover {
      background: ${(props) => props.active || props.picked ? theme.colors.primaryDark : theme.colors.neutral4};
  }
`;

export default Cell;