import React, {FC, memo, useCallback} from 'react';
import {
    Cell,
    GridRowWrapper,
    FieldLabelWrapper,
    FieldValueWrapper,
    FieldValueBoldWrapper,
    RowIdWrapper,
    Block
} from "../../styles/gridRowDefaults";
import styled from "styled-components";
import moment from 'moment';

interface Props {
    data: {
        "id": string;
        "full_name": string;
        "role": {
            name: string;
            role_name_ru: string;
        },
        "job_title": string;
        "partner": {
            name: string;
        },
        "created_at": string;
    };
    onRowClick?: (item: any) => void;
}

const GridRow: FC<Props> = memo(({
    data,
    onRowClick
}) => {
    const rowClickHandle = useCallback(() => {
        onRowClick(data);
    }, []);
    
    return (
        <GridRowWrapper onClick={rowClickHandle}>
            <Block>
                <RowIdWrapper>ID {data.id}</RowIdWrapper>
                <FieldValueBoldWrapper>{data.full_name}</FieldValueBoldWrapper>
            </Block>
            <UserGridRow>
                <Cell setFlex={'0 0 26%'}>
                    <div className="cell-row">
                        <FieldLabelWrapper>Роль</FieldLabelWrapper>
                        <FieldValueWrapper>{data.role?.role_name_ru}</FieldValueWrapper>
                    </div>
                </Cell>
                
                <Cell setFlex={'1 0 26%'}>
                    <div className="cell-row">
                        <FieldLabelWrapper>Должность</FieldLabelWrapper>
                        <FieldValueWrapper>{data?.job_title}</FieldValueWrapper>
                    </div>
                </Cell>
                
                <Cell setFlex={'0 0 26%'}>
                    <div className="cell-row">
                        <FieldLabelWrapper>Партнер</FieldLabelWrapper>
                        <FieldValueWrapper>{data.partner?.name}</FieldValueWrapper>
                    </div>
                </Cell>
    
                <Cell setFlex={'0 0 17%'}>
                    <div className="cell-row">
                        <FieldLabelWrapper>Дата создания</FieldLabelWrapper>
                        <FieldValueWrapper>
                            {moment(data.created_at).format("DD.MM.YYYY HH:mm")}
                        </FieldValueWrapper>
                    </div>
                </Cell>
            </UserGridRow>
        </GridRowWrapper>
    );
});

const UserGridRow = styled(Block)`
  @media (max-width: 700px) {
      flex-wrap: wrap;
      &>div {
          min-width: 180px;
          flex: 1 1 calc((100% - 16px)/2);
      }
  }
`;

export default GridRow;
