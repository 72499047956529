import React, {FC} from 'react';
import styled from "styled-components";
import CloseIcon from "../../assets/icons/CloseIcon";
import {theme} from "../../styles/theme";

interface Props {
    text: string,
    value: string | number;
    withIcon?: boolean;
    icon?: any;
    onClick?: (text?: string, value?: string | number) => void;
}

const Chip: FC<Props> = ({
    text,
    value,
    withIcon = false,
    icon = CloseIcon,
    onClick
}) => {
    const Icon = icon;
    
    return (
        <ChipWrapper>
            <TextWrapper>{text}</TextWrapper>
            
            {withIcon &&
                <IconWrapper>
                    <Icon
                        width="18"
                        height="18"
                        fill="white"
                        onClick={() => onClick(text, value)}
                    />
                </IconWrapper>
            }
        </ChipWrapper>
    );
};

const ChipWrapper = styled.div`
  width: max-content;
  display: flex;
  gap: 10px;
  align-items: center;
  background: ${theme.colors.primary};
  border-radius: 40px;
  padding: 6px 15px;
  font-family: ${theme.fonts.condensedBlack};
  font-size: ${theme.fontSizes.body};
`;

const TextWrapper = styled.div`
  color: white;
`;

const IconWrapper = styled.div`
  display: flex;
  cursor: pointer;
`;

export default Chip;