import cloneDeep from "lodash/cloneDeep";
import React, {FC, memo, useCallback, useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import CloseIcon from "../../assets/icons/CloseIcon";
import ChevronIcon from "../../assets/icons/ChevronIcon";
import sortOptions from "../../utils/sortOptions";
import {theme} from "../../styles/theme";
import {OptionsBlock} from "./OptionsBlock";

interface Props {
    title: string;
    options: any[];
    withSubOptions: boolean;
    subOptionsKey?: string;
    value: string[];
    ref?: any;
    disabled?: boolean;
    onChange: (value: string[]) => void;
}

const MultiSelect: FC<Props> = memo(({
    title,
    options,
    withSubOptions,
    subOptionsKey,
    value,
    disabled,
    onChange
}) => {
    const selectWrapperRef = useRef();
    const [optionsOpen, setOptionsOpen] = useState(false);
    const [headerTitle, setHeaderTitle] = useState("Все");
    const [expandedOptionsIds, setExpandedOptionsIds] = useState([]);
    const [filterInputValue, setFilterInputValue] = useState("");
    const [filteredOptions, setFilteredOptions] = useState(
        sortOptions(options, "label", withSubOptions, subOptionsKey)
    );
    
    useEffect(() => {
        if (withSubOptions) {
            let newFilteredOptions = cloneDeep(options);
            newFilteredOptions = newFilteredOptions.filter((option, index) => {
                let showOption = false;
            
                if (option.label.toLowerCase().includes(filterInputValue.toLowerCase())) {
                    return true;
                }
            
                option[subOptionsKey] = options[index][subOptionsKey].filter(subOption => {
                    if (subOption.label.toLowerCase().includes(filterInputValue.toLowerCase())) {
                        showOption = true;
                        return true;
                    }
                });
            
                return showOption;
            });
            setFilteredOptions(sortOptions(newFilteredOptions, "label", withSubOptions, subOptionsKey));
        } else {
            const newFilteredOptions = options.filter(
                option => option.label.toLowerCase().includes(filterInputValue.toLowerCase())
            );
            setFilteredOptions(sortOptions(newFilteredOptions, "label", withSubOptions, subOptionsKey));
        }
    }, [options, filterInputValue]);
    
    useEffect(() => {
        headerTitleManage();
    }, [value]);
    
    const headerTitleManage = useCallback(() => {
        let totalCount = 0;
        const count = value.length;
        
        if (withSubOptions) {
            options.map(option => {
                totalCount += option[subOptionsKey].length;
            });
        } else {
            totalCount = options.length;
        }
        
        if (totalCount === count && count !== 0) {
            setHeaderTitle("Все");
        } else {
            setHeaderTitle("Выбрано - " + count.toString());
        }
    }, [value, options]);
    
    const headerClickHandle = useCallback(() => {
        if (!disabled) {
            setOptionsOpen(prevState => {
                return !prevState;
            });
        }
    }, [disabled]);
    
    const resetCheckedOptions = useCallback((event) => {
        event.stopPropagation();
        onChange([]);
    }, []);
    
    return (
        <SelectWrapper
          ref={selectWrapperRef}
          disabled={disabled}
        >
            <SelectTitle
              disabled={disabled}
              optionsOpen={optionsOpen}
            >
                <span>{title}</span>
            </SelectTitle>
            
            <SelectHeader
                onClick={headerClickHandle}
                disabled={disabled}
                optionsOpen={optionsOpen}
            >
                {headerTitle}
                
                <IconsWrapper>
                    {optionsOpen &&
                        <>
                            <IconElementWrapper clearBtn>
                                <CloseIcon
                                    width="24"
                                    height="24"
                                    onClick={resetCheckedOptions}
                                />
                            </IconElementWrapper>
                            <IconsSeparator/>
                        </>
                    }
                    <IconElementWrapper>
                        <ChevronIcon
                            direction={optionsOpen ? "top" : "bottom"}
                        />
                    </IconElementWrapper>
                </IconsWrapper>
            </SelectHeader>
            
            {optionsOpen &&
              <OptionsBlock
                value={value}
                filterInputValue={filterInputValue}
                withSubOptions={withSubOptions}
                expandedOptionsIds={expandedOptionsIds}
                filteredOptions={filteredOptions}
                selectWrapperRef={selectWrapperRef}
                subOptionsKey={subOptionsKey}
                setFilterInputValue={setFilterInputValue}
                setExpandedOptionsIds={setExpandedOptionsIds}
                setOptionsOpen={setOptionsOpen}
                onChange={onChange}
              />
            }
        </SelectWrapper>
    );
});

const SelectWrapper = styled.div<{disabled: boolean,}>`
  position: relative;
  user-select: none;
  ${({disabled}) => !disabled && "cursor: pointer"};
`;

const SelectTitle = styled.div<{
    disabled: boolean,
    optionsOpen: boolean,
}>`
  position: absolute;
  top: -9px;
  left: 10px;
  padding: 0 3px;
  z-index: 1;
  color: ${({optionsOpen}) => !optionsOpen ? theme.colors.neutral6 : theme.colors.primaryDark};
  font-family: ${theme.fonts.mainRegular};
  font-size: ${theme.fontSizes.small};
  line-height: 12px;
    &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 37%;
        left: 0;
        bottom: 0;
        background-color: ${({disabled}) => disabled ? theme.colors.neutral1 : "#ffffff"};
    }
    span {
        position: relative;
    }
`;

const IconsSeparator = styled.div`
  width: 1px;
  height: 24px;
  background-color: #D9D9D9;  
  margin: 0 2px 0 6px;
`;

const SelectHeader = styled.div<{
    disabled: boolean,
    optionsOpen: boolean,
}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-radius: 4px;
  line-height: 20px;
  border: 1px solid ${({optionsOpen}) => optionsOpen ? theme.colors.primaryDark : theme.colors.neutral7};
  color: ${theme.colors.bodyText};
  font-family: ${theme.fonts.mainRegular};
  font-size: ${theme.fontSizes.body};
  height: 40px;
  background-color: ${({disabled}) => disabled ? theme.colors.neutral1 : "#ffffff"};
  position: relative;
`;

const IconsWrapper = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: 5px;
`;

const IconElementWrapper = styled.div<{clearBtn?: boolean,}>`
  display: flex;
  ${({clearBtn}) => clearBtn && "cursor: pointer"};

  &:hover {
    path {
      ${({clearBtn}) => clearBtn && "fill: " + theme.colors.primary};
    }
  }

  path {
    fill: ${({theme}) => theme.colors.neutral7};
  }
`;

export default MultiSelect;