import React, {FC, memo, useCallback, useContext} from "react";
import styled from "styled-components";
import ModeEditIcon from "../../../../assets/icons/ModeEditIcon";
import ActionIcon from "../../../../components/ActionIcon";
import {FieldLabelWrapper, FieldValueWrapper} from "../../../../styles/gridRowDefaults";
import {theme} from "../../../../styles/theme";
import {INTERVIEW_STATUSES} from "../const";
import {DirectoryStoreContext} from "../../../../stores/directory";

interface Props {
    onOpenInterviewModal: (event: any) => void;
    candidateProfileData: any;
    interviewObjectValue: any;
    statusSelectValue?: number;
    cityValue: any;
    restaurantValue: any;
    formType: string;
}

const Interview: FC<Props> = memo(({
    onOpenInterviewModal,
    candidateProfileData,
    interviewObjectValue,
    statusSelectValue,
    cityValue,
    restaurantValue,
    formType,
}) => {
    const {cities, restaurants} = useContext(DirectoryStoreContext);
    const renderInterviewTime = useCallback(() => {
        return <>
            {interviewObjectValue.interview_date}
        </>;
    }, [interviewObjectValue]);
    
    const setIconDisabled = () => {
        if(!restaurantValue) return true;
        if (formType === "operator") {
            return (!!((candidateProfileData.id && candidateProfileData?.condition !== "in_work") ||
                candidateProfileData?.is_blocking) || !INTERVIEW_STATUSES.includes(statusSelectValue));
        } else {
            return !INTERVIEW_STATUSES.includes(statusSelectValue);
        }
    };
    
    return (
        <>
            <H2>
                <InterviewHeaderWrapper>
                    <span>Собеседование</span>
                    <ActionIcon
                        action={onOpenInterviewModal}
                        disabled={setIconDisabled()}
                    >
                        <ModeEditIcon/>
                    </ActionIcon>
                </InterviewHeaderWrapper>
            </H2>
            
            <>
                {!interviewObjectValue.interview_date && (
                    <InterviewTextWrapper>Не назначено</InterviewTextWrapper>
                )}
                {!!interviewObjectValue.interview_date && (
                    <>
                        <InterviewRow>
                            {cityValue && (
                                <InterviewDataBlock>
                                    <FieldLabelWrapper>Город</FieldLabelWrapper>
                                    <FieldValueWrapper>
                                        {candidateProfileData.city?.name_ru ||
                                          cities.find((item) => item.id === cityValue)?.name_ru
                                        }
                                    </FieldValueWrapper>
                                </InterviewDataBlock>
                            )}
                            
                            {restaurantValue && (
                                <InterviewDataBlock>
                                    <FieldLabelWrapper>Ресторан</FieldLabelWrapper>
                                    <FieldValueWrapper>
                                        {candidateProfileData.restaurant?.name_ru ||
                                          restaurants.find(
                                            (item) => item.id === restaurantValue
                                          )?.name_ru
                                        }
                                    </FieldValueWrapper>
                                </InterviewDataBlock>
                            )}
                            
                            <InterviewDataBlock>
                                <FieldLabelWrapper>Дата</FieldLabelWrapper>
                                <FieldValueWrapper>
                                    {renderInterviewTime()}
                                </FieldValueWrapper>
                            </InterviewDataBlock>
                        </InterviewRow>
                        
                        <InterviewRow>
                            <InterviewDataBlock>
                                <FieldLabelWrapper>Комментарий</FieldLabelWrapper>
                                <FieldValueWrapper>
                                    {interviewObjectValue.description || "-"}
                                </FieldValueWrapper>
                            </InterviewDataBlock>
                        </InterviewRow>
                    </>
                )}
            </>
        </>
    );
});

const H2 = styled.div`
  font-size: ${theme.fontSizes.h2};
  color: ${theme.colors.neutral10};
  font-family: ${theme.fonts.mainBlack};
  margin-bottom: 16px;
`;

const InterviewHeaderWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const InterviewDataBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const InterviewRow = styled.div`
  display: flex;
  justify-content: space-between;

  &:not(:last-child) {
    margin-bottom: 16px;
  }
`;

const InterviewTextWrapper = styled.div`
  font-size: ${theme.fontSizes.h3};
  color: ${theme.colors.neutral6};
`;

export default Interview;