import {toast} from "react-toastify";
import {deleteUser, postUsers, putUsers} from "../../../actions/users";
import ProblemIcon from "../../../assets/icons/ProblemIcon";
import Toast from "../../../components/Toast";
import {connectModal} from "../../../hocs/Modal";
import {Modal} from "../../../components/Modal";
import React, {FC, useCallback, useContext, useState} from "react";
import Button from "../../../components/Button";
import styled from "styled-components";
import {Controller, useForm, useWatch} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {Input} from "../../../components/Input";
import Select from "../../../components/Select";
import {useFetching} from "../../../hooks/useFetching";
import {DirectoryStoreContext} from "../../../stores/directory";
import {ModalsStoreContext} from "../../../stores/modal";
import {theme} from "../../../styles/theme";
import modalSchema from "./modalSchema";
import ControllerRoleFields from "./roleFields/ControllerRoleFields";
import HrPartnerRoleFields from "./roleFields/HrPartnerRoleFields";
import RestaurantRoleFields from "./roleFields/RestaurantRoleFields";

interface Props {
  onRequestFulfilled: () => void;
}

const UsersModal: FC<Props> = connectModal("user-management-modal")(({
 onRequestFulfilled,
 handleHide
}) => {
  const {row} = useContext(ModalsStoreContext).modals["user-management-modal"].props;
  const [isBlockedUser, setIsBlockedUser] = useState<boolean>(row?.count_fall_logins > 0);
  const {roles} = useContext(DirectoryStoreContext);
  const rolesOptions = roles.map(role => ({label: role.role_name_ru, value: role.id}));
  const {handleSubmit, trigger, setValue, control, formState: {isValid, errors}} = useForm({
    mode: 'onChange',
    resolver: yupResolver(modalSchema),
    defaultValues: {
      id: row?.id || null,
      full_name: row?.full_name || "",
      job_title: row?.job_title || "",
      email: row?.email || "",
      role_id: row?.role?.id || null,
      restaurant: row?.role?.id === 1 ? {
        partner_id: row?.partner?.id || null,
        restaurants_ids: row?.restaurants[0]?.id || null,
        is_access_to_auto_call_status: row?.is_access_to_auto_call_status || false,
        is_plr_access: row?.is_plr_access || false,
        is_candidate_profile_order_access: row?.is_candidate_profile_order_access || false,
        is_access_to_ordering_offline_advertising: row?.is_access_to_ordering_offline_advertising || false
      } : {
        partner_id: null,
        restaurants_ids: null,
        is_access_to_auto_call_status: false,
        is_plr_access: false,
        is_candidate_profile_order_access: false,
        is_access_to_ordering_offline_advertising: false
      },
      controller: row?.role?.id === 2 ? {
        partner_id: row?.partner?.id || null,
        restaurants_ids: row?.restaurants.map(item => item.id) || [],
        is_access_to_auto_call_status: row?.is_access_to_auto_call_status || false,
        is_plr_access: row?.is_plr_access || false,
        is_candidate_profile_order_access: row?.is_candidate_profile_order_access || false,
        is_access_to_ordering_offline_advertising: row?.is_access_to_ordering_offline_advertising || false
      } : {
        partner_id: null,
        restaurants_ids: [],
        is_access_to_auto_call_status: false,
        is_plr_access: false,
        is_candidate_profile_order_access: false,
        is_access_to_ordering_offline_advertising: false
      },
      hr_partner: row?.role?.id === 3 ? {
        partner_id: row?.partner?.id || null,
        is_access_to_auto_call_status: row?.is_access_to_auto_call_status || false,
        is_plr_access: row?.is_plr_access || false,
        is_candidate_profile_order_access: row?.is_candidate_profile_order_access || false,
        is_access_to_ordering_offline_advertising: row?.is_access_to_ordering_offline_advertising || false,
        is_hr_email_notify_about_new_candidate: row?.is_hr_email_notify_about_new_candidate || false,
        is_hr_email_notify_about_interview: row?.is_hr_email_notify_about_interview || false,
      } : {
        partner_id: null,
        is_access_to_auto_call_status: false,
        is_plr_access: false,
        is_candidate_profile_order_access: false,
        is_access_to_ordering_offline_advertising: false,
        is_hr_email_notify_about_new_candidate: false,
        is_hr_email_notify_about_interview: false,
      }
    },
  });

  const roleSelectValue = useWatch({
    control,
    name: "role_id"
  });

  const [requestHandle, isLoading] = useFetching(async (data) => {
    let response;
    if (row?.id) {
      response = await putUsers(data, row.id);
    } else {
      response = await postUsers(data);
    }

    if (response?.error?.email) {
      toast(
        "Данный email уже занят",
        {
          icon: <ProblemIcon
            width="24"
            height="24"
            fill={theme.toast.errorParams.iconColor}
          />,
          style: {
            ...theme.toast.commonParams,
            ...theme.toast.errorParams
          }
        }
      );
      return;
    }

    if (data.unlockUser) {
      if (response.data?.count_fall_logins === 0) {
        setIsBlockedUser(false);
      }

      return;
    }

    onRequestFulfilled();
  });

  const [fetchDeleteUser, isLoadingDeleteUser] = useFetching(async () => {
    const response = await deleteUser(row.id);
    if(response?.message === "OK") {
      handleHide();
      onRequestFulfilled();
    }
  });

  const onSubmit = useCallback(async (formData) => {
    let data = {...formData};
    const hrPartnerData = {...data.hr_partner};
    const controllerData = {...data.controller};
    const restaurantData = {...data.restaurant};

    delete data.hr_partner;
    delete data.controller;
    delete data.restaurant;

    if (data.role_id === 3) {
      data = {...data, ...hrPartnerData};
    }
    if (data.role_id === 2) {
      data = {...data, ...controllerData};
    }
    if (data.role_id === 1) {
      data = {...data, ...restaurantData};
      data.restaurants_ids = [data.restaurants_ids];
    }

    if (data.password) {
      data.password_confirmation = data.password;
    } else {
      delete data.password;
    }

    if (data.email === row?.email) {
      delete data.email;
    }

    delete data.id;

    requestHandle(data);
  }, []);

  const unlockUser = () => {
    requestHandle({
      date_fall_logins: 0,
      count_fall_logins: 0,
      unlockUser: true,
    });
  }

  return (
    <Modal
      title={row?.id ? "Редактирование пользователя ID " + row.id : "Новый пользователь HR-модуля"}
      onClose={handleHide}
      noContentPadding
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Content>
          <Controller
            render={({field, fieldState}) => {
              return (
                <Input
                  {...field}
                  {...fieldState}
                  placeholder="Имя"
                  error={errors?.full_name?.message}
                  ref={null}
                />
              );
            }}
            name="full_name"
            control={control}
          />

          <Controller
            render={({field, fieldState}) => {
              return (
                <Input
                  {...field}
                  {...fieldState}
                  placeholder="Должность"
                  error={errors?.job_title?.message}
                  ref={null}
                />
              );
            }}
            name="job_title"
            control={control}
          />

          <Controller
            render={({field, fieldState}) => {
              return (
                <Input
                  {...field}
                  {...fieldState}
                  placeholder="Email"
                  error={errors?.email?.message}
                  ref={null}
                />
              );
            }}
            name="email"
            control={control}
          />

          <Line/>

          <Controller
            render={({field, fieldState}) => {
              return (
                <Select
                  {...field}
                  {...fieldState}
                  title="Роль (глобальные права доступа)"
                  options={rolesOptions}
                  ref={null}
                />
              );
            }}
            name="role_id"
            control={control}
          />

          {roleSelectValue === 1 &&
            <RestaurantRoleFields
              control={control}
              setValue={setValue}
              row={row}
            />
          }

          {roleSelectValue === 2 &&
            <ControllerRoleFields
              control={control}
              setValue={setValue}
              row={row}
            />
          }

          {roleSelectValue === 3 &&
            <HrPartnerRoleFields
              control={control}
            />
          }
        </Content>

        <ButtonsWrapper isViewUnlockButton={isBlockedUser}>
          {isBlockedUser &&
            <Button
              onClick={unlockUser}
              type="button"
              disabled={isLoading}
              btnStyle='secondary'
              title={`Разблокировать (${row?.count_fall_logins})`}
            />
          }

          <RightButtons>
            {!!row?.id &&
              <Button
                type="button"
                onClick={fetchDeleteUser}
                disabled={isLoadingDeleteUser}
                btnStyle="secondary"
                title="Удалить пользователя"
              />
            }

            <Button
              type="button"
              onClick={handleHide}
              btnStyle='cancel'
              title="Отмена"
            />

            <Button
              type="submit"
              disabled={!isValid || isLoading}
              btnStyle='primary'
              title="Сохранить"
            />
          </RightButtons>
        </ButtonsWrapper>
      </Form>

      <Toast/>
    </Modal>
  );
});

const Form = styled.form`
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 16px;
  overflow: auto;
  padding: 16px 24px 24px;
`;

const ButtonsWrapper = styled.div<{ isViewUnlockButton: boolean }>`
  margin-top: 8px;
  width: 100%;
  display: flex;
  justify-content: ${({isViewUnlockButton}) => isViewUnlockButton ? "space-between" : "flex-end"};
  gap: 16px;
  flex-shrink: 0;
  background-color: #ffffff;
  padding: 0 24px 24px;
`;

const RightButtons = styled.div`
  display: flex;
  gap: 16px;
  background-color: #ffffff;
`;

const Line = styled.div`
  width: 100%;
  height: 1px;
  background: ${theme.colors.neutral2};
`;

export default UsersModal;