import { yupResolver } from "@hookform/resolvers/yup";
import React, { useCallback, useContext, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";
import styled from "styled-components";
import ProblemIcon from "../../assets/icons/ProblemIcon";
import Toast from "../../components/Toast";
import { useFetching } from "../../hooks/useFetching";
import { theme } from "../../styles/theme";
import * as yup from "yup";
import Button from "../../components/Button";
import { Input } from "../../components/Input";
import { AuthStoreContext } from "../../stores/auth";
import Checkbox from "../../components/Checkbox";
import Loader from "../../components/Loader";
import {errorsDescriptions} from "../../config/errors";

const schema = yup.object().shape({
  email: yup.string()
    .required(errorsDescriptions.required)
    .trim(),
  password: yup.string().required(errorsDescriptions.required),
  captcha: yup.string().required(),
  is_remember: yup.boolean().required(),
});

const Auth = observer(() => {
  const {
    login,
    getCaptchaImg,
    setCaptchaImg,
    setCaptchaKey,
    checkCaptcha,
    captchaImg,
    captchaKey
  } = useContext(AuthStoreContext);
  const {
    control,
    handleSubmit,
    formState: { isValid, errors },
    resetField,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      email: "",
      password: "",
      captcha: "",
      is_remember: false,
    },
  });
  const problemToastOptions = {
    icon: (
      <ProblemIcon
        width="24"
        height="24"
        fill={theme.toast.errorParams.iconColor}
      />
    ),
    style: {
      ...theme.toast.commonParams,
      ...theme.toast.errorParams,
    },
  };

  const [fetchCaptcha, isLoadingCaptcha, errorCaptcha] = useFetching(async () => {
    const response = await getCaptchaImg();
    if(response.status === 200) {
      setCaptchaImg(response.data.img);
      setCaptchaKey(response.data.key);
    }
  });

  const [fetchAuth, isLoading, error] = useFetching(async (data) => {
    const { email, password, is_remember, captcha } = data;

    const responseCaptcha: any = await checkCaptcha(captcha, captchaKey);

    if(responseCaptcha.status === 200) {
      try {
        const responseAccessToken: any = await login(email, password, is_remember);
        if(responseAccessToken?.error) {
          resetCaptcha('Логин или пароль указаны неверно');
        }
      } catch (e) {
        console.log(e)
        resetCaptcha('Ошибка сервиса авторизации');
      }
    } else {
      resetCaptcha(responseCaptcha.response.data.error);
    }

    setCaptchaImg("");
    setCaptchaKey("");
  });

  const resetCaptcha = (toastText = "") => {
    if(toastText) {
      toast(`${toastText}`, problemToastOptions);
    }
    fetchCaptcha();
    resetField("captcha");
  }

  useEffect(() => {
    fetchCaptcha();
  }, []);

  const onSubmit = useCallback(async (formData) => {
      fetchAuth(formData);
  }, [captchaKey]);

  return (
    <Wrapper onSubmit={handleSubmit(onSubmit)}>
      <Form>
        <Title>Авторизация MARS</Title>
        <Block>
          <Controller
            render={({ field, fieldState }) => (
              <Input
                {...field}
                {...fieldState}
                placeholder="Логин"
                isTopPlaceholder={true}
                inputId={"authEmail"}
                error={errors?.email?.message}
                ref={null}
              />
            )}
            name="email"
            control={control}
          />

          <Controller
            render={({ field, fieldState }) => (
              <Input
                {...field}
                {...fieldState}
                placeholder="Пароль"
                type="password"
                isTopPlaceholder={true}
                inputId={"authPassword"}
                error={errors?.password?.message}
                ref={null}
              />
            )}
            name="password"
            control={control}
          />

          <CaptchaWrapper>
            <CaptchaBlock onClick={fetchCaptcha}>
              {isLoadingCaptcha
                  ?
                <Loader width={30} height={30} />
                  :
                <img src={captchaImg} alt={"Ошибка при загрузки капчи"}/>
              }
            </CaptchaBlock>
            <Controller
              render={({ field, fieldState }) => (
                <Input
                  {...field}
                  {...fieldState}
                  placeholder="Капча"
                  type="text"
                  ref={null}
                />
              )}
              name="captcha"
              control={control}
            />
          </CaptchaWrapper>

          <Controller
            render={({ field, fieldState }) => (
              <Checkbox
                {...field}
                {...fieldState}
                onChange={(value) => field.onChange(value)}
                label="Запомнить меня"
                ref={null}
              />
            )}
            name="is_remember"
            control={control}
          />

          <Text>
            Этот веб-сайт собирается использовать файлы cookies для
            персонализации сервисов и удобства пользователей. Продолжая
            использовать веб-сайт, вы соглашаетесь с использованием файлов
            cookies.
          </Text>

          <Button
            btnStyle="primary"
            title="Войти"
            disabled={!isValid || isLoading}
          />
        </Block>
      </Form>

      <Toast />
    </Wrapper>
  );
});

const Wrapper = styled.form`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const Form = styled.div`
  width: 400px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  background-color: #ffffff;
  padding: 16px 22px;
`;

const Block = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const Title = styled.h2`
  font-family: ${theme.fonts.mainBlack};
  font-size: ${theme.fontSizes.h2};
  color: ${theme.colors.bodyText};
  margin: 0 0 32px;
  font-weight: normal;
`;

const Text = styled.p`
  margin: 0;
  font-family: ${theme.fonts.mainRegular};
  font-size: ${theme.fontSizes.body};
  color: ${theme.colors.bodyText};
`;

const CaptchaWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
`;

const CaptchaBlock = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default Auth;
