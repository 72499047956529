import React, {FC, memo, useContext} from "react";
import {Controller} from "react-hook-form";
import Checkbox from "../../../../components/Checkbox";
import Select from "../../../../components/Select";
import {DirectoryStoreContext} from "../../../../stores/directory";
import initializeMultiselectOptions from "../../../../utils/initializeMultiselectOptions";

interface Props {
    control: any
}

const HrPartnerRoleFields: FC<Props> = memo(({
    control
}) => {
    const {partners} = useContext(DirectoryStoreContext);
    const partnersOptions = initializeMultiselectOptions(partners, "id", "name", false);
    
    return (
        <>
            <Controller
                render={({field, fieldState}) => {
                    return (
                        <Select
                            {...field}
                            {...fieldState}
                            title="Партнер"
                            options={partnersOptions}
                            ref={null}
                        />
                    );
                }}
                name="hr_partner.partner_id"
                control={control}
            />
    
            <Controller
                render={({field, fieldState}) => {
                    return (
                        <Checkbox
                            {...field}
                            {...fieldState}
                            label="Доступ к статусу авто-обзвона"
                            onChange={(value) => field.onChange(value)}
                            ref={null}
                        />
                    );
                }}
                name="hr_partner.is_access_to_auto_call_status"
                control={control}
            />
    
            <Controller
                render={({field, fieldState}) => {
                    return (
                        <Checkbox
                            {...field}
                            {...fieldState}
                            label="Доступ к ПЛР"
                            onChange={(value) => field.onChange(value)}
                            ref={null}
                        />
                    );
                }}
                name="hr_partner.is_plr_access"
                control={control}
            />
    
            <Controller
                render={({field, fieldState}) => {
                    return (
                        <Checkbox
                            {...field}
                            {...fieldState}
                            label="Доступ к заказу анкет"
                            onChange={(value) => field.onChange(value)}
                            ref={null}
                        />
                    );
                }}
                name="hr_partner.is_candidate_profile_order_access"
                control={control}
            />
    
            <Controller
                render={({field, fieldState}) => {
                    return (
                        <Checkbox
                            {...field}
                            {...fieldState}
                            label="Доступ к заказу offline рекламы"
                            onChange={(value) => field.onChange(value)}
                            ref={null}
                        />
                    );
                }}
                name="hr_partner.is_access_to_ordering_offline_advertising"
                control={control}
            />

          <Controller
            render={({field, fieldState}) => {
              return (
                <Checkbox
                  {...field}
                  {...fieldState}
                  label="Оповещение о поступлении новых анкет"
                  onChange={(value) => field.onChange(value)}
                  ref={null}
                />
              );
            }}
            name="hr_partner.is_hr_email_notify_about_new_candidate"
            control={control}
          />

          <Controller
            render={({field, fieldState}) => {
              return (
                <Checkbox
                  {...field}
                  {...fieldState}
                  label="Оповещение о назначенном собеседование"
                  onChange={(value) => field.onChange(value)}
                  ref={null}
                />
              );
            }}
            name="hr_partner.is_hr_email_notify_about_interview"
            control={control}
          />
        </>
    );
});

export default HrPartnerRoleFields;