import axios from "axios";
import {getCookie} from "../../utils/getCookie";

axios.defaults.headers.authorization = getCookie("refresh_token");

axios.interceptors.response.use((response) => {
    if (!response.data) {
        return null;
    }
    return response;
}, (error) => {
    console.log(error); // ### temp
    return error;
});

export default axios;