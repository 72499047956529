import { useContext } from "react";
import { AuthStoreContext } from "../../../stores/auth";
import AdminCandidateForm from "./Admin/CandidateForm";
import OperatorCandidateForm from "./Operator/CandidateForm";

const CandidateForm = (props) => {
  const { isCallCenterOperator } = useContext(AuthStoreContext);
  if (isCallCenterOperator) return <OperatorCandidateForm {...props} />;
  else return <AdminCandidateForm {...props} />;
};

export default CandidateForm;
