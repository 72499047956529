const MenuOpenIcon = () => {
  return (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
          <g clipPath="url(#clip0_53_1701)">
              <path d="M3 18H16V16H3V18ZM3 13H13V11H3V13ZM3 6V8H16V6H3ZM21 15.59L17.42 12L21 8.41L19.59 7L14.59 12L19.59 17L21 15.59Z" fill="black"/>
          </g>
          <defs>
              <clipPath id="clip0_53_1701">
                  <rect width="24" height="24" fill="white"/>
              </clipPath>
          </defs>
      </svg>
  );
};

export default MenuOpenIcon;
