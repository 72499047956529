import {CANDIDATE_FORMS_ORDER, RESTAURANT_FORMS_ORDER, RESTAURANT_FORMS_ORDER_UPDATE} from "../config/paths";
import axios from "../middlewares/axios";
import moment from "moment/moment";

export const createCandidateFormsOrder = async (data) => {
    const response = axios.post(CANDIDATE_FORMS_ORDER, data);
}

export const getRestaurantFormsOrder = async (
  limit,
  page,
  search,
  order,
  filters,
  exportParams
) => {
    const data = {
        limit,
        page,
        with: {
            relationships: [
                "city",
                "partner",
                "candidateOrdersCurrentMonth",
                "candidateOrdersNextMonth"
            ]
        }
    };

    const config = {};

    if (exportParams) {
        data.export = {
            export_type: "xlsx",
            file_name: `Заказ анкет ${moment().format("YYYY-MM-DD HH:mm:ss")}`,
            fields: exportParams
        };
        data.return_type = "export";
        config.responseType = "arraybuffer";
    }

    if (order) {
        data.order = [order];
    }

    const orderObject = {
        type: "single",
        operator: "!=",
        value: "",
        column: "partners.name"
    };

    const filtersList = [];
    const searchGroup = {
        type: "group",
        group: []
    };
    const filtersGroup = {
        type: "group",
        group: []
    };

    if (filters) {
        if (filters?.city?.length) {
            filtersGroup.group.push({
                column: "city_id",
                operator: "=",
                value: filters.city
            });
        }
        if (filters?.restaurant?.length) {
            filtersGroup.group.push({
                column: "id",
                operator: "=",
                boolean: "and",
                value: filters.restaurant
            });
        }
        if (filters?.partner?.length) {
            filtersGroup.group.push({
                column: "partner_id",
                operator: "=",
                boolean: "and",
                value: filters.partner
            });
        }
        if (filters.is_order_autocomplete !== "all") {
            filtersGroup.group.push({
                column: "is_order_autocomplete",
                operator: "=",
                boolean: "and",
                value: filters.is_order_autocomplete
            });
        }

        if (filtersGroup.group.length !== 0) {
            filtersList.push(filtersGroup);
        }
    }

    if (order) {
        if (order.includes("partners.name")) {
            orderObject.column = "partners.name";
            filtersList.push(orderObject);
        }
    }

    if (search) {
        const searchColumns = ["partners.name", "cities.name_ru", "name_ru", "id"];
        searchColumns.map((column, index) => {
            const searchObject = {
                column,
                operator: "ilike",
                value: "%" + search + "%"
            };
            if (index !== 0) {
                searchObject.boolean = "or";
            }
            searchGroup.group.push(searchObject);
        });

        if (searchGroup.group.length !== 0) {
            filtersList.push(searchGroup);
        }
    }

    if (filtersList.length !== 0) {
        data.filter = filtersList;
    }
    const response = await axios.post(RESTAURANT_FORMS_ORDER, data, config);
    return response.data;
}

export async function putCandidateFormsOrder(id, data) {
    const response = await axios.put(CANDIDATE_FORMS_ORDER + "/" + id, {
        ...data
    });
    return response.data;
}

export const getCandidateFormsOrder = async (
  limit,
  page,
  search,
  month
) => {
    const data = {
        limit,
        page
    };

    const filtersList = [];
    const searchGroup = {
        type: "group",
        group: []
    };

    if (search) {
        const searchColumns = ["restaurant_id", "needed_for_month"];
        searchColumns.map((column, index) => {
            let searchObject;
            if(column === "needed_for_month") {
                searchObject = {
                    column,
                    operator: "=",
                    value: month
                }
            } else {
                searchObject = {
                    column,
                    operator: "=",
                    value: search
                };
            }
            if (index !== 0) {
                searchObject.boolean = "and";
            }
            searchGroup.group.push(searchObject);
        });

        if (searchGroup.group.length !== 0) {
            filtersList.push(searchGroup);
        }
    }

    if (filtersList.length !== 0) {
        data.filter = filtersList;
    }
    const response = await axios.post(`${CANDIDATE_FORMS_ORDER}/search`, data);
    return response.data;
}

export async function putRestaurantsFormsOrder(id, data) {
    const response = await axios.put(RESTAURANT_FORMS_ORDER_UPDATE + "/" + id, {
        ...data
    });
    return response.data;
}