import cloneDeep from "lodash/cloneDeep";
import moment from "moment";
import React, {useCallback, useEffect, useRef, useState} from "react";
import styled from "styled-components";
import {Input} from "../../../../components/Input";
import {Modal} from "../../../../components/Modal";
import {connectModal} from "../../../../hocs/Modal";
import {getCandidateHistory} from "../../../../actions/candidates";
import HistoryItem from "./HistoryItem";

const HistoryFormModal = connectModal("history-form-modal")(({handleHide, data}) => {
    const candidatesData = useRef(null);
    const [filteredData, setFilteredData] = useState(null);
    const [filterInputValue, setFilterInputValue] = useState("");
    
    const fetchCandidateHistory = useCallback(async () => {
        const response = await getCandidateHistory(data.id);
        setFilteredData(response.data || {});
        candidatesData.current = response.data;
    }, []);
    
    useEffect(() => {
        fetchCandidateHistory();
    }, []);
    
    const filterInputChangeHandle = useCallback((value) => {
        let newData = cloneDeep(candidatesData.current);
        
        newData = newData.filter(item => {
            if (moment(item.created_at).format("DD.MM.YYYY HH:mm").includes(value.toLowerCase())) {
                return true;
            }
            if (item.created_by.full_name.toLowerCase().includes(value.toLowerCase())) {
                return true;
            }
            if (item?.new_restaurant?.name_ru.toLowerCase().includes(value.toLowerCase())) {
                return true;
            }
            if (
                item?.new_status?.name.toLowerCase().includes(value.toLowerCase())
                || item?.new_status?.comment.toLowerCase().includes(value.toLowerCase())
            ) {
                return true;
            }
            if (item?.new_vacancy?.name.toLowerCase().includes(value.toLowerCase())) {
                return true;
            }
        });
        
        setFilteredData(newData);
    }, [filteredData, candidatesData]);
    
    return (
        <Modal title="История" onClose={handleHide}>
            <InputWrapper>
                <Input
                    value={filterInputValue}
                    placeholder="Поиск..."
                    search={true}
                    onChange={(value) => {
                        setFilterInputValue(value);
                        filterInputChangeHandle(value);
                    }}
                />
            </InputWrapper>
            
            {(filteredData && filteredData.length > 0) && (
                <>
                    {filteredData.map(item =>
                        <HistoryItem key={item.id} data={item}/>
                    )}
                </>
            )}
            
            {(!filteredData || filteredData.length === 0) && (
                <span>Нет истории анкеты</span>
            )}
        </Modal>
    );
});


const InputWrapper = styled.div`
  width: 100%;
  margin-bottom: 15px;
`;

export default HistoryFormModal;