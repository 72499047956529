import React, {FC} from 'react';
import styled from "styled-components";
import {theme} from "../../../../styles/theme";
import {Data} from "./Data";
import Loader from "../../../../components/Loader";

interface Props {
  data: IData;
  isLoading: boolean;
}

interface IData {
  total: number;
  unprocessed: number;
  invited_to_interview: number;
  application_rejected: number;
  employed: number;
  ring_up: number;
  unreadable: number;
  passed_interview: number;
  in_process_of_employment: number;
  employment_rejected: number;
  rejected: number;
  another: number;
  recall: number;
}

const Statistics: FC<Props> = ({data, isLoading}) => {
    return (
    <Wrapper>
      <Title>Статистика</Title>
      {isLoading
        ? <Loader width={50} height={50} />
        : <List>
          {!!data &&
            Data.map((item, index) => {
              if(item.value === 'fake') {
                return <Fake key={index}/>
              }

              return (
                <StatItem key={index} active={item.active}>
                  <StatItemTitle>{item.title}</StatItemTitle>
                  <StatItemCount>{data[item.value]}</StatItemCount>
                </StatItem>
              )
            })
          }
        </List>
      }

    </Wrapper>
  )
}

const Fake = styled.div`
  width: 100%;
  height: 30px;
`;

const List = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0 8px 10px;
  height: 100%;
`;

const StatItemCount = styled.div`
  font-size: ${theme.fontSizes.body};
  color: ${theme.colors.neutral9};
  font-family: ${theme.fonts.mainRegular};
`;

const StatItemTitle = styled.div`
  font-size: ${theme.fontSizes.small};
  color: ${theme.colors.neutral7};
  font-family: ${theme.fonts.mainRegular};
  margin-bottom: 5px;
`;

const StatItem = styled.div<{active: boolean,}>`
  width: 100%;
  padding: 5px 8px;
  border-radius: 4px;
  background-color: ${({active}) => active ? theme.colors.primary : '#ffffff'};
  ${({active}) => !active && 'cursor: pointer'};
  
  &:hover {
    background-color: ${({active}) => active ? theme.colors.primary : theme.colors.neutral2};
  }
  
  ${StatItemTitle},
  ${StatItemCount}{
    ${({active}) => active && 'color: #ffffff'};
  }

  ${StatItemCount} {
    ${({active}) => active && 'font-family: ' + theme.fonts.mainBlack};
  }
`;

const Title = styled.div`
  font-family: ${theme.fonts.mainBlack};
  font-size: ${theme.fontSizes.title};
  color: ${theme.colors.neutral10};
  margin-bottom: 6px;
  padding: 0 8px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  height: 100%;
  width: 199px;
  background-color: #ffffff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 10px 0 0;
  flex-shrink: 0;

  @media (max-width: 700px) {
    order: 1;
    width: 100%;
    margin: 0 0 24px;
    max-height: 200px;
  }
`;

export default Statistics;