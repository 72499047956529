import moment from "moment";

const DEFAULT_VALUE = 0;

export class EffectiveProperties {
    id: number;
    restaurant_id: number;
    first_month: string;
    second_month: string;
    third_month: string;
    hour_average_load: number;
    need_for_hours: number;
    need_for_people: number;
    number_of_employees: number;
    number_of_employees_in_hours: number;
    number_of_employees_people: number;
    number_of_hours_per_month: number;
    personnel_reserve_in_hours: number;
    personnel_reserve_people: number;
    planned_absences_in_hours: number;
    planned_turnover_in_hours: number;
    planned_turnover_people: number;
    target_performance: number;
    total_number_of_hours_worked: number;
    turnover_plan: number;

    constructor(properties) {
        this.id = properties?.id;
        this.restaurant_id = properties?.restaurant_id;
        this.first_month = properties?.first_month;
        this.second_month = properties?.second_month;
        this.third_month = properties?.third_month;
        this.hour_average_load = properties?.hour_average_load || DEFAULT_VALUE;
        this.need_for_hours = properties?.need_for_hours || DEFAULT_VALUE;
        this.need_for_people = properties?.need_for_people || DEFAULT_VALUE;
        this.number_of_employees = properties?.number_of_employees || DEFAULT_VALUE;
        this.number_of_employees_in_hours = properties?.number_of_employees_in_hours || DEFAULT_VALUE;
        this.number_of_employees_people = properties?.number_of_employees_people || DEFAULT_VALUE;
        this.number_of_hours_per_month = properties?.number_of_hours_per_month || DEFAULT_VALUE;
        this.personnel_reserve_in_hours = properties?.personnel_reserve_in_hours || DEFAULT_VALUE;
        this.personnel_reserve_people = properties?.personnel_reserve_people || DEFAULT_VALUE;
        this.planned_absences_in_hours = properties?.planned_absences_in_hours || DEFAULT_VALUE;
        this.planned_turnover_in_hours = properties?.planned_turnover_in_hours || DEFAULT_VALUE;
        this.planned_turnover_people = properties?.planned_turnover_people || DEFAULT_VALUE;
        this.target_performance = properties?.target_performance || DEFAULT_VALUE;
        this.total_number_of_hours_worked = properties?.total_number_of_hours_worked || DEFAULT_VALUE;
        this.turnover_plan = properties?.turnover_plan || DEFAULT_VALUE;
    }

    get firstMonth() {
        return moment(this.first_month);
    }

    fillMonths = () => {
        const firstMonth = this.firstMonth;
        firstMonth.add(1, "months");
        this.second_month = firstMonth.format("YYYY-MM-DD");
        firstMonth.add(1, "months");
        this.third_month = firstMonth.format("YYYY-MM-DD");
    };

    set targetPerformance(value) {
        this.target_performance = value === "" ? 0 : parseInt(value);
        if (this.target_performance === 0) {
            this.numberOfHoursPerMonth = 0;
        }
        else {
            this.numberOfHoursPerMonth = Math.ceil(this.turnover_plan / this.target_performance);
        }
    }

    set turnoverPlan(value) {
        if (value !== "") {
            this.turnover_plan = parseInt(value);
            if (this.turnover_plan === 0) {
                this.numberOfHoursPerMonth = 0;
            }
            else {
                if (this.target_performance === 0) {
                    this.numberOfHoursPerMonth = 0;
                }
                else {
                    this.numberOfHoursPerMonth = Math.ceil(this.turnover_plan / this.target_performance);
                }
            }
        }
        else {
            this.numberOfHoursPerMonth = 0;
        }
    }

    set numberOfHoursPerMonth(value) {
        this.number_of_hours_per_month = parseInt(value);
        if (this.number_of_hours_per_month === 0) {
            this.number_of_employees = 0;
        }
        else {
            this.number_of_employees = Math.ceil(this.number_of_hours_per_month / 168);
        }
        this.calculateResult()
    }

    set plannedAbsencesInHours(value) {
        this.planned_absences_in_hours = value === "" ? 0 : parseInt(value);
        this.calculateResult()
    }

    set totalNumberOfHoursWorked(value) {
        this.total_number_of_hours_worked = parseInt(value);
    }

    calculateResult = () => {
        this.need_for_hours = this.number_of_hours_per_month - (
            this.number_of_employees_in_hours -
            this.planned_turnover_in_hours -
            this.personnel_reserve_in_hours -
            this.planned_absences_in_hours
        );
        this.need_for_people = Math.ceil(this.need_for_hours / 168);
    };

    get dataForSave() {
        return {
            id: this.id,
            restaurant_id: this.restaurant_id,
            target_performance: this.target_performance,
            hour_average_load: this.hour_average_load,
            turnover_plan: this.turnover_plan,
            number_of_hours_per_month: this.number_of_hours_per_month,
            number_of_employees: this.number_of_employees,
            total_number_of_hours_worked: this.total_number_of_hours_worked,
            number_of_employees_in_hours: this.number_of_employees_in_hours,
            number_of_employees_people: this.number_of_employees_people,
            planned_turnover_in_hours: this.planned_turnover_in_hours,
            planned_turnover_people: this.planned_turnover_people,
            personnel_reserve_in_hours: this.personnel_reserve_in_hours,
            personnel_reserve_people: this.personnel_reserve_people,
            planned_absences_in_hours: this.planned_absences_in_hours,
            need_for_hours: this.need_for_hours,
            need_for_people: this.need_for_people,
            first_month: moment(this.first_month).format("YYYY-MM"),
            second_month: moment(this.second_month).format("YYYY-MM"),
            third_month: moment(this.third_month).format("YYYY-MM")
        }
    }
}

export const IndexToKey = {
    0: "target_performance",
    1: "hour_average_load",
    2: "turnover_plan",
    3: "number_of_hours_per_month",
    4: "number_of_employees",
    5: "total_number_of_hours_worked",
    6: "number_of_employees_in_hours",
    7: "number_of_employees_people",
    8: "planned_turnover_in_hours",
    9: "planned_turnover_people",
    10: "personnel_reserve_in_hours",
    11: "personnel_reserve_people",
    12: "planned_absences_in_hours",
    13: "need_for_hours",
    14: "need_for_people",
};

export const IndexToKeySetter = {
    0: "targetPerformance",
    1: "hour_average_load",
    2: "turnoverPlan",
    3: "",
    4: "",
    5: "totalNumberOfHoursWorked",
    6: "numberOfEmployeesInHours",
    7: "",
    8: "",
    9: "",
    10: "",
    11: "",
    12: "plannedAbsencesInHours",
    13: "",
    14: "",
};

export const KeyToType = {
    target_performance: null,
    hour_average_load: "string",
    turnover_plan: null,
    number_of_hours_per_month: "string",
    number_of_employees: "string",
    total_number_of_hours_worked: null,
    number_of_employees_in_hours: "string",
    number_of_employees_people: "string",
    planned_turnover_in_hours: "string",
    planned_turnover_people: "string",
    personnel_reserve_in_hours: "string",
    personnel_reserve_people: "string",
    planned_absences_in_hours: null,
    need_for_hours: "string",
    need_for_people: "string",
};
