import { MAX_LIMIT, PAGE_DEFAULT } from "../config/consts";
import axios from "../middlewares/axios";
import { COMMENTS } from "../config/paths";

export const getComments = async (filters = null) => {
  const data = {
    limit: MAX_LIMIT,
    page: PAGE_DEFAULT,
  };

  data.order = ["created_at"];

  data.with = {
    relationships: ["user"],
  };

  data.filter = [];
  data.filter.push({
    column: "model_id",
    operator: "=",
    value: filters.model_id,
  });
  data.filter.push({
    column: "model_type",
    operator: "=",
    value: filters.model_type,
  });

  const response = await axios.post(COMMENTS + "/search", data);

  return response.data;
};

export const createComment = async (data) => {
  const response = await axios.post(COMMENTS, {
    ...data,
  });

  return response.data;
};
