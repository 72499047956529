/**
 * @file This file renders and handles the top bar of the calendar (navigating between months)
 * Note: All dates are moment objects
 * @author Alwyn Tan
 */

import React, {FC} from 'react';
import styled, {keyframes} from 'styled-components';
import moment from 'moment';
import ActionIcon from "../ActionIcon";
import ChevronIcon from "../../assets/icons/ChevronIcon";

export const fadeIn = keyframes`
  from {
    opacity: 0.2
  }
  to {
    opacity: 1
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  > * {
    margin-bottom: 5%;
    margin-top: 3%;
  }
  > img {
    user-select: none;
    cursor: pointer;
    transition: 0.5s ease all;
    margin-left: 5px;
    margin-right: 5px;
  }
  > img:hover {
    filter: brightness(0);
  }
  > span {
    font-size: 1.35em;
    color: #424242;
    animation: ${fadeIn} 0.5s ease;
  }
`;

interface Props {
    date: moment.Moment,
    onPrevClick: () => void,
    onNextClick: () => void,
}

const TopBar: FC<Props> = ({date, onPrevClick, onNextClick}) => {
    return (
        <Container>
            <ActionIcon action={onPrevClick}>
                <ChevronIcon direction={"left"}/>
            </ActionIcon>
            <span key={date.format()}>
                  {date.format('MMMM YYYY').toUpperCase()}
                </span>
            <ActionIcon action={onNextClick}>
                <ChevronIcon direction={"right"}/>
            </ActionIcon>
        </Container>
    )
};

export default TopBar;
