import React, { FC } from 'react';
import styled from "styled-components";
import {theme} from "../../styles/theme";
import Button from "../../components/Button";
import {history} from "../../routes/history";

interface Props {
  text?: string;
}

const Page404:FC<Props> = ({text}) => {
  return (
    <Wrapper>
      <ErrorText>{text ? text : "Page404"}</ErrorText>
      <Button
        onClick={() => history.goBack()}
        title="Назад"
      />
    </Wrapper>

  )
};

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
`;

const ErrorText = styled.div`
  font-size: ${theme.fontSizes.h1};
  font-family: ${theme.fonts.mainBold};
`;

export default Page404;
