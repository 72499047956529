import moment from "moment/moment";
import React, {FC, memo} from "react";
import styled from "styled-components";
import {FieldLabelWrapper, FieldValueWrapper} from "../../../../styles/gridRowDefaults";

interface Props {
    data: {
        created_at: string;
        created_by: {
            full_name: string;
        };
        vacancy_id: number | null;
        new_restaurant: {
            name_ru: string
        } | null;
        new_status: {
            name: string;
            comment: string;
        } | null;
        new_vacancy: {
            name: string;
        } | null;
    }
}

const HistoryItem: FC<Props> = memo(({data}) => {
    return (
        <Wrapper>
            <>
                <Row>
                    <FieldLabelWrapper>
                        <span>{moment(data.created_at).format("DD.MM.YYYY HH:mm")} </span>
                        <span>{data.created_by.full_name}</span>
                    </FieldLabelWrapper>
                </Row>
    
                {data.vacancy_id && (
                    <Row>
                        <FieldValueWrapper>
                            <span>Новый тип анкеты: </span>
                            {data.vacancy_id === 1 && (
                                <span>Сотрудник ресторана</span>
                            )}
                            {data.vacancy_id !== 1 && (
                                <span>Менеджер смены</span>
                            )}
                        </FieldValueWrapper>
                    </Row>
                )}
    
                {data.new_restaurant && (
                    <Row>
                        <FieldValueWrapper>
                            <span>Анкета передана в новый ресторан: {data.new_restaurant.name_ru}</span>
                        </FieldValueWrapper>
                    </Row>
                )}
    
                {data.new_status && (
                    <Row>
                        <FieldValueWrapper>
                            <span>{data.new_status.name}: </span>
                            <span>{data.new_status.comment}</span>
                        </FieldValueWrapper>
                    </Row>
                )}
    
                {data.new_vacancy && (
                    <Row>
                        <FieldValueWrapper>
                            <span>Новая вакансия анкеты: {data.new_vacancy.name}</span>
                        </FieldValueWrapper>
                    </Row>
                )}
            </>
        </Wrapper>
    );
});

const Wrapper = styled.div`
  &:not(:last-child) {
    margin-bottom: 15px;
  }
`;

const Row = styled.div`
  display: flex;
  gap: 10px;
`;

export default HistoryItem;