import React, { useCallback } from "react";
import styled from "styled-components";
import Button from "../../../../components/Button";
import { Modal } from "../../../../components/Modal";
import { connectModal } from "../../../../hocs/Modal";
import { deleteCandidateProfile } from "../../../../actions/candidates";

const DeleteCandidateFormModal = connectModal("delete-candidate-form-modal")(
  ({ handleHide, data, callback }) => {
    const onSubmit = useCallback(async () => {
      await deleteCandidateProfile(data.id);
      handleHide();
      callback();
    }, []);

    return (
      <Modal title="Удалить анкету" onClose={handleHide}>
        <ButtonsWrapper>
          <Button btnStyle="cancel" title="Отмена" onClick={handleHide} />
          <Button btnStyle="primary" title="Применить" onClick={onSubmit} />
        </ButtonsWrapper>
      </Modal>
    );
  }
);

const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
  gap: 10px;
`;

export default DeleteCandidateFormModal;
