import React, {FC} from 'react';
import Button from "../../Button";
import AngleLeft from "../../../assets/icons/AngleLeft";
import AngleRight from "../../../assets/icons/AngleRight";
import styled from "styled-components";
import {observer} from "mobx-react-lite";
import {ILink} from "../../../types/metaTypes";
import {theme} from "../../../styles/theme";

interface Props {
  page: number;
  totalPage: number;
  pagesArray: ILink[];
  setPage: (string) => void;
  nextPage?: (number) => void;
  prevPage?: (number) => void;
}

const Pagination: FC<Props> = observer(({
  page,
  totalPage,
  pagesArray,
  setPage,
  nextPage,
  prevPage
}) => {

  return (
    <PaginationWrapper>
      <ButtonArrow
        svg={AngleLeft}
        disabled={page === 1}
        onClick={() => setPage(page - 1)}
        mainWidth={'32px'}
      />
      {pagesArray.slice(1, pagesArray.length - 1).map((page, idx) => {
          if(page.label === '...') {
            return (
              <span key={page.label + idx.toString()}>
                {page.label}
              </span>
            )
          } else {
            return (
              <ItemButton
                key={page.label}
                title={page.label}
                btnStyle={page.active ? 'primary' : "secondary"}
                onClick={() => setPage(+page.label)}
                mainWidth={'32px'}
              />
            )
          }
        }
      )}
      <ButtonArrow
        svg={AngleRight}
        disabled={page === +pagesArray.at(- 2).label}
        onClick={() => setPage(page + 1)}
        mainWidth={'32px'}
      />
    </PaginationWrapper>
  );
});

const ItemButton = styled(Button)<{btnStyle: string,}>`
  font-family: ${theme.fonts.mainRegular};
  ${({btnStyle}) => btnStyle === 'secondary' && `
    color: ${theme.colors.neutral8};
    border: 1px solid ${theme.colors.neutral4};
  `}
`;

const ButtonArrow = styled(Button)`
  border: 1px solid ${theme.colors.neutral4};
  padding: 0;
  font-family: ${theme.fonts.mainRegular};
`;

const PaginationWrapper = styled.div`
  display: flex;
  gap: 8px;

  @media (max-width: 630px) { 
    flex-wrap: wrap;
  }
`;

export default Pagination;
