import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import React, {FC, useCallback, useContext, useEffect, useState} from "react";
import {Controller, useForm, useWatch} from "react-hook-form";
import styled from "styled-components";
import * as yup from "yup";
import EyeIcon from "../../../assets/icons/EyeIcon";
import Button from "../../../components/Button";
import Checkbox from "../../../components/Checkbox";
import Chip from "../../../components/Chip";
import {Modal} from "../../../components/Modal";
import Select from "../../../components/Select";
import {errorsDescriptions} from "../../../config/errors";
import {connectModal} from "../../../hocs/Modal";
import {ModalsStoreContext} from "../../../stores/modal";
import {theme} from "../../../styles/theme";
import {Input} from "../../../components/Input";
import formatSelectOptions from "../../../assets/Restaurants/formMain/formatSelectOptions.json";
import Notifications from "./Notifications";
import {changeRestaurant, getRestaurantNotification} from "../../../actions/restaurants";
import {IRow} from "../types/types";
import {DirectoryStoreContext} from "../../../stores/directory";
import {useFetching} from "../../../hooks/useFetching";

const schema = yup.object().shape({
    partner: yup.string().required(errorsDescriptions.required),
    tariff : yup.object().shape({
        name: yup.string().required(errorsDescriptions.required),
    }),
    interview_chk_format: yup.string().required(errorsDescriptions.required),
    interview_chk_place: yup.string().required(errorsDescriptions.required),
    interview_ms_format: yup.string().required(errorsDescriptions.required),
    interview_ms_place: yup.string().required(errorsDescriptions.required)
});

interface Props {
    onRequestFulfilled: () => void;
}

const FormModal: FC<Props> = connectModal("restaurants-modal")(({
    onRequestFulfilled,
    handleHide
}) => {
    const {modals} = useContext(ModalsStoreContext);
    const data: IRow = modals["restaurants-modal"].props.data;
    const [blockingForms, setBlockingForms] = useState(data.block_interviews);
    const [notifications, setNotifications] = useState(null);
    const [formPart, setFormPart] = useState("mainPart");
    const [newDataRow, setNewDataRow] = useState(null);
    const [fetchChange, isLoading, error] = useFetching(async () => {
        const response = await changeRestaurant(data.id, newDataRow)
        if(response.message === "OK") {
            setRestaurants();
            onRequestFulfilled();
            handleHide();
        }
    });
    const [fetchNotifications, isLoadingNotify, errorNotify] = useFetching(async () => {
        const response = await getRestaurantNotification(data.id);
        if(response.message === "OK") {
            setNotifications(response.data);
        }
    });
    const {setRestaurants} = useContext(DirectoryStoreContext);
    useEffect(() => {
        if(newDataRow) {
            fetchChange();
        }
    }, [newDataRow])

    const {
        partners,
        tariffs,
    } = useContext(DirectoryStoreContext)

    const {
        handleSubmit,
        control,
        setValue,
        formState: {isValid, errors}
    } = useForm({
        mode: "onChange",
        resolver: yupResolver(schema),
        defaultValues: {
            partner: data.partner?.name,
            tariff: {
                name: data.tariff?.name,
                description: data.tariff?.description
            },
            is_tariff_manager: data.is_tariff_manager,
            call_bot: !!data.call_bot,
            is_forwarding: !!data.forwarding_link,
            use_in_call_center: data.use_in_call_center,
            ignore_citizenship: data.ignore_citizenship,
            corporative: data.corporative,
            forwarding_link: data.forwarding_link || "",
            is_sixteen_recruitment: data.is_sixteen_recruitment,
            is_seventeen_recruitment: data.is_seventeen_recruitment,
            interview_chk_format: data.interview_format_for_restaurant_employee ? data.interview_format_for_restaurant_employee : "",
            interview_chk_place: data.location_for_restaurant_employee ? data.location_for_restaurant_employee : "",
            interview_ms_format: data.interview_format_for_shift_manager ? data.interview_format_for_shift_manager : "",
            interview_ms_place: data.location_for_shift_manager ? data.location_for_shift_manager : "",
        }
    });

    const tariffValue = useWatch({
        control,
        name: "tariff.name"
    });
    
    const isForwardingValue = useWatch({
        control,
        name: "is_forwarding"
    });

    const notificationsClickHandle = useCallback(async (event) => {
        event.preventDefault();
        await fetchNotifications();
        if(!errorNotify) setFormPart("notificationsPart");
    }, []);
    
    const unlockButtonClickHandle = useCallback((event) => {
        event.preventDefault();
        setBlockingForms([]);
    }, []);
    
    const cancelButtonClickHandle = useCallback((event) => {
        event.preventDefault();
        handleHide();
    }, []);

    const onSubmit = async (formData) => {
        const fetchData = {
            tariff_id: tariffs.find(tariff => tariff.name === formData.tariff.name).id,
            is_tariff_manager: formData.is_tariff_manager,
            partner_id: partners.find(partner => partner.name === formData.partner).id,
            city_id: data.city_id,
            forwarding_link: formData.forwarding_link,
            interview_format_for_restaurant_employee: formData.interview_chk_format,
            location_for_restaurant_employee: formData.interview_chk_place,
            interview_format_for_shift_manager: formData.interview_ms_format,
            location_for_shift_manager: formData.interview_ms_place,
            call_bot: formData.call_bot ? 1 : 0,
            use_in_call_center: formData.use_in_call_center,
            ignore_citizenship: formData.ignore_citizenship,
            corporative: formData.corporative,
            is_sixteen_recruitment: formData.is_sixteen_recruitment,
            is_seventeen_recruitment: formData.is_seventeen_recruitment,

            is_blocking: blockingForms.length !== 0,
            restaurant_id: data.id,
        }
          // "email": "string",
          // "name_ru": "string",
          // "address_ru": "string"

        setNewDataRow(fetchData);
    };

    return (
        <Modal
            title={"Редактирование ресторана " + data.name_ru}
            onClose={handleHide}
        >
            {formPart === "mainPart" &&
                <RestaurantsFormWrapper onSubmit={handleSubmit(onSubmit)} >
                    <RowWrapper>
                        <InputWrapper>
                            <Controller
                              render={({field, fieldState}) =>
                                <Select
                                  {...field}
                                  {...fieldState}
                                  title="Партнер"
                                  // options={partners}
                                  options={partners.map(item => {
                                      return {...item, value: item.name, label: item.name}
                                  })}
                                  onChange={field.onChange}
                                  ref={null}
                                />
                              }
                              name="partner"
                              control={control}
                            />
                        </InputWrapper>
                    </RowWrapper>
        
                    <RowWrapper>
                        <Controller
                            render={({field, fieldState}) =>
                                <Select
                                    {...field}
                                    {...fieldState}
                                    title="Тариф"
                                    // options={tariffs}
                                     options={tariffs.map(item => {
                                        return {...item, value: item.name, label: item.name}
                                     })}
                                    onChange={field.onChange}
                                    ref={null}
                                />
                            }
                            name="tariff.name"
                            control={control}
                        />
                    </RowWrapper>
                    {tariffValue && tariffs.find(tariff => tariff.name === tariffValue)?.description &&
                        <TariffDesc>
                            <TariffDescTitle>Описание тарифа</TariffDescTitle>
                            <TariffDescBody>
                                {tariffs.find(tariff => tariff.name === tariffValue).description}
                            </TariffDescBody>
                        </TariffDesc>
                    }

                    <RowWrapper>
                        <Controller
                          render={({field, fieldState}) =>
                            <Checkbox
                              {...field}
                              {...fieldState}
                              label="Трудоустроенный МС"
                              onChange={(value) => field.onChange(value)}
                              ref={null}
                            />
                          }
                          name="is_tariff_manager"
                          control={control}
                        />
                    </RowWrapper>

                    <RowWrapper>
                        <Controller
                            render={({field, fieldState}) =>
                                <Checkbox
                                    {...field}
                                    {...fieldState}
                                    label="Переадресация"
                                    onChange={(value) => {
                                        field.onChange(value)
                                        setValue("forwarding_link", "")
                                    }}
                                    ref={null}
                                />
                            }
                            name="is_forwarding"
                            control={control}
                        />
                        <InputWrapper>
                            <Controller
                              render={({field, fieldState}) =>
                                <Input
                                  {...field}
                                  {...fieldState}
                                  placeholder="Ссылка"
                                  disabled={!isForwardingValue}
                                  ref={null}
                                />
                              }
                              name="forwarding_link"
                              control={control}
                            />
                        </InputWrapper>
                    </RowWrapper>

                    <RowWrapper>
                        <Controller
                          render={({field, fieldState}) =>
                            <Checkbox
                              {...field}
                              {...fieldState}
                              label="anketa.kfc.ru + call bot"
                              onChange={(value) => field.onChange(value)}
                              ref={null}
                            />
                          }
                          name="call_bot"
                          control={control}
                        />
                    </RowWrapper>
                    <RowWrapper>
                        <Controller
                          render={({field, fieldState}) =>
                            <Checkbox
                              {...field}
                              {...fieldState}
                              label="Подключён к колл-центру"
                              onChange={(value) => field.onChange(value)}
                              ref={null}
                            />
                          }
                          name="use_in_call_center"
                          control={control}
                        />
                    </RowWrapper>
                    <RowWrapper>
                        <Controller
                          render={({field, fieldState}) =>
                            <Checkbox
                              {...field}
                              {...fieldState}
                              label="Осуществляет набор кандидатов 16 лет"
                              onChange={(value) => field.onChange(value)}
                              ref={null}
                            />
                          }
                          name="is_sixteen_recruitment"
                          control={control}
                        />
                    </RowWrapper>
                    <RowWrapper>
                        <Controller
                          render={({field, fieldState}) =>
                            <Checkbox
                              {...field}
                              {...fieldState}
                              label="Осуществляет набор кандидатов 17 лет"
                              onChange={(value) => field.onChange(value)}
                              ref={null}
                            />
                          }
                          name="is_seventeen_recruitment"
                          control={control}
                        />
                    </RowWrapper>
                    <RowWrapper>
                        <Controller
                          render={({field, fieldState}) =>
                            <Checkbox
                              {...field}
                              {...fieldState}
                              label="Автообзвон игнорирует гражданство"
                              onChange={(value) => field.onChange(value)}
                              ref={null}
                            />
                          }
                          name="ignore_citizenship"
                          control={control}
                        />
                    </RowWrapper>
                    <RowWrapper>
                        <Controller
                          render={({field, fieldState}) =>
                            <Checkbox
                              {...field}
                              {...fieldState}
                              label="Корпоративный ресторан"
                              onChange={(value) => field.onChange(value)}
                              ref={null}
                            />
                          }
                          name="corporative"
                          control={control}
                        />
                    </RowWrapper>
        
                    <RowWrapper>
                        <Button
                            title="Настроить уведомления"
                            onClick={notificationsClickHandle}
                            disabled={isLoadingNotify}
                        />
                    </RowWrapper>
        
                    <BlockWrapper>
                        <H2>Собеседования ЧК</H2>

                        <RowWrapper>
                            <Controller
                                render={({field, fieldState}) =>
                                    <Select
                                        {...field}
                                        {...fieldState}
                                        title="Формат"
                                        options={formatSelectOptions}
                                        ref={null}
                                    />
                                }
                                name="interview_chk_format"
                                control={control}
                            />
                        </RowWrapper>

                        <RowWrapper>
                            <InputWrapper>
                                <Controller
                                    render={({field, fieldState}) =>
                                        <Input
                                            {...field}
                                            {...fieldState}
                                            placeholder="Место проведения"
                                            error={errors?.interview_chk_place?.message}
                                            ref={null}
                                        />
                                    }
                                    name="interview_chk_place"
                                    control={control}
                                />
                            </InputWrapper>
                        </RowWrapper>
                    </BlockWrapper>

                    <BlockWrapper>
                        <H2>Собеседования МС</H2>

                        <RowWrapper>
                            <Controller
                                render={({field, fieldState}) =>
                                    <Select
                                        {...field}
                                        {...fieldState}
                                        title="Формат"
                                        options={formatSelectOptions}
                                        ref={null}
                                    />
                                }
                                name="interview_ms_format"
                                control={control}
                            />
                        </RowWrapper>

                        <RowWrapper>
                            <InputWrapper>
                                <Controller
                                    render={({field, fieldState}) =>
                                        <Input
                                            {...field}
                                            {...fieldState}
                                            placeholder="Место проведения"
                                            error={errors?.interview_ms_place?.message}
                                            ref={null}
                                        />
                                    }
                                    name="interview_ms_place"
                                    control={control}
                                />
                            </InputWrapper>
                        </RowWrapper>
                    </BlockWrapper>
        
                    <BlockWrapper>
                        <H2>Блокирующие анкеты</H2>

                        <ChipsWrapper>
                            {blockingForms.length !== 0 && blockingForms.map((form, index) =>
                                <Chip
                                    key={form.candidate_profile_id + index}
                                    text={`ID ${form.candidate_profile_id}`}
                                    value={`ID ${form.candidate_profile_id}`}
                                    withIcon={true}
                                    icon={EyeIcon}
                                    onClick={(text, value) => {
                                        console.log("chip click, id: " + text)
                                    }}
                                />
                            )}

                            {blockingForms.length === 0 &&
                                <span>Нет анкет</span>
                            }
                        </ChipsWrapper>
                    </BlockWrapper>
        
                    <ButtonsWrapper>
                        <Button
                            title="Снять блокировку"
                            onClick={unlockButtonClickHandle}
                        />
            
                        <RightSide>
                            <Button
                                title="Отмена"
                                btnStyle="cancel"
                                onClick={cancelButtonClickHandle}
                            />
                            <Button
                            type="submit"
                            title="Сохранить"
                            btnStyle="primary"
                            disabled={!isValid || isLoading}
                            />

                        </RightSide>
                    </ButtonsWrapper>
                </RestaurantsFormWrapper>
            }
    
            {formPart === "notificationsPart" &&
                <Notifications
                    restaurant_id={data.id}
                    notifications={notifications}
                    onChangeFormPart={() => setFormPart("mainPart")}
                    onSubmit={(notifications) => setNotifications(notifications)}
                />
            }
        </Modal>
    );
});

const RestaurantsFormWrapper = styled.form`
  width: 100%;
  font-family: ${theme.fonts.mainRegular};
`;

const RowWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 15px;

  &:not(:last-child) {
    margin-bottom: 20px;
  }
`;

const TariffDesc = styled.div`
  margin-top: -10px;
  margin-bottom: 15px;
`;

const TariffDescTitle = styled.div`
  font-size: ${theme.fontSizes.small};
  color: ${theme.colors.neutral7};
  margin-bottom: 5px;
`;

const TariffDescBody = styled.div`
  font-size: ${theme.fontSizes.body};
  color: ${theme.colors.neutral10};
`;

const InputWrapper = styled.div`
  width: 100%;
`;

const BlockWrapper = styled.div`
  margin-bottom: 15px;
`;

const H2 = styled.div`
  color: ${theme.colors.neutral10};
  font-size: ${theme.fontSizes.h2};
  font-family: ${theme.fonts.mainBlack};
  margin-bottom: 15px;
`;

const ChipsWrapper = styled.div`
  display: flex;
  flex-flow: wrap;
  gap: 5px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
`;

const RightSide = styled.div`
  display: flex;
  gap: 15px;
`;

export default FormModal;