import TableIcons from "../../pages/AdCompanies/TableIcons";

const tableFields = [
    {
        label: "Название кампании",
        field: "name",
        type: "string"
    },
    {
        label: "utm_source",
        field: "utm_source",
        type: "string"
    },
    {
        label: "Тип метки",
        field: "source_type",
        type: "string"
    },
    {
        label: "Краткое описание РК",
        field: "description",
        type: "string"
    },
    {
        label: "",
        type: "component-icons",
        field: "icon",
        component: TableIcons
    }
];

export default tableFields;