const AngleLeft = (props) => (
  <svg
    width={7}
    height={11}
    viewBox="0 0 7 11"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.198 1.38a.318.318 0 0 1 0 .462L2.25 5.79l3.948 3.947a.318.318 0 0 1 0 .462l-.503.502a.317.317 0 0 1-.462 0l-4.68-4.68a.318.318 0 0 1 0-.462L5.232.878a.318.318 0 0 1 .462 0l.503.502Z"
      fill={props.disabled ? "#ADB5BD" : "#101213"}
    />
  </svg>
);

export default AngleLeft;
