import React, {FC, memo, ReactElement} from "react";
import styled from "styled-components";

interface Props {
    children: ReactElement
}

const AuthLayout: FC<Props> = memo(({children}) => {
    return (
        <LayoutWrapper>
            <Content>
                {children}
            </Content>
        </LayoutWrapper>
    );
});

const LayoutWrapper = styled.div`
  display: flex;
  height: 100%;
`;
const Content = styled.div<{children: ReactElement}>`
  position: relative;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px 24px 22px 24px;
  height: 100%;
`;

export default AuthLayout;