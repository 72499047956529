import React, {useCallback, useState} from "react";
import { Modal } from "../../../../components/Modal";
import { connectModal } from "../../../../hocs/Modal";
import {TextArea} from "../../../../components/TextArea";
import styled from "styled-components";
import Button from "../../../../components/Button";

const RecommendationModal = connectModal("recommendation-modal")(
  ({handleHide, recommendation, changeRecommendation}) => {

    const [textareaValue, setTextareaValue] = useState(recommendation);

    const save = useCallback(() => {
        changeRecommendation(textareaValue);
        handleHide();
    }, [textareaValue]);

    return (
      <Modal
        title="Рекомендация"
        onClose={handleHide}
        footer={
          <ButtonsWrapper>
            <Button
              btnStyle="cancel"
              title="Отмена"
              onClick={handleHide}
            />

            <Button
              btnStyle="primary"
              title="Сохранить"
              disabled={!textareaValue}
              onClick={save}
            />
          </ButtonsWrapper>
        }
      >
        <TextArea
          value={textareaValue}
          onChange={(e)=>{
            setTextareaValue(e)
          }}
        />
      </Modal>
    )
})

const ButtonsWrapper = styled.div`
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: end;
  gap: 10px;
`;

export default RecommendationModal
