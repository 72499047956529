import {makeAutoObservable} from "mobx";
import {createContext} from "react";

class Filters {
    constructor() {
        makeAutoObservable(this);
    };
    
    filtersData = {};
    
    checkFilterObject = (filterId) => {
        if (!this.filtersData[filterId]) {
            this.filtersData[filterId] = {
                open: false,
            };
        }
    };
    
    filtersIconClickHandle = (filterId, iconRef) => {
        this.checkFilterObject(filterId);
        const rect = iconRef.current.getBoundingClientRect();
        this.filtersData[filterId].open = !this.filtersData[filterId].open;
    };
    
    closeFiltersHandle = (event, filterId, iconRef) => {
        if (event && iconRef.current.contains(event.target)) {
            return;
        }
        this.checkFilterObject(filterId);
        this.filtersData[filterId].open = false;
    };
}

export const FiltersStore = new Filters();
export const FiltersStoreContext = createContext(FiltersStore);