import React, { FC, JSXElementConstructor } from "react";
import Page404 from "../../pages/Page404";
import Loader from "../../components/Loader";
import PaginationGroup from "../../components/PaginationGroup";
import { observer } from "mobx-react-lite";
import ContentWrapper from "../ContentWrapper";

interface Props {
  data: any;
  gridRow: JSXElementConstructor<any>;
  isLoading: boolean;
  error: string;
  setPage: (string) => void;
  setLimit: (string) => void;
  onRowClick?: (data: any) => void;
  onInterviewIconClick?: (data: object) => void;
}

const Grid: FC<Props> = observer(
  ({
    data,
    gridRow,
    isLoading,
    error,
    setPage,
    setLimit,
    onRowClick,
    onInterviewIconClick,
  }) => {
    const GridRow = gridRow;

    const renderGrid = () => {
      return (
        <ContentWrapper>
          {data?.data?.map((item, index) => (
            <GridRow
              key={index}
              data={item}
              onRowClick={onRowClick}
              onInterviewIconClick={onInterviewIconClick}
            />
          ))}
        </ContentWrapper>
      );
    };

    return (
      <>
        {isLoading ? <Loader width={70} height={70} /> : renderGrid()}

        <PaginationGroup
          meta={data?.meta}
          setLimit={setLimit}
          setPage={setPage}
        />
      </>
    );
  }
);

export default Grid;
