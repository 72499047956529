import React, {FC} from 'react';

interface Props {
    width?: string,
    height?: string,
    fill?: string,
    onClick?: () => void
}

const ArrowBack: FC<Props> = ({
    width,
    height,
    fill,
    onClick
}) => {
    return (
        <svg
            width={width || "24"}
            height={height || "24"}
            fill="none"
            onClick={onClick}
        >
            <g clipPath="url(#a)">
                <path
                    d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2Z"
                    fill="#000"
                />
            </g>
            <defs>
                <clipPath id="a">
                    <path fill={fill || "#fff"} d="M0 0h24v24H0z" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default ArrowBack;