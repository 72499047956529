import {REGISTRATION, USERS} from "../config/paths";
import axios from "../middlewares/axios";

export async function getUsers(limit, page, search, order, filters) {
    const data = {
        limit,
        page,
        with: {
            relationships: ["role", "restaurants", "partner"]
        }
    };
    
    if (order) {
        data.order = [order];
    }
    
    const filtersList = [];
    
    const filtersGroup = {
        type: "group",
        group: []
    };
    const orderObject = {
        type: "single",
        operator: "!=",
        value: ""
    };
    const searchGroup = {
        type: "group",
        group: []
    };
    
    if (filters) {
        if (filters.role) {
            filtersGroup.group.push({
                column: "role_id",
                operator: "=",
                value: filters.role
            });
        }
        if (filters.partner && filters.partner.length > 0) {
            filtersGroup.group.push({
                column: "partner_id",
                operator: "=",
                value: filters.partner
            });
        }
        
        filtersGroup.group.map((item, index) => {
            if (index !== 0) {
                item.boolean = "and";
            }
        });
        
        if (filtersGroup.group.length !== 0) {
            filtersList.push(filtersGroup);
        }
    }
    
    if (order) {
        if (order.includes("partners.name")) {
            orderObject.column = "partners.name";
            filtersList.push(orderObject);
        }
    }
    
    if (search) {
        const searchColumns = ["full_name", "email", "roles.role_name_ru"];
        searchColumns.map((column, index) => {
            const searchObject = {
                column,
                operator: "ilike",
                value: "%" + search + "%"
            };
            if (index !== 0) {
                searchObject.boolean = "or";
            }
            searchGroup.group.push(searchObject);
        });
        
        if (searchGroup.group.length !== 0) {
            filtersList.push(searchGroup);
        }
    }
    
    filtersList.map((item, index) => {
        if (index !== 0) {
            item.boolean = "and";
        }
    });
    
    if (filtersList.length !== 0) {
        data.filter = filtersList;
    }
    
    const response = await axios.post(USERS + "/search", data);
    return response.data;
}

export async function postUsers(data) {
    const response = await axios.post(REGISTRATION, {
        ...data
    });
    
    if (response.data) {
        return response.data
    } else {
        return response.response.data;
    }
}

export async function putUsers(data, id) {
    const response = await axios.put(USERS + "/" + id, {
        ...data
    });
    
    if (response.data) {
        return response.data
    } else {
        return response.response.data;
    }
}

export async function deleteUser(id) {
    const response = await axios.delete(USERS + "/" + id);

    return response.data;
}
