const MinusIcon = () => {
    return (
        (
            <svg
                width="12"
                height="12"
                viewBox="0 0 200 200"
                fill="#ffffff"
            >
                <path d="M28 83.7c-4.5 1.7-5 3.2-5 14.8 0 10.1.2 11.4 2.1 13.1 2 1.8 4.7 1.9 73.4 1.9s71.4-.1 73.4-1.9c1.9-1.7 2.1-3 2.1-13.1s-.2-11.4-2.1-13.1c-2-1.8-4.8-1.9-72.3-2.1-38.5-.1-70.8.1-71.6.4z" />
            </svg>
        )
    );
};

export default MinusIcon;