const HelpIcon = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
      <g clipPath="url(#clip0_5291_40428)">
        <path d="M8.25 13.5H9.75V12H8.25V13.5ZM9 1.5C4.86 1.5 1.5 4.86 1.5 9C1.5 13.14 4.86 16.5 9 16.5C13.14 16.5 16.5 13.14 16.5 9C16.5 4.86 13.14 1.5 9 1.5ZM9 15C5.6925 15 3 12.3075 3 9C3 5.6925 5.6925 3 9 3C12.3075 3 15 5.6925 15 9C15 12.3075 12.3075 15 9 15ZM9 4.5C7.3425 4.5 6 5.8425 6 7.5H7.5C7.5 6.675 8.175 6 9 6C9.825 6 10.5 6.675 10.5 7.5C10.5 9 8.25 8.8125 8.25 11.25H9.75C9.75 9.5625 12 9.375 12 7.5C12 5.8425 10.6575 4.5 9 4.5Z" fill="#6A7178"/>
      </g>
      <defs>
        <clipPath id="clip0_5291_40428">
          <rect width="18" height="18" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
};

export default HelpIcon;
