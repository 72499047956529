import {useContext, useMemo} from "react";
import {DirectoryStoreContext} from "../stores/directory";

export const useMemoVacanciesOptions = (candidateProfileData, url, restaurantID = null) => {
  const {vacancies} = useContext(DirectoryStoreContext);
  const {restaurants} = useContext(DirectoryStoreContext);

  return useMemo(() => {
    let isTariffManager = false;

    if(url === "newcandidate" && !!restaurantID) {
      isTariffManager = restaurants.find(restaurant =>
        restaurant.id === restaurantID)?.is_tariff_manager;
    }

    if (candidateProfileData?.restaurant?.is_tariff_manager ||
      (!candidateProfileData?.restaurant?.is_tariff_manager && candidateProfileData?.vacancy?.id !== 1 && url !== "newcandidate") ||
      isTariffManager
    ) {
      return vacancies.map((item) => ({
        label: item.name,
        value: item.id,
      }))
    } else {
      const restaurantVacancy = vacancies.find(vacancy => vacancy.id === 1)
      return [{
        label: restaurantVacancy?.name,
        value: restaurantVacancy?.id,
      }];
    }
  }, [candidateProfileData?.restaurant?.is_tariff_manager, restaurantID, restaurants, url, vacancies]);
}