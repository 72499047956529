import styled from "styled-components";
import {theme} from "../../../../styles/theme";

export const Answers = styled.div<{onMargin?: boolean,}>`
  display: flex;
  background-color: ${theme.colors.neutral1};
  padding: 8px;
  align-self: stretch;
  border-radius: 4px;
  gap: 16px;
  ${({onMargin}) => onMargin && "margin: 16px 24px 0;"};
  
  @media (max-width: 580px) {
    flex-wrap: wrap;
  }
`;

export const AnswerItem = styled.div<{
  width?: string,
  bold?: boolean,
}>`
  width: ${({width}) => width ? width : "100%"};
  ${({bold}) => bold && "flex-shrink: 0;"};
  
  ul {
    color: ${theme.colors.neutral10};
    ${({bold}) => !bold ? "padding-left: 20px; font-size: " + theme.fontSizes.body : 
            "list-style-type: none; font-family: " + theme.fonts.mainBlack + 
            "; font-size: " + theme.fontSizes.h3};
  }
`;

export const AnswerTitle = styled.div`
  margin-bottom: 8px;
  color: ${theme.colors.primary};
  font-family: ${theme.fonts.mainBlack};
  font-size: ${theme.fontSizes.h2};
  display: flex;
  align-items: center;
  
  &:before {
    content: '';
    width: 16px;
    height: 16px;
    border-radius: 100%;
    background-color: ${theme.colors.primary};
    margin-right: 8px;
  }
`;

export const AnswerText = styled.div`
  font-family: ${theme.fonts.mainRegular};
  font-size: ${theme.fontSizes.body};
  color: ${theme.colors.neutral10};
`;

export const FormActions = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

export const QuestionItem = styled.div`
  padding: 16px 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
  color: ${theme.colors.neutral10};
`;

export const QuestionsList = styled.div`
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 24px;
`;

export const Block = styled.div<{
  gap?: number,
  padding?: string,
  flexDirection?: string,
}>`
  display: flex;
  ${({gap}) => gap && 'gap: ' + gap + 'px;'};
  ${({padding}) => padding && 'padding: ' + padding};
  ${({flexDirection}) => flexDirection && 'flex-direction: ' + flexDirection};
`;

export const QuestionNumber = styled.div`
  width: 24px;
  font-family: ${theme.fonts.mainBlack};
  line-height: 0.8;
  font-size: 20px;
  flex-shrink: 0;
`;

export const QuestionText = styled.div`
  font-family: ${theme.fonts.mainRegular};
  font-size: ${theme.fontSizes.body};
`;