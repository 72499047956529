import {makeAutoObservable} from "mobx";
import {createContext} from "react";
import {
    getCitiesList,
    getCountriesList,
    getRejectsList,
    getRolesList,
    getSourcesList, getStatusDetailsList,
    getVacanciesList
} from "../actions/directory";
import {getPartnersList} from "../actions/directory";
import {getRestaurantsList} from "../actions/directory";
import {getTariffsList} from "../actions/directory";
import {getCitizenshipList} from "../actions/directory";
import {getCandidateProfileStatusesList} from "../actions/directory";

class Directory {
    constructor() {
        makeAutoObservable(this);
    }

    restaurants = [];
    restaurantsEndFetching = false;
    tariffs = [];
    tariffsEndFetching = false;
    cities = [];
    citiesEndFetching = false;
    countries = [];
    citizenship = [];
    citizenshipEndFetching = false;
    roles = [];
    partners = [];
    partnersEndFetching = false;
    candidateProfileStatuses = [];
    candidateProfileStatusesEndFetching = false;
    sources = [];
    sourcesEndFetching = false;
    vacancies = [];
    rejects = [];
    rejectsEndFetching = false;
    statusDetails = [];
    statusDetailEndFetching = false;

    setRestaurants = async () => {
        this.restaurants = await getRestaurantsList().then((response) => response.data);
        this.restaurantsEndFetching = true;
    };

    setVacancies = async () => {
        this.vacancies = await getVacanciesList().then((response) => response.data);
    };

    setTariffs = async () => {
        this.tariffs = await getTariffsList().then((response) => response.data);
        this.tariffsEndFetching = true;
    };

    setCities = async () => {
        this.cities = await getCitiesList().then((response) => response.data);
        this.citiesEndFetching = true;
    };

    setCountries = async () => {
        this.countries = await getCountriesList().then((response) => response.data);
    };

    setCitizenship = async () => {
        this.citizenship = await getCitizenshipList().then((response) => response.data);
        this.citizenshipEndFetching = true;
    };

    setRoles = async () => {
        this.roles = await getRolesList().then((response) => response.data);
    };

    setPartners = async () => {
        this.partners = await getPartnersList().then((response) => response.data);
        this.partnersEndFetching = true;
    };

    setCandidateProfileStatuses = async () => {
        this.candidateProfileStatuses = await getCandidateProfileStatusesList().then((response) => response.data);
        this.candidateProfileStatusesEndFetching = true;
    };

    setSources = async () => {
        this.sources = await getSourcesList().then((response) => response.data);
        this.sourcesEndFetching = true;
    };

    setRejects = async () => {
        this.rejects = await getRejectsList().then((response) => response.data);
        this.rejectsEndFetching = true;
    }

    setStatusDetail = async () => {
        this.statusDetails = await getStatusDetailsList().then((response) => response.data);
        this.statusDetailEndFetching = true;
    }
}

export const DirectoryStore = new Directory();
export const DirectoryStoreContext = createContext(DirectoryStore);
