import React, {FC, memo} from "react";
import {Controller, useWatch} from "react-hook-form";
import styled from "styled-components";
import Checkbox from "../../../../../components/Checkbox";
import {TextArea} from "../../../../../components/TextArea";

interface Props {
  setRestaurantNewCandidate: (val: boolean) => void;
  setRestaurantInterview: (val: boolean) => void;
  setRestaurantReminder: (val: boolean) => void;
  control: any
}

const RestaurantRole: FC<Props> = memo(({
  setRestaurantNewCandidate,
  setRestaurantInterview,
  setRestaurantReminder,
  control
}) => {
  const chkFormsEmailValue = useWatch({
    control,
    name: "to_user.restaurant.new_forms_chk_email"
  });

  const chkInterviewsEmailValue = useWatch({
    control,
    name: "to_user.restaurant.new_interviews_chk_email"
  });

  const chkReminderValue = useWatch({
    control,
    name: "to_user.restaurant.email_reminder_chk"
  });

  const changesNewCandidateHandler = () => {
    setRestaurantNewCandidate(true);
  }

  const changesInterviewHandler = () => {
    setRestaurantInterview(true);
  }

  const changesReminderHandler = () => {
    setRestaurantReminder(true);
  }

  return (
    <Wrapper>
      <FieldWrapper>
        <Controller
          render={({field, fieldState}) =>
            <Checkbox
              {...field}
              {...fieldState}
              label="Email о новых анкетах ЧК"
              onChange={(value) => {
                field.onChange(value);
                changesNewCandidateHandler();
              }}
              ref={null}
            />
          }
          name="to_user.restaurant.new_forms_chk_email"
          control={control}
        />
      </FieldWrapper>

      <FieldWrapper>
        <Controller
          render={({field, fieldState}) =>
            <TextArea
              {...field}
              {...fieldState}
              placeholder="Текст сообщения (шаблон)"
              disabled={!chkFormsEmailValue}
              onChange={(value) => {
                field.onChange(value);
                changesNewCandidateHandler();
              }}
              ref={null}
            />
          }
          name="to_user.restaurant.new_forms_message"
          control={control}
        />
      </FieldWrapper>

      <FieldWrapper>
        <Controller
          render={({field, fieldState}) =>
            <Checkbox
              {...field}
              {...fieldState}
              label="Email о новых собеседованиях ЧК"
              onChange={(value) => {
                field.onChange(value);
                changesInterviewHandler();
              }}
              ref={null}
            />
          }
          name="to_user.restaurant.new_interviews_chk_email"
          control={control}
        />
      </FieldWrapper>

      <FieldWrapper>
        <Controller
          render={({field, fieldState}) =>
            <TextArea
              {...field}
              {...fieldState}
              placeholder="Текст сообщения (шаблон)"
              disabled={!chkInterviewsEmailValue}
              onChange={(value) => {
                field.onChange(value);
                changesInterviewHandler();
              }}
              ref={null}
            />
          }
          name="to_user.restaurant.new_interviews_message"
          control={control}
        />
      </FieldWrapper>

      <FieldWrapper>
        <Controller
          render={({field, fieldState}) =>
            <Checkbox
              {...field}
              {...fieldState}
              label="Email напоминание за час до собеседования ЧК"
              onChange={(value) => {
                field.onChange(value);
                changesReminderHandler();
              }}
              ref={null}
            />
          }
          name="to_user.restaurant.email_reminder_chk"
          control={control}
        />
      </FieldWrapper>

      <FieldWrapper>
        <Controller
          render={({field, fieldState}) =>
            <TextArea
              {...field}
              {...fieldState}
              placeholder="Текст сообщения (шаблон)"
              disabled={!chkReminderValue}
              onChange={(value) => {
                field.onChange(value);
                changesReminderHandler();
              }}
              ref={null}
            />
          }
          name="to_user.restaurant.reminder_message"
          control={control}
        />
      </FieldWrapper>
    </Wrapper>
  );
});

const Wrapper = styled.div`
  width: 100%;
`;

const FieldWrapper = styled.div`
  &:not(:last-child) {
    margin-bottom: 15px;
  }
`;

export default RestaurantRole;