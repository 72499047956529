import { connectModal } from "../../../hocs/Modal";
import { Modal } from "../../../components/Modal";
import styled from "styled-components";
import React from "react";
import moment from "moment";
import {theme} from "../../../styles/theme";
import _get from "lodash/get";
import {history} from "../../../routes/history";
import {privateRoutes} from "../../../config/routes";
import cloneDeep from "lodash/cloneDeep";

const CalendarMonthDayModal = connectModal("calendar-month-day-modal")(({ handleHide, day, items }) => {

  return (
    <Modal title={day.format("DD.MM.YYYY")}
           width={"350px"}
           onClose={handleHide}>
        <Content>
            {
                items.map((item, i) => {
                    return (
                        <Item key={i}
                              onClick={() =>
                                  history.push({
                                      pathname: `${privateRoutes.candidateFormItem.alias}${item.candidateProfile.id}`,
                                      state: { data: cloneDeep(item), viewMode: "candidate-form" },
                                  })
                              }>
                            {moment(item.interview_date).format("HH:mm")} {_get(item, "candidateProfile.first_name")} {_get(item, "candidateProfile.second_name")}
                        </Item>
                    )
                })
            }
        </Content>
    </Modal>
  );
});

const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  padding-top: 16px;
  margin-top: -16px;
  width: 100%;
`;

const Item = styled.div`
  display: flex;
  margin: 0 0 8px 0;
  height: 26px;
  color: ${theme.colors.bodyText};
  padding: 3px 5px;
  font-size: ${theme.fontSizes.body};
  font-family: ${theme.fonts.mainRegular};
  line-height: 20px;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  
  &:hover {
    background: ${theme.colors.neutral2};
  }
`;

export default CalendarMonthDayModal;
