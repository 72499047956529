import React from "react";
import Button from "../../../../../components/Button";
import { Modal } from "../../../../../components/Modal";
import { connectModal } from "../../../../../hocs/Modal";
import Radio from "../../../../../components/Radio";
import {Data} from "./data";
import styled from "styled-components";
import {
  Answers,
  AnswerItem,
  AnswerTitle,
  FormActions,
  QuestionItem,
  Block,
  QuestionNumber,
  QuestionText,
  QuestionsList
} from "../styles";

const RJPModal = connectModal('rjp-modal')(
  ({
     handleHide
   }) => {
    return (
      <Modal
        title={"RJP-опросник"}
        onClose={handleHide}
        width={"900px"}
        footer={<FormActions><Button title={"Отправить"} btnStyle={'primary'}/></FormActions>}
        noContentPadding
        closeDataTip={"После закрытия ответы не сохрантся"}
      >
        <Answers onMargin>
          {
            Data.answers.map((item, index) => {
              return (
                <AnswerItem
                  key={'answer' + index}
                  width={index === 2 ? "100px" : "100%"}
                  bold={index === 2}
                >
                  <AnswerTitle>{item.title}</AnswerTitle>
                  <ul>
                    {
                      item.textList.map((item, index) => {
                        return (
                          <li>{item.text}</li>
                        )
                      })
                    }
                  </ul>
                </AnswerItem>
              )
            })
          }
        </Answers>
        <Questions>
          {
            Data.questions.map((item, index) => {
              const num = index + 1;
              return (
                <QuestionItem>
                  <Block gap={4}>
                    <QuestionNumber>{num}</QuestionNumber>
                    <QuestionText>{item.text}</QuestionText>
                  </Block>
                  <Block padding={"0 0 0 28px"} gap={16}>
                    <Radio checked={false} value={"A"} name={'question' + num} label={"A"} onChange={()=>{}}/>
                    <Radio checked={false} value={"B"} name={'question' + num} label={"B"} onChange={()=>{}}/>
                    <Radio checked={false} value={"C"} name={'question' + num} label={"C"} onChange={()=>{}}/>
                  </Block>
                </QuestionItem>
              )
            })
          }
        </Questions>
      </Modal>
    )
  })

const Questions = styled(QuestionsList)`
  @media (max-width: 580px) {
    overflow: unset;
  }
`;

export default RJPModal;