import TableInput from "../../../pages/CandidateForms/OrderSection/TableInput";
import TableCheckbox from "../../../pages/CandidateForms/OrderSection/TableCheckbox";
import TableActionIcon from "../../../pages/CandidateForms/OrderSection/TableActionIcon";

const tableFields = [
    {
        label: "ID ресторана",
        field: "id",
        type: "string",
        font: "condensed",
    },
    {
        label: "Партнер",
        field: "partner",
        type: "string"
    },
    {
        label: "Город",
        field: "city_name",
        type: "string"
    },
    {
        label: "Ресторан",
        field: "name_ru",
        type: "string",
        needBorderRight: true,
    },
    {
        label: "Потребность ТР ЧК",
        field: "candidate_orders_current_month.requirement_la_re",
        type: "component-input",
        component: TableInput,
        font: "condensed",
        textAlign: "right",
    },
    {
        label: "Кол-во анкет ЧК",
        field: "candidate_orders_current_month.count_re_candidate_profile",
        type: "string",
        font: "condensed",
        textAlign: "right",
    }
    ,
    {
        label: "Потребность ТР МС",
        field: "candidate_orders_current_month.requirement_la_sm",
        type: "component-input",
        component: TableInput,
        font: "condensed",
        textAlign: "right",
    },
    {
        label: "Кол-во анкет МС",
        field: "candidate_orders_current_month.count_sm_candidate_profile",
        type: "string",
        font: "condensed",
        textAlign: "right",
        needBorderRight: true,
    },
    {
        label: "Потребность ТР ЧК",
        field: "candidate_orders_next_month.requirement_la_re",
        type: "component-input",
        component: TableInput,
        font: "condensed",
        textAlign: "right",
    },
    {
        label: "Кол-во анкет ЧК",
        field: "candidate_orders_next_month.count_re_candidate_profile",
        type: "string",
        font: "condensed",
        textAlign: "right",
    }
    ,
    {
        label: "Потребность ТР МС",
        field: "candidate_orders_next_month.requirement_la_sm",
        type: "component-input",
        component: TableInput,
        font: "condensed",
        textAlign: "right",
    },
    {
        label: "Кол-во анкет МС",
        field: "candidate_orders_next_month.count_sm_candidate_profile",
        type: "string",
        font: "condensed",
        textAlign: "right",
        needBorderRight: true,
    },
    {
        label: "Автопродление заказа",
        field: "candidate_orders_current_month.is_order_autocomplete",
        type: "component-checkbox",
        component: TableCheckbox,
        font: "condensed",
        textAlign: "right",
    },
    {
        label: "Обнулить заказ за текущий месяц",
        field: "is_cancel_order_for_current_month",
        type: "component-icons",
        component: TableActionIcon,
        font: "condensed",
        textAlign: "right",
    }
];

export default tableFields;