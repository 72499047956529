import { Controller } from "react-hook-form";
import { Input } from "../../../../../components/Input";
import React, {FC} from "react";
import {TextArea} from "../../../../../components/TextArea";
import {PROMOTER_VALUE} from "../../const";

interface Props {
    control: any;
}

const PromoterFields: FC<Props> = ({control}) => {

    return (
        <>
            <Controller
                render={({ field, fieldState }) => {
                    return (
                        <Input
                            placeholder="Желаемые точки раздачи листовок (адрес + ориентир)"
                            {...field}
                            {...fieldState}
                            ref={null}
                        />
                    );
                }}
                name={`${PROMOTER_VALUE}.dotes`}
                control={control}
            />
            <Controller
                render={({ field, fieldState }) => {
                    return (
                        <TextArea
                            placeholder="Комментарий"
                            {...field}
                            {...fieldState}
                            ref={null}
                        />
                    );
                }}
                name={`${PROMOTER_VALUE}.comment`}
                control={control}
            />
        </>
    );
};

export default PromoterFields;
