import { createContext } from 'react';
import { makeAutoObservable } from 'mobx';

class App {
  constructor() {
    makeAutoObservable(this);
  }

  menuIsOpen = window.innerWidth > 1366;
  setMenuIsOpen = (menuIsOpen) => (this.menuIsOpen = menuIsOpen);
}

export const AppStore = new App();
export const AppStoreContext = createContext(AppStore);
