import React, {FC, memo, useRef} from "react";
import Button from "../../../../components/Button";
import styled from "styled-components";
import {toast} from "react-toastify";
import ProblemIcon from "../../../../assets/icons/ProblemIcon";
import {theme} from "../../../../styles/theme";
import {getCv, uploadCv} from "../../../../actions/candidates";
import DoneIcon from "../../../../assets/icons/DoneIcon";
import FileNameIcon from "../../../../assets/icons/FileNameIcon";

//region Styled
const ButtonWrapper = styled.div`
  width: 120px;
  
  button {
    width: 100%;
  }
`;

const ButtonAddWrapper = styled.div`
  width: 100%;
  
  button {
    width: 100%;
  }
`;

const Formats = styled.p`
  color: ${theme.colors.neutral7};
  font-size: ${theme.fontSizes.small};
  font-weight: 400;
  line-height: 14px;
`;

const FileNameWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const IconWrapper = styled.div`
  position: relative;
  margin-right: 10px;
`;

const FileFormat = styled.div`
  position: absolute;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px; 
  color: #FFFFFF;
  bottom: 10px;
  left: 10px;
`;

const UserName = styled.div`
  font-weight: 400;
  font-size: ${theme.fontSizes.body};
  color: ${theme.colors.bodyText};
`;

const ButtonsWrapper = styled.div`  
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
//endregion

interface Props {
    candidate_profile: any;
    changeResumeFileName: (filename:string) => void;
}

const Resume: FC<Props> = memo(({ candidate_profile, changeResumeFileName }) => {
    const fileRef = useRef(null);

    /**
     * Загрузка выбранного файла
     */
    const selectFileHandler = async e => {
        let files:any = Array.from(e.target.files);
        const fileNameInfo = files[0].name.split(".");
        const format = fileNameInfo[fileNameInfo.length-1];
        const accessFormats = ["pdf", "doc", "docx"];
        if (!accessFormats.includes(format)){
            return toast(
                "Файл с данным форматом прикрепить невозможно",
                {
                    icon: <ProblemIcon
                        width="24"
                        height="24"
                        fill={theme.toast.errorParams.iconColor}
                    />,
                    style: {
                        ...theme.toast.commonParams,
                        ...theme.toast.errorParams
                    }
                }
            );
        }

        const data = {
            cv: files[0]
        };

        try {
          const response = await uploadCv(data);
          changeResumeFileName(response.data.data.filename);
          toast(
            "Резюме успешно загружено",
            {
              icon: <DoneIcon
                width="24"
                height="24"
                fill={theme.toast.successParams.iconColor}
              />,
              style: {
                ...theme.toast.commonParams,
                ...theme.toast.successParams
              }
            }
          );
        } catch (e) {
          toast(
            "При загрузке файла произошла ошибка",
            {
              icon: <ProblemIcon
                width="24"
                height="24"
                fill={theme.toast.errorParams.iconColor}
              />,
              style: {
                ...theme.toast.commonParams,
                ...theme.toast.errorParams
              }
            }
          )
        }
    };

    /**
     * Открытие окна выбора файла
     */
    const onClickAddFile = () => fileRef?.current?.click();

    /**
     * Получение формата файла
     */
    const getFileFormat = () => {
        const arr = candidate_profile.resume_file_name.split(".");
        return arr[1].toUpperCase();
    };

    /**
     * Получение формата файла
     */
    const getUserName = () => {
        return `Резюме ${candidate_profile.second_name} ${candidate_profile.first_name}`;
    };

    /**
     * Скачать резюме
     */
    const downloadResume = () => {
        getCv(candidate_profile.id, getFileFormat().toLowerCase());
    };

    if (candidate_profile.resume_file_name) {
        return (
            <>
                <FileNameWrapper>
                    <IconWrapper>
                        <FileNameIcon/>
                        <FileFormat>{getFileFormat()}</FileFormat>
                    </IconWrapper>
                    <UserName>{getUserName()}</UserName>
                </FileNameWrapper>
                <ButtonsWrapper>
                    <ButtonWrapper>
                        <Button
                            onClick={(event) => {
                                event.preventDefault();
                                downloadResume();
                            }}
                            title="Скачать"
                            btnStyle="secondary"
                        />
                    </ButtonWrapper>
                </ButtonsWrapper>
            </>
        )
    }

    return (
        <>
            <input type="file"
                   accept=".pdf, .doc, .docx, .zip"
                   style={{'display': 'none'}}
                   ref={fileRef}
                   onChange={selectFileHandler}/>
            <Formats>Форматы: .doc, .docx, .pdf</Formats>
            <ButtonAddWrapper>
                <Button
                    onClick={(event) => {
                        event.preventDefault();
                        onClickAddFile();
                    }}
                    title="Прикрепить"
                    btnStyle="secondary"
                />
            </ButtonAddWrapper>
        </>
    )
});

export default Resume;
