import React, {FC, useCallback} from "react";
import styled from "styled-components";
import moment from "moment";
import {theme} from "../../../../styles/theme";
import _get from "lodash/get";
import {history} from "../../../../routes/history";
import {privateRoutes} from "../../../../config/routes";
import {ModalsStore} from "../../../../stores/modal";
import CalendarMonthDayModal from "../../CalendarMonthDayModal";
import {COUNT_ITEMS_WEEK} from "../consts";

interface Props {
    date: moment.Moment,
    data: any
}

const WeekView: FC<Props> = ({date, data}) => {
    const onOpenModal = (day, items) => {
        ModalsStore.showModal("calendar-month-day-modal", {day, items});
    };

    const dayForClick = moment(date);

    const _renderByWeek = useCallback(() => {
        const currDay = moment(date);

        let daysToRender = [];

        for(let i = 0; i < 7; i++) {
            const isToday = currDay.diff(moment().startOf('day'), 'days') === 0;
            const items = data.data.filter(i =>
                currDay.isSame(moment(i.interview_date), "day") &&
                currDay.isSame(moment(i.interview_date), "month")
            );

            daysToRender.push(
                <CardWrapper key={i}>
                    <DayNumber isToday={isToday}>{currDay.format('D')}</DayNumber>
                    {
                        items.slice(0, COUNT_ITEMS_WEEK).map((item, i) => {
                            return <Item key={i}
                                         onClick={() =>
                                             history.push({pathname: `${privateRoutes.candidateFormItem.alias}${item.candidateProfile.id}`})
                                         }>
                                {moment(item.interview_date, "YYYY-MM-DD HH:mm:ss").format("HH:mm")} {_get(item, "candidateProfile.first_name")} {_get(item, "candidateProfile.second_name")}
                            </Item>
                        })
                    }
                    {
                      items.length > COUNT_ITEMS_WEEK &&
                      <ItemMore onClick={() => onOpenModal(dayForClick, items)}>Еще {items.slice(COUNT_ITEMS_WEEK).length}...</ItemMore>
                    }
                </CardWrapper>
            );

            currDay.add(1, "d");
        }

        return daysToRender;
    }, [date]);

    if (!date)
        return null;

    return (
      <>
        <Wrapper>{_renderByWeek()}</Wrapper>
        <CalendarMonthDayModal/>
      </>
    );
};

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
`;

const CardWrapper = styled.div`
  width: calc(95% / 4);
  height: 329px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 16px 11px;
  gap: 5px;
    
  @media (max-width: 1024px) {
      width: calc(95% / 3);
  }

    @media (max-width: 690px) {
        width: calc(95% / 2);
    }

    @media (max-width: 470px) {
        width: 100%;
    }
`;

const DayNumber = styled.div<{isToday?: boolean}>`
    font-family: ${theme.fonts.mainBlack};
    font-size: ${theme.fontSizes.h2};
    color: ${({isToday}) => isToday ? theme.colors.primary : theme.colors.bodyText};
    line-height: 27px;
    margin-bottom: 19px;
    padding-left: 5px;
`;

const Item = styled.div<{isToday?: boolean}>`
    width: 100%;
    font-family: ${theme.fonts.mainRegular};
    font-size: ${theme.fontSizes.body};
    color: ${theme.colors.bodyText};
    border-radius: 4px;
    padding: 3px 5px;
    
    &:hover {
        background: ${theme.colors.neutral2};
        cursor: pointer;
    }
`;

const ItemMore = styled(Item)`
    width: fit-content;
    color: ${theme.colors.primary};
    background: transparent;
`;

export default WeekView;
