import TableInput from "../../../pages/Plr/TableInput";


const tableFields = list => {
    if (!list || !list.length)
        return [];

    return [
        {
            label: "Факторы, влияющие на потребность",
            field: "name",
            type: "string",
            needBorderRight: true,
        },
        {
            label: list[0].firstMonth.format("MMMM YYYY"),
            field: "month1",
            type: "component-input",
            component: TableInput,
            needBorderRight: true,
        },
        {
            label: list[1].firstMonth.format("MMMM YYYY"),
            field: "month2",
            type: "component-input",
            component: TableInput,
            needBorderRight: true,
        },
        {
            label: list[2].firstMonth.format("MMMM YYYY"),
            field: "month3",
            type: "component-input",
            component: TableInput
        }
    ];
}

export default tableFields;
