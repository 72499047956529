const DEFAULT_VALUE = 0;

export class Demands {
    id: number;
    restaurant_id: number;
    need_for_hours_in_the_morning: number;
    need_for_hours_in_the_day: number;
    need_for_hours_in_the_evening: number;
    need_for_hours_in_the_night: number;
    total_hours: number;
    total_people: number;
    month_demand_plr: string;

    constructor(demands) {
        this.id = demands?.id;
        this.restaurant_id = demands?.restaurant_id;
        this.need_for_hours_in_the_morning = demands?.need_for_hours_in_the_morning || DEFAULT_VALUE;
        this.need_for_hours_in_the_day = demands?.need_for_hours_in_the_day || DEFAULT_VALUE;
        this.need_for_hours_in_the_evening = demands?.need_for_hours_in_the_evening || DEFAULT_VALUE;
        this.need_for_hours_in_the_night = demands?.need_for_hours_in_the_night || DEFAULT_VALUE;
        this.total_hours = demands?.total_hours || DEFAULT_VALUE;
        this.total_people = demands?.total_people || DEFAULT_VALUE;
        this.month_demand_plr = demands?.month_demand_plr || DEFAULT_VALUE;
    }

    changeValue = (key, value) => {
        this[key] = parseInt(value);
        this.calculateTotal();
    };

    calculateTotal = () => {
        this.total_hours = this.need_for_hours_in_the_morning +
                           this.need_for_hours_in_the_day +
                           this.need_for_hours_in_the_evening +
                           this.need_for_hours_in_the_night;
        this.total_people = Math.ceil(this.total_hours / 168);
    };
}

export const IndexToKeyDemands = {
    0: "need_for_hours_in_the_morning",
    1: "need_for_hours_in_the_day",
    2: "need_for_hours_in_the_evening",
    3: "need_for_hours_in_the_night",
};
