export const Data = {
  answers: [
    {
      title: 'A',
      textList: [
        {
          text: 'Дал ясный пример отличного обслуживания.'
        },
        {
          text: 'Выявил причины отличного обслуживания.'
        },
        {
          text: 'Отменил важность отличного обслуживания.'
        },
        {
          text: 'Приводил ясный пример того, как важно представлять великолепное обслуживание.'
        },
      ],
    },
    {
      title: 'B',
      textList: [
        {
          text: 'У кандидата нет примеров.'
        },
        {
          text: 'Не приводит примеры отличного обслуживания.'
        },
        {
          text: 'Раздражается на вопрос об идеальном обслуживании.'
        },
        {
          text: 'Не сделал выводы\\не посчитал важным обращать внимание на обслуживание.'
        },
      ],
    },
    {
      title: 'C',
      textList: [
        {
          text: 'A+B'
        },
      ],
    },
  ],
  questions: [
    {
      text: 'Каковы были Ваши ожидания от данной работы перед Предварительным Ознакомлением? Как Ваши ожидания изменились?',
    },
    {
      text: 'Что из того, что вы увидели сегодня, оказалось похожим на Ваш предыдущий опыт работы или представления об этой работе?',
    },
    {
      text: 'Какую информацию вы получили сегодня, чтобы принять решение работать с нами?',
    },
    {
      text: 'У вас есть какие-либо вопросы по работе в нашей компании?',
    },
    {
      text: 'Вы все еще заинтересованы в работе у нас? Почему да или почему нет?',
    },
  ]
}