import React, {FC, Fragment} from "react";
import styled from "styled-components";
import {theme} from "../../../../styles/theme";
import {
  BUS_STOP_VALUE,
  ELEVATORS_VALUE,
  ENTRANCES_VALUE,
  ISelectOption,
  ItemOfflineAdv,
  MAGAZINE_VALUE,
  MATERIALS_VALUE, OTHER_VALUE,
  POST_BOX_VALUE,
  PROMOTER_VALUE,
  TRANSPORT_VALUE
} from "../const";
import _get from "lodash/get";
import ActionIcon from "../../../../components/ActionIcon";
import DeleteIcon from "../../../../assets/icons/DeleteIcon";
import Button from "../../../../components/Button";
import {useFetching} from "../../../../hooks/useFetching";
import {saveOfflineAdvertising} from "../../../../actions/offlineAdvertising";

const Title = styled.div`
  line-height: 38px;
  padding-right: 16px;
  padding: 24px 24px 0;
  font-family: ${theme.fonts.condensedBlack};
  font-size: ${theme.fontSizes.h1};
  color: ${theme.colors.bodyText};
  margin-bottom: 16px;
`;

const ItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 24px;
  height: 100%;
  overflow: auto;
`;

const ColumnTitle = styled.div`
  line-height: 14px;
  font-family: ${theme.fonts.mainRegular};
  font-size: ${theme.fontSizes.small};
`;

const ColumnHeader = styled.div`
  line-height: 20px;
  font-family: ${theme.fonts.mainRegular};
  font-size: ${theme.fontSizes.body};
`;

const ItemWrapper = styled.div<{isActive: boolean}>`
  display: flex;
  width: 100%;
  cursor: pointer;
  position: relative;
  flex-wrap: wrap;
  padding: 10px;
  border-radius: 4px;
  background: ${({isActive}) => isActive ? theme.colors.primary : "white"};
  margin-bottom: 10px;
  
  &:last-child {
    margin-bottom: 0;
  }
    
  &:hover {
    background: ${({isActive}) => isActive ? theme.colors.primary : "#F1F3F5"};
    .btn-delete {
      opacity: 1;
    }
  }
  
  .btn-delete {
    position: absolute;
    top: 5px;
    right: 5px;
    opacity: 0;

    @media (hover: none) { 
      opacity: 1 !important;
    }
  }
  
  ${ColumnTitle} {
    color: ${({isActive}) => !isActive ? theme.colors.neutral7 : "white" };
  }
  
  ${ColumnHeader} {
    color: ${({isActive}) => !isActive ? theme.colors.bodyText : "white" };
  }
`;

const Subtitle = styled.div`
  line-height: 27px;
  font-family: ${theme.fonts.mainBlack};
  font-size: ${theme.fontSizes.h2};
  color: ${theme.colors.bodyText};
  margin-bottom: 10px;
`;

const TotalBlock = styled.div`
  margin-bottom: 16px;
`;

const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 8px 24px 16px;
`;

const Column = styled.div<{width: string, marginBottom?: string}>`
  margin-bottom: ${({marginBottom}) => marginBottom || "10px"};
  margin-right: 10px;
  width: ${({width}) => width};
  display: flex;
  flex-direction: column;
`;

interface Props {
    offlineAdvertisingType: ISelectOption[];
    basket: object,
    editItem?: ItemOfflineAdv,
    onSelectEditItem: (type: string, item: ItemOfflineAdv) => void,
    deleteItem: (type: string, item: ItemOfflineAdv) => void,
    fetchOfflineAdvertising: () => void;
}

const Basket: FC<Props> = ({
  offlineAdvertisingType,
  basket,
  deleteItem,
  onSelectEditItem,
  editItem,
  fetchOfflineAdvertising
}) => {

    let editItemId = () => {
        if (editItem) {
            return editItem[editItem.type.value].id;
        }
        else return null;
    };

  const [fetchSave, isLoading, error] = useFetching(async (params) => {
    const response = await saveOfflineAdvertising(params);
    if(response.message === "OK") fetchOfflineAdvertising();
  });

  const onFetchSave = async () => {
    const paramsArray = getParamsArray();

    for(let params of paramsArray) {
      fetchSave(params);
    }
  }

  const getParamsArray = () => {
    const paramsArray = [];
    Object.keys(basket).map(type => {
      for(let advertisement of basket[type]) {
        paramsArray.push(getParams(type, advertisement));
      }
    });

    return paramsArray;
  }

  const getParams = (type, advertisement) => {
    const order = {
      offline_advertising_type_id: type,
      advertising_month: advertisement.advertisingMonth,
      restaurant_id: advertisement.restaurant.value,
      comment: advertisement.comment
    }

    if(type === MATERIALS_VALUE) {
      order["printed_product_id"] = Number(advertisement.type.value);
      order["poster_size_id"] = Number(advertisement.size.value);
      order["products_count"] = advertisement.count;
    }

    if(type === POST_BOX_VALUE ||
      type === ENTRANCES_VALUE ||
      type === BUS_STOP_VALUE ||
      type === ELEVATORS_VALUE) {
      order["accommodation_addresses"] = advertisement.address;
    }

    if(type === MAGAZINE_VALUE) {
      order["print_media_name"] = advertisement.name;
    }

    if(type === TRANSPORT_VALUE) {
      order["route_numbers"] = advertisement.numbers;
    }

    if(type === PROMOTER_VALUE) {
      order["leaflet_distribution_points"] = advertisement.dotes;
    }

    return order;

    // return {
    //   another: undefined,
    // }
  }

    const renderItem = (type: string, item: ItemOfflineAdv) => {
        switch(type) {
            case MATERIALS_VALUE:
                return (
                    <Fragment>
                      <Column width={"250px"}>
                        <ColumnTitle>Печатная продукция</ColumnTitle>
                        <ColumnHeader>{item.type.label}</ColumnHeader>
                      </Column>
                      <Column width={"115px"}>
                        <ColumnTitle>Размер материалов</ColumnTitle>
                        <ColumnHeader>{item.size.label}</ColumnHeader>
                      </Column>
                      <Column width={"70px"}>
                        <ColumnTitle>Количество</ColumnTitle>
                        <ColumnHeader>{item.count}</ColumnHeader>
                      </Column>
                      <Column width={"100%"} marginBottom={"0"}>
                        <ColumnTitle>Комментарий</ColumnTitle>
                        <ColumnHeader>{item.comment || "-"}</ColumnHeader>
                      </Column>
                    </Fragment>
              );
            case BUS_STOP_VALUE:
            case ELEVATORS_VALUE:
                return (
                    <Fragment>
                        <Column width={"40%"}>
                          <ColumnTitle>Желаемые адреса</ColumnTitle>
                          <ColumnHeader>{item.address}</ColumnHeader>
                        </Column>
                        <Column width={"40%"} marginBottom={"0"}>
                            <ColumnTitle>Комментарий</ColumnTitle>
                            <ColumnHeader>{item.comment || "-"}</ColumnHeader>
                        </Column>
                    </Fragment>
                );
            case MAGAZINE_VALUE:
                return (
                    <Fragment>
                        <Column width={"100%"}>
                            <ColumnTitle>Наименование печатных изданий</ColumnTitle>
                            <ColumnHeader>{item.name}</ColumnHeader>
                        </Column>
                        <Column width={"100%"} marginBottom={"0"}>
                            <ColumnTitle>Комментарий</ColumnTitle>
                            <ColumnHeader>{item.comment || "-"}</ColumnHeader>
                        </Column>
                    </Fragment>
                );
            case TRANSPORT_VALUE:
                return (
                    <Fragment>
                        <Column width={"100%"}>
                            <ColumnTitle>Желаемые номера маршрутов</ColumnTitle>
                            <ColumnHeader>{item.numbers}</ColumnHeader>
                        </Column>
                        <Column width={"100%"} marginBottom={"0"}>
                            <ColumnTitle>Комментарий</ColumnTitle>
                            <ColumnHeader>{item.comment || "-"}</ColumnHeader>
                        </Column>
                    </Fragment>
                );
            case POST_BOX_VALUE:
            case ENTRANCES_VALUE:
                return (
                    <Fragment>
                        <Column width={"100%"}>
                            <ColumnTitle>Желаемые адреса</ColumnTitle>
                            <ColumnHeader>{item.address}</ColumnHeader>
                        </Column>
                        <Column width={"100%"} marginBottom={"0"}>
                            <ColumnTitle>Комментарий</ColumnTitle>
                            <ColumnHeader>{item.comment || "-"}</ColumnHeader>
                        </Column>
                    </Fragment>
                );
            case PROMOTER_VALUE:
                return (
                    <Fragment>
                        <Column width={"100%"}>
                            <ColumnTitle>Желаемые точки раздачи листово</ColumnTitle>
                            <ColumnHeader>{item.dotes}</ColumnHeader>
                        </Column>
                        <Column width={"100%"} marginBottom={"0"}>
                            <ColumnTitle>Комментарий</ColumnTitle>
                            <ColumnHeader>{item.comment || "-"}</ColumnHeader>
                        </Column>
                    </Fragment>
                );
            case OTHER_VALUE:
                return (
                    <Fragment>
                        <Column width={"100%"} marginBottom={"0"}>
                            <ColumnTitle>Комментарий</ColumnTitle>
                            <ColumnHeader>{item.comment || "-"}</ColumnHeader>
                        </Column>
                    </Fragment>
                );
        }
    };

    return (
        <>
            <Title>Итого</Title>
            <ItemsWrapper>
                {
                    Object.keys(basket).map(key => {
                        const title = offlineAdvertisingType.find(j => j.value === key);
                        if (basket[key].length)
                            return (
                                <TotalBlock key={key}>
                                    <Subtitle>{_get(title, "label", "-")}</Subtitle>
                                    {
                                        basket[key].map((item, i) => {
                                            const itemId = editItemId();
                                            return (
                                                <ItemWrapper key={i}
                                                             isActive={itemId === item.id}
                                                             onClick={() => onSelectEditItem(key, item)}>
                                                  {itemId !== item.id &&
                                                    <ActionIcon
                                                    className={"btn-delete"}
                                                    action={() => deleteItem(key, item)}
                                                    size={24}
                                                    tooltip={"Удалить"}
                                                    >
                                                    <DeleteIcon/>
                                                    </ActionIcon>
                                                  }
                                                    {renderItem(key, item)}
                                                </ItemWrapper>
                                            )
                                        })
                                    }
                                </TotalBlock>
                            )
                    })
                }
            </ItemsWrapper>
            <ButtonsWrapper>
                <Button
                  btnStyle={'primary'}
                  title={"Сохранить"}
                  onClick={onFetchSave}
                  disabled={isLoading}
                />
            </ButtonsWrapper>
        </>
    );
};

export default Basket;
