import React, {FC, memo, MutableRefObject, useRef} from "react";
import {NavLink} from "react-router-dom";
import styled from "styled-components";
import {useOutsideClick} from "../../../../hooks/useOutsideClick";
import useResize from "../../../../hooks/useResize";
import {theme} from "../../../../styles/theme";

interface Props {
    options: {
        exact: boolean;
        path: string;
        title: string;
    }[];
    position: {
        left: number;
        top: number;
    };
    itemRef: MutableRefObject<any>;
    onClose: () => void;
    onClick: () => void;
}

const Options: FC<Props> = memo(({
    options,
    position,
    itemRef,
    onClose,
    onClick,
}) => {
    const optionsRef = useRef(null);
    
    useOutsideClick(optionsRef, (event) => {
        if (itemRef.current.contains(event.target)) {
            return;
        }
        onClose();
    });
    
    useResize(() => {
        onClose();
    });
    
    return (
        <OptionsWrapper
            ref={optionsRef}
            position={position}
            onClick={onClick}
        >
            {options.map(option =>
                <NavLink
                    key={option.path}
                    exact={option.exact}
                    to={option.path}
                    className={window.location.pathname === option.path ? "active-menu-item-option" : ""}
                >
                    {option.title}
                </NavLink>
            )}
        </OptionsWrapper>
    );
});

const OptionsWrapper = styled.div<{position: {left: number, top: number}}>`
  display: flex;
  flex-direction: column;
  position: fixed;
  max-width: 240px;
  left: ${props => props.position.left}px;
  top: ${props => props.position.top}px;
  padding: 4px 0;
  background: #ffffff;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  z-index: 1;
  font-family: ${theme.fonts.mainRegular};
  font-size: ${theme.fontSizes.body};
    
  @media (max-width: 820px) {
      position: relative;
      left: 0;
      top: 0;
      box-shadow: none;
      width: 100%;
      max-width: 100%;
      background-color: ${({theme}) => theme.colors.neutral2};
  }
  
  a {
    text-decoration: none;
    color: ${theme.colors.neutral10};
    font-weight: normal;
    padding: 5px 10px;
    
    &:hover {
      color: white;
      background: ${theme.colors.primary};
    }
  }
  
  a.active-menu-item-option {
    color: white;
    background: ${theme.colors.primary};
  }
`;

export default Options;