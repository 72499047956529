import React, {FC, memo, useCallback, useEffect, useMemo, useRef, useState} from "react";
import styled from "styled-components";
import ChevronIcon from "../../../../../assets/icons/ChevronIcon";
import ActionIcon from "../../../../../components/ActionIcon";
import Button from "../../../../../components/Button";
import moment from "moment";
import Loader from "../../../../../components/Loader";
import useResize from "../../../../../hooks/useResize";
import useScroll from "../../../../../hooks/useScroll";
import {theme} from "../../../../../styles/theme";
import stringifyNumber from "../../../../../utils/stringifyNumber";
import Cell from "./Cell";
import ContextMenu from "./ContextMenu";
import {useFetching} from "../../../../../hooks/useFetching";
import {getCandidateInterviews} from "../../../../../actions/candidateInterviewsCalendar";
import dayjs from "dayjs";
import {TIMES} from "../../const";

interface Props {
    value: string;
    restaurantId: number;
    candidateProfileId: number;
    onInterviewDatePick: (date: string) => void;
}

const getContextMenuPosition = (ref) => {
    if (ref && ref.current) {
        const rect = ref.current.getBoundingClientRect();
        return {
            left: rect.left,
            top: rect.top + rect.height,
        };
    } else {
        return {
            left: 0,
            top: 0,
        };
    }
};

const CandidateFormCalendar: FC<Props> = memo(({
    value,
    restaurantId,
    candidateProfileId,
    onInterviewDatePick
}) => {
    const [candidateInterviews, setCandidateInterviews] = useState(null);
    const [currentDate, setCurrentDate] = useState(moment());
    const [contextMenuPosition, setContextMenuPosition] = useState({left: 0, top: 0});
    const [contextMenuOpen, setContextMenuOpen] = useState(false);
    const [contextMenuPickedTime, setContextMenuPickedTime] = useState(
        value ? moment(value, "DD.MM.YYYY HH:mm").format("HH:mm") : ""
    );
    const [pickedDate, setPickedDate] = useState(
        value ? moment(value, "DD.MM.YYYY HH:mm").format("DD.MM.YYYY") : ""
    );
    const [pickedDay, setPickedDay] = useState("");
    const currentCellRef = useRef(null);
    const currentDay = useRef(null);
    currentDay.current = 0;
    const slotsTotal = TIMES.length;
    
    const daysInMonth = moment(
        "01." +
        String(moment(currentDate).month() + 1) + "." +
        String(moment(currentDate).year()),
        "DD.MM.YYYY"
    ).daysInMonth();
    const startOfMonth = moment(
        "01." +
        String(moment(currentDate).month() + 1) + "." +
        String(moment(currentDate).year()),
        "DD.MM.YYYY"
    ).startOf("month");
    const firstDayIndex = parseInt(moment(startOfMonth).format("e"));
    const daysInFirstWeek = 7 - firstDayIndex;
    const tableRows = Math.ceil((daysInMonth - daysInFirstWeek) / 7) + 1;
    
    const [fetchCandidateInterviews, isLoading, error] = useFetching(async (data) => {
        const response = await getCandidateInterviews(data);
        setCandidateInterviews(response);
    });

    const reservedSlots = useMemo(() => {
        let _pickedDay;
        if(pickedDay) {
            _pickedDay = String(pickedDay).length === 1 ? `0${pickedDay}` : pickedDay;
        }
        const interviewDate = `${currentDate.format("YYYY")}-${currentDate.format("MM")}-${_pickedDay}`
        const interviews = candidateInterviews?.data
        return interviews ?
          interviews[interviewDate]?.map(interview => ({
              time: moment(interview.interview_date, "YYYY-MM-DD HH:mm:ss").format("HH:mm"),
              candidateProfileId: interview.candidate_profile_id
          })) :
          []
    }, [currentDate, pickedDay, candidateInterviews])

    useEffect(() => {
        fetchCandidateInterviews({
            restaurant_id: restaurantId,
            date_from: startOfMonth.format("YYYY-MM-DD"),
            date_to: dayjs(startOfMonth.toISOString())
                .endOf("month")
                .format("YYYY-MM-DD"),
        });
    }, [currentDate]);
    
    useScroll(() => {
        setContextMenuPosition(getContextMenuPosition(currentCellRef.current));
    });
    
    useResize(() => {
        setContextMenuPosition(getContextMenuPosition(currentCellRef.current));
    });
    
    const getPrevMonthHandle = useCallback(() => {
        const currentMonth = moment(currentDate).month() + 1;
        let prevMonth = currentMonth - 1;
        let currentYear = moment(currentDate).year();
        if (prevMonth === 0) {
            prevMonth = 12;
            currentYear -= 1;
        }
        const date = moment(
            "01." + String(prevMonth) + "." + String(currentYear),
            "DD.MM.YYYY"
        );
        setCurrentDate(date);
    }, [currentDate, pickedDay]);
    
    const getNextMonthHandle = useCallback(() => {
        const currentMonth = moment(currentDate).month() + 1;
        let nextMonth = currentMonth + 1;
        let currentYear = moment(currentDate).year();
        if (nextMonth === 13) {
            nextMonth = 1;
            currentYear += 1;
        }
        const date = moment(
            "01." + String(nextMonth) + "." + String(currentYear),
            "DD.MM.YYYY"
        );
        setCurrentDate(date);
    }, [currentDate]);
    
    const getReservedSlots = useCallback((day) => {
        const reservedSlots = [];
        const date = moment(getDateFromDay(day), "DD.MM.YYYY").format("YYYY-MM-DD");
        
        if (candidateInterviews?.data) {
            for (let key in candidateInterviews.data) {
                const reservedDay = candidateInterviews.data[key];
                
                if (key === date) {
                    reservedDay.map(reservedTime => {
                        const time = moment(reservedTime.interview_date).format("HH:mm");
                        if (!reservedSlots.find(item => item === time)) {
                            reservedSlots.push(time);
                        }
                    });
                }
            }
        }
        
        return reservedSlots;
    }, [currentDate, candidateInterviews, pickedDay]);
    
    const getPickedTime = useCallback((day) => {
        const date = getDateFromDay(day);
        if (date === pickedDate) {
            return contextMenuPickedTime;
        } else {
            return null;
        }
    }, [contextMenuPickedTime, currentDate, pickedDate]);
    
    const getDateFromDay = (day) => {
        const currentDay = stringifyNumber(day);
        const currentMonth = stringifyNumber(moment(currentDate).month() + 1);
        const currentYear = moment(currentDate).year();
        return currentDay + "." + currentMonth + "." + currentYear;
    };
    
    const getCellDisabled = useCallback(() => {
        const date = String(currentDay.current) + "." +
            String(currentDate.month() + 1) + "." +
            String(currentDate.year());
        
        return moment(moment().format("DD.MM.YYYY"), "DD.MM.YYYY")
            .isAfter(moment(date, "DD.MM.YYYY"))
    }, [currentDate, currentDay.current]);
    
    return (
        <>
            {isLoading && (
                <Loader width={100} height={100}/>
            )}
            
            {!isLoading && (
                <CalendarWrapper>
                    <CalendarHeaderWrapper>
                        <DateWrapper>{currentDate.format("MMMM YYYY")}</DateWrapper>
                        <DatePickWrapper>
                            <ActionIcon action={() => getPrevMonthHandle()}>
                                <ChevronIcon direction="left"/>
                            </ActionIcon>
                            <Button title="Сегодня" onClick={(event) => {
                                event.preventDefault();
                                setCurrentDate(moment());
                            }}/>
                            <ActionIcon action={() => getNextMonthHandle()}>
                                <ChevronIcon direction="right"/>
                            </ActionIcon>
                        </DatePickWrapper>
                    </CalendarHeaderWrapper>
        
                    <CalendarBodyWrapper>
                        {new Array(tableRows).fill("").map((rowItem, rowIndex) => {
                            if (rowIndex === 0) {
                                return (
                                    <CalendarBodyRowWrapper key={rowIndex}>
                                        {new Array(7).fill("").map((cellItem, cellIndex) => {
                                            if (cellIndex >= firstDayIndex) {
                                                currentDay.current += 1;
                                                return (
                                                    <Cell
                                                        key={cellIndex}
                                                        dayMonth={currentDay.current}
                                                        dayWeek={moment.weekdaysMin()[cellIndex]}
                                                        reservedSlots={getReservedSlots(currentDay.current)}
                                                        pickedTime={getPickedTime(currentDay.current)}
                                                        slotsTotal={slotsTotal}
                                                        disabled={getCellDisabled()}
                                                        onSetContextMenuPosition={(position) =>
                                                            setContextMenuPosition(position)
                                                        }
                                                        onSetContextMenuOpen={(open) =>
                                                            setContextMenuOpen(open)
                                                        }
                                                        onSetCurrentCellRef={(ref) => {
                                                            currentCellRef.current = ref;
                                                        }}
                                                        onSetPickedDate={(day) => setPickedDay(day)}
                                                    />
                                                );
                                            } else {
                                                return (
                                                    <Cell
                                                        key={cellIndex}
                                                        dayWeek={moment.weekdaysMin()[cellIndex]}
                                                    />
                                                );
                                            }
                                        })}
                                    </CalendarBodyRowWrapper>
                                );
                            } else {
                                return (
                                    <CalendarBodyRowWrapper key={rowIndex}>
                                        {new Array(7).fill("").map((cellItem, cellIndex) => {
                                            if (currentDay.current < daysInMonth) {
                                                currentDay.current += 1;
                                                return (
                                                    <Cell
                                                        key={cellIndex}
                                                        dayMonth={currentDay.current}
                                                        reservedSlots={getReservedSlots(currentDay.current)}
                                                        pickedTime={getPickedTime(currentDay.current)}
                                                        slotsTotal={slotsTotal}
                                                        disabled={getCellDisabled()}
                                                        currentDay={moment(currentDate).date()}
                                                        onSetContextMenuPosition={(position) =>
                                                            setContextMenuPosition(position)
                                                        }
                                                        onSetContextMenuOpen={(open) =>
                                                            setContextMenuOpen(open)
                                                        }
                                                        onSetCurrentCellRef={(ref) => {
                                                            currentCellRef.current = ref;
                                                        }}
                                                        onSetPickedDate={(day) => setPickedDay(day)}
                                                    />
                                                );
                                            } else {
                                                return <Cell key={cellIndex}/>;
                                            }
                                        })}
                                    </CalendarBodyRowWrapper>
                                );
                            }
                        })}
                    </CalendarBodyWrapper>
        
                    <ContextMenu
                        pickedDay={pickedDay}
                        candidateProfileId={candidateProfileId}
                        currentDate={currentDate}
                        position={contextMenuPosition}
                        open={contextMenuOpen}
                        reservedSlots={reservedSlots}
                        onClick={(time) => {
                            setContextMenuPickedTime(time);
                            setPickedDate(getDateFromDay(pickedDay));
                            setContextMenuOpen(false);
                            onInterviewDatePick(getDateFromDay(pickedDay) + " " + time);
                        }}
                        onOutsideClick={() => setContextMenuOpen(false)}
                    />
                </CalendarWrapper>
            )}
        </>
    );
});

const CalendarWrapper = styled.div`
  overflow: auto;
  padding: 0 1px;
`;

const CalendarHeaderWrapper = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;

const DateWrapper = styled.div`
  color: ${theme.colors.neutral10};
  font-size: ${theme.fontSizes.h3};
  font-family: ${theme.fonts.mainBold};
`;

const DatePickWrapper = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
`;

const CalendarBodyWrapper = styled.div`
  background-color: rgba(248, 249, 250, 0.2);
`;

const CalendarBodyRowWrapper = styled.div`
  display: flex;
  border-bottom: 1px solid ${theme.colors.neutral2};

    &:last-child {
        border-bottom: 0;
    }
`;

export default CandidateFormCalendar;