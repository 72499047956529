import { connectModal } from "../../../hocs/Modal";
import { Modal } from "../../../components/Modal";
import React, {FC, useEffect, useState} from "react";
import FormComponent from "./Form";
import {
  DEFAULT_FROM_VALUE,
  DEFAULT_RESULT_VALUE, ISelectOption,
  ItemOfflineAdv,
} from "./const";
import Basket from "./Basket";
import cloneDeep from "lodash/cloneDeep";

interface Props {
  offlineAdvertisingType: ISelectOption[];
  printedProduct: ISelectOption[];
  posterSize: ISelectOption[];
  isLoading: boolean;
  fetchOfflineAdvertising: () => void;
}

const AddModal: FC<Props> = connectModal("add-offline-adv-modal")(({
  offlineAdvertisingType,
  printedProduct,
  posterSize,
  isLoading,
  fetchOfflineAdvertising,
  handleHide
}) => {
  const [result, setResult] = useState(cloneDeep(DEFAULT_RESULT_VALUE));
  const [editItem, setEditItem] = useState(null);

  const getFillResult = () => {
    let isFill = false;
    Object.keys(result).map(key => {
      if (result[key].length) {
        isFill = true;
      }
    });
    return isFill;
  };

  const addNewItem = (type: string, item: ItemOfflineAdv) => {
    const newResult = {...result};
    newResult[type].push(item);
    setResult(newResult);
  };

  const editOldItem = (type: string, item: ItemOfflineAdv) => {
    const newResult = {...result};
    const index = newResult[type].findIndex(i => i.id === item.id);
    newResult[type][index] = item;
    setResult(newResult);
    setEditItem(null);
  };

  const onSelectEditItem = (type: string, item: ItemOfflineAdv) => {
    if(!!editItem && editItem[editItem.type.value].id === item.id) {
      setEditItem(null);
    } else {
      const newEditItem = cloneDeep(DEFAULT_FROM_VALUE);
      newEditItem.type = offlineAdvertisingType.find(i => i.value === type);
      newEditItem.restaurant = item.restaurant;
      newEditItem["advertisingMonth"] = item.advertisingMonth;
      newEditItem[type] = item;

      setEditItem(newEditItem);
    }
  };

  const deleteItemFromForm = () => {
    let newResult = {...result};
    newResult[editItem.type.value] = newResult[editItem.type.value].filter(i => i.id !== editItem[editItem.type.value].id);
    setEditItem(null);
    setResult(newResult);
  };

  const deleteItemFromBasket = (type: string, item: ItemOfflineAdv) => {
    let newResult = {...result};
    newResult[type] = newResult[type].filter(i => i.id !== item.id);
    setEditItem(null);
    setResult(newResult);
  };

  useEffect(() => {
    setResult(cloneDeep(DEFAULT_RESULT_VALUE));
  }, []);

  const isFill = getFillResult();

  return (
    <Modal title={"Добавление заказа"}
           width={"550px"}
           secondWidth={isFill ? "550px" : "0"}
           secondContainerAdaptiveWidth={"1100px"}
           onClose={handleHide}
           secondContainer={!isFill ? null :
               <Basket offlineAdvertisingType={offlineAdvertisingType}
                       basket={result}
                       editItem={editItem}
                       deleteItem={deleteItemFromBasket}
                       onSelectEditItem={onSelectEditItem}
                       fetchOfflineAdvertising={fetchOfflineAdvertising}/>
           }>
      <FormComponent
        offlineAdvertisingType={offlineAdvertisingType}
        printedProduct={printedProduct}
        posterSize={posterSize}
        isLoading={isLoading}
        handleHide={handleHide}
        addNewItem={addNewItem}
        editOldItem={editOldItem}
        editItem={editItem}
        deleteItem={deleteItemFromForm}
        resultIsFill={getFillResult()}
      />
    </Modal>
  );
});

export default AddModal;
