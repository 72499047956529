export const Data = {
  answers: [
    {
      title: 'A',
      text: 'Полностью согласен',
    },
    {
      title: 'B',
      text: 'Согласен',
    },
    {
      title: 'C',
      text: 'Не согласен',
    },
    {
      title: 'D',
      text: 'Совсем не согласен',
    },
  ],
  questions: [
    {
      text: 'Я часто не понимаю поступки у других людей.',
    },
    {
      text: 'Я могу подолгу общаться с продавцом в магазине.',
    },
    {
      text: 'Я никогда не брал чужую вещь без спроса, даже на время.',
    },
    {
      text: 'Решение проблем на работе редко зависит от моих действий.',
    },
    {
      text: 'Я всегда радуюсь успехам других людей, даже тех, которые мне не нравятся.',
    },
    {
      text: 'Я теряюсь, сталкиваясь с грубостью и наглостью.',
    },
    {
      text: 'Все говорят, что я внимателен к потребностям других.',
    },
    {
      text: 'Мне не нравится, когда коллеги в рабочее время занимаются своими личными делами.',
    },
    {
      text: 'Мне удается решать проблемы благодаря хорошим отношениям с другими людьми.',
    },
    {
      text: 'Нет ничего страшного, если сотрудник забудет какую-то просьбу гостя или не даст ответ на его вопрос.',
    },
    {
      text: 'Зачем говорить о своих ошибках, если другие могут этого и не заметить.',
    },
    {
      text: 'Я учитываю интересы других людей, даже в ущерб своим.',
    },
    {
      text: 'Я пристально слежу за поведением других сотрудников, чтобы убедиться, все ли они делают правильно.',
    },
    {
      text: 'Я редко ошибаюсь в других людях',
    },
    {
      text: 'Мне сложно общаться с негативно настроенными людьми.',
    },
    {
      text: 'Мне говорят, что я воспринимаю правила и нормы очень серьезно.',
    },
    {
      text: 'Чтобы добиться результата, можно пожертвовать чужими интересами.',
    },
    {
      text: 'В детстве я сразу и без пререканий делал всё, что от меня требовалось.',
    },
    {
      text: 'Если мне кто-то не нравится, я хочу понять, почему.',
    },
    {
      text: 'Я ничем не могу помочь, если другие не понимают важность качественного обслуживания.',
    },
    {
      text: 'Ничего страшного не случится, если пару раз уйти с работы пораньше без ведома руководителя.',
    },
    {
      text: 'Иногда я раздражаюсь.',
    },
    {
      text: 'Я часто общаюсь с коллегами с прошлого места работы.',
    },
    {
      text: 'Я никогда не принимал(а) решения, которые могли бы навредить другим.',
    },
    {
      text: 'Можно игнорировать те правила, которые являются не очень разумными и мешают работе.',
    },
    {
      text: 'Сотрудники сами должны понимать важность качества обслуживания, без моих напоминаний.',
    },
    {
      text: 'Я стараюсь не полагаться на других людей, а решать все проблемы самостоятельно.',
    },
    {
      text: 'Я всегда перв(ым/ой) начинаю общаться с незнакомым человеком.',
    },
    {
      text: 'Бывает, откладываю то, что требуется сделать немедленно.',
    },
    {
      text: 'Благодаря мне сотрудники всегда внимательны к просьбам и запросам гостей.',
    },
    {
      text: 'Каждый сотрудник сам несет ответственность за свое поведение, я не считаю нужным следить за каждым.',
    },
    {
      text: 'Если кто-то нарушил правила, то это не означает, что я должен сообщать об этом еще кому-то.',
    },
    {
      text: 'Говорят, я могу успокоить кого угодно.',
    },
    {
      text: 'Обычно я прошу других вмешаться и решить возникшую на работе проблему, т.к. от меня мало что зависит.',
    },
    {
      text: 'Я никогда не опаздывал(а) на работу.',
    },
    {
      text: 'Нет никакой необходимости прислушиваться ко всем просьбам гостей.',
    },
    {
      text: 'Любому человеку позволено соврать, чтобы избежать неприятностей.',
    },
    {
      text: 'Я слежу, чтобы сотрудники были внимательны к запросам гостей.',
    },
    {
      text: 'Я всегда выполняю данные мной обещания.',
    },
    {
      text: 'Я обязательно сделаю замечание тому, кто выбросил мусор на улице.',
    },
    {
      text: 'Я считаю, что проблемы с обслуживанием гостей (клиентов) должен решать человек выше меня по должности.',
    },

  ]
}