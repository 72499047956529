import React, {FC, memo} from "react";
import styled from "styled-components";
import {theme} from "../../styles/theme";
import HelpIcon from "../../assets/icons/HelpIcon";
import ReactTooltip from "react-tooltip";
import {FOR_LANDING, FOR_MANUAL_INPUT, FOR_UTM_SOURCE, SOURCE_TYPE} from "../../pages/AdCompanies/const";

interface RowComponent {
    row: {
        [key: string]: string | number | boolean;
    };
    field?: string;
    rowIndex?: number;
    value?: string | number | boolean;
    onRequestFulfilled?: () => void;
    onChange?: (value, field, rowIndex, row) => void;
}

interface Props {
    data: {
        [key: string]: string | number | boolean;
    }[];
    fields: {
        label: string;
        field?: string;
        type: string;
        component?: FC<RowComponent>;
        help?: string;
    }[];
    onClick?: (id: string | number | boolean) => void;
    onRequestFulfilled?: () => void;
    onInputTableChange?: (value, field, rowIndex, row) => void;
    textAlign?: string;
    className?: string;
}

const TableBody: FC<Props> = memo(({
    data,
    fields,
    onClick,
    onRequestFulfilled,
    onInputTableChange,
    textAlign,
    className,
}) => {

    return (
        <>
            {data?.map((row, rowIndex) => (
                <RowWrapper
                  key={rowIndex}
                  onClick={() => onClick(row.id)}
                  textAlign={textAlign}
                  className={className}
                >
                    {fields.map((element, elementIndex) => {
                        const property = element.field.split('.');
                        let show = row[property[0]];

                        if(property.length > 1 && show !== null) {
                            show = show[property[1]];
                        }
                        if (row[`${element.field}Type`] === "string") {
                            return <td key={elementIndex}>{show}</td>;
                        }
                        if (element.type === "string" || element.type === "number") {
                            if (show === "Плановое отсутствие, в часах") {
                                ReactTooltip.rebuild();
                                return (
                                  <td key={elementIndex}>
                                      <Block>
                                          {show}
                                          <HelpIconWrapper data-tip={"К плановым отсутствиям относятся отпуск, отгул, больничный. Указывается сумма запланированных часов отсутствия по всем сотрудникам."}>
                                              <HelpIcon/>
                                          </HelpIconWrapper>
                                      </Block>
                                  </td>
                                )
                            }
                            if (show === "Целевая производительность/продуктивность на 1 сотрудника в час") {
                                ReactTooltip.rebuild();
                                return (
                                  <td key={elementIndex}>
                                      <Block>
                                          {show}
                                          <HelpIconWrapper data-tip={"Целевая производительность заполняется в рублях - сумма чеков на 1 сотрудника в час, целевая продуктивность заполняется в кол-ве транзакций/средних чеков на 1 сотрудника в час"}>
                                              <HelpIcon/>
                                          </HelpIconWrapper>
                                      </Block>
                                  </td>
                                )
                            }
                            if (show === "План по товарообороту/Плановое количество транзакций") {
                                ReactTooltip.rebuild();
                                return (
                                  <td key={elementIndex}>
                                      <Block>
                                          {show}
                                          <HelpIconWrapper data-tip={"План по товарообороту заполняется в рублях, если указана целевая производительность в рублях. Плановое количество транзакций заполняется, если указана Целевая продуктивность в кол-че чеков"}>
                                              <HelpIcon/>
                                          </HelpIconWrapper>
                                      </Block>
                                  </td>
                                )
                            }

                            if(show === FOR_MANUAL_INPUT || show === FOR_UTM_SOURCE || show === FOR_LANDING) {
                                show = SOURCE_TYPE.find(el => el.value === show).label;
                            }
                            return <td key={elementIndex}>{show}</td>
                        }
                        if (element.type === "component-icons") {
                            const Component = element.component;
                            return (
                                <td key={elementIndex}>
                                    <Component row={row} onRequestFulfilled={onRequestFulfilled}/>
                                </td>
                            );
                        }
                        if (element.type === "component-input") {
                            const Component = element.component;
                            if (show !== null && show.toString() === "NaN") {
                                show = "";
                            }
                            return (
                                <td key={elementIndex}>
                                    <Component
                                        row={row}
                                        rowIndex={rowIndex}
                                        field={element.field}
                                        value={show}
                                        onChange={onInputTableChange}
                                        onRequestFulfilled={onRequestFulfilled}
                                    />
                                </td>
                            );
                        }
                        if (element.type === "component-checkbox") {
                            const Component = element.component;
                            return (
                              <td key={elementIndex}>
                                  <Component
                                    row={row}
                                    onRequestFulfilled={onRequestFulfilled}
                                  />
                              </td>
                            );
                        }
                    })}
                </RowWrapper>
            ))}
        </>
    );
});

const Block = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const HelpIconWrapper = styled.div`
  width: 18px;
  height: 18px;
    
  &:hover {
      path {
          fill: ${theme.colors.primary};
      }
  }
`;

const RowWrapper = styled.tr<{textAlign?: string,}>`
  td {
    font-family: ${theme.fonts.mainRegular};
    font-size: ${theme.fontSizes.body};
    color: ${theme.colors.bodyText};
    border-bottom: 1px solid ${theme.colors.neutral3};
      
    ${({textAlign}) => textAlign && 'text-align: ' + textAlign};

    &:not(.icons) {
      padding: 10px;
      word-break: break-word;
    }

    &.icons {
      & > div {
        opacity: 0;
      }
    }
  }

  &:hover {
    background: ${theme.colors.neutral2};

    td.icons {
      & > div {
        opacity: 1;
      }
    }
  }
    
  @media (hover: none) {
      td.icons {
          & > div {
              opacity: 1 !important;
          }
      }
  }
`;

export default TableBody;
