import moment from "moment";
import React, {FC, useCallback, useContext, useEffect, useMemo, useState,} from "react";
import ReactTooltip from "react-tooltip";
import styled from "styled-components";
import * as yup from "yup";
import {createCandidateInterview, putCandidateInterview,} from "../../../../actions/interviews";
import {Gender, rejectCandidateProfileStatuses, sourceResources} from "../../../../assets/CandidateForms/RowsSection/Enums";
import ArrowBack from "../../../../assets/icons/ArrowBack";
import ActionIcon from "../../../../components/ActionIcon";
import Button from "../../../../components/Button";
import {Input} from "../../../../components/Input";
import Select from "../../../../components/Select";
import {errorsDescriptions} from "../../../../config/errors";
import {useFetching} from "../../../../hooks/useFetching";
import {ModalsStore} from "../../../../stores/modal";
import {theme} from "../../../../styles/theme";
import Comments from "../Comments";
import DeleteCandidateFormModal from "../DeleteFormModal";
import HandOverFormModal from "../HandOverFormModal";
import Interview from "../Interrview";
import InterviewModal from "../InterviewModal";
import VacancyFormModal from "../VacancyFormModal";
import {DirectoryStoreContext} from "../../../../stores/directory";
import {Controller, useForm, useWatch} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {createCandidateProfile, getCandidateProfile, putCandidateProfile,} from "../../../../actions/candidates";
import {useHistory, useParams} from "react-router-dom";
import {privateRoutes} from "../../../../config/routes";
import {observer} from "mobx-react-lite";
import {AuthStoreContext} from "../../../../stores/auth";
import {INTERVIEW_REQUIRED_STATUSES, NOT_FOUND_TEXT} from "../const";
import {checkKeyDown} from "../chekKeyDown";
import Loader from "../../../../components/Loader";
import {FOR_MANUAL_INPUT} from "../../../AdCompanies/const";
import {useMemoVacanciesOptions} from "../../../../hooks/useMemoVacanciesOptions";
import Page404 from "../../../Page404";
import {
  getStatuses,
  showClarification,
  showInterview,
  showMedicalDate,
  showRefuseReason,
  showRegistrationDate,
  showRJP,
  showStatusComment
} from "../../../../utils/getStatuses";
import Datepicker from "../../../../components/Datepicker";
import {createComment} from "../../../../actions/comments";
import {useMemoStatusDetailOptions} from "../../../../hooks/useMemoStatusDetailOptions";
import {useMemoRejectOptions} from "../../../../hooks/useMemoRejectOptions";
import {onChangeOnlyLetters} from "../../../../utils/onChangeOnlyLetters";

interface Props {
  data?: any;
  changeViewMode: () => void;
  onRequestFulfilled: () => void;
}

const schema = yup.object().shape({
  candidate_profile_status_id: yup
    .number(),
  rjpDate: yup
    .string()
    .when('candidate_profile_status_id', (status, field) => status === 27
      ? field.required(errorsDescriptions.required)
      : field.nullable()),
  medicalDate: yup
    .string()
    .when('candidate_profile_status_id', (status, field) => status === 28
      ? field.required(errorsDescriptions.required)
      : field.nullable()),
  registrationDate: yup
    .string()
    .when('candidate_profile_status_id', (status, field) => status === 29
      ? field.required(errorsDescriptions.required)
      : field.nullable()),
  clarification: yup
    .number()
    .when('candidate_profile_status_id', (status, field) => [2, 6, 8, 22].includes(status)
      ? field.required("")
      : field.nullable()),
  /*resource: yup
      .number()
      .when(['source_id'], {
        is: (...value) => sourceResources.includes(value[0]),
        then: yup.number().required(""),
        otherwise: yup.number().nullable()
      }),*/
  refuseReason: yup
    .number()
    .when(['clarification'], {
      is: (...value) => rejectCandidateProfileStatuses.includes(value[0]),
      then: yup.number().required(""),
      otherwise: yup.number().nullable()
    }),
  statusComment: yup
    .string()
    .when(
      ['refuseReason', 'clarification'],
      {
        is: (...values) => values[0] === 8 || values[1] === 6,
        then: yup.string().required(errorsDescriptions.required),
        otherwise: yup.string().nullable()
      }),
  first_name: yup
    .string()
    .required(errorsDescriptions.required)
      .min(2, "Это поле должно содержать не меньше 2 символов"),
  age: yup
    .number()
    .nullable()
    .required(errorsDescriptions.required)
    .min(0, errorsDescriptions.age)
    .max(200, errorsDescriptions.age),
  sex: yup.string().required(errorsDescriptions.required),
  citizenship_id: yup.string().required(errorsDescriptions.required),
  phone: yup
    .string()
    .required(errorsDescriptions.required)
    .matches(
      /^(7)?[0-9]{3}[0-9]{3}[0-9]{2}[0-9]{2}$/g,
      errorsDescriptions.phone
    ),
  email: yup
    .string()
    .matches(
        /^[_a-zA-Z\d-]+(\.[_a-zA-Z\d-]+)*@[a-zA-Z\d-]+(\.[a-z\d-]+)*(\.[a-z]{1,6})$|^$/g,
      errorsDescriptions.email
    ),
  city_id: yup.string().required(errorsDescriptions.required),
  vacancy_id: yup.number().required(errorsDescriptions.required),
  restaurant_id: yup.string().required(errorsDescriptions.required),
  source_id: yup.string().required(errorsDescriptions.required),
  interview: yup
    .object()
    .when('candidate_profile_status_id', (status, field) => {
      return INTERVIEW_REQUIRED_STATUSES.includes(status) ?
        field
          .shape({
            interview_date: yup.string().required(errorsDescriptions.required)
          })
        : field.nullable()
    })
});

const CandidateForm: FC<Props> = observer(() => {
  const {
    handleSubmit,
    control,
    setError,
    getValues,
    setValue,
    formState: { errors, isValid },
    watch,
    resetField
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      candidate_profile_status_id: null,
      clarification: null,
      refuseReason: null,
      statusComment: "",
      rjpDate: null,
      medicalDate: null,
      registrationDate: null,
      second_name: "",
      first_name: "",
      age: null,
      sex: "",
      citizenship_id: null,
      phone: "",
      email: "",
      city_id: null,
      vacancy_id: null,
      restaurant_id: null,
      source_id: null,
      resource: null,
      interview: {
        description: "",
        interview_date: null,
      },
    },
  });

  const getVacancy = (vacancyId) => {
    const vacancy = vacanciesOptions.find(el => el.value === vacancyId);

    if(vacancy) {
      return vacancyId;
    }else {
      return vacancies.find(el => el.id === vacancyId).name;
    }
  }

  const initFields = (fields) => {
    setValue("candidate_profile_status_id", fields?.candidate_profile_status?.id);
    if(showClarification(fields?.candidate_profile_status?.id) && fields.status_detail_id) {
      setValue("clarification", fields.status_detail_id)
    }
    if(showRJP(fields?.candidate_profile_status?.id) && fields.rjp_date)
      setValue("rjpDate", fields.rjp_date);
    if(showMedicalDate(fields?.candidate_profile_status?.id) && fields.medical_date)
      setValue("medicalDate",fields.medical_date);
    if(showRegistrationDate(fields?.candidate_profile_status?.id) && fields.registration_date)
      setValue("registrationDate", fields.registration_date);
    if(showRefuseReason(fields?.candidate_profile_status?.id, fields.status_detail_id)
      && fields.reject_id) {
      setValue("refuseReason", fields.reject_id);
    }
    setValue("second_name", fields.second_name);
    setValue("first_name", fields.first_name);
    setValue("age", fields.age);
    setValue("sex", fields.sex);
    setValue("citizenship_id", fields.citizenship?.id);
    setValue("phone", fields.phone);
    setValue("email", fields.email ? fields.email : "");
    setValue("city_id", fields.city?.id);
    setValue("vacancy_id", fields?.vacancy?.id
      ? getVacancy(fields?.vacancy?.id)
      : "");
    setValue("restaurant_id", fields.restaurant?.id);
    setValue("source_id", fields.source?.id);
    setValue("resource", fields.source_resource?.id || fields.source_resource_comment);
    setValue("interview",
      {
        description: fields.interview?.description,
        interview_date: fields.interview ?
          moment(fields.interview?.interview_date, "YYYY-MM-DD HH:mm:ss")
            .format("DD.MM.YYYY HH:mm") :
          "",
      }
    );
  }

  const statusSelectValue = useWatch({
    control,
    name: "candidate_profile_status_id",
  });
  const interviewObjectValue = useWatch({
    control,
    name: "interview",
  });
  const restaurantValue = useWatch({
    control,
    name: "restaurant_id",
  });
  const cityValue = useWatch({
    control,
    name: "city_id",
  });
  const statusClarificationValue = useWatch({
    control,
    name: "clarification",
  });
  const statusRefuseValue = useWatch({
    control,
    name: "refuseReason",
  });

  const [candidateProfileData, setCandidateProfileData] = useState<
    Props["data"]
  >({});
  const { checkAuth } = useContext(AuthStoreContext);
  const {
    candidateProfileStatuses,
    candidateProfileStatusesEndFetching,
    citizenship,
    citizenshipEndFetching,
    cities,
    citiesEndFetching,
    restaurants,
    restaurantsEndFetching,
    sources,
    sourcesEndFetching,
    vacancies,
    rejects,
    rejectsEndFetching,
    statusDetails,
    statusDetailEndFetching
  } = useContext(DirectoryStoreContext);

  const { userData } = useContext(AuthStoreContext);

  const urlParams = useParams<{ id: string }>();
  const history = useHistory<any>();
  const [allDataIsDone, setAllDataIsDone] = useState(false);
  const [cityOptionsIsDone, setCityOptionsIsDone] = useState(false);
  const [restaurantOptionsIsDone, setRestaurantOptionsIsDone] = useState(false);
  const [isInsufficientRights, setUsInsufficientRights] = useState<boolean>(false)

  useEffect(() => {
    if (candidateProfileStatusesEndFetching &&
      citizenshipEndFetching &&
      citiesEndFetching &&
      restaurantsEndFetching &&
      sourcesEndFetching &&
      rejectsEndFetching &&
      statusDetailEndFetching &&
      cityOptionsIsDone &&
      restaurantOptionsIsDone
    ) {
      setAllDataIsDone(true);
    }
  }, [
    candidateProfileStatusesEndFetching,
    citizenshipEndFetching,
    citiesEndFetching,
    restaurantsEndFetching,
    sourcesEndFetching,
    rejectsEndFetching,
    statusDetailEndFetching,
    cityOptionsIsDone,
    restaurantOptionsIsDone
  ]);

  const vacanciesOptions = useMemoVacanciesOptions(candidateProfileData, urlParams.id, restaurantValue);

  const [fetchCandidateProfile, isLoading] = useFetching(async () => {
    const response = await getCandidateProfile(urlParams.id);

    if (response.code === 403 || response.code === 404) {
      setUsInsufficientRights(true);
    }
    if (response.message === "OK") {
      setCandidateProfileData(response.data);
      initFields(response.data);
    }
  });

  useEffect(() => {
    if (!candidateProfileStatusesEndFetching ||
      !citizenshipEndFetching ||
      !citiesEndFetching ||
      !restaurantsEndFetching ||
      !sourcesEndFetching ||
      !rejectsEndFetching ||
      !statusDetailEndFetching
    ) {
      return;
    }

    if (urlParams.id !== "newcandidate") {
      fetchCandidateProfile();
    }
  }, [
    candidateProfileStatusesEndFetching,
    citizenshipEndFetching,
    citiesEndFetching,
    restaurantsEndFetching,
    sourcesEndFetching,
    rejectsEndFetching,
    statusDetailEndFetching
  ]);

  const onVacancyFormModal = useCallback(
    (event) => {
      event.preventDefault();
      ModalsStore.showModal("vacancy-form-modal", {
        data: candidateProfileData,
        vacanciesList: vacanciesOptions,
        onChange: (value) => setValue("vacancy_id", value),
      });
    },
    [candidateProfileData]
  );

  const onOpenHandOverFormModal = useCallback(
    (event) => {
      event.preventDefault();

      ModalsStore.showModal("hand-over-form-modal", {
        pickedCity: cityValue,
        cityOptions,
        restaurantOptions,
        onChange: (restaurant_id, city_id) => {
          setValue("restaurant_id", restaurant_id);
          setValue("city_id", city_id);
          setCandidateProfileData(
            {
              ...candidateProfileData,
              restaurant: restaurants.find(restaurant => restaurant.id === restaurant_id)
          })
          setValue('interview', {
            description: "",
            interview_date: null,
          }, {shouldValidate: true})
        }
      });
    },
    [candidateProfileData, cityValue]
  );

  const onOpenInterviewModal = useCallback(
    (event) => {
      event.preventDefault();

      ModalsStore.showModal("candidate-profile-interview-modal", {
        interviewData: interviewObjectValue,
        candidateData: {
          city: candidateProfileData.city || cities.find((item) => item.id === cityValue),
          restaurant: candidateProfileData.restaurant || restaurants.find(
            (item) => item.id === restaurantValue
          ),
          candidateProfileId: candidateProfileData.id
        },
        onChange: (value) => setValue("interview", value, {shouldValidate: true}),
      });
    },
    [interviewObjectValue, cities, restaurants, candidateProfileData, restaurantValue]
  );

  const [takeFormInProcessHandle, isLoadingCondition] = useFetching(
    async (event) => {
      event.preventDefault();

      const condition =
        candidateProfileData.condition === "free" ? "in_work" : "free";
      const response = await putCandidateProfile(candidateProfileData.id, { condition });

      if (response.data.message === "OK") {
        setCandidateProfileData(response.data.data);
      }

      if (!!candidateProfileData.id && candidateProfileData.condition === "in_work") {
        history.push(history.location.state?.goBackPath || privateRoutes.candidateFormsChk.path);
      }
    }
  );

  const onSubmit = useCallback(async (formData) => {
    let candidateResponse;
    const candidateData = {
      candidate_profile_status_id: formData.candidate_profile_status_id,
      first_name: formData.first_name,
      second_name: formData.second_name || "",
      age: String(formData.age),
      email: formData.email,
      city_id: formData.city_id,
      sex: formData.sex,
      phone: formData.phone,
      experience: formData.experience,
      vacancy_id: parseInt(formData.vacancy_id),
      restaurant_id: parseInt(formData.restaurant_id),
      source_id: formData.source_id,
      interview_date: formData.interview
        ? moment(formData.interview.interview_date, "DD.MM.YYYY HH:mm").format("YYYY-MM-DD HH:mm:ss")
        : "",
      citizenship_id: formData.citizenship_id,
      is_auto_dialing: false,
      is_interrogation: false,
      interview: formData.interview
    };
    candidateData.age = String(candidateData.age);

    if(formData.rjpDate) {
      candidateData["rjp_date"] = formData.rjpDate;
    }
    if(formData.medicalDate) {
      candidateData["medical_date"] = formData.medicalDate;
    }
    if(formData.registrationDate) {
      candidateData["registration_date"] = formData.registrationDate;
    }
    if(formData.clarification) {
      candidateData["status_detail_id"] = formData.clarification;
    }
    if(formData.refuseReason) {
      candidateData["reject_id"] = formData.refuseReason;
    }

    const interviewData = candidateData.interview;
    delete candidateData.interview;
    delete candidateData.city_id;

    if(formData.resource){
      if(typeof formData.resource === "string") {
        candidateData["source_resource_comment"] = formData.resource;
        candidateData["source_resource_id"] = null;
      } else {
        candidateData["source_resource_comment"] = null;
        candidateData["source_resource_id"] = formData.resource;
      }
    }

    if (candidateProfileData.id) {
      if (candidateData?.email === candidateProfileData.email) {
        delete candidateData.email;
      }
      const response = await putCandidateProfile(candidateProfileData.id, candidateData);

      if(response.data) {
        candidateResponse = response.data.data;
      } else {
        if(response?.response?.data?.error?.email) {
          setError('email', {type: 'errorEmail', message: 'Такой email уже зарегестрирован'});
        }
        return;
      }
    } else {
      candidateData["source_marker"] = "added_manually";
      const response = await createCandidateProfile(candidateData);

      if(response.data) {
        candidateResponse = response.data.data;
      } else {
        if(response?.response?.data?.error?.email) {
          setError('email', {type: 'errorEmail', message: 'Такой email уже зарегестрирован'});
        }
        return;
      }
    }

    if(formData.statusComment && candidateResponse.id) {
      const commentData = {
        model_id: candidateResponse.id,
        model_type: "App\\Models\\CandidateProfile",
        comment: formData.statusComment,
      };
      await createComment(commentData);
    }

    if (interviewData.interview_date) {
      interviewData.candidate_profile_id = candidateResponse.id;
      interviewData.candidate_profile_status_id =
        candidateResponse.candidate_profile_status.id;
      interviewData.vacancy_id = candidateResponse.vacancy.id;
      interviewData.interview_date = moment(
        interviewData.interview_date,
        "DD.MM.YYYY HH:mm"
      ).format("YYYY-MM-DD HH:mm:ss");
      
      if (!interviewData.description) {
        delete interviewData.description;
      }

      if (candidateProfileData.interview) {
        await putCandidateInterview(
          candidateProfileData.interview.id,
          interviewData
        );
      } else {
        await createCandidateInterview(interviewData);
      }
    }

    checkAuth();

    if (!candidateProfileData.id) {
      history.push(
        history.location.state?.isChk
          ? privateRoutes.candidateFormsChk.path
          : privateRoutes.candidateFormsMs.path
      );
    }
  }, [candidateProfileData]);

  const getColoredStateValue = useCallback(() => {
    switch (candidateProfileData.condition) {
      case "free":
        return (
          <StateValueWrapper state="free">Свободна</StateValueWrapper>
        );
      case "archived":
        return <StateValueWrapper state="archiver">В архиве</StateValueWrapper>;
      case "in_work":
        return <StateValueWrapper state="in_work">В работе</StateValueWrapper>;
    }
  }, [candidateProfileData]);

  const getCityOptions = () => {
    return cities.map((item) => ({
      value: item.id,
      label: item.name_ru,
    }))
  };

  const getRestaurantsOptions = () => {
    return restaurants.map(item => (
      {
        label: item.name_ru,
        value: item.id,
        city_id: item.city_id,
        address_ru: item.address_ru,
      }
    ));
  };

  const cityOptions = useMemo(
    () => {
      const filteredCityOptions = getCityOptions();
      if (citiesEndFetching) setCityOptionsIsDone(true);

      return filteredCityOptions;
    },
    [citiesEndFetching]);

  const restaurantOptions = useMemo(
    () => {
      const filteredRestaurantOptions = getRestaurantsOptions();
      if(restaurantsEndFetching) setRestaurantOptionsIsDone(true);

      return filteredRestaurantOptions;
    },
    [cityValue, restaurantsEndFetching]);

  const filteredRestaurantOptions = useMemo(
    () => {
      return restaurantOptions.filter(option => option.city_id === cityValue)
    },
    [restaurantOptions]);

  const statusDetailOptions = useMemoStatusDetailOptions(statusDetails, statusSelectValue);

  const rejectOptions = useMemoRejectOptions(rejects, statusClarificationValue)

  const resourceRender = () => {
    const sourceId = watch("source_id")
    const resources = sources.find(source => source.id === sourceId)?.source_resources

    let disabled = false;
    if(candidateProfileData.id) {
      disabled = blockedSource()
    }

    return (
      <>
        {!!resources?.length &&
          <FieldsRowWrapper>
            <FieldElementWrapper fullWidth={true}>
              <Controller
                render={({field, fieldState}) => (
                  <Select
                    {...field}
                    {...fieldState}
                    title="Ресурс"
                    withSearch={true}
                    //required={true}
                    disabled={disabled}
                    options={resources.map(resource => ({value: resource.id, label: resource.name}))}
                    ref={null}
                  />
                )}
                name="resource"
                control={control}
              />
            </FieldElementWrapper>
          </FieldsRowWrapper>
        }
        {
          watch("source_id") === 51 && // источник "Другое"
          <FieldsRowWrapper>
            <FieldElementWrapper fullWidth={true}>
              <Controller
                render={({field, fieldState}) => (
                  <Input
                    {...field}
                    {...fieldState}
                    placeholder="Ресурс"
                    //required={true}
                    disabled={disabled}
                    ref={null}
                  />
                )}
                name="resource"
                control={control}
              />
            </FieldElementWrapper>
          </FieldsRowWrapper>
        }
      </>
    )}

  const onChangeSource = (value, onChange) => {
    onChange(value)
    setValue("resource", "")
  }

  const blockedSource = () => {
    return !!candidateProfileData?.source?.name ||
      candidateProfileData?.is_blocking ||
      (candidateProfileData.id && candidateProfileData?.condition !== "in_work")
  }

  const isBlocking = (): boolean => {
    return (candidateProfileData.id &&
        candidateProfileData?.condition !== "in_work") ||
      candidateProfileData?.is_blocking
  }

  const isBlockingAnotherUser = !!candidateProfileData?.assigned_by && (candidateProfileData?.assigned_by !== userData?.id)

  const isBlockingRestaurant = (): boolean => {
    return isBlocking() || cities.length === 0;
  }

  return (
    <>
      {(isLoading || !allDataIsDone)
          ?
        <Loader width={100} height={100}/>
          :
        isInsufficientRights
            ?
        <Page404 text={NOT_FOUND_TEXT} />
            :
        <CandidateFormWrapper
          onSubmit={handleSubmit(onSubmit)}
          onKeyDown={checkKeyDown}
        >
          <FormHeader>
            <FlexBlock gap={24} alignItems={"center"}>
              <ActionIcon
                action={() => {
                  history.goBack();
                  ReactTooltip.hide();
                }}
                tooltip="Вернуться к списку анкет"
              >
                <ArrowBack/>
              </ActionIcon>
              {candidateProfileData.id && (
                <>
                  <H1>Анкета ID {candidateProfileData.id}</H1>
                  {candidateProfileData.is_blocking && (
                    <BlockedFormWrapper>Заблокирована</BlockedFormWrapper>
                  )}
                </>
              )}
              {!candidateProfileData.id && <H1>Новая анкета</H1>}
            </FlexBlock>

            <HeaderRightSide>
              {candidateProfileData.id && (
                <>
                  {getColoredStateValue()}
                  <Button
                    type="button"
                    title={
                      candidateProfileData.condition === "in_work"
                        ? "Освободить анкету"
                        : "Взять в работу"
                    }
                    btnStyle="secondary"
                    disabled={
                      !candidateProfileData.id || candidateProfileData?.is_blocking || isBlockingAnotherUser
                    }
                    onClick={takeFormInProcessHandle}
                  />
                </>
              )}
              {!candidateProfileData.id && (
                <StateValueWrapper state="free">Свободна</StateValueWrapper>
              )}
              <Button
                title={isBlockingAnotherUser ? "Занята другим пользователем" : "Сохранить"}
                type="submit"
                disabled={
                  !isValid ||
                  (candidateProfileData.id && candidateProfileData.condition !== "in_work") ||
                  isLoadingCondition ||
                  candidateProfileData?.is_blocking ||
                  isBlockingAnotherUser
                }
                btnStyle="primary"
              />
            </HeaderRightSide>
          </FormHeader>

          <FormRowWrapper>
            <BlockWrapper>
              <H2>Статус анкеты<StarWrapper> *</StarWrapper></H2>

              <Controller
                render={({field, fieldState}) => (
                  <Select
                    {...field}
                    {...fieldState}
                    title=""
                    options={getStatuses(candidateProfileData, candidateProfileStatuses, userData)}
                    onChange={(value) => {
                      setValue('clarification', null)
                      setValue('refuseReason', null)
                      setValue('statusComment', null)
                      setValue('rjpDate', null)
                      setValue('medicalDate', null)
                      setValue('registrationDate', null)
                      field.onChange(value);
                    }}
                    error={errors?.candidate_profile_status_id?.message}
                    disabled={
                      (candidateProfileData.id &&
                        candidateProfileData?.condition !== "in_work") ||
                      candidateProfileData?.is_blocking ||
                      candidateProfileData?.candidate_profile_status?.id === 30
                    }
                    ref={null}
                  />
                )}
                name="candidate_profile_status_id"
                control={control}
              />

              {showRJP(statusSelectValue) &&
                <StatusInputWrapper>
                  <Controller
                    render={({field, fieldState}) => (
                      <Datepicker
                        value={field.value
                          ? moment(field.value, "YYYY-MM-DD HH:mm:ss")
                          : null
                        }
                        title="Дата и время RJP"
                        timeInput={true}
                        required={true}
                        disabled={isBlocking()}
                        onChange={(date) => field.onChange(date.format("YYYY-MM-DD HH:mm:ss"))}
                      />
                    )}
                    name="rjpDate"
                    control={control}
                  />
                </StatusInputWrapper>
              }

              {showMedicalDate(statusSelectValue) &&
                <StatusInputWrapper>
                  <Controller
                    render={({field, fieldState}) => (
                      <Datepicker
                        value={field.value
                          ? moment(field.value, "YYYY-MM-DD HH:mm:ss")
                          : null
                        }
                        title="Дата и время медосмотра"
                        timeInput={true}
                        required={true}
                        disabled={isBlocking()}
                        onChange={(date) => field.onChange(date.format("YYYY-MM-DD HH:mm:ss"))}
                      />
                    )}
                    name="medicalDate"
                    control={control}
                  />
                </StatusInputWrapper>
              }

              {showRegistrationDate(statusSelectValue) &&
                <StatusInputWrapper>
                  <Controller
                    render={({field, fieldState}) => (
                      <Datepicker
                        value={field.value
                          ? moment(field.value, "YYYY-MM-DD HH:mm:ss")
                          : null
                        }
                        title="Дата и время оформления"
                        timeInput={true}
                        required={true}
                        disabled={isBlocking()}
                        onChange={(date) => field.onChange(date.format("YYYY-MM-DD HH:mm:ss"))}
                      />
                    )}
                    name="registrationDate"
                    control={control}
                  />
                </StatusInputWrapper>
              }
              {showClarification(statusSelectValue) &&
                <StatusInputWrapper>
                  <Controller
                    render={({field, fieldState}) => (
                      <Select
                        {...field}
                        {...fieldState}
                        title="Уточнение"
                        onChange={(value) => {
                          field.onChange(value);
                          setValue('refuseReason', null, { shouldValidate: true })
                          setValue('statusComment', null)
                        }}
                        options={statusDetailOptions}
                        error={errors?.clarification?.message}
                        required={true}
                        disabled={isBlocking()}
                        ref={null}
                      />
                    )}
                    name="clarification"
                    control={control}
                  />
                </StatusInputWrapper>
              }

              {showRefuseReason(statusSelectValue, statusClarificationValue) && (
                <StatusInputWrapper>
                  <Controller
                    render={({field, fieldState}) => (
                      <Select
                        {...field}
                        {...fieldState}
                        title="Причина отказа"
                        options={rejectOptions}
                        onChange={(value) => {
                          field.onChange(value);
                          setValue('statusComment', null)
                        }}
                        disabled={isBlocking()}
                        ref={null}
                      />
                    )}
                    name="refuseReason"
                    control={control}
                  />
                </StatusInputWrapper>
              )}
              {showStatusComment(statusSelectValue, statusRefuseValue, statusClarificationValue) && (
                <StatusInputWrapper>
                  <Controller
                    render={({field, fieldState}) => (
                      <Input
                        {...field}
                        {...fieldState}
                        placeholder="Комментарий"
                        error={errors?.statusComment?.message}
                        required={true}
                        disabled={isBlocking()}
                        ref={null}
                      />
                    )}
                    name="statusComment"
                    control={control}
                  />
                </StatusInputWrapper>
              )}
            </BlockWrapper>

            <BlockWrapper>
              {showInterview(candidateProfileData, statusSelectValue, restaurants, cities) && (
                <Interview
                  onOpenInterviewModal={onOpenInterviewModal}
                  candidateProfileData={candidateProfileData}
                  interviewObjectValue={interviewObjectValue}
                  statusSelectValue={statusSelectValue}
                  cityValue={cityValue}
                  restaurantValue={restaurantValue}
                  formType="operator"
                />
              )}
            </BlockWrapper>
          </FormRowWrapper>

          <MainContentWrapper>
            <MainContentBlockWrapper>
              <H2>Информация о соискателе</H2>
              <MainContentFields>
                <FieldsRowWrapper>
                  <FieldElementWrapper fullWidth={false}>
                    <Controller
                      render={({field, fieldState}) => (
                        <Input
                          {...field}
                          {...fieldState}
                          placeholder="Фамилия"
                          onChange={value => onChangeOnlyLetters(value, field.onChange)}
                          error={errors?.second_name?.message}
                          disabled={
                            (candidateProfileData.id &&
                              candidateProfileData?.condition !== "in_work") ||
                            candidateProfileData?.is_blocking
                          }
                          ref={null}
                        />
                      )}
                      name="second_name"
                      control={control}
                    />
                  </FieldElementWrapper>
                  <FieldElementWrapper fullWidth={false}>
                    <Controller
                      render={({field, fieldState}) => (
                        <Input
                          {...field}
                          {...fieldState}
                          placeholder="Имя"
                          onChange={value => onChangeOnlyLetters(value, field.onChange)}
                          required={true}
                          error={errors?.first_name?.message}
                          disabled={
                            (candidateProfileData.id &&
                              candidateProfileData?.condition !== "in_work") ||
                            candidateProfileData?.is_blocking
                          }
                          ref={null}
                        />
                      )}
                      name="first_name"
                      control={control}
                    />
                  </FieldElementWrapper>
                  <FieldElementWrapper fullWidth={false}>
                    <Controller
                      render={({field, fieldState}) => (
                        <Input
                          {...field}
                          {...fieldState}
                          onChange={(value) => {
                            if (value === "") value = null;
                            field.onChange(value);
                          }}
                          placeholder="Возраст"
                          required={true}
                          type="number"
                          error={errors?.age?.message}
                          disabled={
                            (candidateProfileData.id &&
                              candidateProfileData?.condition !== "in_work") ||
                            candidateProfileData?.is_blocking
                          }
                          ref={null}
                        />
                      )}
                      name="age"
                      control={control}
                    />
                  </FieldElementWrapper>
                  <FieldElementWrapper fullWidth={false}>
                    <Controller
                      render={({field, fieldState}) => (
                        <Select
                          {...field}
                          {...fieldState}
                          title="Гражданство"
                          required={true}
                          options={citizenship.map((item) => ({
                            value: item.id,
                            label: item.name,
                          }))}
                          onChange={(value) => field.onChange(value)}
                          disabled={
                            (candidateProfileData.id &&
                              candidateProfileData?.condition !== "in_work") ||
                            candidateProfileData?.is_blocking
                          }
                          ref={null}
                        />
                      )}
                      name="citizenship_id"
                      control={control}
                    />
                  </FieldElementWrapper>
                </FieldsRowWrapper>

                <FieldsRowWrapper>
                  <FieldElementWrapper fullWidth={false}>
                    <Controller
                      render={({field, fieldState}) => (
                        <Input
                          {...field}
                          {...fieldState}
                          value={field.value[0] === '8' ? field.value.slice(1) : field.value}
                          mask={"+{7}(000)000-00-00"}
                          placeholder="Номер телефона"
                          required={true}
                          error={errors?.phone?.message}
                          disabled={
                            (candidateProfileData.id &&
                              candidateProfileData?.condition !== "in_work") ||
                            candidateProfileData?.is_blocking
                          }
                          ref={null}
                        />
                      )}
                      name="phone"
                      control={control}
                    />
                  </FieldElementWrapper>
                  <FieldElementWrapper fullWidth={false}>
                    <Controller
                      render={({field, fieldState}) => (
                        <Input
                          {...field}
                          {...fieldState}
                          placeholder="Email"
                          error={errors?.email?.message}
                          disabled={
                            (candidateProfileData.id &&
                              candidateProfileData?.condition !== "in_work") ||
                            candidateProfileData?.is_blocking
                          }
                          ref={null}
                        />
                      )}
                      name="email"
                      control={control}
                    />
                  </FieldElementWrapper>
                  <FieldElementWrapper fullWidth={false}>
                    <Controller
                      render={({field, fieldState}) => (
                        <Select
                          {...field}
                          {...fieldState}
                          title="Город"
                          required={true}
                          options={cityOptions}
                          withSearch={true}
                          onChange={(value) => {
                            if (field.value === value) return;
                            field.onChange(value);
                            setValue("restaurant_id", null)
                            setValue("vacancy_id", null)
                          }}
                          disabled={
                            (candidateProfileData.id) ||
                            (candidateProfileData.id && candidateProfileData?.condition !== "in_work") ||
                            candidateProfileData?.is_blocking
                          }
                          ref={null}
                        />
                      )}
                      name="city_id"
                      control={control}
                    />
                  </FieldElementWrapper>
                </FieldsRowWrapper>
                <FieldsRowWrapper fullWidth={true}>
                  <FieldElementWrapper fullWidth={false}>
                    <Controller
                      render={({field, fieldState}) => (
                        <Select
                          {...field}
                          {...fieldState}
                          title="Пол"
                          required={true}
                          options={[
                            {label: Gender.male, value: Gender.male},
                            {label: Gender.female, value: Gender.female}
                          ]}
                          ref={null}
                        />
                      )}
                      name="sex"
                      control={control}
                    />
                  </FieldElementWrapper>
                </FieldsRowWrapper>
              </MainContentFields>
            </MainContentBlockWrapper>

            <SecondContentBlockWrapper>
              {!candidateProfileData.id && (
                <MainContentBlockWrapper width={"50%"}>
                  <H2>Отклик</H2>
                  <MainContentFields>
                    <FieldsRowWrapper fullWidth={true}>
                      <FieldElementWrapper fullWidth={true}>
                        <Controller
                          render={({field, fieldState}) => (
                            <Select
                              {...field}
                              {...fieldState}
                              title="Ресторан"
                              required={true}
                              options={filteredRestaurantOptions}
                              disabled={!cityValue}
                              onChange={(value) => {
                                if (field.value === value) return;
                                field.onChange(value)
                                setValue("vacancy_id", null)
                              }}
                              withSorting={true}
                              withSearch={true}
                              ref={null}
                            />
                          )}
                          name="restaurant_id"
                          control={control}
                        />
                      </FieldElementWrapper>
                    </FieldsRowWrapper>

                    <FieldsRowWrapper fullWidth={true}>
                      <FieldElementWrapper fullWidth={true}>
                        <Controller
                          render={({field, fieldState}) => (
                            <Select
                              {...field}
                              {...fieldState}
                              title="Вакансия"
                              required={true}
                              options={vacanciesOptions}
                              disabled={!restaurantValue}
                              ref={null}
                            />
                          )}
                          name="vacancy_id"
                          control={control}
                        />
                      </FieldElementWrapper>
                    </FieldsRowWrapper>

                    <FieldsRowWrapper>
                      <FieldElementWrapper fullWidth={true}>
                        <Controller
                          render={({field, fieldState}) => (
                            <Select
                              {...field}
                              {...fieldState}
                              title="Источник"
                              required={true}
                              withSearch={true}
                              optionsDirection={"top"}
                              options={sources.filter(item => item.source_type === FOR_MANUAL_INPUT)
                                .map(item => ({
                                    value: item.id,
                                    label: item.name,
                                  })
                                )}
                              onChange={value => onChangeSource(value, field.onChange)}
                              ref={null}
                            />
                          )}
                          name="source_id"
                          control={control}
                        />
                      </FieldElementWrapper>
                    </FieldsRowWrapper>

                    {resourceRender()}
                  </MainContentFields>
                </MainContentBlockWrapper>
              )}

              {candidateProfileData.id && (
                <MainContentBlockWrapper width={"50%"}>
                  <H2>Отклик</H2>
                  <MainContentFields>
                    <FieldsRowWrapper fullWidth={true}>
                      <FieldElementWrapper fullWidth={true}>
                        <ReviewInfo>
                          <span>Город</span>
                          <span>{candidateProfileData?.city?.name_ru}</span>
                        </ReviewInfo>
                      </FieldElementWrapper>
                    </FieldsRowWrapper>

                    <ReviewContainer>
                      <FieldsRowWrapper fullWidth={true}>
                        <FieldElementWrapper fullWidth={true}>
                          <ReviewInfo>
                            <span>Ресторан</span>
                            <span>{candidateProfileData?.restaurant?.name_ru}</span>
                          </ReviewInfo>
                        </FieldElementWrapper>
                      </FieldsRowWrapper>
                      <Button
                        title="Передать другому ресторану"
                        btnStyle="secondary"
                        onClick={onOpenHandOverFormModal}
                        disabled={isBlockingRestaurant()}
                      />
                    </ReviewContainer>

                    <ReviewContainer>
                      <FieldsRowWrapper fullWidth={true}>
                        <FieldElementWrapper fullWidth={true}>
                          <ReviewInfo>
                            <span>Вакансия</span>
                            <span>{candidateProfileData?.vacancy?.name}</span>
                          </ReviewInfo>
                        </FieldElementWrapper>
                      </FieldsRowWrapper>
                      <Button
                        title="Изменить вакансию"
                        btnStyle="secondary"
                        onClick={onVacancyFormModal}
                        disabled={isBlocking()}
                      />
                    </ReviewContainer>

                    <FieldsRowWrapper>
                      <FieldElementWrapper fullWidth={true}>
                        <Controller
                          render={({field, fieldState}) => (
                            <Select
                              {...field}
                              {...fieldState}
                              title="Источник"
                              required={true}
                              withSearch={true}
                              optionsDirection={"top"}
                              options={sources.filter(item => item.source_type === FOR_MANUAL_INPUT)
                                .map(item => ({
                                    value: item.id,
                                    label: item.name,
                                  })
                                )}
                              onChange={value => onChangeSource(value, field.onChange)}
                              disabled={blockedSource()}
                              ref={null}
                            />
                          )}
                          name="source_id"
                          control={control}
                        />
                      </FieldElementWrapper>
                    </FieldsRowWrapper>

                    {resourceRender()}
                  </MainContentFields>
                </MainContentBlockWrapper>
              )}

              {candidateProfileData.id && (
                <MainContentBlockWrapper width={"50%"}>
                  <MainContentFields>
                    <CommentsBlockWrapper>
                      <Comments
                        data={candidateProfileData}
                        type={"App\\Models\\CandidateProfile"}
                        getValues={getValues}
                        resetField={resetField}
                        disabled={isBlocking()}
                      />
                    </CommentsBlockWrapper>
                  </MainContentFields>
                </MainContentBlockWrapper>
              )}
            </SecondContentBlockWrapper>
          </MainContentWrapper>

          <HandOverFormModal/>
          <VacancyFormModal/>
          <DeleteCandidateFormModal/>
          <InterviewModal/>
        </CandidateFormWrapper>
      }
    </>
  );
});

const H1 = styled.div`
  font-family: ${theme.fonts.condensedBlack};
  font-size: ${theme.fontSizes.h1};
  color: ${theme.colors.neutral10};
`;

const H2 = styled.div`
  font-size: ${theme.fontSizes.h2};
  color: ${theme.colors.neutral10};
  font-family: ${theme.fonts.mainBlack};
  margin-bottom: 16px;
`;

const CandidateFormWrapper = styled.form`
  font-family: ${theme.fonts.mainRegular};
`;

const FormHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
`;

const FlexBlock = styled.div<{
  gap?: number;
  alignItems?: string;
  justifyContent?: string;
}>`
  display: flex;
  ${({ alignItems }) => alignItems && "align-items: " + alignItems};
  ${({ gap }) => gap && "gap: " + gap + "px"};
  ${({ justifyContent }) =>
    justifyContent && "justify-content: " + justifyContent};
`;

const HeaderRightSide = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const FormRowWrapper = styled.div`
  display: flex;
  margin-bottom: 16px;
  gap: 16px;
`;

const BlockWrapper = styled.div`
  border-radius: 4px;
  padding: 16px;
  background: #ffffff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
`;

const StatusInputWrapper = styled.div`
  margin-top: 10px;
`;

const MainContentWrapper = styled.div`
  display: block;
`;

const ReviewContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const MainContentBlockWrapper = styled.div<{
  width?: string;
}>`
  background: #ffffff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 16px;
  margin-bottom: 24px;
  ${({ width }) => width && "width: " + width};
`;

const SecondContentBlockWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

const MainContentFields = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
`;

const FieldsRowWrapper = styled.div<{ fullWidth?: boolean }>`
  display: flex;
  gap: 10px;
  width: ${(props) => (props.fullWidth ? "100%" : "auto")};
`;

const FieldElementWrapper = styled.div<{ fullWidth: boolean }>`
  width: ${(props) => (props.fullWidth ? "100%" : "calc((100% - 48px) / 4)")};
`;

const ReviewInfo = styled.div`
  width: 100%;
  height: 100%;
  padding-left: 10px;
  background-color: #F8F9FA;

  span {
    display: block;
    font-family: ${theme.fonts.mainRegular};

    &:first-child {
      margin-bottom: 5px;
      font-size: ${theme.fontSizes.small};
      color: ${theme.colors.neutral7};
    }

    &:last-child {
      font-size: ${theme.fontSizes.body};
      color: ${theme.colors.neutral10};
    }
  }
`;

const CommentsBlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const StateValueWrapper = styled.div<{ state: string }>`
  font-size: ${theme.fontSizes.body};
  color: ${(props) =>
    props.state === "in_work" ? theme.colors.green : theme.colors.neutral6};
`;

const BlockedFormWrapper = styled.span`
  font-family: ${theme.fonts.mainBold};
  font-size: ${theme.fontSizes.title};
  color: ${theme.colors.primary};
`;

const StarWrapper = styled.span`
  color: ${theme.colors.primary};
`;

export default CandidateForm;
