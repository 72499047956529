import React, {FC, memo, useEffect, useState} from 'react';
import {Input} from "../Input";
import AutoCompleteList from "./AutoCompleteList";
import {useFetching} from "../../hooks/useFetching";

interface Props {
  value: string;
  searchField: string;
  setAutocompletedValue: (string) => void;
  getData: () => any;
}

const InputAutocomplete:FC<Props> = memo(({
  value,
  searchField,
  setAutocompletedValue,
  getData
}) => {
  const [items, setItems] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [canMakeRequest, setCanMakeRequest] = useState(true);

  const [fetchItems, isLoading, error] = useFetching(async () => {
    const response = await getData();
    const items = response.data.map(company => company[searchField])
    setItems(items);
  });

  useEffect(() => {
    if(canMakeRequest && isOpen) {
      setCanMakeRequest(false);

      setTimeout(async () => {
        await fetchItems();
        setCanMakeRequest(true);
      }, 300)
    }
  }, [value])

  const [isItemHovered, setIsItemHovered] = useState(false);
  const [activeItemIdx, setActiveItemIdx] = useState(-1);

  const handleChange = (item, isClear=false) => {
    if(!isOpen) setIsOpen(true);
    if(isClear) setIsOpen(false);
    setAutocompletedValue(item);
  }

  const handleBlur = () => {
    if(isItemHovered) return;

    setIsOpen(false);
    setItems([]);
  }

  const handleClickListItem = (item) => {
    setAutocompletedValue(item);
    setIsOpen(false);
    setItems([]);
  }

  const handleKeyboardEvents = (event) => {
    if(event.code === 'ArrowDown') {
      activeItemIdx === -1 || activeItemIdx === items.length - 1 ?
        setActiveItemIdx(0) :
        setActiveItemIdx(activeItemIdx + 1);
    }

    if(event.code === 'ArrowUp') {
      activeItemIdx === -1 || activeItemIdx === 0 ?
        setActiveItemIdx(items.length - 1) :
        setActiveItemIdx(activeItemIdx - 1);
    }

    if(event.code === 'Enter') {
      handleClickListItem(items[activeItemIdx]);
    }
  }

  return (
    <div>
      <div>
        <Input
          value={value}
          placeholder="Autocomplete"
          onChange={handleChange}
          onBlur={handleBlur}
          onKeyUp={handleKeyboardEvents}
        />
      </div>

      {isOpen &&
        <AutoCompleteList
          items={items}
          activeItemIdx={activeItemIdx}
          isLoading={isLoading}
          error={error}
          onClick={handleClickListItem}
          onMouseEnter={() => setIsItemHovered(true)}
          onMouseLeave={() => setIsItemHovered(false)}
          onKeyUp={handleKeyboardEvents}
        />
      }

    </div>
  );
});

export default InputAutocomplete;