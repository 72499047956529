import React, {FC, useCallback} from 'react';
import styled from "styled-components";
import {theme} from "../../styles/theme";
import CheckIcon from "../../assets/icons/CheckIcon";

interface Props {
    value: boolean,
    icon?: JSX.Element | (() => JSX.Element),
    label?: string,
    disabled?: boolean,
    ref?: any,
    onChange?: (value: boolean, event?: any) => void,
    size?: 'default' | 'small',
}

const Checkbox: FC<Props> = ({
    value,
    icon = null,
    label,
    disabled = false,
    onChange,
    size= 'default',
}) => {
    let Icon;
    if (icon) {
        Icon = icon;
    } else {
        Icon = value ? CheckIcon : null;
    }
    
    const iconClickHandle = useCallback((event) => {
        if (!disabled) {
            onChange(!value, event)
        }
    }, [onChange]);
    
    return (
        <CheckboxWrapper
          disabled={disabled}
          onClick={iconClickHandle}
          size={size}
        >
            <input
                hidden={true}
                type="checkbox"
                checked={value}
                onChange={() => null}
            />
    
            <IconWrapper
              disabled={disabled}
              size={size}
            >
                <Square
                  disabled={disabled}
                  value={value}
                >
                    {Icon &&
                      <Icon/>
                    }
                </Square>
            </IconWrapper>
            
            {label &&
                <Label disabled={disabled}>{label}</Label>
            }
        </CheckboxWrapper>
    );
};

const pathColors = theme.colors;

const squareBorderColor = (disabled, value) => {
    if(!disabled && !value) return pathColors.neutral7;
    if(!disabled && value) return pathColors.primary;
    if(disabled && !value || disabled && value) return pathColors.neutral4;
}

const squareBgColor = (disabled, value) => {
    if(!disabled && !value || disabled && !value) return '#ffffff';
    if(!disabled && value) return pathColors.primary;
    if(disabled && value) return pathColors.neutral4;
}

const Square = styled.div<{
    disabled: boolean,
    value: boolean,
}>`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: ${props => '2px solid ' + squareBorderColor(props.disabled, props.value)};
    background-color: ${props => squareBgColor(props.disabled, props.value)};
    border-radius: 4px;
    position: relative;
`;

const IconWrapper = styled.div<{
    disabled: boolean,
    size: string,
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: ${({size}) => size === 'small' ? 18 : 24}px;
  height: ${({size}) => size === 'small' ? 18 : 24}px;
  aspect-ratio: 1;
  box-sizing: border-box;
`;


const CheckboxWrapper = styled.div<{
    disabled: boolean,
    size: string,
}>`
  display: flex;
  width: fit-content;
  gap: 13px;
  align-items: center;
  color: ${props => props.disabled ? pathColors.neutral5 : pathColors.neutral10};
  ${props => !props.disabled && 'cursor: pointer;'};

  &:hover {
      ${IconWrapper} {
          &:before {
              ${props => !props.disabled && "content: '';"};
              width: ${({size}) => size === 'small' ? 32 : 40}px;
              height: ${({size}) => size === 'small' ? 32 : 40}px;
              position: absolute;
              top: ${({size}) => size === 'small' ? -7 : -8}px;
              left: ${({size}) => size === 'small' ? -7 : -8}px;
              border-radius: 4px;
              background-color: ${pathColors.neutral3};
          }
      }
  }
`;

const Label = styled.div<{disabled: boolean}>`
    font-family: ${theme.fonts.mainRegular};
    font-size: ${theme.fontSizes.body};
    color: ${props => props.disabled ? pathColors.neutral5 : pathColors.bodyText};
    line-height: 24px;
`;

export default Checkbox;