import {FC} from "react";

interface Props {
    width?: string,
    height?: string,
    fill?: string,
    onClick?: () => void
}

const FileNameIcon: FC<Props> = ({}) => {
  return (
      <svg xmlns="http://www.w3.org/2000/svg" width="52" height="64" viewBox="0 0 52 64" fill="none">
          <path fillRule="evenodd" clipRule="evenodd" d="M6.88232 0.253967H29.1862V6.60317H6.88232V57.3968H45.1176V22.4762H51.4902V57.3968C51.4902 60.8889 48.6225 63.746 45.1176 63.746H6.85045C3.34555 63.746 0.509766 60.8889 0.509766 57.3968V6.60317C0.509766 3.11111 3.37741 0.253967 6.88232 0.253967Z" fill="#E4002B"/>
          <path d="M51.4902 19.3016L32.3725 0.253967H29.1862V6.60317V22.4762H45.1176H51.4902V19.3016Z" fill="#A0001E"/>
          <path d="M6.88232 57.3968H45.1176V22.4762H29.1862V6.60317H6.88232V57.3968Z" fill="#E4002B"/>
      </svg>
  );
};

export default FileNameIcon;
