import React, { useCallback, useState } from "react";
import styled from "styled-components";
import Button from "../../../../components/Button";
import { Modal } from "../../../../components/Modal";
import { connectModal } from "../../../../hocs/Modal";
import { theme } from "../../../../styles/theme";

const VacancyFormModal = connectModal("vacancy-form-modal")(
  ({ handleHide, data, vacanciesList, onChange }) => {
    const [pickedVacancy, setPickedVacancy] = useState(data.vacancy);

    const onSubmit = useCallback(async () => {
      onChange(pickedVacancy);
      handleHide();
    }, [pickedVacancy]);

    return (
      <Modal title="Смена вакансии" onClose={handleHide}>
        <Content>
          <AlertWrapper>
            <h3>Внимание!</h3>
            <div>
              Если вы измените вакансию. Анкета ID {data.id} станет неактуальной
              и будет создана новая анкета.
            </div>
          </AlertWrapper>
          {vacanciesList.map((item) => (
            <ItemWrapper
              key={item.value}
              picked={item.value === pickedVacancy}
              onClick={(value) => setPickedVacancy(item.value)}
            >
              {item.label}
            </ItemWrapper>
          ))}
        </Content>

        <ButtonsWrapper>
          <Button btnStyle="cancel" title="Отмена" onClick={handleHide} />
          <Button btnStyle="primary" title="Применить" onClick={onSubmit} />
        </ButtonsWrapper>
      </Modal>
    );
  }
);

const ItemWrapper = styled.div<{ picked: boolean }>`
  cursor: pointer;
  font-size: ${theme.fontSizes.body};
  background: ${(props) => (props.picked ? theme.colors.primary : "white")};
  color: ${(props) => (props.picked ? "white" : theme.colors.neutral10)};
  padding: 10px;
  border-radius: 4px;

  &:hover {
    background: ${(props) =>
      props.picked ? theme.colors.primary : theme.colors.neutral2};
  }
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  margin-bottom: 15px;
`;

const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
  gap: 10px;
`;

const AlertWrapper = styled.div`
  background: rgba(3, 173, 0, 0.1);
  border-radius: 4px;
  font-size: ${theme.fontSizes.body};
  padding: 10px;
`;

export default VacancyFormModal;
