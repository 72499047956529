import React, {FC, memo} from "react";
import {Controller, useWatch} from "react-hook-form";
import styled from "styled-components";
import Select from "../../../../../components/Select";
import candidateSelectOptions from
    "../../../../../assets/Restaurants/formNotifications/toCandidate/candidateSelectOptions.json";
import RestaurantEmployeeFields from "./RestaurantEmployeeFields";
import ShiftManagerFields from "./ShiftManagerFields";

interface Props {
  setChangedEmployee: (val: boolean) => void;
  setChangedManager: (val: boolean) => void;
  control: any;
}

const ToCandidate: FC<Props> = memo(({setChangedEmployee, setChangedManager, control}) => {
  const candidateType = useWatch({
    name: "to_candidate.candidateType",
    control
  });

  return (
    <Wrapper>
      <FieldWrapper>
        <Controller
          render={({field, fieldState}) =>
            <Select
              {...field}
              {...fieldState}
              title="Кандидат"
              options={candidateSelectOptions}
              ref={null}
            />
          }
          name="to_candidate.candidateType"
          control={control}
        />
      </FieldWrapper>

      {candidateType === "restaurant_employee" &&
        <RestaurantEmployeeFields setChangedEmployee={setChangedEmployee} control={control}/>
      }
      {candidateType === "shift_manager" &&
        <ShiftManagerFields setChangedManager={setChangedManager} control={control}/>
      }
    </Wrapper>
  );
});

const Wrapper = styled.div`
  width: 100%;
`;

const FieldWrapper = styled.div`
  &:not(:last-child) {
    margin-bottom: 15px;
  }
`;

export default ToCandidate;