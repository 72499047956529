const RU_LOCALE = {
    months: 'Январь_Февраль_Март_Апрель_Май_Июнь_Июль_Август_Сентябрь_Октябрь_Ноябрь_Декабрь'.split('_'),
    monthsShort: 'Янв_Фев_Март_Апр_Май_Июнь_Июль_Авг_Сент_Окт_Ноя_Дек'.split('_'),
    weekdays: 'понедельник_вторник_среда_четверг_пятница_суббота_воскресенье'.split('_'),
    weekdaysMin: 'ПН_ВТ_СР_ЧТ_ПТ_СБ_ВС'.split('_'),
    week: {
        dow: 1,
        doy: 4
    }
};

export default RU_LOCALE;