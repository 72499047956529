import axios from "../middlewares/axios";
import {CANDIDATE_PROFILES} from "../config/paths";
import moment from 'moment';

export const getAnalytics = async (
    filters = null,
) => {
  const data = {
    date_from: moment(filters.dateRange.from).format("YYYY-MM-DD"),
    date_to: moment(filters.dateRange.to).format("YYYY-MM-DD")
  };
  if (data.date_to === "Invalid date") {
    data.date_to = data.date_from;
  }
  if (filters.country_id) {
    data.country_id = filters.country_id;
  }
  if (filters.city_id) {
    data.city_id = filters.city_id;
  }
  if (filters.restaurant_id) {
    data.restaurant_id = filters.restaurant_id;
  }
  if (filters.partner_id) {
    data.partner_id = filters.partner_id;
  }
  if (filters.tariff_id) {
    data.tariff_id = filters.tariff_id;
  }
  if (filters.vacancy_id) {
    data.vacancy_id = filters.vacancy_id;
  }
  if (filters.funnel_stage) {
    data.funnel_stage = filters.funnel_stage;
  }
  if (filters.candidate_profile_status_id) {
    data.candidate_profile_status_id = filters.candidate_profile_status_id;
  }
  if (filters.source_id) {
    data.source_id = filters.source_id;
  }
  if (filters.sex) {
    data.sex = filters.sex;
  }
  if (filters.age) {
    data.age = filters.age;
  }
  if (filters.citizenship_id) {
    data.citizenship_id = filters.citizenship_id;
  }

  const response = await axios.post(CANDIDATE_PROFILES + "/analytic", data);

  return response.data;
};

export const getAnalyticReport = async (data) => {
  const config = {};

  data.export = {
    export_type: "xlsx",
  };
  data.return_type = "export";
  config.responseType = "arraybuffer";

  const response = await axios.post(CANDIDATE_PROFILES + "/analytic_report", data, config);

  return response.data;
}
