import React from "react";
import styled from "styled-components";
import {theme} from "../../styles/theme";

export const withError = (Component) => {
  return (componentProps) => {
    return (
      <Wrapper>
        <Component {...componentProps} />
        {componentProps.error && <Error>{componentProps.error}</Error>}
      </Wrapper>
    );
  };
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Error = styled.div`
  font-size: ${theme.fontSizes.small};
  color: ${theme.colors.primary};
  font-family: ${theme.fonts.mainRegular};
  line-height: 14px;
  margin-bottom: 5px;
`;
