import {FC} from "react";

interface Props {
    width?: string;
    height?: string;
    fill?: string;
    onClick?: () => void;
}

const EyeIcon: FC<Props> = ({
    width,
    height,
    fill,
    onClick
}) => {
  return (
      <svg
          width={width || "24"}
          height={height || "24"}
          viewBox="0 0 24 24"
          fill="none"
          onClick={onClick}
      >
          <g clipPath="url(#clip0_1199_21458)">
              <path
                  d="M12 6C15.79 6 19.17 8.13 20.82 11.5C19.17 14.87 15.79 17 12 17C8.21 17 4.83 14.87 3.18 11.5C4.83 8.13 8.21 6 12 6ZM12 4C7 4 2.73 7.11 1 11.5C2.73 15.89 7 19 12 19C17 19 21.27 15.89 23 11.5C21.27 7.11 17 4 12 4ZM12 9C13.38 9 14.5 10.12 14.5 11.5C14.5 12.88 13.38 14 12 14C10.62 14 9.5 12.88 9.5 11.5C9.5 10.12 10.62 9 12 9ZM12 7C9.52 7 7.5 9.02 7.5 11.5C7.5 13.98 9.52 16 12 16C14.48 16 16.5 13.98 16.5 11.5C16.5 9.02 14.48 7 12 7Z"
                  fill={fill || "black"}
              />
          </g>
          <defs>
              <clipPath id="clip0_1199_21458">
                  <rect width="24" height="24" fill="white"/>
              </clipPath>
          </defs>
      </svg>
  );
};

export default EyeIcon;
