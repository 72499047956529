import React, {FC, MutableRefObject, useCallback} from 'react';
import {Input} from "../Input";
import Checkbox from "../Checkbox";
import ChevronIcon from "../../assets/icons/ChevronIcon";
import {theme} from "../../styles/theme";
import styled from "styled-components";
import CheckIcon from "../../assets/icons/CheckIcon";
import MinusIcon from "../../assets/icons/MinusIcon";
import {useOutsideClick} from "../../hooks/useOutsideClick";


interface Props {
  value: string[];
  filterInputValue: string;
  withSubOptions: boolean;
  expandedOptionsIds: number[];
  filteredOptions: any;
  selectWrapperRef: MutableRefObject<HTMLDivElement>;
  subOptionsKey?: string;
  setFilterInputValue: (state: string) => void;
  setExpandedOptionsIds: (state: number[] | ((state: number[]) => number[])) => void;
  setOptionsOpen: (state: boolean) => void;
  onChange: (value: string[]) => void;
}

export const OptionsBlock:FC<Props> = ({
  value,
  filterInputValue,
  withSubOptions,
  expandedOptionsIds,
  filteredOptions,
  selectWrapperRef,
  subOptionsKey,
  setFilterInputValue,
  setExpandedOptionsIds,
  setOptionsOpen,
  onChange,
}) => {

  useOutsideClick(selectWrapperRef, () => {
    setOptionsOpen(false);
  });

  const subOptionChangeHandle = useCallback((option) => {
    let newValue = [...value];

    if (value.indexOf(option.value) === -1) {
      newValue.push(option.value);
    } else {
      newValue = newValue.filter(id => id !== option.value);
    }

    onChange(newValue);
  }, [value]);

  const optionClickHandle = useCallback((option) => {
    const optionIdIndex = expandedOptionsIds.indexOf(option.value);
    if (optionIdIndex === -1) {
      setExpandedOptionsIds(prevState => {
        const newState = [...prevState];
        newState.push(option.value);
        return newState;
      });
    } else {
      setExpandedOptionsIds(prevState => {
        const newState = [...prevState];
        newState.splice(optionIdIndex, 1);
        return newState;
      });
    }
  }, [expandedOptionsIds]);

  const getAllSubOptionsChecked = (option) => {
    let allChecked = true;

    option[subOptionsKey].map(subOption => {
      if (value.indexOf(subOption.value) === -1) {
        allChecked = false;
      }
    });

    return allChecked;
  };

  const getOneSubOptionChecked = (option) => {
    let oneChecked = false;

    option[subOptionsKey].map(subOption => {
      if (value.indexOf(subOption.value) !== -1) {
        oneChecked = true;
      }
    });

    return oneChecked;
  };

  const getOptionIcon = useCallback((option) => {
    const allSubOptionsChecked = getAllSubOptionsChecked(option);
    const oneSubOptionsChecked = getOneSubOptionChecked(option);

    if (option[subOptionsKey].length === 0) {
      return null;
    }

    if (allSubOptionsChecked) {
      return <CheckIcon/>;
    } else if (!allSubOptionsChecked && oneSubOptionsChecked) {
      return <MinusIcon/>;
    } else {
      return null;
    }
  }, [value]);

  const optionChangeHandle = useCallback((event, option) => {
    event.stopPropagation();

    let newValue = [...value];
    const allSubOptionsChecked = getAllSubOptionsChecked(option);

    if (allSubOptionsChecked) {
      option[subOptionsKey].map(subOption => {
        const subOptionIdIndex = newValue.indexOf(subOption.value);
        if (subOptionIdIndex !== -1) {
          newValue.splice(subOptionIdIndex, 1);
        }
      });
    }

    if (!allSubOptionsChecked) {
      option[subOptionsKey].map(subOption => {
        newValue.push(subOption.value);
      });
      newValue = [...new Set(newValue)];
    }

    onChange(newValue);
  }, [value]);

  const renderSubOptions = useCallback((option) => {
    return (
      <>
        {option[subOptionsKey].map(subOption =>
          <SelectSubOption key={subOption.value}>
            <Checkbox
              value={value.indexOf(subOption.value) !== -1}
              onChange={() => subOptionChangeHandle(subOption)}
              label={subOption.label}
            />
          </SelectSubOption>
        )}
      </>
    );
  }, [value]);

  return (
    <SelectOptions>
      <Search>
        <Input
          placeholder="Поиск..."
          value={filterInputValue}
          onChange={(value) => setFilterInputValue(value)}
          maxLength={200}
          search
        />
      </Search>
      <Separator/>
      {withSubOptions &&
        <>
          {filteredOptions.map(option =>
            (!!option[subOptionsKey].length &&
              <SelectOptionWrapper key={option.value}>
                <SelectOption
                  onClick={() => optionClickHandle(option)}
                  titleOptions
                >
                  <Checkbox
                    value={getAllSubOptionsChecked(option) || getOneSubOptionChecked(option)}
                    icon={() => getOptionIcon(option)}
                    onChange={(checked, event) => optionChangeHandle(event, option)}
                    label={option.label}
                  />

                  {expandedOptionsIds.indexOf(option.value) === -1 &&
                    <ChevronIcon
                      direction="bottom"
                      fill={theme.colors.neutral7}
                    />
                  }

                  {expandedOptionsIds.indexOf(option.value) !== -1 &&
                    <ChevronIcon
                      direction="top"
                      fill={theme.colors.neutral7}
                    />
                  }
                </SelectOption>

                {expandedOptionsIds.indexOf(option.value) !== -1 &&
                  <>
                    {renderSubOptions(option)}
                  </>
                }
              </SelectOptionWrapper>
            )
          )}
        </>
      }

      {!withSubOptions &&
        <>
          {filteredOptions.map(option =>
            <SelectOptionWrapper key={option.value}>
              <SelectOption>
                <Checkbox
                  value={value.indexOf(option.value) !== -1}
                  label={option.label}
                  onChange={() => subOptionChangeHandle(option)}
                />
              </SelectOption>
            </SelectOptionWrapper>
          )}
        </>
      }
    </SelectOptions>
  );
};

const SelectOptions = styled.div`
  position: absolute;
  width: 100%;
  box-sizing: border-box;
  margin-top: 5px;
  border: 1px solid ${theme.colors.primaryDark};
  border-radius: 4px;
  background: white;
  z-index: 10;
  max-height: 300px;
  overflow: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const Search = styled.div`
  width: 100%;
  padding: 8px 9px;
`;

const Separator = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${theme.colors.neutral3};
  flex-shrink: 0;
`;

const SelectOptionWrapper = styled.div``;

const SelectOption = styled.div<{titleOptions?: boolean,}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 10px;
    
  div {
    div:last-child {
      ${({titleOptions}) => titleOptions && "font-family: " + theme.fonts.mainBlack + " !important"};
      ${({titleOptions}) => titleOptions && "font-size: " + theme.fontSizes.title + " !important"};
      line-height: ${({titleOptions}) => titleOptions ? 24 : 16}px !important;
    }
  }
    
  &:hover {
    background-color: ${theme.colors.neutral1};
  }
`;

const SelectSubOption = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 10px 8px 17px;
    
  div {
    div:last-child {
      line-height: 16px;
    }
  }

  &:hover {
    background-color: ${theme.colors.neutral1};
  }
`;
