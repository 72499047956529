const AngleRight = (props) => (
  <svg
    width={6}
    height={11}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.876 5.559a.318.318 0 0 1 0 .462l-4.68 4.68a.318.318 0 0 1-.463 0L.231 10.2a.317.317 0 0 1 0-.462L4.18 5.79.23 1.842a.318.318 0 0 1 0-.462L.733.878a.318.318 0 0 1 .462 0l4.681 4.68Z"
      fill={props.disabled ? "#ADB5BD" : "#101213"}
    />
  </svg>
);

export default AngleRight;
