export const theme = {

  fonts: {
    mainRegular: 'CeraPro-Regular, sans-serif', //font-weight: 400
    mainBold: 'CeraPro-Bold, sans-serif', // font-weight: 700
    mainBlack: 'CeraPro-Black, sans-serif', //font-weight: 900
    condensedRegular: 'CeraCondensedPro-Regular, sans-serif', //font-weight: 400
    condensedBlack: 'CeraCondensedPro-Black, sans-serif', //font-weight: 900
  },

  fontSizes: {
    h1: '32px',
    h2: '22px',
    h3: '20px',
    title: '16px',
    body: '14px',
    small: '12px',
  },

  colors: {
    primaryLight: 'rgba(228, 0, 43, 0.1);',
    primary: '#E4002B',
    primaryDark: '#A0001E',
    green: '#03AD00',
    yellow: '#FFDB15',
    disabledGray: '#9D9D9D',
    bodyText: '#101213',
    neutral1: '#F8F9FA',
    neutral2: '#F1F3F5',
    neutral3: '#E9ECEF',
    neutral4: '#DEE2E6',
    neutral5: '#CED4DA',
    neutral6: '#ADB5BD',
    neutral7: '#6A7178',
    neutral8: '#4F575E',
    neutral9: '#272B30',
    neutral10: '#101213',
    buttonHoverBg: 'rgba(173, 181, 189, 0.15)',
    iconDefault: '#6A7178',
    inputBackgroundNormalState: 'rgb(255, 255, 255)'
  },

  toast: {
    containerParams: {
      autoClose: 3000,
      hideProgressBar: true,
      draggable: false,
      pauseOnHover: false,
      closeOnClick: false,
      pauseOnFocusLoss: false
    },
    commonParams: {
      borderRadius: "4px",
      boxShadow: "none",
      padding: "5px 10px"
    },
    successParams: {
      iconColor: "#03AD00",
      backgroundColor: "rgba(200, 251, 199, 1)",
      border: "1px solid rgba(3, 173, 0, 1)"
    },
    errorParams: {
      iconColor: "#E4002B",
      backgroundColor: "rgba(255, 226, 232, 1)",
      border: "1px solid rgba(228, 0, 43, 1)"
    }
  }

};
