import React, {useImperativeHandle} from 'react';
import moment from 'moment';
import styled from 'styled-components';
import TopBar from './TopBar';
import TableContent from './TableContent';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
`;

const Calendar = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

const BigCalendar = ({onChangeDate, currentDate, renderDay, hideHeader}, ref) => {
    const _handleMonthChange = count => {
      onChangeDate(count);
    };

    const _handleOpenCurrentMonth = () => {
        const newDate = moment().startOf('month');

      onChangeDate(moment(newDate));
    };

    useImperativeHandle(ref, () => ({
        _handleMonthChange,
        _handleOpenCurrentMonth,
    }));

    return (
        <Container>
            {
                !hideHeader &&
                <TopBar
                    date={currentDate}
                    onPrevClick={() => _handleMonthChange(-1)}
                    onNextClick={() => _handleMonthChange(1)}
                />
            }
            <Calendar>
                    <TableContent
                        date={currentDate}
                        renderDay={renderDay}
                    />
            </Calendar>
        </Container>
    )
};

export default React.forwardRef(BigCalendar);
