import React, {FC, memo} from "react";
import {Controller, useWatch} from "react-hook-form";
import styled from "styled-components";
import Checkbox from "../../../../../components/Checkbox";
import {TextArea} from "../../../../../components/TextArea";

interface Props {
  setControllerNewCandidate: (val: boolean) => void;
  setControllerInterview: (val: boolean) => void;
  setControllerReminder: (val: boolean) => void;
  control: any
}

const ControllerRole: FC<Props> = memo(({
  setControllerNewCandidate,
  setControllerInterview,
  setControllerReminder,
  control
}) => {
  const msFormsEmailValue = useWatch({
    control,
    name: "to_user.controller.new_forms_ms_email"
  });

  const msInterviewsEmailValue = useWatch({
    control,
    name: "to_user.controller.new_interviews_ms_email"
  });

  const msReminderValue = useWatch({
    control,
    name: "to_user.controller.email_reminder_ms"
  });

  const changesNewCandidateHandler = () => {
    setControllerNewCandidate(true);
  }

  const changesInterviewHandler = () => {
    setControllerInterview(true);
  }

  const changesReminderHandler = () => {
    setControllerReminder(true);
  }

  return (
    <Wrapper>
      <FieldWrapper>
        <Controller
          render={({field, fieldState}) =>
            <Checkbox
              {...field}
              {...fieldState}
              label="Email о новых анкетах МС"
              onChange={(value) => {
                field.onChange(value);
                changesNewCandidateHandler();
              }}
              ref={null}
            />
          }
          name="to_user.controller.new_forms_ms_email"
          control={control}
        />
      </FieldWrapper>

      <FieldWrapper>
        <Controller
          render={({field, fieldState}) =>
            <TextArea
              {...field}
              {...fieldState}
              placeholder="Текст сообщения (шаблон)"
              disabled={!msFormsEmailValue}
              onChange={(value) => {
                field.onChange(value);
                changesNewCandidateHandler();
              }}
              ref={null}
            />
          }
          name="to_user.controller.new_forms_message"
          control={control}
        />
      </FieldWrapper>

      <FieldWrapper>
        <Controller
          render={({field, fieldState}) =>
            <Checkbox
              {...field}
              {...fieldState}
              label="Email о новых собеседованиях МС"
              onChange={(value) => {
                field.onChange(value);
                changesInterviewHandler();
              }}
              ref={null}
            />
          }
          name="to_user.controller.new_interviews_ms_email"
          control={control}
        />
      </FieldWrapper>

      <FieldWrapper>
        <Controller
          render={({field, fieldState}) =>
            <TextArea
              {...field}
              {...fieldState}
              placeholder="Текст сообщения (шаблон)"
              disabled={!msInterviewsEmailValue}
              onChange={(value) => {
                field.onChange(value);
                changesInterviewHandler();
              }}
              ref={null}
            />
          }
          name="to_user.controller.new_interviews_message"
          control={control}
        />
      </FieldWrapper>

      <FieldWrapper>
        <Controller
          render={({field, fieldState}) =>
            <Checkbox
              {...field}
              {...fieldState}
              label="Email напоминание за час до собеседования МС"
              onChange={(value) => {
                field.onChange(value);
                changesReminderHandler();
              }}
              ref={null}
            />
          }
          name="to_user.controller.email_reminder_ms"
          control={control}
        />
      </FieldWrapper>

      <FieldWrapper>
        <Controller
          render={({field, fieldState}) =>
            <TextArea
              {...field}
              {...fieldState}
              placeholder="Текст сообщения (шаблон)"
              disabled={!msReminderValue}
              onChange={(value) => {
                field.onChange(value);
                changesReminderHandler();
              }}
              ref={null}
            />
          }
          name="to_user.controller.reminder_message"
          control={control}
        />
      </FieldWrapper>
    </Wrapper>
  );
});

const Wrapper = styled.div`
  width: 100%;
`;

const FieldWrapper = styled.div`
  &:not(:last-child) {
    margin-bottom: 15px;
  }
`;

export default ControllerRole;