import {FC} from "react";

interface Props {
    width?: string,
    height?: string,
    fill?: string,
    onClick?: () => void
}

const FileDownloadIcon: FC<Props> = ({
    width,
    height,
    fill,
    onClick
}) => {
  return (
      <svg
          width={width || "24"}
          height={height || "24"}
          viewBox="0 0 24 24"
          fill="none"
          onClick={onClick}
      >
          <g clipPath="url(#clip0_95_6132)">
              <path
                  d="M18 15V18H6V15H4V18C4 19.1 4.9 20 6 20H18C19.1 20 20 19.1 20 18V15H18ZM17 11L15.59 9.59L13 12.17V4H11V12.17L8.41 9.59L7 11L12 16L17 11Z"
                  fill={fill || "black"}
              />
          </g>
          <defs>
              <clipPath id="clip0_95_6132">
                  <rect width="24" height="24" fill="white"/>
              </clipPath>
          </defs>
      </svg>
  );
};

export default FileDownloadIcon;
