import {LIMIT_DEFAULT, PAGE_DEFAULT} from "../config/consts";
import {PLR_RATE, PLR_EFFECTIVE_PROPERTY, PLR_DEMANDS} from "../config/paths";
import axios from "../middlewares/axios";
import _get from "lodash/get";
import moment from 'moment';

//region Rates
export const getRates = async (
    limit= LIMIT_DEFAULT,
    page= PAGE_DEFAULT,
    month,
    restaurantId,
) => {
  const data = {
    limit: limit,
    page: page,
    filter: [
      {
        column: "month_rates_plr",
        operator: "=",
        value: month,
      },
      {
        column: "restaurant_id",
        operator: "=",
        value: restaurantId,
        boolean: "and"
      }
    ]
  };

  const response = await axios.post(PLR_RATE + "/search", data);

  return _get(response.data, "data.0", null);
};

export const createRates = async (data) => {
  const response = await axios.post(PLR_RATE, data);
  return response.data.data.id;
};

export const editRates = async (id, data) => {
  const response = await axios.put(PLR_RATE + "/" + id, data);
  return response.data;
};
//endregion

//region Properties
export const getEffectiveProperties = async (
    limit= 3,
    page= PAGE_DEFAULT,
    month,
    restaurantId,
) => {
  const data = {
    filter: [
      {
        column: "restaurant_id",
        operator: "=",
        value: restaurantId
      },
      {
        type: "group",
        group: [],
        boolean: "and"
      }
    ]
  };
  const newMonth = moment(month);
  for (let i = 0; i < 3; i++){
    newMonth.add(1, "months");
    const obj = {
      column: "first_month",
      operator: "=",
      value: newMonth.format("YYYY-MM-DD"),
    };
    if (i !== 0) {
      obj.boolean = "or"
    }
    data.filter[1].group.push(obj);
  }

  try {
    const response = await axios.post(`${PLR_EFFECTIVE_PROPERTY}/search?limit=${limit}&page=${page}`, data);
    return _get(response.data, "data", []);
  }
  catch (e){
    return [];
  }

};

export const createProperties = async (data) => {
  const response = await axios.post(PLR_EFFECTIVE_PROPERTY, data);
  return response.data.data;
};

export const editProperties = async (id, data) => {
  const response = await axios.put(PLR_EFFECTIVE_PROPERTY + "/" + id, data);
  return response.data;
};
//endregion

//region Demands
export const getDemands = async (
    limit= LIMIT_DEFAULT,
    page= PAGE_DEFAULT,
    month,
    restaurantId,
) => {
  const data = {
    limit: limit,
    page: page,
    filter: [
      {
        column: "month_demand_plr",
        operator: "=",
        value: month,
      },
      {
        column: "restaurant_id",
        operator: "=",
        value: restaurantId,
        boolean: "and"
      }
    ]
  };

  const response = await axios.post(PLR_DEMANDS + "/search", data);

  return _get(response.data, "data.0", null);
};

export const createDemands = async (data) => {
  const response = await axios.post(PLR_DEMANDS, data);
  return response.data.data.id;
};

export const editDemands = async (id, data) => {
  const response = await axios.put(PLR_DEMANDS + "/" + id, data);
  return response.data;
};
//endregion
