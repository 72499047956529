import React, {FC} from 'react';
import {Controller, useWatch} from "react-hook-form";
import Checkbox from "../../../../../components/Checkbox";
import {TextArea} from "../../../../../components/TextArea";
import styled from "styled-components";
import {Control} from "react-hook-form/dist/types/form";


interface Props {
  setChangedManager: (val: boolean) => void;
  control: Control;
}

const ShiftManagerFields:FC<Props> = ({setChangedManager, control}) => {
  const smsValue = useWatch({
    control,
    name: "to_candidate.shift_manager.sms"
  });

  const emailValue = useWatch({
    control,
    name: "to_candidate.shift_manager.email"
  });

  const changesHandler = () => {
    setChangedManager(true);
  }

  return (
    <>
      <FieldWrapper>
        <CheckboxesWrapper>
          <Controller
            render={({field, fieldState}) =>
              <Checkbox
                {...field}
                {...fieldState}
                label="sms"
                onChange={(value) => {
                  field.onChange(value);
                  changesHandler();
                }}
                ref={null}
              />
            }
            name="to_candidate.shift_manager.sms"
            control={control}
          />
          <Controller
            render={({field, fieldState}) =>
              <Checkbox
                {...field}
                {...fieldState}
                label="Email"
                onChange={(value) => {
                  field.onChange(value);
                  changesHandler();
                }}
                ref={null}
              />
            }
            name="to_candidate.shift_manager.email"
            control={control}
          />
        </CheckboxesWrapper>
      </FieldWrapper>
      <FieldWrapper>
        <Controller
          render={({field, fieldState}) =>
            <TextArea
              {...field}
              {...fieldState}
              placeholder="Текст сообщения (шаблон)"
              disabled={!smsValue && !emailValue}
              onChange={(value) => {
                field.onChange(value);
                changesHandler();
              }}
              ref={null}
            />
          }
          name="to_candidate.shift_manager.message"
          control={control}
        />
      </FieldWrapper>
    </>
  );
};

const FieldWrapper = styled.div`
  &:not(:last-child) {
    margin-bottom: 15px;
  }
`;

const CheckboxesWrapper = styled.div`
  display: flex;
  gap: 15px;
`;

export default ShiftManagerFields;