const QueryStatsIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_3038_37412)">
                <path d="M19.88 18.47C20.32 17.77 20.58 16.96 20.58 16.08C20.58 13.59 18.57 11.58 16.08 11.58C13.59 11.58 11.58 13.59 11.58 16.08C11.58 18.57 13.59 20.58 16.07 20.58C16.95 20.58 17.77 20.32 18.46 19.88L21.58 23L23 21.58L19.88 18.47ZM16.08 18.58C14.7 18.58 13.58 17.46 13.58 16.08C13.58 14.7 14.7 13.58 16.08 13.58C17.46 13.58 18.58 14.7 18.58 16.08C18.58 17.46 17.46 18.58 16.08 18.58ZM15.72 10.08C14.98 10.1 14.27 10.26 13.62 10.53L13.07 9.7L9.27 15.88L6.26 12.36L2.63 18.17L1 17L6 9L9 12.5L13 6L15.72 10.08ZM18.31 10.58C17.67 10.3 16.98 10.13 16.26 10.09L21.38 2L23 3.18L18.31 10.58Z" fill="#6A7178"/>
            </g>
            <defs>
                <clipPath id="clip0_3038_37412">
                    <rect width="24" height="24" fill="white"/>
                </clipPath>
            </defs>
        </svg>

    );
};

export default QueryStatsIcon;
