import React, {ErrorInfo} from "react";
import styled from "styled-components";
import Button from "../Button";
import {theme} from "../../styles/theme";
import { history } from "../../routes/history";
import {privateRoutes} from "../../config/routes";

interface Props {
  children?: React.ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.log(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <ErrorElement>
          <ErrorText>Если у вас появилось данное окно, просьба обратиться к разработчикам с описанием проблемы.</ErrorText>

          <Button
            onClick={() => {
              history.push({pathname: `${privateRoutes.main.path}`});
              window.location.reload();
            }}
            title={"Обновить страницу"}
          />
        </ErrorElement>
      );
    }

    return this.props.children;
  }
}

const ErrorElement = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;

const ErrorText = styled.div`
  font-family: ${theme.fonts.mainBold};
  font-size: ${theme.fontSizes.h3};
`;

export default ErrorBoundary;