import {useEffect} from "react";

export const useOutsideClick = (ref, callback) => {
    const listener = (event) => {
        if (ref.current && !ref?.current.contains(event.target)) {
            callback(event);
        }
    };
    
    useEffect(() => {
        document.addEventListener("mousedown", listener);
        document.addEventListener("touchstart", listener);
        
        return () => {
            document.removeEventListener("mousedown", listener);
            document.removeEventListener("touchstart", listener);
        };
    }, [ref, callback]);
};