import React, { FC, ReactNode, useEffect, useRef } from "react";
import styled from "styled-components";
import { useKeyClick } from "../../hooks/useKeyClick";
import CloseIcon from "../../assets/icons/CloseIcon";
import ActionIcon from "../ActionIcon";
import {theme} from "../../styles/theme";
import ReactTooltip from "react-tooltip";

interface Props {
  title: string;
  onClose: () => void;
  children: ReactNode;
  width?: string;
  secondWidth?: string;
  footer?: ReactNode;
  header?: ReactNode;
  secondContainer?: ReactNode;
  noContentPadding?: boolean;
  closeDataTip?: string;
  secondContainerAdaptiveWidth?: string;
}

const Modal: FC<Props> = ({
  title,
  onClose,
  children,
  width = "550px",
  secondWidth = "550px",
  footer,
  header,
  secondContainer,
  noContentPadding,
  closeDataTip,
  secondContainerAdaptiveWidth,
}) => {
  const ref = useRef(null);
  useKeyClick(onClose, 27);

  useEffect(() => {
    document.getElementsByTagName('body')[0].classList.add('modal-open');
    return () => {
      onClose();
      closeDataTip && ReactTooltip.hide();
      document.getElementsByTagName('body')[0].classList.remove('modal-open');
    }
  }, [onClose]);

  return (
    <ModalContainer
      ref={ref}
      secondContainerAdaptiveWidth={secondContainerAdaptiveWidth}
    >
      <Overlay onClick={onClose}/>
      {
        secondContainer &&
        <Wrapper
          width={secondWidth}
          right={width}
          secondContainerAdaptiveWidth={secondContainerAdaptiveWidth}
        >
          {secondContainer}
        </Wrapper>
      }
      <Wrapper
        width={width}
        secondContainerExist={!!secondContainer}
        secondContainerAdaptiveWidth={secondContainerAdaptiveWidth}
      >
        <ModalHeader>
          {title && <Title>{title}</Title>}
          <Header>{header}</Header>
          {header && <HeaderSeparator />}
          <ActionIcon
            action={onClose}
            size={36}
            svgSize={36}
            tooltip={closeDataTip}
          >
            <CloseIcon />
          </ActionIcon>
        </ModalHeader>
        <Content noContentPadding={noContentPadding}>{children}</Content>
        {footer && <Footer>{footer}</Footer>}
      </Wrapper>
    </ModalContainer>
  );
};

const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
`;

const HeaderSeparator = styled.div`
  width: 1px;
  height: 28px;
  background-color: rgba(0, 0, 0, 0.12);
  margin: 0 16px 0 24px;
`;

const Header = styled.div`
  flex: 1 1 auto;
  display: flex;
`;

const ModalContainer = styled.div<{secondContainerAdaptiveWidth?: string,}>`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: right;
  align-items: center;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(20px);
  z-index: 11;
  
  @media (max-width: ${({secondContainerAdaptiveWidth}) => secondContainerAdaptiveWidth}) {
    flex-direction: column;
    overflow: auto;
  } 
`;

const Wrapper = styled.div<{
  width: string,
  secondContainerExist?: boolean,
  right?: string
  secondContainerAdaptiveWidth?: string,
}>`
  //position: absolute;
  background: #fff;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  border-radius: ${({secondContainerExist}) => secondContainerExist ? "0" : "4px 0 0 4px"};
  max-width: ${({ width }) => width};
  right: ${({ right }) => right};
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  z-index: 1;

  @media (max-width: ${({secondContainerAdaptiveWidth}) => secondContainerAdaptiveWidth}) {
    order: ${({secondContainerExist}) => secondContainerExist ? '1' : '2'};
    max-width: 100%;
  }
`;

const ModalHeader = styled.div`
  display: flex;
  padding: 24px 24px 0;
  justify-content: space-between;
  align-items: center;
  
  svg {
    fill-opacity: 1;
    path {
      fill: #000000;
    }
  }
`;

const Content = styled.div<{noContentPadding?: boolean,}>`
  display: flex;
  flex: 0 1 auto;
  flex-direction: column;
  align-items: flex-start;
  overflow: auto;
  padding: ${({noContentPadding}) => noContentPadding ? "0" : "16px 24px 16px"};
  height: 100%;
  width: 100%;
  position: relative;
  z-index: 1;
`;

const Footer = styled.div`
  display: flex;
  padding: 8px 24px 24px;
`;

const Title = styled.div`
  line-height: 38px;
  padding-right: 16px;
  font-family: ${theme.fonts.condensedBlack};
  font-size: ${theme.fontSizes.h1};
`;

export { Modal };
