import ReactTooltip from "react-tooltip";
import React from "react";

const CustomReactTooltip = () => {

  const overPos = (
    { left, top },
    currentEvent, currentTarget, node) => {
    const d = document.documentElement;
    if(d.clientWidth - currentTarget.getBoundingClientRect().right < node.clientWidth) {
      left = currentTarget.getBoundingClientRect().right - node.clientWidth;
      top = currentTarget.getBoundingClientRect().bottom;
    } else {
      left = Math.min(d.clientWidth - node.clientWidth, left);
    }
    if(d.clientHeight - currentTarget.getBoundingClientRect().bottom < node.clientHeight) {
      top = currentTarget.getBoundingClientRect().top - node.clientHeight - 10
    } else {
      top = Math.min(d.clientHeight - node.clientHeight, top);
    }
    left = Math.max(0, left);
    top = Math.max(0, top);
    return { top, left }
  }

  return (
    <ReactTooltip
      place={'bottom'}
      className={'tooltip'}
      arrowColor={'rgba(0, 0, 0, 0)'}
      effect={'solid'}
      textColor={'#ffffff'}
      backgroundColor={'#4F575E'}
      overridePosition={overPos}
    />
  )
}

export default CustomReactTooltip;