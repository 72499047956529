import React, { FC, memo, ReactNode } from "react";
import styled from 'styled-components';

interface Props {
  rightActions?: ReactNode;
  leftActions?: ReactNode;
  title: string;
}

const Header: FC<Props> = memo(({ title, rightActions, leftActions }) => {
  return (
    <Wrapper>
      <Title>
          <p>{title}</p>
          {
              leftActions &&
              <LeftActions>{leftActions}</LeftActions>
          }
      </Title>
      <Block>
        {rightActions}
      </Block>
    </Wrapper>
  );
});

const Block = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: flex-end;
  flex-grow: 1;
`;

const LeftActions = styled.div`
  display: flex;
  align-items: center;
  
  @media (max-width: 413px) {
    width: 100%;
    margin-bottom: 24px;
  }
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  
  @media (max-width: 500px) {
    flex-wrap: wrap;
    gap: 16px;
    width: 100%;
  }
  
  p {
      ${({theme}) => `
        font-family: ${theme.fonts.condensedBlack};
        font-size: ${theme.fontSizes.h1};
      `}
  }
`;

const Wrapper = styled.div`
  //min-height: 40px;
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 16px;
`;

export default Header;
