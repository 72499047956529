import React, {useCallback, useContext, useMemo, useState} from "react";
import styled from "styled-components";
import Button from "../../../../components/Button";
import {Input} from "../../../../components/Input";
import {Modal} from "../../../../components/Modal";
import {connectModal} from "../../../../hocs/Modal";
import sortOptions from "../../../../utils/sortOptions";
import RestaurantsBlock from "./RestaurantsBlock";
import {DirectoryStoreContext} from "../../../../stores/directory";
import {AuthStoreContext} from "../../../../stores/auth";

const HandOverFormModal = connectModal("hand-over-form-modal")(({
    handleHide,
    pickedCity,
    cityOptions,
    restaurantOptions,
    onChange,
}) => {
    const {cities} = useContext(DirectoryStoreContext);
    const {userData} = useContext(AuthStoreContext);

    const restaurantsByCities = useMemo(() => {
      const restaurantsByCities = [];

      if(userData.role.name === "restaurant") {
        const partnerID = userData.partner.id
        const restaurantsByCities = [];
        for(let city of cities) {
          const restaurants = [];
          for(let restaurant of city.restaurants_minimal) {
            if(restaurant.partner_id === partnerID) {
              restaurants.push(restaurant)
            }
          }
          if(restaurants.length){
            city.restaurants_minimal = restaurants;
            restaurantsByCities.push(city);
          }
        }

        return restaurantsByCities
      }

      for(let city of cityOptions) {
        const cityRes = {
          id: city.value,
          name_ru: city.label,
          restaurants_minimal: []
        }
        for(let restaurant of restaurantOptions) {
          if(cityRes.id === restaurant.city_id) {
            cityRes.restaurants_minimal.push({
              id: restaurant.value,
              name_ru: restaurant.label,
              address_ru: restaurant.address_ru,
            });
          }
        }
        if (cityRes.restaurants_minimal.length) {
          restaurantsByCities.push(cityRes)
        }
      }
      return restaurantsByCities;
    }, [cities, userData.role?.name, userData.partner?.id, cityOptions, restaurantOptions])

    const [filterInputValue, setFilterInputValue] = useState("");
    const [filteredRestaurants, setFilteredRestaurants] = useState(
      sortOptions(restaurantsByCities, "name_ru", true, "restaurants_minimal")
    );
    const [pickedRestaurant, setPickedRestaurant] = useState({
        city_name: "",
        city_id: "",
        restaurant_name: "",
        restaurant_id: ""
    });

    const filterInputChangeHandle = (value) => {
      const newRestaurantsByCities = []
      for(let city of restaurantsByCities) {
        const cityLower = city.name_ru.toLowerCase();
        const inputValueLower = value.toLowerCase();

        if (cityLower.includes(inputValueLower)) {
          newRestaurantsByCities.push(city)
          continue;
        }

        const newCity = {
          ...city,
          restaurants_minimal: []
        }
        for (let restaurant of city.restaurants_minimal) {
          const restaurantLower = restaurant.name_ru.toLowerCase();
          if (restaurantLower.includes(inputValueLower)) {
            newCity.restaurants_minimal.push(restaurant)
          }
        }
        if(newCity.restaurants_minimal.length) {
          newRestaurantsByCities.push(newCity)
        }
      }

      setFilteredRestaurants(
        sortOptions(newRestaurantsByCities, "name_ru", true, "restaurants_minimal")
      );
    };
    
    const onSubmit = useCallback(async () => {
        onChange(pickedRestaurant.restaurant_id, pickedRestaurant.city_id);
        handleHide();
    }, [pickedRestaurant]);
    
    return (
        <Modal title="Передать анкету другому ресторану" onClose={handleHide}>
            <InputWrapper>
                <Input
                    value={filterInputValue}
                    placeholder="Поиск..."
                    search={true}
                    onChange={(value) => {
                        setFilterInputValue(value);
                        filterInputChangeHandle(value);
                    }}
                />
            </InputWrapper>
            
            <Content>
                {filteredRestaurants.map((city) => (
                    <RestaurantsBlock
                        key={city.name_ru}
                        city={city}
                        restaurants={city.restaurants_minimal}
                        pickedRestaurant={pickedRestaurant}
                        onRestaurantPick={
                            (value) => setPickedRestaurant(value)
                        }
                    />
                ))}
            </Content>
            
            <ButtonsWrapper>
                <Button btnStyle="cancel" title="Отмена" onClick={handleHide}/>
                <Button btnStyle="primary" title="Передать" onClick={onSubmit}/>
            </ButtonsWrapper>
        </Modal>
    );
});

const InputWrapper = styled.div`
  width: 100%;
  margin-bottom: 15px;
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  margin-bottom: 15px;
`;

const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
  gap: 10px;
`;

export default HandOverFormModal;