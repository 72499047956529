import React, {FC, memo} from "react";
import {deleteAdCompany} from "../../actions/adCompany";
import DeleteIcon from "../../assets/icons/DeleteIcon";
import DoneIcon from "../../assets/icons/DoneIcon";
import LinkIcon from "../../assets/icons/LinkIcon";
import styled from "styled-components";
import ProblemIcon from "../../assets/icons/ProblemIcon";
import Toast from "../../components/Toast";
import {toast} from "react-toastify";
import {theme} from "../../styles/theme";
import ActionIcon from "../../components/ActionIcon";

interface Props {
    row: {
        [key: string]: string;
    };
    onRequestFulfilled?: () => void;
}

const TableIcons: FC<Props> = memo(({
    row,
    onRequestFulfilled
}) => {
    const onLinkIconClick = async () => {
      const link = row.utm_source;
      const company = row.name;

      try {
        await navigator.clipboard.writeText(`https://rabotavrostics.ru/employee.php?utm_source=${link}&utm_restaurants=${link}`);

        toast(
          <>Ссылка <Span>{link}</Span> кампании <Span>{company}</Span> скопирована.</>,
          {
            icon: <DoneIcon
              width="24"
              height="24"
              fill={theme.toast.successParams.iconColor}
            />,
            style: {
              ...theme.toast.commonParams,
              ...theme.toast.successParams
            }
          }
        );
      } catch (e) {
        console.log("Error:", e);
        toast(
          "Скопировать ссылку не удалось.",
          {
            icon: <ProblemIcon
              width="24"
              height="24"
              fill={theme.toast.errorParams.iconColor}
            />,
            style: {
              ...theme.toast.commonParams,
              ...theme.toast.errorParams
            }
          }
        )
      }
    };
    
    const onDeleteIconClick = async () => {
        const company = row.company_name;
        await deleteAdCompany(row.id);
        onRequestFulfilled();
    
        toast(
            "Кампания " + company + " удалена.",
            {
                icon: <DoneIcon
                    width="24"
                    height="24"
                    fill="#03AD00"
                />,
                style: {
                    ...theme.toast.commonParams,
                    ...theme.toast.successParams
                }
            }
        );
    };
    
    return (
        <IconsWrapper>
            <ActionIcon
                action={onLinkIconClick}
                size={34}
                tooltip={"Скопировать ссылку"}
                disabled={!row.utm_source}
            >
                <LinkIcon/>
            </ActionIcon>
            
            <ActionIcon
                action={onDeleteIconClick}
                size={34}
                tooltip={"Удалить"}
            >
                <DeleteIcon/>
            </ActionIcon>
            
            <Toast/>
        </IconsWrapper>
    );
});

const IconsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Span = styled.span`
  font-family: ${theme.fonts.mainBold};
  color: ${theme.colors.bodyText};
`;

export default TableIcons;