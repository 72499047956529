import {observer} from "mobx-react-lite";
import React, {useCallback, useEffect, useState} from 'react';
import styled from "styled-components";
import FileDownloadIcon from "../../assets/icons/FileDownloadIcon";
import ActionIcon from "../../components/ActionIcon";
import Header from "../../components/Header";
import {theme} from "../../styles/theme";
import Datepicker from "../../components/Datepicker";
import moment from "moment";
import Button from "../../components/Button";
import Content from "./Content/index";
import {ModalsStore} from "../../stores/modal";
import AddModal from "./AddModal/index";
import {useFetching} from "../../hooks/useFetching";
import {
  getOfflineAdvertising,
  getOfflineAdvertisingType,
  getPosterSize,
  getPrintedProduct
} from "../../actions/offlineAdvertising";
import cloneDeep from "lodash/cloneDeep";
import {DEFAULT_RESULT_VALUE} from "./AddModal/const";
import {saveAs} from 'file-saver';

const DatepickerWrapper = styled.div`
  width: 167px;
  
  @media (max-width: 355px) {
    width: calc(100% - 56px);
  }
`;

const RightActions = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: flex-end;
  flex-grow: 1;
  
  @media (max-width: 355px) {
    flex-wrap: wrap;
    justify-content: flex-start;
    button {
      width: 100%;
    }
  }
`;

const OfflineAdvertising = observer(() => {
  const [data, setData] = useState(null);
  const [filterDate, setFilterDate] = useState(null);
  const [offlineAdvertisingType, setOfflineAdvertisingType] = useState([]);
  const [printedProduct, setPrintedProduct] = useState([]);
  const [posterSize, setPosterSize] = useState([]);
  const [fetchOfflineAdvertising, isLoading, error] = useFetching(async () => {
    const dateFilter = filterDate?.format("YYYY-MM-DD");

    const response = await getOfflineAdvertising(dateFilter);
    const distributedArr = distributionByType(response.data);
    setData(distributedArr);
  });
  const [fetchAddingOrder, isLoadingAdd, errorAdd] = useFetching(async () => {
    const responseAdvertisingType = await getOfflineAdvertisingType();
    const responsePrintedProduct = await getPrintedProduct();
    const responsePosterSize = await getPosterSize();

    setOfflineAdvertisingType(modifySelectValues(responseAdvertisingType.data));
    setPrintedProduct(modifySelectValues(responsePrintedProduct.data));
    setPosterSize(modifySelectValues(responsePosterSize.data));
  });

  useEffect(() => {
    fetchOfflineAdvertising();
  }, [filterDate]);

  useEffect(() => {
    fetchAddingOrder();
  }, [])

  const setFilterDateHandler = (date:moment.Moment) => setFilterDate(date);

  const onOpenModal = () => {
      ModalsStore.showModal("add-offline-adv-modal");
  };

  const distributionByType = (data) => {
    const distributedArr = cloneDeep(DEFAULT_RESULT_VALUE);

    for(let item of data) {
      if(item.offline_advertising_type)
        distributedArr[item.offline_advertising_type.id].push(item)
    }

    return distributedArr;
  };
    
  const exportIconClickHandle = useCallback(async () => {
      const exportParams = [
          {column: "id", alias: "Идентификатор"},
          {column: "restaurant_id", alias: "Идентификатор ресторана"},
          {column: "restaurant.name_ru", alias: "Ресторан"},
          {column: "offlineAdvertisingType.name_ru", alias: "Тип рекламы"},
          {column: "printedProduct.name_ru", alias: "Тип для печатной продукции"},
          {column: "posterSize.name_ru", alias: "Размер"},
          {column: "products_count", alias: "Количество"},
          {column: "route_numbers", alias: "Номера маршрутов"},
          {column: "accommodation_addresses", alias: "Адрес размещения"},
          {column: "leaflet_distribution_points", alias: "Пункты распространения листовок"},
          {column: "print_media_name", alias: "Печатаемое название"},
          {column: "another", alias: "Прочее"},
          {column: "comment", alias: "Комментарий"},
          {column: "advertising_month", alias: "Рекламный месяц"},
          {column: "created_at", alias: "Дата создания"},
          {column: "updated_at", alias: "Дата обновления"},
          {column: "deleted_at ", alias: "Дата удаления"}
      ];
      const dateFilter = filterDate?.format("YYYY-MM-DD");

      const response = await getOfflineAdvertising(dateFilter, exportParams);
      const data = new Blob([response], {type: "xlsx"});
      saveAs(data, "offlineAdvertisingOrders.xlsx");
  }, [filterDate]);

  const modifySelectValues = (serverData) => {
    return serverData.map(item => {
      return {label: item.name_ru, value: String(item.id)}
    })
  }

    return (
        <>
            <Header
                rightActions={
                    <RightActions>
                        <ActionIcon
                            action={exportIconClickHandle}
                            tooltip="Экспорт в файл статистики по всем ресторанам"
                        >
                            <FileDownloadIcon
                                fill={theme.colors.neutral7}
                            />
                        </ActionIcon>
                        <DatepickerWrapper>
                            <Datepicker value={filterDate}
                                        format={"MM.yyyy"}
                                        title={"Месяц и год"}
                                        monthSelect
                                        onChange={setFilterDateHandler}/>
                        </DatepickerWrapper>
                        <Button title={"Добавить"}
                                btnStyle={"primary"}
                                onClick={onOpenModal}
                                weight={700}/>
                    </RightActions>
                }
                title="Заказ offline рекламы"
            />
            <Content
              data={data}
              offlineAdvertisingType={offlineAdvertisingType}
              isLoading={isLoading || isLoadingAdd}
              error={error}
              fetchOfflineAdvertising={fetchOfflineAdvertising}
            />
            <AddModal
              offlineAdvertisingType={offlineAdvertisingType}
              printedProduct={printedProduct}
              posterSize={posterSize}
              isLoading={isLoadingAdd}
              fetchOfflineAdvertising={fetchOfflineAdvertising}
            />
        </>
    );
});

export default OfflineAdvertising;
