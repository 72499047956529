import React, {FC, memo} from "react";
import {Controller, useWatch} from "react-hook-form";
import styled from "styled-components";
import Checkbox from "../../../../../components/Checkbox";
import {TextArea} from "../../../../../components/TextArea";

interface Props {
  setHRPartnerNewCandidate: (val: boolean) => void;
  setHRPartnerInterview: (val: boolean) => void;
  setHRPartnerReminder: (val: boolean) => void;
  control: any
}

const HrPartnerRole: FC<Props> = memo(({
 setHRPartnerNewCandidate,
 setHRPartnerInterview,
 setHRPartnerReminder,
 control
}) => {
  const chkFormsEmailValue = useWatch({
    control,
    name: "to_user.hr_partner.new_forms_chk_email"
  });

  const msFormsEmailValue = useWatch({
    control,
    name: "to_user.hr_partner.new_forms_ms_email"
  });

  const chkInterviewsEmailValue = useWatch({
    control,
    name: "to_user.hr_partner.new_interviews_chk_email"
  });

  const msInterviewsEmailValue = useWatch({
    control,
    name: "to_user.hr_partner.new_interviews_ms_email"
  });

  const chkReminderValue = useWatch({
    control,
    name: "to_user.hr_partner.email_reminder_chk"
  });

  const msReminderValue = useWatch({
    control,
    name: "to_user.hr_partner.email_reminder_ms"
  });

  const changesNewCandidateHandler = () => {
    setHRPartnerNewCandidate(true);
  }

  const changesInterviewHandler = () => {
    setHRPartnerInterview(true);
  }

  const changesReminderHandler = () => {
    setHRPartnerReminder(true);
  }

  return (
    <Wrapper>
      <FieldWrapper>
        <CheckboxesWrapper>
          <Controller
            render={({field, fieldState}) =>
              <Checkbox
                {...field}
                {...fieldState}
                label="Email о новых анкетах ЧК"
                onChange={(value) => {
                  field.onChange(value);
                  changesNewCandidateHandler();
                }}
                ref={null}
              />
            }
            name="to_user.hr_partner.new_forms_chk_email"
            control={control}
          />

          <Controller
            render={({field, fieldState}) =>
              <Checkbox
                {...field}
                {...fieldState}
                label="Email о новых анкетах МС"
                onChange={(value) => {
                  field.onChange(value);
                  changesNewCandidateHandler();
                }}
                ref={null}
              />
            }
            name="to_user.hr_partner.new_forms_ms_email"
            control={control}
          />
        </CheckboxesWrapper>
      </FieldWrapper>

      <FieldWrapper>
        <Controller
          render={({field, fieldState}) =>
            <TextArea
              {...field}
              {...fieldState}
              placeholder="Текст сообщения (шаблон)"
              disabled={!chkFormsEmailValue && !msFormsEmailValue}
              onChange={(value) => {
                field.onChange(value);
                changesNewCandidateHandler();
              }}
              ref={null}
            />
          }
          name="to_user.hr_partner.new_forms_message"
          control={control}
        />
      </FieldWrapper>

      <FieldWrapper>
        <CheckboxesWrapper>
          <Controller
            render={({field, fieldState}) =>
              <Checkbox
                {...field}
                {...fieldState}
                label="Email о новых собеседованиях ЧК"
                onChange={(value) => {
                  field.onChange(value);
                  changesInterviewHandler();
                }}
                ref={null}
              />
            }
            name="to_user.hr_partner.new_interviews_chk_email"
            control={control}
          />

          <Controller
            render={({field, fieldState}) =>
              <Checkbox
                {...field}
                {...fieldState}
                label="Email о новых собеседованиях МС"
                onChange={(value) => {
                  field.onChange(value);
                  changesInterviewHandler();
                }}
                ref={null}
              />
            }
            name="to_user.hr_partner.new_interviews_ms_email"
            control={control}
          />
        </CheckboxesWrapper>
      </FieldWrapper>

      <FieldWrapper>
        <Controller
          render={({field, fieldState}) =>
            <TextArea
              {...field}
              {...fieldState}
              placeholder="Текст сообщения (шаблон)"
              disabled={!chkInterviewsEmailValue && !msInterviewsEmailValue}
              onChange={(value) => {
                field.onChange(value);
                changesInterviewHandler();
              }}
              ref={null}
            />
          }
          name="to_user.hr_partner.new_interviews_message"
          control={control}
        />
      </FieldWrapper>

      <FieldWrapper>
        <Controller
          render={({field, fieldState}) =>
            <Checkbox
              {...field}
              {...fieldState}
              label="Email напоминание за час до собеседования ЧК"
              onChange={(value) => {
                field.onChange(value);
                changesReminderHandler();
              }}
              ref={null}
            />
          }
          name="to_user.hr_partner.email_reminder_chk"
          control={control}
        />
      </FieldWrapper>

      <FieldWrapper>
        <Controller
          render={({field, fieldState}) =>
            <Checkbox
              {...field}
              {...fieldState}
              label="Email напоминание за час до собеседования МС"
              onChange={(value) => {
                field.onChange(value);
                changesReminderHandler();
              }}
              ref={null}
            />
          }
          name="to_user.hr_partner.email_reminder_ms"
          control={control}
        />
      </FieldWrapper>

      <FieldWrapper>
        <Controller
          render={({field, fieldState}) =>
            <TextArea
              {...field}
              {...fieldState}
              placeholder="Текст сообщения (шаблон)"
              disabled={!chkReminderValue && !msReminderValue}
              onChange={(value) => {
                field.onChange(value);
                changesReminderHandler();
              }}
              ref={null}
            />
          }
          name="to_user.hr_partner.reminder_message"
          control={control}
        />
      </FieldWrapper>
    </Wrapper>
  );
});

const Wrapper = styled.div`
  width: 100%;
`;

const FieldWrapper = styled.div`
  &:not(:last-child) {
    margin-bottom: 15px;
  }
`;

const CheckboxesWrapper = styled.div`
  display: flex;
  gap: 20px;
`;

export default HrPartnerRole;