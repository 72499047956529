import React from "react";
import styled from "styled-components";
import BigCalendar from "../../../../components/BigCalendar";
import moment from "moment";
import {theme} from "../../../../styles/theme";
import CalendarMonthDayModal from "../../CalendarMonthDayModal";
import {ModalsStore} from "../../../../stores/modal";
import _get from "lodash/get";
import {history} from "../../../../routes/history";
import {privateRoutes} from "../../../../config/routes";
import {COUNT_ITEMS_MONTH} from "../consts";

const MonthView = ({onChangeDate, data, currentDate}, ref) => {
    const onOpenModal = (day, items) => {
        ModalsStore.showModal("calendar-month-day-modal", {day, items});
    };

    const renderDay = (day: moment.Moment) => {
        const items = data.data.filter(i =>
            day.isSame(moment(i.interview_date), "day") &&
            day.isSame(moment(i.interview_date), "month")
        );
        const dayForClick = moment(day);
        return (
            <CellWrapper>
                {
                    items.slice(0, COUNT_ITEMS_MONTH).map((item, i) => {
                        return (
                            <Item key={i}
                                  onClick={() =>
                                      history.push({pathname: `${privateRoutes.candidateFormItem.alias}${item.candidateProfile.id}`})
                                  }>
                                {moment(item.interview_date, "YYYY-MM-DD HH:mm:ss").format("HH:mm")} {_get(item, "candidateProfile.first_name")} {_get(item, "candidateProfile.second_name")}
                            </Item>
                        )
                    })
                }
                {
                    items.length > COUNT_ITEMS_MONTH &&
                    <ItemMore onClick={() => onOpenModal(dayForClick, items)}>Еще {items.slice(COUNT_ITEMS_MONTH).length}...</ItemMore>
                }
            </CellWrapper>
        );
    };

    return (
        <Wrapper>
            <BigCalendar ref={ref}
                         hideHeader={true}
                         currentDate={currentDate}
                         onChangeDate={onChangeDate}
                         renderDay={renderDay} />
             <CalendarMonthDayModal/>
        </Wrapper>
    );
};

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  margin-bottom: 17px;
  border-radius: 4px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);

  @media (max-width: 900px) {
      overflow: unset;
      margin-bottom: 24px;
  }
`;

const CellWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 10px;
  gap: 3px;
`;

const Item = styled.span`
  display: block;
  height: 20px;
  background: ${theme.colors.neutral2};
  color: ${theme.colors.bodyText};
  border-radius: 4px;
  padding: 3px 5px;
  font-size: ${theme.fontSizes.small};
  font-family: ${theme.fonts.mainRegular};
  line-height: 14px;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;

    &:hover {
        background: ${theme.colors.neutral3};
    }
`;

const ItemMore = styled(Item)`
    width: fit-content;
    color: ${theme.colors.primary};
    background: transparent;
`;

export default React.forwardRef(MonthView);
