import React from 'react';
import ReactDOM from 'react-dom/client';
import {ThemeProvider} from 'styled-components';
import App from "./App";
import {theme} from "./styles/theme";
import {GlobalStyles} from './styles/globalStyles'
import CustomReactTooltip from "./components/CustomReactTooltip";
import moment from "moment";
import RU_LOCALE from "./config/moment";
import './index.css';
import ErrorBoundary from "./components/ErrorBoundary";

moment.locale("ru", RU_LOCALE);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
if (!root) {
    throw new Error('Failed to find the root element');
}
root.render(
    <ThemeProvider theme={theme}>
        <GlobalStyles/>
        <CustomReactTooltip/>
        <ErrorBoundary>
          <App/>
        </ErrorBoundary>
    </ThemeProvider>,
);