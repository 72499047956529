import React, {FC} from 'react';
import PaginationLimit from "./PaginationLimit";
import Pagination from "./Pagination";
import styled from "styled-components";
import {observer} from "mobx-react-lite";
import {IMeta} from "../../types/metaTypes";


interface Props {
  meta: IMeta;
  setPage: (string) => void;
  setLimit: (string) => void;
}

const PaginationGroup: FC<Props> = observer(({meta, setPage, setLimit}) => {
  const changeLimit = (newLimit) => {
    newLimit = +newLimit;


    const newPage = meta.current_page === 1
      ? 1
      : Math.floor((meta.per_page * meta.current_page - meta.per_page + newLimit) / newLimit);

    setLimit(newLimit);
    setPage(newPage);
  }

  return (
    <PaginationGroupWrapper>
      {meta &&
        <>
          <PaginationLimit
            limit={meta.per_page}
            from={meta.from}
            to={meta.to}
            totalCount={meta.total}
            changeLimit={changeLimit}
          />


          <Pagination
            page={meta.current_page}
            totalPage={meta.total}
            pagesArray={meta.links}
            setPage={setPage}
          />
        </>
      }
    </PaginationGroupWrapper>
  );
});

const PaginationGroupWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 15px;
  
  @media (max-width: 990px) {
    flex-wrap: wrap;
    gap: 16px;
  }
`;

export default PaginationGroup;