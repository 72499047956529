import React, {
  ChangeEvent,
  FC,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import { withError } from "../../hocs/Error";
import {theme} from "../../styles/theme";
import SearchIcon from "../../assets/icons/SearchIcon";
import CloseIcon from "../../assets/icons/CloseIcon";
import ActionIcon from "../ActionIcon";
import DOMPurify from 'dompurify';

interface Props {
  type?: "text" | "password" | "number";
  placeholder?: string;
  onBlur?: () => void;
  autoFocus?: boolean;
  onChange: (value: string | number) => void;
  value?: string | number;
  width?: string;
  height?: string;
  search?: boolean;
  disabled?: boolean;
}

const TextAreaComponent: FC<Props> = ({
  type = "text",
  placeholder = "",
  onBlur = () => null,
  autoFocus = false,
  onChange,
  value,
  width = '100%',
  height = '140px',
  search= false,
  disabled = false,
}) => {
  const inputRef = useRef(null);
  const [topPlaceholder, setTopPlaceholder] = useState(!!value);
  const [focusInput, setFocusInput] = useState(false);

  const focusToInput = useCallback(() => {
    inputRef.current.focus();
  }, []);

  useEffect(() => {
    if (autoFocus) {
      focusToInput();
      setFocusInput(true);
    }
  }, [autoFocus, focusToInput]);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => {
      const value = DOMPurify.sanitize(e.target.value);
      onChange(value);
    },
    [onChange]
  );

  const handleBlur = useCallback(
    () => {
      !inputRef.current.value && setTopPlaceholder(false);
      setFocusInput(false);
    },
    [onBlur]
  )

  const handleClear = () => {
    const input = inputRef.current;
    onChange("");
    input.focus();
  }

  return (
    <Wrapper
      width={width}
      height={height}
    >
      <Placeholder
        topPlaceholder={topPlaceholder}
        search={search}
        focusInput={focusInput}
        disabled={disabled}
      >
        <span>{placeholder}</span>
      </Placeholder>
      {
        search &&
          <IconSearchWrapper>
              <SearchIcon/>
          </IconSearchWrapper>
      }
      <TextAreaElement
        ref={inputRef}
        onBlur={handleBlur}
        onFocus={() => {
          setTopPlaceholder(true);
          setFocusInput(true);
        }}
        value={value}
        onChange={handleChange}
        field={type}
        search={search}
        disabled={disabled}
      />
      {
        value &&
          <IconClearWrapper action={handleClear} size={24}>
              <CloseIcon/>
          </IconClearWrapper>
      }
    </Wrapper>
  );
};

const IconClearWrapper = styled(ActionIcon)`
  position: absolute;
  top: 8px;
  right: 17px;
`;

const IconSearchWrapper = styled.div`
  width: 24px;
  height: 24px;
  position: absolute;
  top: 8px;
  left: 5px;
`;

const placeholderPosition = (top, search) => {
  switch (top) {
    case true:
      return 'line-height: 12px; top: -6px; left: 4px; font-size: 12px;'
    default:
      return 'line-height: 20px; top: 10px; left: ' + (!search ? '7px;' : '31px;')
  }
}

const Placeholder = styled.div<{
  topPlaceholder: boolean,
  search: boolean,
  focusInput: boolean,
  disabled?: boolean,
}>`
  position: absolute;
  pointer-events: none;
  color: ${({focusInput}) => !focusInput ? theme.colors.neutral6 : theme.colors.primaryDark};
  padding: 0 3px;
  ${({topPlaceholder, search}) => placeholderPosition(topPlaceholder, search)};
  transition: all .1s ease;

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    bottom: 0;
    background-color: ${({disabled}) => disabled ? theme.colors.neutral1 : "#ffffff"};
  }

  span {
    position: relative;
  }
`;

const Wrapper = styled.div<{
  width: string,
  height: string,
}>`
  position: relative;
  font-family: ${theme.fonts.mainRegular};
  font-size: ${theme.fontSizes.body};
  width: ${({width}) => width};
  height: ${({height}) => height};
  
  path[fill] {
    fill: ${theme.colors.neutral7};
  }
`;

const TextAreaElement = styled.textarea<{
  field: string,
  search: boolean,
  disabled: boolean,
}>`
  border: 1px solid ${theme.colors.neutral7};
  font-size: ${theme.fontSizes.body};
  height: 100%;
  border-radius: 4px;
  outline: none;
  resize: none;
  font-family: ${theme.fonts.mainRegular};
  width: 100%;
  color: ${theme.colors.bodyText};
  padding: ${({search}) => !search ? '10px 32px 0 10px' : '10px 32px 0 34px'};
  
  &:hover {
    ${({disabled}) => !disabled && 'border: 1px solid ' + theme.colors.neutral10 + ';'}
  }
  
  &:focus {
    border: 1px solid ${theme.colors.primaryDark};
  }
`;

export const TextArea = withError(memo(TextAreaComponent));
