import {useContext, useEffect} from "react";
import {useFetching} from "./useFetching";
import {getUserData} from "../actions/auth";
import {AuthStoreContext} from "../stores/auth";
import {candidateFormsFiltersStoreContext} from "../stores/candidateFormsFilters";

export const useUpdateProfile = () => {
  const { updateUserData } = useContext(AuthStoreContext);
  const { setAllDefault } = useContext(candidateFormsFiltersStoreContext);

  const [updateProfile] = useFetching(async () => {
    const response = await getUserData();
    if(response.status === 200) {
      updateUserData(response.data.data);
    }
  });

  useEffect(() => {
    updateProfile();
    return () => {
      const path = window.location.pathname.split("/")[2];

      if(path === "newcandidate" || Number(path)) return;

      setAllDefault();
    }
  }, []);
}