import React, { useCallback, useEffect, useMemo, useState } from "react";
import Button from "../../../../components/Button";
import { Modal } from "../../../../components/Modal";
import { connectModal } from "../../../../hocs/Modal";
import Radio from "../../../../components/Radio";
import { Data as ACVData } from "./ACV/data";
import { Data as RJPData } from "./RJP/data";
import {
  Answers,
  AnswerItem,
  AnswerTitle,
  AnswerText,
  FormActions,
  QuestionItem,
  Block,
  QuestionNumber,
  QuestionText,
  QuestionsList,
} from "./styles";
import {getQuestionnaireByType, saveAnswers} from "../../../../actions/candidates";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as yup from "yup";

const QuestionnaireModal = connectModal("questionnaire-modal")(
  ({ handleHide, type, candidate_profile_id, updateAfterSave }) => {
    const [data, setData] = useState({});
    const [schemaContext, setSchemaContext] = useState(yup.object().shape({}));

    const fetchQuestionnaire = useCallback(async () => {
      const response = await getQuestionnaireByType(type);
      setData(response.data || {});
    }, []);

    useEffect(() => {
      fetchQuestionnaire();
    }, [fetchQuestionnaire]);

    const {
      control,
      handleSubmit,
      getValues,
      reset,
      formState: { errors, isValid },
    } = useForm({
      mode: "onChange",
      context: schemaContext,
      resolver: (data, context, options) => {
        return yupResolver(schemaContext)(data, context, options);
      },
    });

    useEffect(() => {
      setSchemaContext(setSchema);
      reset();
    }, [data]);

    const setSchema = useMemo(() => {
      const schemaArray = Object.keys(data).map((key) => {
        return { [key]: yup.string() };
      });
      return yup.object().shape(
        schemaArray.reduce(
          (previousValue, currentValue, currentIndex) => ({
            ...previousValue,
            ...currentValue,
          }),
          {}
        )
      );
    }, [data]);

    const title = useMemo(() => {
      if (type === "tmri") return "ACV-опросник";
      if (type === "sig") return "SIG-опросник";
      if (type === "rjp") return "RJP-опросник";
    }, [type]);

    const onSubmit = () => {
      const answers = getValues();
      const question_answer = Object.keys(answers)
          .map(key => ({
              question_id: key,
              answer_id: answers[key]
          }))
          .filter(i => i.answer_id);
      const data = {
        question_answer: JSON.stringify(question_answer),
        candidate_profile_id,
        questionnaire_type: type
      };
      const valueForUpdate = `${question_answer.length}/${Object.keys(answers).length}`;
      saveAnswers(data)
          .then(() => {
              updateAfterSave(type, valueForUpdate);
              handleHide();
          })
    };

    return (
      <Modal
        title={title}
        onClose={handleHide}
        width={type === "tmri" ? "700px" : "900px"}
        footer={
          <FormActions>
              {/*<Button
              title={"Получить"}
              btnStyle={"primary"}
              onClick={() => showValues()}
            />*/}
            <Button
              title="Отправить"
              onClick={handleSubmit(onSubmit)}
              btnStyle="primary"
              disabled={!isValid || Object.keys(errors).length !== 0}
            />
          </FormActions>
        }
        noContentPadding={type === "tmri" || type === "rjp"}
        closeDataTip={"После закрытия ответы не сохрантся"}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          {type === "tmri" && (
            <Answers onMargin>
              {ACVData.answers.map((item, index) => {
                return (
                  <AnswerItem key={"answer" + index}>
                    <AnswerTitle>{item.title}</AnswerTitle>
                    <AnswerText>{item.text}</AnswerText>
                  </AnswerItem>
                );
              })}
            </Answers>
          )}
          {type === "rjp" && (
            <Answers onMargin>
              {RJPData.answers.map((item, index) => {
                return (
                  <AnswerItem
                    key={"answer" + index}
                    width={index === 2 ? "100px" : "100%"}
                    bold={index === 2}
                  >
                    <AnswerTitle>{item.title}</AnswerTitle>
                    <ul>
                      {item.textList.map((item, index) => {
                        return <li>{item.text}</li>;
                      })}
                    </ul>
                  </AnswerItem>
                );
              })}
            </Answers>
          )}
          {(type === "tmri" || type === "rjp") && (
            <QuestionsList>
              {Object.keys(data).map((questionIndex) => {
                return (
                  <QuestionItem key={data[questionIndex].question.id}>
                    <Block gap={4}>
                      <QuestionNumber>
                        {data[questionIndex].question.sort_order}
                      </QuestionNumber>
                      <QuestionText>
                        {data[questionIndex].question.text}
                      </QuestionText>
                    </Block>
                    <Block padding={"0 0 0 28px"} gap={16}>
                      {data[questionIndex].question.answers.map((answer) => (
                        <Controller
                          render={({ field, fieldState }) => (
                            <Radio
                              {...field}
                              {...fieldState}
                              key={answer.id}
                              checked={field.value === answer.id}
                              value={answer.id}
                              name={"question" + questionIndex}
                              label={answer.short_answer[0]}
                            />
                          )}
                          name={questionIndex}
                          control={control}
                        />
                      ))}
                    </Block>
                  </QuestionItem>
                );
              })}
            </QuestionsList>
          )}
          {type === "sig" && (
            <Block flexDirection={"column"} gap={16}>
              {Object.keys(data).map((sigKey, index) => {
                return (
                  <Block flexDirection={"column"}>
                    <Answers>
                      {data[sigKey].question.answers.map((answer) => {
                        return (
                          <AnswerItem
                            key={"answer" + answer.id}
                            width={index === 2 ? "100px" : "100%"}
                            bold={index === 2}
                          >
                            <AnswerTitle>{answer.short_answer}</AnswerTitle>
                            <ul>
                              {answer.long_answer.split("<br>").map((item) => {
                                return <li>{item}</li>;
                              })}
                            </ul>
                          </AnswerItem>
                        );
                      })}
                    </Answers>
                    <QuestionItem>
                      <Block gap={4}>
                        <QuestionNumber>
                          {data[sigKey].question.sort_order}
                        </QuestionNumber>
                        <QuestionText>
                          (
                          {data[sigKey].question.competences.map(
                            (competence) => competence.name
                          )}
                          ) {data[sigKey].question.text}
                        </QuestionText>
                      </Block>
                      <Block padding={"0 0 0 28px"} gap={16}>
                        {data[sigKey].question.answers.map((answer) => (
                          <Controller
                            render={({ field, fieldState }) => (
                              <Radio
                                {...field}
                                {...fieldState}
                                key={answer.id}
                                checked={field.value === answer.id}
                                value={answer.id}
                                name={"question" + sigKey}
                                label={answer.short_answer[0]}
                              />
                            )}
                            name={sigKey}
                            control={control}
                          />
                        ))}
                      </Block>
                    </QuestionItem>
                  </Block>
                );
              })}
            </Block>
          )}
        </form>
      </Modal>
    );
  }
);

export default QuestionnaireModal;
