import { LIMIT_DEFAULT, PAGE_DEFAULT } from "../config/consts";
import axios from "../middlewares/axios";
import {
  CANDIDATE_PROFILES,
  CANDIDATE_PROFILES_HISTORY,
  CANDIDATE_QUESTIONNAIRES,
  CANDIDATE_QUESTION_ANSWER,
  QUESTION, CANDIDATE_STATISTICS
} from "../config/paths";
import dayjs from "dayjs";
import fileSaver from "file-saver";
import moment from "moment/moment";

export const getCandidates = async (
  limit = LIMIT_DEFAULT,
  page = PAGE_DEFAULT,
  order = "",
  search = "",
  filters = null,
  filterOptionLengths= {},
  exportParams = null
) => {
  const data = {
    limit: limit,
    page: page,
    with: {
      relationships: [
        "candidateProfileStatus",
        "candidateProfileStatusDetail",
        "candidateProfileReject",
        "source",
        "restaurant",
        "partner",
        "citizenship",
        "candidateInterview",
        "comments",
        "parentProfile",
        "childrenProfile",
        "vacancy",
        "city"
      ],
      properties: ["interview"],
    },
  };

  if (order) {
    data.order = order;
  }

  const config = {};
  data.filter = [];

  if (search) {
    data.filter.push({
      type: "group",
      group: [
        {
          column: "full_name",
          operator: "ilike",
          value: `%${search}%`,
          boolean: "or"
        },
        {
          column: "phone",
          operator: "ilike",
          value: `%${search}%`,
          boolean: "or"
        },
        {
          column: "id",
          operator: "ilike",
          value: `%${search}%`,
          boolean: "or"
        }
      ],
    });
  }

  let index = 0;
  let needBoolean = false;
  if (filters) {
    if (data.filter.length === 1) {
      index = 1;
    }

    data.filter.push({
      type: "group",
      group: [],
    });

    if (filters.candidate_profile_type) {
      data.filter[index].group.push({
        column: "vacancy_id",
        operator: filters.candidate_profile_type === "restaurant_employee" ? "=" : "!=",
        value: 1,
      });
      needBoolean = true;
    }
    if (filters?.country?.length && filters?.country?.length !== filterOptionLengths.countryLength) {
      data.filter[index].group.push({
        column: "cities.country_id",
        operator: "=",
        value: filters.country,
      });
      needBoolean = true;
    }
    if (filters?.partner?.length && filters?.partner?.length !== filterOptionLengths.partnerLength) {
      data.filter[index].group.push({
        column: "restaurants.partner_id",
        operator: "=",
        value: filters.partner,
      });
      needBoolean = true;
    }
    if (filters?.restaurant_id?.length && filters?.restaurant_id?.length !== filterOptionLengths.restaurantLength) {
      data.filter[index].group.push({
        column: "restaurants.id",
        operator: "=",
        value: filters.restaurant_id,
      });
      needBoolean = true;
    }
    if (filters.candidate_profile_status_id?.length && filters.candidate_profile_status_id?.length !== filterOptionLengths.statusLength) {
      const item = {
        column: "candidate_profile_status_id",
        operator: "=",
        value: filters.candidate_profile_status_id,
      };
      if (needBoolean) {
        item.boolean = "and";
      }
      data.filter[index].group.push(item);
      needBoolean = true;
    }
    if (filters?.refuse) {
      data.filter[index].group.push({
        column: "reject_reason",
        operator: "=",
        value: filters.refuse,
      });
      needBoolean = true;
    }
    if (filters.ageFromTo.from) {
      const itemFrom = {
        column: "age",
        operator: ">=",
        value: filters.ageFromTo.from,
      };
      if (needBoolean) {
        itemFrom.boolean = "and";
      }
      data.filter[index].group.push(itemFrom);
      needBoolean = true;
    }

    if (filters.ageFromTo.to) {
      const itemTo = {
        column: "age",
        operator: "<=",
        value: filters.ageFromTo.to,
      };
      if (needBoolean) {
        itemTo.boolean = "and";
      }
      data.filter[index].group.push(itemTo);
    }
    if (filters.citizenship_id?.length && filters.citizenship_id?.length !== filterOptionLengths.citizenshipLength) {
      const item = {
        column: "citizenship_id",
        operator: "=",
        value: filters.citizenship_id,
      };
      if (needBoolean) {
        item.boolean = "and";
      }
      data.filter[index].group.push(item);
      needBoolean = true;
    }
    if (filters.vacancy) {
      const item = {
        column: "vacancy_id",
        operator: "=",
        value: filters.vacancy,
      };
      if (needBoolean) {
        item.boolean = "and";
      }
      data.filter[index].group.push(item);
      needBoolean = true;
    }

    if (filters.interviewDate?.from || filters.interviewDate?.to) {
      const itemFrom = {
        column: "candidate_interviews.interview_date",
        operator: ">=",
        value: dayjs(filters.interviewDate.from).format("YYYY-MM-DD HH:mm:ss"),
      };
      if (needBoolean) {
        itemFrom.boolean = "and";
      }
      data.filter[index].group.push(itemFrom);
      needBoolean = true;

      const itemTo = {
        column: "candidate_interviews.interview_date",
        operator: "<",
        value: dayjs(filters.interviewDate.to).add(1, 'day').format("YYYY-MM-DD HH:mm:ss"),
      };
      if (needBoolean) {
        itemTo.boolean = "and";
      }
      data.filter[index].group.push(itemTo);
    }
    if (filters.dateRange?.from || filters.dateRange?.to) {
      const itemFrom = {
        column: "created_at",
        operator: ">=",
        value: dayjs(filters.dateRange.from).format("YYYY-MM-DD HH:mm:ss"),
      };
      if (needBoolean) {
        itemFrom.boolean = "and";
      }
      data.filter[index].group.push(itemFrom);
      needBoolean = true;

      const itemTo = {
        column: "created_at",
        operator: "<",
        value: dayjs(filters.dateRange.to).add(1, 'day').format("YYYY-MM-DD HH:mm:ss"),
      };
      if (needBoolean) {
        itemTo.boolean = "and";
      }
      data.filter[index].group.push(itemTo);
    }

    if (data.filter[index].group.length === 0) {
      data.filter = data.filter.filter((i, ii) => ii !== index);
    }
  }
  if (data.filter.length === 0) delete data.filter;

  if (exportParams) {
    data.export = {
      export_type: "xlsx",
      file_name: `Анкеты кандидатов ${moment().format("YYYY-MM-DD HH:mm:ss")}`,
      fields: exportParams
    };
    data.return_type = "export";
    config.responseType = "arraybuffer";

    if (!filters.dateRange?.from || !filters.dateRange?.to) {
      const itemFrom = {
        column: "created_at",
        operator: ">=",
        value: dayjs().add(-1, 'month').format("YYYY-MM-DD HH:mm:ss"),
      };
      if (needBoolean) {
        itemFrom.boolean = "and";
      }
      data.filter[index].group.push(itemFrom);
      needBoolean = true;

      const itemTo = {
        column: "created_at",
        operator: "<",
        value: dayjs().add(1, 'day').format("YYYY-MM-DD HH:mm:ss"),
      };
      if (needBoolean) {
        itemTo.boolean = "and";
      }
      data.filter[index].group.push(itemTo);
    }
  }

  const response = await axios.post(CANDIDATE_PROFILES + "/search", data, config);

  return response.data;
};

export const getCandidateProfile = async (id) => {
  const response = await axios.get(CANDIDATE_PROFILES + "/" + id);
  if(response.code === "ERR_BAD_REQUEST") {
    return response.response.data;
  }
  return response.data;
};

export const putCandidateProfile = async (id, data) => {
  try {
    return await axios.put(CANDIDATE_PROFILES + "/" + id, {
      ...data,
    });
  } catch (e) {
    return e;
  }
};

export const createCandidateProfile = async (data) => {
  try {
    return await axios.post(CANDIDATE_PROFILES, {
      ...data,
    });
  } catch (e) {
    return e;
  }

};

export const deleteCandidateProfile = async (id) => {
  const response = await axios.delete(CANDIDATE_PROFILES + "/" + id);

  return response.data;
};

export const uploadCv = data => {
  return axios.post(CANDIDATE_PROFILES + "/upload-cv", data,
      {headers:{"Content-Type":'multipart/form-data'}}
      );
};

export const getCv = (id, fileType) => {
  return axios.get(CANDIDATE_PROFILES + `/${id}/get-cv`, {responseType: 'arraybuffer'})
      .then(response => {
        const data = new Blob([response.data], { type: fileType });
        fileSaver.saveAs(data, `resume.${fileType}`);
      })
};

export const getCandidateHistory = async (id) => {
  const data = {
      filter: [
          {
              type: "single",
              column: "candidate_profile_id",
              operator: "=",
              value: parseInt(id)
          }
      ],
      with:  {
          relationships: [
              "oldStatus", "newStatus", "candidateProfile", "createdBy", "oldRestaurant", "newRestaurant",
              "oldVacancy", "newVacancy"
          ]
      }
  };
  
  const response = await axios.post(CANDIDATE_PROFILES_HISTORY, data);
  return response.data;
};

export const getStatistics = async (filters, isChk, filterOptionLengths= {}) => {
  const params = {
    vacancy_id: isChk ? 1 : 2 // 1 - chk(restaurantemployee) 2 - ms(shiftmanager)
  };

  if (filters?.restaurant_id?.length && filters?.restaurant_id?.length !== filterOptionLengths.restaurantLength) {
    params.restaurant_id = filters.restaurant_id;
  }

  if (filters.candidate_profile_status_id?.length && filters.candidate_profile_status_id?.length !== filterOptionLengths.statusLength) {
    params.candidate_profile_status_id = filters.candidate_profile_status_id;
  }

  if (filters.citizenship_id?.length && filters.citizenship_id?.length !== filterOptionLengths.citizenshipLength) {
    params.citizenship_id = filters.citizenship_id;
  }

  if (filters.dateRange.from)
    params.date_from = dayjs(filters.dateRange.from).format("YYYY-MM-DD");

  if (filters.dateRange.to)
    params.date_to = dayjs(filters.dateRange.to).format("YYYY-MM-DD");

  if (filters.ageFromTo.from)
    params.age_from = +filters.ageFromTo.from;

  if (filters.ageFromTo.to)
    params.age_to = +filters.ageFromTo.to;

  if (filters.vacancy)
    params.vacancy_id = +filters.vacancy;

  if (filters.interviewDate.from)
    params.interview_date_from = dayjs(filters.interviewDate.from).format("YYYY-MM-DD");

  if (filters.interviewDate.to)
    params.interview_date_to = dayjs(filters.interviewDate.to).format("YYYY-MM-DD");

  const response = await axios.post(CANDIDATE_STATISTICS, params);

  return response.data;
}

//questionnaire
export const getQuestionnaireByType = async (type) => {
  const response = await axios.put(CANDIDATE_QUESTIONNAIRES, { type });

  return response.data;
};

//answer
export const saveAnswers = async (data) => {
  const response = await axios.put(CANDIDATE_QUESTION_ANSWER + "/questionnaire_save", data );

  return response.data;
};

export const downloadQuestionnaire = async (id, type) => {
  axios.get(CANDIDATE_QUESTION_ANSWER + `/get_candidate_questionnaire/${id}/${type}/1`, {responseType: 'arraybuffer'})
      .then(response => {
        const data = new Blob([response.data], {type: "pdf"});
        fileSaver.saveAs(data, `${type}.pdf`);
      })
};

export const downloadQuestionnaireResults = async (id) => {
  axios.get(CANDIDATE_QUESTION_ANSWER + `/get_candidate_questionnaires_result/${id}`, {responseType: 'arraybuffer'})
      .then(response => {
        const data = new Blob([response.data], {type: "pdf"});
        fileSaver.saveAs(data, `result_${id}.pdf`);
      })
};

export const downloadQuestionnaireBlank = async (type) => {
  axios.get(QUESTION + `/getQuestionnaireBlank/${type}`, {responseType: 'arraybuffer'})
      .then(response => {
        const data = new Blob([response.data], {type: "pdf"});
        fileSaver.saveAs(data, `${type}.pdf`);
      })
};
