import { Controller } from "react-hook-form";
import { Input } from "../../../../../components/Input";
import React, {FC} from "react";
import {TextArea} from "../../../../../components/TextArea";
import {ELEVATORS_VALUE} from "../../const";

interface Props {
  control: any;
}

const ElevatorsFields: FC<Props> = ({
  control
}) => {

  return (
    <>
      <Controller
        render={({ field, fieldState }) => {
          return (
            <Input
              placeholder="Желаемые адреса (город, улица, дом)"
              {...field}
              {...fieldState}
              ref={null}
            />
          );
        }}
        name={`${ELEVATORS_VALUE}.address`}
        control={control}
      />
      <Controller
        render={({ field, fieldState }) => {
          return (
            <TextArea
              placeholder="Комментарий"
              {...field}
              {...fieldState}
              ref={null}
            />
          );
        }}
        name={`${ELEVATORS_VALUE}.comment`}
        control={control}
      />
    </>
  );
};

export default ElevatorsFields;
