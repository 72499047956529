import { observer } from "mobx-react-lite";
import {Controller} from "react-hook-form";
import ActionIcon from "../../../../components/ActionIcon";
import FileDownloadIcon from "../../../../assets/icons/FileDownloadIcon";
import { Input } from "../../../../components/Input";
import Select from "../../../../components/Select";
import React, {FC, useCallback, useContext} from "react";
import styled from "styled-components";
import sortBySelectOptions from "../../../../assets/Interview/sortBySelectOptions.json";
import sortOrderSelectOptions from "../../../../assets/sortOrderSelectOptions.json";
import debounce from "lodash/debounce";
import {getInterviews} from "../../../../actions/interviews";
import {privateRoutes} from "../../../../config/routes";
import {saveAs} from 'file-saver';
import {PAGE_DEFAULT} from "../../../../config/consts";
import cloneDeep from "lodash/cloneDeep";
import {candidateFormsFiltersStoreContext} from "../../../../stores/candidateFormsFilters";
import {AuthStoreContext} from "../../../../stores/auth";
import {toast} from "react-toastify";
import DoneIcon from "../../../../assets/icons/DoneIcon";
import {theme} from "../../../../styles/theme";
import Toast from "../../../../components/Toast";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  
  @media (max-width: 1197px) {
    width: 100%;
    &>div {
      &:nth-child(2) {
        flex-grow: 1;
      }
    }
  }
  
  @media (max-width: 720px) {
    flex-wrap: wrap;
    
    &>div {
      &:nth-child(2) {
        width: calc(100% - 56px);
      }  
    }
  }
`;

const ActionWrapper = styled.div`
`;

const InputWrapper = styled(ActionWrapper)`
  width: 180px;
  
  @media (max-width: 451px) {
    width: 100%;
  }
`;

interface Props {
  control: any;
  filteredRestaurants: any;
  getValues: any;
  resetFilters: any;
  setSearchValue: (value: string) => void;
}

const HeaderElement: FC<Props> = observer(({
  control,
  filteredRestaurants,
  getValues,
  resetFilters,
  setSearchValue
}) => {
  const { userData } = useContext(AuthStoreContext);
  const {
    limit,
    page,
    defaultFilterValues,
    setPage,
    setSortBy,
    setSortOrder,
    setFilters,
  } = useContext(candidateFormsFiltersStoreContext);

    const sortBySelectChangeHandle = value => setSortBy(value);

    const sortOrderSelectChangeHandle = value => setSortOrder(value);

    const handlerSearch = debounce((search) => {
      setSearchValue(search);

      const filters = cloneDeep(defaultFilterValues);
      filters.restaurant_id = filteredRestaurants;
      setFilters(filters);

      setPage(PAGE_DEFAULT);
    }, 400);

  const exportIconClickHandle = useCallback(async () => {
    let exportParams = [
      {column: "id", alias: "ID анкеты"},
      {column: "created_at", alias: "Дата создания"},
      {column: "updated_at", alias: "Дата обновления"},
      {column: "city_name_ru", alias: "Город"},
      {column: "restaurant_name_ru", alias: "Ресторан"},
      {column: "vacancy_name_ru", alias: "Вакансия"},
      {column: "auto_dialing_status", alias: "Статус автообзвона"},
      {column: "candidate_profile_status_name", alias: "Статус анкеты"},
      {column: "candidateProfile.reject_reason", alias: "Причина отказа"},
      {column: "candidateProfile.first_name", alias: "Имя"},
      {column: "candidateProfile.second_name", alias: "Фамилия"},
      {column: "candidateProfile.age", alias: "Возраст"},
      {column: "citizenship_name", alias: "Гражданство"},
      {column: "candidate_profile_phone", alias: "Телефон"},
      {column: "candidateProfile.email", alias: "Email"},
      {column: "candidateProfile.sex", alias: "Пол"},
      {column: "interview_date", alias: "Дата и время собеседования"},
      {column: "deleted_at ", alias: "Дата удаления"},
    ];

    if(userData.role?.name === "superadmin") {
      exportParams = [
        ...exportParams,
        {column: "candidateProfile.source_marker", alias: "source_marker"},
        {column: "created_by_role_name", alias: "Роль пользователя, добавившего анкету"},
        {column: "candidateProfile.utm_source", alias: "utm_source"},
        {column: "candidateProfile.utm_medium", alias: "utm_medium"},
        {column: "candidateProfile.utm_campaign", alias: "utm_campaign"},
        {column: "candidateProfile.utm_term", alias: "utm_term"},
        {column: "candidate_profile_click_id", alias: "click_id"},
        {column: "restaurant_boss_code", alias: "boss_code"},
      ];
    }

    const {sortBy, sortOrder, search, filters} = getValues();
    let order = sortBy;
    if(!!order) {
      if(sortOrder === "descending") order = `-${order}`;

      order = [order];
    }

    if(!filters.dateRange?.from || !filters.dateRange?.to) {
      toast(
        "Вы не указали диапазон дат, поэтому данные будут сформированы за последний месяц",
        {
          icon: <DoneIcon
            width="24"
            height="24"
            fill="#03AD00"
          />,
          style: {
            ...theme.toast.commonParams,
            ...theme.toast.successParams
          }
        }
      );
    }

    const isChk = window.location.pathname === privateRoutes.interviewChk.path;

    const response = await getInterviews(limit, page, order, search, filters, {}, isChk, exportParams);
    const data = new Blob([response], {type: "xlsx"});
    saveAs(data, "candidateInterview.xlsx");
  }, [getValues, limit, page]);

  return (
    <Wrapper>
      <ActionWrapper>
        <ActionIcon action={exportIconClickHandle} tooltip={"Экспорт в файл"}>
          <FileDownloadIcon />
        </ActionIcon>
      </ActionWrapper>
      <InputWrapper>
        <Controller
          render={({field, fieldState}) =>
            <Input
              {...field}
              {...fieldState}
              onChange={(value) =>  {
                handlerSearch(value);
                field.onChange(value);
              }}
              placeholder="Поиск..."
              search={true}
              ref={null}
            />
          }
          name="search"
          control={control}
        />
      </InputWrapper>

      <InputWrapper>
        <Controller
          render={({field, fieldState}) =>
            <Select
              {...field}
              {...fieldState}
              title="Сортировать по..."
              options={sortBySelectOptions}
              onChange={(value) => {
                sortBySelectChangeHandle(value);
                field.onChange(value);
              }}
              ref={null}
            />
          }
          name="sortBy"
          control={control}
        />
      </InputWrapper>

      <InputWrapper>
        <Controller
          render={({field, fieldState}) =>
            <Select
              {...field}
              {...fieldState}
              title="Порядок сортировки"
              options={sortOrderSelectOptions}
              onChange={(value) => {
                sortOrderSelectChangeHandle(value);
                field.onChange(value);
              }}
              ref={null}
            />
          }
          name="sortOrder"
          control={control}
        />
      </InputWrapper>
      <Toast/>
    </Wrapper>
  );
});

export { HeaderElement };
