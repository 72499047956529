import React, { FC, memo } from "react";
import styled from "styled-components";
import { theme } from "../../../../styles/theme";

interface Props {
  candidateProfile: any;
}

const Testing: FC<Props> = memo(({ candidateProfile }) => {

  /**
   * Получение текста рекомендация для отображения
   */
  const getRecommendation = () => {
    if (candidateProfile.recommendation) {
      return candidateProfile.recommendation;
    }
    return "Нет рекомендации"
  };

  return (
    <>
      <H2>Результаты тестирований</H2>

      <TestingWrapper>
        <TestingWrapperSecond>
          <TestingHeaderElement>Статус автообзвона</TestingHeaderElement>
          <TestingMessageWrapper>
            {candidateProfile.auto_redial_status || "-"}
          </TestingMessageWrapper>
        </TestingWrapperSecond>
        <TestingWrapperSecond>
          <TestingHeaderElement>Рекомендация</TestingHeaderElement>
          <TestingMessageWrapper>{getRecommendation()}</TestingMessageWrapper>
        </TestingWrapperSecond>
        <TestingWrapperSecond>
          <TestingHeaderElement>
            Кол-во заполненных ACV тестов
          </TestingHeaderElement>
          <TestingMessageWrapper>{candidateProfile.count_tmri}</TestingMessageWrapper>
        </TestingWrapperSecond>
        <TestingWrapperSecond>
          <TestingHeaderElement>
            Кол-во заполненных SIG тестов
          </TestingHeaderElement>
          <TestingMessageWrapper>{candidateProfile.count_sig}</TestingMessageWrapper>
        </TestingWrapperSecond>
        <TestingWrapperSecond>
          <TestingHeaderElement>
            Кол-во заполненных RJP тестов
          </TestingHeaderElement>
          <TestingMessageWrapper>{candidateProfile.count_rjp}</TestingMessageWrapper>
        </TestingWrapperSecond>
      </TestingWrapper>
    </>
  );
});

const H2 = styled.div`
  font-size: ${theme.fontSizes.h2};
  color: ${theme.colors.neutral10};
  font-family: ${theme.fonts.mainBlack};
  margin-bottom: 16px;
`;

const TestingWrapper = styled.div`
  
`;

const TestingWrapperSecond = styled.div`
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 10px;
  }
`;

const TestingHeaderElement = styled.div`
  color: ${theme.colors.neutral7};
  font-size: ${theme.fontSizes.small};
  margin-bottom: 5px;
`;

const TestingMessageWrapper = styled.div`
  color: ${theme.colors.neutral10};
  font-size: ${theme.fontSizes.body};
`;

export default Testing;
