import {makeAutoObservable} from "mobx";
import {createContext} from "react";
import {LIMIT_DEFAULT, PAGE_DEFAULT} from "../config/consts";
import cloneDeep from "lodash/cloneDeep";

class candidateFormsFilters {
  constructor() {
    makeAutoObservable(this);
  };

  defaultFilterValues = {
    country: [],
    partner: [],
    restaurant_id: [],
    candidate_profile_status_id: [],
    refuse: null,
    dateRange: { from: null, to: null },
    ageFromTo: { from: "", to: "" },
    citizenship_id: [],
    vacancy: "",
    interviewDate: { from: null, to: null },
  };

  currentPath = "";
  limit = LIMIT_DEFAULT;
  page = PAGE_DEFAULT;
  sortBy = "id";
  sortOrder = "descending";
  filters = cloneDeep(this.defaultFilterValues);

  setLimit = value => this.limit = value;

  setPage = value => this.page = value;

  setSortBy = value => this.sortBy = value;

  setSortOrder = value => this.sortOrder = value;

  setFilters = values => {
    this.filters = {
      country: values.country,
      partner: values.partner,
      restaurant_id: values.restaurant_id,
      candidate_profile_status_id: values.candidate_profile_status_id,
      refuse: values.refuse,
      dateRange: { from: values.dateRange.from, to: values.dateRange.to },
      age: values.age,
      ageFromTo: values.ageFromTo,
      citizenship_id: values.citizenship_id,
      vacancy: values.vacancy,
      interviewDate: values.interviewDate,
    }
  }

  setCurrentPath = value => this.currentPath = value;

  setCountriesFilters = values => this.filters.country = values;

  setPartnersFilters = values => this.filters.partner = values;

  setRestaurantFilters = values => this.filters.restaurant_id = values;

  setStatusFilters = values => this.filters.candidate_profile_status_id = values;

  setCitizenshipFilters = values => this.filters.citizenship_id = values;

  setDefaultSortBy = () => {
    this.sortBy = "id";
  }

  setDefaultSortOrder = () => {
    this.sortOrder = "descending";
  }

  setDefaultFilter = () => {
    this.filters = cloneDeep(this.defaultFilterValues);
  }

  setAllDefault = () => {
    this.page = PAGE_DEFAULT;
    this.limit = LIMIT_DEFAULT;
    this.setDefaultSortBy();
    this.setDefaultSortOrder();
    this.setDefaultFilter();
  }
}

export const candidateFormsFiltersStore = new candidateFormsFilters();
export const candidateFormsFiltersStoreContext = createContext(candidateFormsFiltersStore);