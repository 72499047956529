import styled from "styled-components";
import {theme} from "./theme";

export const Cell = styled.div<{setFlex?: string, minWidth?: string,}>`
  display: flex;
  flex-direction: column;
  flex: ${({setFlex}) => setFlex ? setFlex : '0 1 auto'};
  word-break: break-all;
  ${({minWidth}) => minWidth && 'min-width: ' + minWidth};
`;

export const GridRowWrapper = styled.div<{
  hoverColor?: string,
  noCursorPointer?: boolean,
}>`
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 10px;
  border-bottom: 1px solid ${theme.colors.neutral2};
  position: relative;
  ${({noCursorPointer}) => !noCursorPointer && 'cursor: pointer'};

  .cell {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
  }

  .cell-align-bottom {
    justify-content: flex-end;
  }

  .cell-row:not(:last-child) {
    margin-bottom: 10px;
  }

  .icon {
    opacity: 0;
    position: absolute;
    top: 8px;
    right: 16px;
    cursor: pointer;
    min-width: 24px;
    
    @media (hover: none) {
      opacity: 1;
    }
  }

  &:hover {
    background: ${props => props.hoverColor ? props.hoverColor : theme.colors.neutral2};

    .icon {
      opacity: 1;
    }
  }
`;

export const FieldLabelWrapper = styled.div`
  font-family: ${theme.fonts.mainRegular};
  color: ${theme.colors.neutral7};
  font-size: ${theme.fontSizes.small};
  margin-bottom: 5px;
`;

export const FieldValueWrapper = styled.div`
  font-family: ${theme.fonts.mainRegular};
  color: ${theme.colors.bodyText};
  font-size: ${theme.fontSizes.body};
`;

export const FieldValueBoldWrapper = styled.div`
  font-family: ${theme.fonts.mainBlack};
  color: ${theme.colors.bodyText};
  font-size: ${theme.fontSizes.title};
`;

export const FieldValueGreenWrapper = styled.div`
  font-family: ${theme.fonts.mainRegular};
  color: ${theme.colors.green};
  font-size: ${theme.fontSizes.body};
`;

export const FieldValueRedWrapper = styled.div`
  font-family: ${theme.fonts.mainRegular};
  color: ${theme.colors.primary};
  font-size: ${theme.fontSizes.body};
`;

export const RowIdWrapper = styled.div`
  font-family: ${theme.fonts.condensedRegular};
  color: ${theme.colors.neutral5};
  font-size: ${theme.fontSizes.title};
`;

export const Block = styled.div`
  display: flex;
  gap: 10px;
`;