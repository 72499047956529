import {ReactElement, ReactNode, FC} from "react";
import styled from "styled-components";

interface Props {
  children: ReactElement | ReactNode;
  className?: string,
}

const ContentWrapper: FC<Props> = ({
  children,
  className,
                                   }) => {
  return (
    <Wrapper className={'grid-content-wrapper ' + (className ? className : '')}>
      {children}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: #ffffff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
`;

export default ContentWrapper;