import {observer} from "mobx-react-lite";
import React, {useCallback, useContext, useEffect, useState} from 'react';
import styled from "styled-components";
import Header from "../../components/Header";
import {theme} from "../../styles/theme";
import Button from "../../components/Button";
import Table from "../../components/Table";
import ratesTableData from "../../assets/Plr/rates/ratesTableData.json";
import ratesTableFields from "../../assets/Plr/rates/ratesTableFields";
import ratesTableSubtitles from "../../assets/Plr/rates/ratesTableSubtitles.json";
import factorsTableFields from "../../assets/Plr/factors/factorsTableFields";
import factorsTableData from "../../assets/Plr/factors/factorsTableData.json";
import needTableFields from "../../assets/Plr/need/needTableFields";
import needTableData from "../../assets/Plr/need/needTableData.json";
import {useFetching} from "../../hooks/useFetching";
import {
    createRates,
    editRates,
    getRates,
    getEffectiveProperties,
    createProperties,
    editProperties,
    getDemands, editDemands, createDemands
} from "../../actions/plr";
import {IndexToCurrentKey, IndexToPlannedKey, IndexToPersonnelKey, Rates} from "./Classes/Rates";
import moment from "moment";
import Datepicker from "../../components/Datepicker";
import {AuthStoreContext} from "../../stores/auth";
import {DirectoryStoreContext} from "../../stores/directory";
import Select from "../../components/Select";
import _orderBy from "lodash/orderBy";
import _get from "lodash/get";
import {EffectiveProperties, IndexToKey, KeyToType, IndexToKeySetter} from "./Classes/EffectiveProperties";
import {Demands, IndexToKeyDemands} from "./Classes/Demands";
import {
    createCandidateFormsOrder,
    getCandidateFormsOrder,
    putCandidateFormsOrder
} from "../../actions/candidateFormsOrder";
import {LIMIT_DEFAULT, PAGE_DEFAULT} from "../../config/consts";

//region Styled
const BlockWrapper = styled.div<{marginBottom?: string}>`
  width: 100%;
  margin-bottom: ${({marginBottom}) => marginBottom};
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
    
  @media (max-width: 900px) {
      overflow: auto;
      flex-shrink: 0;
  }
`;

const HeaderBlockWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
    
  @media (max-width: 500px) {
      flex-wrap: wrap;
      gap: 16px;
  }
`;

const TitleHeaderBlockWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const TitleHeaderBlock = styled.div`
  margin-right: 16px;
    font-family: ${theme.fonts.mainBlack};
  font-size: ${theme.fontSizes.h2};
  line-height: 27px;
  color: ${theme.colors.bodyText};
`;

const DatepickerWrapper = styled.div`
  width: 169px;
`;

const NeedTableWrapper = styled.div`
  width: 450px;
`;

const MsgWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background: rgba(3, 173, 0, 0.1);
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 16px;
`;

const MsgHeader = styled.p`
  line-height: 25px;
  font-family: ${theme.fonts.mainBlack};
  font-size: ${theme.fontSizes.h3};
  color: ${theme.colors.bodyText};
`;

const MsgText = styled.p`
    line-height: 20px;
    font-family: ${theme.fonts.mainRegular};
    font-size: ${theme.fontSizes.body};
    color: ${theme.colors.bodyText};
    margin-top: 10px;
`;

//endregion

const COUNTRY_BELARUS_ID = 6;

export type TRestOrder = {
    id: number
    needed_for_month: string
    requirement_la_re: number
    restaurant_id: number
}

const Plr = observer(() => {
    const [selectMonth, setSelectMonth] = useState(moment().startOf('month'));
    const [rates, setRates] = useState<Rates>();
    const [demands, setDemands] = useState<Demands>();
    const [changedInput, setChangeInput] = useState<boolean>(false);
    const [effectivePropertiesList, setEffectivePropertiesList] = useState<EffectiveProperties[]>([]);
    const [ratesForTable, setRatesForTable] = useState(ratesTableData);
    const { isSuperAdmin, userRestaurants, isHrPartner } = useContext(AuthStoreContext);
    const {restaurants} = useContext(DirectoryStoreContext);

    const getDefaultRestaurant = () => {
        if (isSuperAdmin) {
            if (restaurants.length > 0) {
                return restaurants[0].id;
            }
        }
        else if(userRestaurants.length > 0) {
            return userRestaurants[0].id;
        }
        return "";
    };

    const [restaurantId, setRestaurantId] = useState<string>(getDefaultRestaurant());

    let restaurantOptions = restaurants.map(i => ({value: i.id, label: i.name_ru}));
    let selectRestaurant = restaurants.find(i => i.id === restaurantId);

    useEffect(() => {
        if (restaurants.length > 0) {
            setRestaurantId(getDefaultRestaurant());
        }
    }, [restaurants]);

    const loadData = () => {
        fetchRates()
            .then(fetchEffectiveProperties)
            .then(fetchDemands);
    };

    useEffect(() => {
        loadData();
    }, [selectMonth, restaurantId]);

    const [fetchRates] = useFetching(async () => {
        const result = await getRates(1, 1, selectMonth.format("YYYY-MM-DD"), restaurantId);
        let ratesResult;
        if (result) {
            ratesResult = new Rates(result);
        }
        else {
            ratesResult = new Rates(null);
        }
        setRates(ratesResult);
        calculateRatesTable(ratesResult);
    });

    const [fetchEffectiveProperties] = useFetching(async () => {
        const result = await getEffectiveProperties(3, 1, selectMonth, restaurantId);
        let list = result.map(i => new EffectiveProperties(i));
        const newMonth = moment(selectMonth);
        for (let i = 0; i < 3; i++){
            newMonth.add(1, "months");
            if (!list.find(i => i.firstMonth.format("MM.YYYY") === newMonth.format("MM.YYYY"))) {
                const newProperties = new EffectiveProperties({
                    restaurant_id: restaurantId,
                    first_month: newMonth.format("YYYY-MM-DD")
                });
                newProperties.fillMonths();
                list.push(newProperties)
            }
        }
        setEffectivePropertiesList(_orderBy(list, i => i.firstMonth.utc()));
    });

    const [fetchDemands] = useFetching(async () => {
        const result = await getDemands(1, 1, selectMonth.format("YYYY-MM-DD"), restaurantId);
        let demandsResult;
        if (result) {
            demandsResult = new Demands(result);
        }
        else {
            demandsResult = new Demands(null);
        }
        setDemands(demandsResult);
    });

    const [restaurantOrderData, setRestaurantOrderData] = useState<TRestOrder>();
    const [fetchRestaurantOrder] = useFetching(async () => {
        const month = moment(selectMonth).add(1, "month").format("YYYY-MM-DD");
        const response = await getCandidateFormsOrder(LIMIT_DEFAULT, PAGE_DEFAULT, restaurantId, month);
        setRestaurantOrderData(response.data[0]);
    });

    const setSelectMonthHandler = (date) => setSelectMonth(date.startOf('month'));

    useEffect(() => {
        if(restaurantId) {
            fetchRestaurantOrder();
        }
    }, [restaurantId, selectMonth])

    const saveRatesAndProperties = async () => {
        setChangeInput(false);

        const promises = [];
        if (rates.id) {
            //Редактирование существующей ставки
            const data = {...rates};
            delete data.id;
            promises.push(editRates(rates.id, data));
        }
        else {
            //Создание новой
            const data = {
                ...rates,
                restaurant_id: restaurantId,
                month_rates_plr: selectMonth.format("YYYY-MM")
            };
            delete data.id;
            promises.push(createRates(data));
        }

        effectivePropertiesList.forEach(pr => {
            const data = pr.dataForSave;
            if (pr.id) {
                promises.push(editProperties(pr.id, data));
            }
            else {
                delete data.id;
                promises.push(createProperties(data));
            }
        });

        if (restaurantOrderData) {
            promises.push(putCandidateFormsOrder(restaurantOrderData.id, {
                needed_for_month: restaurantOrderData.needed_for_month,
                requirement_la_re: effectivePropertiesList[0].need_for_people,
                restaurant_id: restaurantOrderData.restaurant_id
            }))
        } else {

            promises.push(createCandidateFormsOrder({
                needed_for_month: moment(selectMonth).add(1, "month").format("YYYY-MM-DD"),
                requirement_la_re: effectivePropertiesList[0].need_for_people,
                restaurant_id: restaurantId
            }))
        }

        Promise.all(promises).then(response => {
            let isEverythingOk = true;
            response.forEach(el => {
                if(el?.code !== 200) isEverythingOk = false;
            })

            if(isEverythingOk) loadData();
        });
    };

    const saveDemands = async () => {
        if (demands.id) {
            //Редактирование существующей потребности
            const data = {...demands};
            delete data.id;
            await editDemands(demands.id, data);
        }
        else {
            //Создание новой
            const data = {
                ...demands,
                restaurant_id: restaurantId,
                month_demand_plr: selectMonth.format("YYYY-MM")
            };
            delete data.id;
            await createDemands(data);
        }
        loadData();
    };

    const canSaveChanges = () => {
        if (isSuperAdmin || isHrPartner) {
            return true;
        }
        return moment().isBefore(moment("26-" + selectMonth.format("MM-YYYY" + " 00:00:00"), "DD-MM-YYYY HH:mm:ss"));
    };

    const calculateRatesTable = useCallback((rates) => {
        const newArray = ratesForTable.map((item, i) => {
            item.countNow = rates[IndexToCurrentKey[i]];
            item.summaryNow = item.hourNow * (isNaN(item.countNow) ? 0 : item.countNow);
            item.countPlan = rates[IndexToPlannedKey[i]];
            item.summaryPlan = item.hourPlan * (isNaN(item.countPlan) ? 0 : item.countPlan);
            item.countReserve = rates[IndexToPersonnelKey[i]];
            item.summaryReserve = item.hourReserve * (isNaN(item.countReserve) ? 0 : item.countReserve);
            return item;
        });
        setRatesForTable(newArray);
    }, [ratesForTable]);

    const onInputRatesTableChange = useCallback((value, field, rowIndex) => {
        if(!changedInput) setChangeInput(true);

        if (parseInt(value) < 0)
            return;

        const newRates = new Rates(rates);
        switch(field) {
            case "countNow":
                newRates[IndexToCurrentKey[rowIndex]] = parseInt(value);
                break;
            case "countPlan":
                newRates[IndexToPlannedKey[rowIndex]] = parseInt(value);
                break;
            case "countReserve":
                newRates[IndexToPersonnelKey[rowIndex]] = parseInt(value);
                break;
        }
        setRates(newRates);
        calculateRatesTable(newRates);
        calculateEffectiveResults();
    }, [rates, effectivePropertiesList]);

    const onInputTablePropertiesChange = useCallback((value, field, rowIndex) => {
        if(!changedInput) setChangeInput(true);

        if (parseInt(value) < 0)
            return;

        const indexObj = field === "month1" ? 0 : field === "month2" ? 1 : 2;
        const newPropertiesList = [...effectivePropertiesList];
        newPropertiesList[indexObj][IndexToKeySetter[rowIndex]] = value;

        setEffectivePropertiesList(newPropertiesList);

    }, [effectivePropertiesList]);

    const onInputTableDemandsChange = useCallback((value, field, rowIndex) => {
        if (parseInt(value) < 0)
            return;

        const newDemands = new Demands(demands);
        newDemands.changeValue([IndexToKeyDemands[rowIndex]], value);

        setDemands(newDemands);

    }, [demands]);

    const calculateEffectiveResults = () => {
        let countNowAll = 0,
            countPlanAll = 0,
            countReserveAll = 0,
            summaryNowAll = 0,
            summaryPlanAll = 0,
            summaryReserveAll = 0;

        ratesForTable.forEach(i => {
            countNowAll += i.countNow;
            summaryNowAll += i.summaryNow;
            countPlanAll += i.countPlan;
            summaryPlanAll += i.summaryPlan;
            countReserveAll += i.countReserve;
            summaryReserveAll += i.summaryReserve;
        });

        if (effectivePropertiesList.length > 0) {
            const newList = effectivePropertiesList;
            newList[0].hour_average_load = Math.round(summaryNowAll / countNowAll) || 0;
            // newList[1].hour_average_load = Math.round(summaryNowAll / countNowAll);
            // newList[2].hour_average_load = Math.round(summaryNowAll / countNowAll);

            newList[0].number_of_employees_in_hours = summaryNowAll;
            //newList[1].number_of_employees_in_hours = summaryNowAll;
            //newList[2].number_of_employees_in_hours = summaryNowAll;

            newList[0].number_of_employees_people = Math.round(summaryNowAll / 168);
            //newList[1].number_of_employees_people = Math.round(summaryNowAll / 168);
            //newList[2].number_of_employees_people = Math.round(summaryNowAll / 168);

            newList[0].planned_turnover_in_hours = summaryPlanAll;
            //newList[1].planned_turnover_in_hours = summaryPlanAll;
            //newList[2].planned_turnover_in_hours = summaryPlanAll;

            newList[0].planned_turnover_people = Math.round(summaryPlanAll / 168);
            //newList[1].planned_turnover_people = Math.round(summaryPlanAll / 168);
            //newList[2].planned_turnover_people = Math.round(summaryPlanAll / 168);

            newList[0].personnel_reserve_in_hours = summaryReserveAll;
            //newList[1].personnel_reserve_in_hours = summaryReserveAll;
            //newList[2].personnel_reserve_in_hours = summaryReserveAll;

            newList[0].personnel_reserve_people = Math.round(summaryReserveAll / 168);
            //newList[1].personnel_reserve_people = Math.round(summaryReserveAll / 168);
            //newList[2].personnel_reserve_people = Math.round(summaryReserveAll / 168);

            newList[0].calculateResult();
            newList[1].calculateResult();
            newList[2].calculateResult();

            setEffectivePropertiesList(newList);
        }
    };

    const calculateRatesSummary = () => {
        let countNowAll = 0,
            countPlanAll = 0,
            countReserveAll = 0,
            summaryNowAll = 0,
            summaryPlanAll = 0,
            summaryReserveAll = 0;

        ratesForTable.forEach(i => {
            countNowAll += (isNaN(i.countNow) ? 0 : i.countNow);
            summaryNowAll += (isNaN(i.summaryNow) ? 0 : i.summaryNow);
            countPlanAll += (isNaN(i.countPlan) ? 0 : i.countPlan);
            summaryPlanAll += (isNaN(i.summaryPlan) ? 0 : i.summaryPlan);
            countReserveAll += (isNaN(i.countReserve) ? 0 : i.countReserve);
            summaryReserveAll += (isNaN(i.summaryReserve) ? 0 : i.summaryReserve);
        });

        return [
            [
                {
                    title: "Всего",
                    colSpan: 2
                },
                {
                    title: countNowAll,
                    colSpan: 1
                },
                {
                    title: summaryNowAll,
                    colSpan: 1,
                    textBold: true,
                    needBorderRight: true,
                },
                {
                    title: "Всего",
                    colSpan: 2
                },
                {
                    title: countPlanAll,
                    colSpan: 1
                },
                {
                    title: summaryPlanAll,
                    colSpan: 1,
                    textBold: true,
                    needBorderRight: true,
                },
                {
                    title: "Всего",
                    colSpan: 2
                },
                {
                    title: countReserveAll,
                    colSpan: 1
                },
                {
                    title: summaryReserveAll,
                    colSpan: 1,
                    textBold: true,
                }
            ],
            [
                {
                    title: "Итого чел.",
                    colSpan: 2,
                    textBold: true,
                },
                {
                    title: Math.round(summaryNowAll / 168),
                    colSpan: 2,
                    textBold: true,
                    needBorderRight: true,
                },
                {
                    title: "Итого чел.",
                    colSpan: 2,
                    textBold: true,
                },
                {
                    title: Math.round(summaryPlanAll / 168),
                    colSpan: 2,
                    textBold: true,
                    needBorderRight: true,
                },
                {
                    title: "Итого чел.",
                    colSpan: 2,
                    textBold: true,
                },
                {
                    title: Math.round(summaryReserveAll / 168),
                    colSpan: 2,
                    textBold: true,
                },
            ]
        ];
    };

    const getLeftAction = () => {
        if (isSuperAdmin) {
            return <Select title="Ресторан"
                           width={"300px"}
                           value={restaurantId}
                           withSearch={true}
                           onChange={setRestaurantId}
                           options={restaurantOptions}/>
        }
        if (userRestaurants.length > 1) {
            restaurantOptions = restaurantOptions.filter(i => userRestaurants.find(j => j.id === i.value));
            return <Select title="Ресторан"
                           width={"300px"}
                           value={restaurantId}
                           withSearch={true}
                           onChange={setRestaurantId}
                           options={restaurantOptions}/>
        }
        return null;
    };

    const getHeader = () => {
        if (!isSuperAdmin && userRestaurants.length === 1) {
            const restaurant = restaurantOptions.find(i => userRestaurants[0].id === i.value);
            return restaurant?.label;
        }
        return "";
    };

    const getTablePropertiesData = () => {
        if (effectivePropertiesList.length === 0)
            return [];

        return factorsTableData.map((row, rowIndex) => {
            return {
                ...row,
                month1: effectivePropertiesList[0][IndexToKey[rowIndex]],
                month1Type: KeyToType[IndexToKey[rowIndex]],
                month2: effectivePropertiesList[1][IndexToKey[rowIndex]],
                month2Type: KeyToType[IndexToKey[rowIndex]],
                month3: effectivePropertiesList[2][IndexToKey[rowIndex]],
                month3Type: KeyToType[IndexToKey[rowIndex]],
            }
        })
    };

    const getTableDemandsData = () => {
        if (!demands)
            return [];

        return needTableData.map((row, rowIndex) => {
            return {
                ...row,
                need_hour: demands[IndexToKeyDemands[rowIndex]],
                need_people: Math.ceil(demands[IndexToKeyDemands[rowIndex]] / 168),
            }
        })
    };

    const getSummaryTableDemands = () => {
        if (!demands)
            return [];

        return [
            [
                {
                    title: "Итого",
                    colSpan: 1
                },
                {
                    title: demands.total_hours,
                    colSpan: 1,
                    color: demands.total_hours !== _get(effectivePropertiesList, "0.need_for_hours") ?
                        theme.colors.primary :
                        null
                },
                {
                    title: demands.total_people,
                    colSpan: 1
                },
            ]
        ];
    };

    return (
        <>
            <Header title={`ПЛР ${getHeader()}`}
                    leftActions={getLeftAction()}/>
            <MsgWrapper>
                <MsgHeader>Внимание!</MsgHeader>
                <MsgText>
                    Уважаемые коллеги, внесение изменение данных доступно исключительно с 1 по 25 число.
                    При заполнении плана необходимо учитывать товарооборот следующего месяца (например,
                    для января – товарооборот февраля).
                </MsgText>
            </MsgWrapper>
            <BlockWrapper marginBottom={"24px"}>
                <HeaderBlockWrapper>
                    <TitleHeaderBlockWrapper>
                        <TitleHeaderBlock>Ставки</TitleHeaderBlock>
                        <DatepickerWrapper>
                            <Datepicker value={selectMonth}
                                        format={"MM.yyyy"}
                                        title={"Месяц и год"}
                                        monthSelect
                                        onChange={setSelectMonthHandler}/>
                        </DatepickerWrapper>
                    </TitleHeaderBlockWrapper>
                    <Button
                        title="Сохранить"
                        disabled={!changedInput || !canSaveChanges()}
                        btnStyle={'primary'}
                        onClick={saveRatesAndProperties}
                    />
                </HeaderBlockWrapper>
                <Table
                    data={ratesForTable}
                    fields={ratesTableFields}
                    headerSubtitles={ratesTableSubtitles}
                    summaryRows={calculateRatesSummary()}
                    onInputTableChange={onInputRatesTableChange}
                    margin={"0 0 16px 0"}
                    textAlignBody={"center"}
                    textAlignHeader={"center"}
                    inputTextAlign={"center"}
                    classNameBody={"body-row"}
                />
                <Table
                    data={getTablePropertiesData()}
                    onInputTableChange={onInputTablePropertiesChange}
                    fields={factorsTableFields(effectivePropertiesList)}
                    inputTextAlign={"right"}
                    textAlignBody={"right"}
                    textAlignHeader={"right"}
                    textAlignBodyTitle={"left"}
                    needBorderRight={true}
                />
            </BlockWrapper>
            {
                _get(selectRestaurant, "country_id") === COUNTRY_BELARUS_ID &&
                <BlockWrapper marginBottom={"26px"}>
                    <HeaderBlockWrapper>
                        <TitleHeaderBlockWrapper>
                            <TitleHeaderBlock>Потребность</TitleHeaderBlock>
                        </TitleHeaderBlockWrapper>
                        <Button
                            title="Сохранить план"
                            btnStyle={'primary'}
                            disabled={!canSaveChanges()}
                            onClick={saveDemands}
                        />
                    </HeaderBlockWrapper>
                    <NeedTableWrapper>
                        <Table
                            data={getTableDemandsData()}
                            fields={needTableFields}
                            summaryRows={getSummaryTableDemands()}
                            onInputTableChange={onInputTableDemandsChange}
                        />
                    </NeedTableWrapper>
                </BlockWrapper>
            }
        </>
    );
});

export default Plr;
