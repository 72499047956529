import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { observer } from "mobx-react-lite";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {Controller, useForm} from "react-hook-form";
import ReactTooltip from "react-tooltip";
import styled from "styled-components";
import * as yup from "yup";
import AddIcon from "../../../assets/icons/AddIcon";
import FilterIcon from "../../../assets/icons/FilterIcon";
import sortOrderSelectOptions from "../../../assets/sortOrderSelectOptions.json";
import sortBySelectOptions from "../../../assets/CandidateForms/RowsSection/sortBySelectOptions.json";
import {getCandidates, getStatistics} from "../../../actions/candidates";
import ActionIcon from "../../../components/ActionIcon";
import Filters from "../../../components/Filters";
import Header from "../../../components/Header";
import { Input } from "../../../components/Input";
import Rangepicker from "../../../components/Rangepicker";
import Select from "../../../components/Select";
import { CANDIDATE_FORMS_ROWS_FILTER_ID as FILTER_ID } from "../../../config/filters";
import { useFetching } from "../../../hooks/useFetching";
import { FiltersStoreContext } from "../../../stores/filters";
import { AuthStoreContext } from "../../../stores/auth";
import { DirectoryStoreContext } from "../../../stores/directory";
import debounce from "lodash/debounce";
import QueryStatsIcon from "../../../assets/icons/QueryStatsIcon";
import Statistics from "./Statistics";
import { useHistory } from "react-router-dom";
import { privateRoutes } from "../../../config/routes";
import Grid from "../../../components/Grid";
import GridRowOperator from "./Operator/GridRow";
import GridRowAdmin from "./Admin/GridRow";
import moment from "moment";
import {candidateFormsFiltersStoreContext} from "../../../stores/candidateFormsFilters";
import FileDownloadIcon from "../../../assets/icons/FileDownloadIcon";
import {saveAs} from 'file-saver';
import MultiSelect from "../../../components/MultiSelect";
import cloneDeep from "lodash/cloneDeep";
import {PAGE_DEFAULT} from "../../../config/consts";
import {useUpdateProfile} from "../../../hooks/useUpdateProfile";
import {useUpdateForm} from "../../../hooks/useUpdateForm";
import {useMemoFilteredRestaurants} from "../../../hooks/useMemoFilteredRestaurants";
import {useMemoRestaurantOption} from "../../../hooks/useMemoRestaurantOption";
import Toast from "../../../components/Toast";
import {toast} from "react-toastify";
import DoneIcon from "../../../assets/icons/DoneIcon";
import {theme} from "../../../styles/theme";
import initializeMultiselectOptions from "../../../utils/initializeMultiselectOptions";
import initializeMultiselectValue from "../../../utils/initializeMultiselectValue";
import {useMemoRejectOptions} from "../../../hooks/useMemoRejectOptions";

const schema = yup.object().shape({
  search: yup.string(),
  sortBy: yup.string(),
  sortOrder: yup.string(),
  filters: yup.object().shape({
    restaurant_id: yup.string(),
    candidate_profile_status_id: yup.string(),
    dateRange: yup.object(),
    ageFromTo: yup.object(),
    citizenship_id: yup.string(),
    vacancy: yup.string(),
  }),
});

const RowsSection = observer(() => {
  const { userData, isCallCenterOperator } = useContext(AuthStoreContext);
  useUpdateProfile();

  const history = useHistory();
  const [candidatesData, setCandidatesData] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [statisticsOn, setStatisticsOn] = useState(false);
  const [statisticsData, setStatisticsData] = useState(null);
  const filtersIconRef = useRef(null);
  const { filtersData, filtersIconClickHandle, closeFiltersHandle } =
    useContext(FiltersStoreContext);
  const {
    partners,
    restaurants,
    citizenship,
    candidateProfileStatuses,
    countries ,
    rejects
  } = useContext(
    DirectoryStoreContext
  );
  const {vacancies} = useContext(DirectoryStoreContext);

  const {
    limit,
    page,
    currentPath,
    sortBy,
    sortOrder,
    defaultFilterValues,
    filters,
    setLimit,
    setPage,
    setSortBy,
    setSortOrder,
    setFilters,
    setCurrentPath,
    setCountriesFilters,
    setPartnersFilters,
    setRestaurantFilters,
    setStatusFilters,
    setCitizenshipFilters,
  } = useContext(candidateFormsFiltersStoreContext);

  const filteredRestaurants = useMemoFilteredRestaurants(userData, restaurants);

  const { control, getValues, setValue, reset } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      search: "",
      sortBy,
      sortOrder,
      filters: defaultFilterValues,
    },
  });

  const [fetchStatistics, statisticsIsLoading, statisticsError] = useFetching(async () => {
    const isChk = window.location.pathname === privateRoutes.candidateFormsChk.path;

    const filterOptionLengths = {
      restaurantLength: filteredRestaurants.length,
      statusLength: checkedStatuses.length,
      citizenshipLength: checkedCitizenship.length,
    }

    const response = await getStatistics(filters, isChk, filterOptionLengths);
    setStatisticsData(response.data.statistic);
  });

  const [fetchCandidates, isLoading, error] = useFetching(async (data) => {
    const { limit, page, order, search, filters, filterOptionLengths } = data;
    const isChk =
      window.location.pathname === privateRoutes.candidateFormsChk.path;
    const response = await getCandidates(limit, page, order, search, {
      ...filters,
      candidate_profile_type: isChk ? "restaurant_employee" : "shift_manager",
    }, filterOptionLengths);
    setCandidatesData(response);
  });

  useEffect(() => {
    if(currentPath !== window.location.pathname) {
      setCurrentPath(window.location.pathname);
      return;
    }

    let order = null;
    if (sortBy) {
      const orderValue = `${sortOrder === "ascending" ? "" : "-"}${sortBy}`
      order = [orderValue];
    }
    const search = searchValue;

    const filterOptionLengths = {
      countryLength: filteredCountries.length,
      partnerLength: filteredPartners.length,
      restaurantLength: filteredRestaurants.length,
      statusLength: checkedStatuses.length,
      citizenshipLength: checkedCitizenship.length,
    }

    fetchCandidates({ limit, page, order, search, filters, filterOptionLengths });
  }, [limit, page, searchValue, sortBy, sortOrder, filters, currentPath]);

  useUpdateForm(setValue, {searchValue, sortBy, sortOrder, filters});

  const restaurantOptions = useMemoRestaurantOption();

  const sortBySelectChangeHandle = value => setSortBy(value);

  const sortOrderSelectChangeHandle = value => setSortOrder(value);

  const resetFilters = () => {
    const filters = cloneDeep(defaultFilterValues);
    filters.restaurant_id = filteredRestaurants;
    filters.country = filteredCountries;
    filters.partner = filteredPartners;
    filters.candidate_profile_status_id = checkedStatuses;
    filters.citizenship_id = checkedCitizenship;

    reset({
      search: getValues().search,
      sortBy: getValues().sortBy,
      sortOrder: getValues().sortOrder,
      filters: filters,
    });
  };

  const applyFilters = () => {
    const filters = getValues().filters;
    setFilters(filters);
    setPage(PAGE_DEFAULT);
    setSearchValue("");
    setStatisticsOn(false);
  }

  const citizenshipOptions = useMemo(() => {
    return citizenship.map((i) => ({
    value: i.id,
    label: i.name,
  }))},[citizenship]);

  const checkedCitizenship = useMemo(() => {
    return citizenship.map(item => item.id)
  }, [citizenship])

  const statusesOptions = useMemo(() => {
    if(userData.role.name === "call_center_operator") {
      return candidateProfileStatuses.filter(item =>
        item.status_module === "call_center_statuses" || item.status_module === "general_statuses")
        .map(item => ({
          value: item.id,
          label: item.name,
        }))
    }
    else if(userData.role.name === "restaurant" || userData.role.name === "controller" || userData.role.name === "hr_partner" || userData.role.name === "superadmin") {
      return candidateProfileStatuses.filter(item =>
          item.status_module === "general_statuses"  || item.status_module === "restaurant_statuses" || item.status_module === "reject_statuses")
          .map(item => ({
            value: item.id,
            label: item.name,
          }))
    }

    return candidateProfileStatuses.map((item) => ({
      value: item.id,
      label: item.name,
    }))
  }, [userData.role.name, candidateProfileStatuses])

  const checkedStatuses = useMemo(() => {
    return statusesOptions.map(status => status.value)
  }, [statusesOptions])

  const handlerSearch = debounce((search) => {
    setSearchValue(search);

    const filters = cloneDeep(defaultFilterValues);
    filters.restaurant_id = filteredRestaurants;
    setFilters(filters);

    setPage(PAGE_DEFAULT);
  }, 400);

  const rejectOptions = useMemoRejectOptions(rejects)

  const statisticsOnHandle = () => {
    setStatisticsOn(!statisticsOn);
  }

  useEffect(() => {
    if(statisticsOn) fetchStatistics();
  }, [statisticsOn])

  const exportIconClickHandle = useCallback(async () => {
    let exportParams = [
      {column: "id", alias: "ID анкеты"},
      {column: "created_at", alias: "Дата создания"},
      {column: "updated_at", alias: "Дата обновления"},
      {column: "city.name_ru", alias: "Город"},
      {column: "restaurant.name_ru", alias: "Ресторан"},
      {column: "vacancy.name_ru", alias: "Вакансия"},
      {column: "auto_redial_status", alias: "Статус автообзвона"},
      {column: "candidateProfileStatus.name", alias: "Статус анкеты"},
      {column: "candidateProfileStatusDetail.name", alias: "Уточнение"},
      {column: "candidateProfileReject.name", alias: "Причина отказа"},
      {column: "first_name", alias: "Имя"},
      {column: "second_name", alias: "Фамилия"},
      {column: "age", alias: "Возраст"},
      {column: "citizenship.name", alias: "Гражданство"},
      {column: "phone", alias: "Телефон"},
      {column: "email", alias: "Email"},
      {column: "sex", alias: "Пол"},
      {column: "deleted_at", alias: "Дата удаления"},
      {column: "source.name", alias: "Источник"},
      {column: "sourceResource.name", alias: "Ресурс"},
      {column: "source_resource_comment", alias: "Ресурс источника Другое"},
    ];

    if(userData.role?.name === "superadmin") {
      exportParams = [
        ...exportParams,
        {column: "source_marker", alias: "source_marker"},
        {column: "created_by_role_name", alias: "Роль пользователя, добавившего анкету"},
        {column: "utm_source", alias: "utm_source"},
        {column: "utm_medium", alias: "utm_medium"},
        {column: "utm_campaign", alias: "utm_campaign"},
        {column: "utm_term", alias: "utm_term"},
        {column: "click_id", alias: "click_id"},
        {column: "restaurant_boss_code", alias: "boss_code"},
        {column: "partner.name", alias: "Партнёр"},
      ]
    }

    if(userData.role?.name === "controller") {
      exportParams = [
        ...exportParams,
        {column: "partner.name", alias: "Партнёр"},
      ]
    }

    if(!filters.dateRange?.from || !filters.dateRange?.to) {
      toast(
        "Вы не указали диапазон дат, поэтому данные будут сформированы за последний месяц",
        {
          icon: <DoneIcon
            width="24"
            height="24"
            fill="#03AD00"
          />,
          style: {
            ...theme.toast.commonParams,
            ...theme.toast.successParams
          }
        }
      );
    }

    let orderValue = `${getValues().sortOrder === "ascending" ? "" : "-"}${
      getValues().sortBy
    }`;
    let search = getValues().search;
    let order = null;
    if (orderValue) {
      order = [orderValue];
    }

    const isChk = window.location.pathname === privateRoutes.candidateFormsChk.path;

    const response = await getCandidates(limit, page, order, search, {
      ...filters,
      candidate_profile_type: isChk ? "restaurant_employee" : "shift_manager",
    }, {
      countryLength: filteredCountries.length,
      partnerLength: filteredPartners.length,
      restaurantLength: filteredRestaurants.length,
      statusLength: checkedStatuses.length,
      citizenshipLength: checkedCitizenship.length,
    }, exportParams);

    const data = new Blob([response], {type: "xlsx"});
    saveAs(data, "candidateProfiles.xlsx");
  }, [limit, page, restaurants, filters]);

  const countriesOptions = countries.map(i => ({value: i.id, label: i.name_ru}));

  const filteredCountries = useMemo(() => {
    if(userData.role.name === "superadmin") {
      return initializeMultiselectValue(countries, "id");
    } else {
      return  [];
    }
  }, [countries])

  const partnersOptions = initializeMultiselectOptions(partners, "id", "name", false);

  const filteredPartners = useMemo(() => {
    if(userData.role.name === "superadmin") {
      return initializeMultiselectValue(partners, "id");
    } else {
      return  [];
    }
  }, [partners])

  useEffect(() => {
    if(!filters.country.length)
      setCountriesFilters(filteredCountries);
    if(!filters.partner.length)
      setPartnersFilters(filteredPartners);
    if(!filters.restaurant_id.length)
      setRestaurantFilters(filteredRestaurants);
    if(!filters.candidate_profile_status_id.length)
      setStatusFilters(checkedStatuses)
    if(!filters.citizenship_id.length)
      setCitizenshipFilters(checkedCitizenship)
  }, [filteredCountries, filteredPartners, filteredRestaurants, checkedStatuses, checkedCitizenship]);

  return (
    <>
      <Header
        leftActions={
          <ActionIcon
            action={() => {
              history.push({
                pathname:
                  privateRoutes.candidateFormItem.alias + "newcandidate",
                state: {
                  isChk: window.location.pathname === privateRoutes.candidateFormsChk.path,
                  goBackPath: window.location.pathname
                },
              });
              ReactTooltip.hide();
            }}
            tooltip="Добавить новую анкету"
          >
            <AddIcon />
          </ActionIcon>
        }
        rightActions={
          <RightActions>

            <ActionIcon action={exportIconClickHandle} tooltip={"Экспорт в файл"}>
              <FileDownloadIcon />
            </ActionIcon>

            <SearchWrapper>
              <Controller
                render={({ field, fieldState }) => (
                  <Input
                    {...field}
                    {...fieldState}
                    onChange={(value) => {
                      handlerSearch(value);
                      field.onChange(value);
                    }}
                    placeholder="Поиск..."
                    search={true}
                    maxLength={200}
                    ref={null}
                  />
                )}
                name="search"
                control={control}
              />
            </SearchWrapper>

            <HeaderSelectWrapper>
              <Controller
                render={({ field, fieldState }) => (
                  <Select
                    {...field}
                    {...fieldState}
                    title="Сортировать по..."
                    options={sortBySelectOptions}
                    onChange={sortBySelectChangeHandle}
                    ref={null}
                  />
                )}
                name="sortBy"
                control={control}
              />
            </HeaderSelectWrapper>

            <HeaderSelectWrapper>
              <Controller
                render={({ field, fieldState }) => (
                  <Select
                    {...field}
                    {...fieldState}
                    title="Порядок сортировки"
                    options={sortOrderSelectOptions}
                    onChange={sortOrderSelectChangeHandle}
                    ref={null}
                  />
                )}
                name="sortOrder"
                control={control}
              />
            </HeaderSelectWrapper>
            {!isCallCenterOperator && (
              <ActionIcon
                active={statisticsOn}
                action={statisticsOnHandle}
              >
                <QueryStatsIcon />
              </ActionIcon>
            )}
            <FiltersBtn ref={filtersIconRef}>
              <ActionIcon
                action={() => {
                  filtersIconClickHandle(FILTER_ID, filtersIconRef);
                }}
                active={filtersData[FILTER_ID]?.open}
                disabled={!partnersOptions.length ||
                  !filteredRestaurants.length ||
                  !restaurantOptions.length ||
                  !checkedStatuses.length ||
                  !statusesOptions.length ||
                  !checkedCitizenship.length ||
                  !citizenshipOptions.length
              }
                tooltip="Фильтры"
              >
                <FilterIcon />
              </ActionIcon>

              {filtersData[FILTER_ID]?.open && (
                <Filters
                  onReset={resetFilters}
                  btnRef={filtersIconRef}
                  onApply={() => {
                    applyFilters();
                    closeFiltersHandle(null, FILTER_ID, filtersIconRef);
                  }}
                  onClose={(event) => {
                    closeFiltersHandle(event, FILTER_ID, filtersIconRef);
                  }}
                >
                  {userData.role.name === "superadmin" &&
                    <>
                      <FilterRowWrapper>
                        <Controller
                          render={({field, fieldState}) =>
                            <MultiSelect
                              {...field}
                              {...fieldState}
                              title="Страна"
                              options={countriesOptions}
                              withSubOptions={false}
                              ref={null}
                            />
                          }
                          name="filters.country"
                          control={control}
                        />
                      </FilterRowWrapper>

                      <FilterRowWrapper>
                        <Controller
                          render={({field, fieldState}) =>
                            <MultiSelect
                              {...field}
                              {...fieldState}
                              title="Партнер"
                              options={partnersOptions}
                              withSubOptions={false}
                              ref={null}
                            />
                          }
                          name="filters.partner"
                          control={control}
                        />
                      </FilterRowWrapper>
                    </>
                  }

                  {userData.role.name !== "restaurant" &&
                    <FilterRowWrapper>
                      <Controller
                        render={({ field, fieldState }) => (
                          <MultiSelect
                            {...field}
                            {...fieldState}
                            title="Ресторан"
                            options={restaurantOptions}
                            withSubOptions={true}
                            subOptionsKey="restaurants_minimal"
                            ref={null}
                          />
                        )}
                        name="filters.restaurant_id"
                        control={control}
                      />
                    </FilterRowWrapper>
                  }

                  <FilterRowWrapper>
                    <Controller
                      render={({ field, fieldState }) => (
                        <MultiSelect
                          {...field}
                          {...fieldState}
                          title="Статус анкеты"
                          withSubOptions={false}
                          options={statusesOptions}
                          ref={null}
                        />
                      )}
                      name="filters.candidate_profile_status_id"
                      control={control}
                    />
                  </FilterRowWrapper>

                  <FilterRowWrapper>
                    <Controller
                      render={({ field, fieldState }) => (
                        <Select
                          {...field}
                          {...fieldState}
                          title="Причина отказа"
                          options={rejectOptions}
                          ref={null}
                        />
                      )}
                      name="filters.refuse"
                      control={control}
                    />
                  </FilterRowWrapper>

                  <FilterRowWrapper>
                    <Controller
                      render={({ field, fieldState }) => (
                        <Rangepicker
                          startDate={field.value.from
                            ? moment(field.value.from, "YYYY-MM-DD HH:mm:ss")
                            : null
                          }
                          endDate={field.value.to
                            ? moment(field.value.to, "YYYY-MM-DD HH:mm:ss")
                            : null
                          }
                          onChange={(from, to) =>
                            field.onChange({from, to})}
                          title="С - по"
                        />
                      )}
                      name="filters.dateRange"
                      control={control}
                    />
                  </FilterRowWrapper>

                  <SelectAgeWrapper>
                    <Controller
                      render={({ field, fieldState }) => (
                        <Input
                          {...field}
                          {...fieldState}
                          title="Возраст от"
                          placeholder="Возраст от"
                          type="number"
                          positive={true}
                          ref={null}
                        />
                      )}
                      name="filters.ageFromTo.from"
                      control={control}
                    />
                    <Controller
                      render={({ field, fieldState }) => (
                        <Input
                          {...field}
                          {...fieldState}
                          title="Возраст до"
                          placeholder="Возраст до"
                          type="number"
                          positive={true}
                          ref={null}
                        />
                      )}
                      name="filters.ageFromTo.to"
                      control={control}
                    />
                  </SelectAgeWrapper>

                  <FilterRowWrapper>
                    <Controller
                      render={({ field, fieldState }) => (
                        <MultiSelect
                          {...field}
                          {...fieldState}
                          title="Гражданство"
                          withSubOptions={false}
                          options={citizenshipOptions}
                          ref={null}
                        />
                      )}
                      name="filters.citizenship_id"
                      control={control}
                    />
                  </FilterRowWrapper>

                  <FilterRowWrapper>
                    <Controller
                      render={({ field, fieldState }) => (
                        <Select
                          {...field}
                          {...fieldState}
                          title="Вакансия"
                          options={vacancies.map((item) => ({
                            label: item.name,
                            value: item.id,
                          }))}
                          ref={null}
                        />
                      )}
                      name="filters.vacancy"
                      control={control}
                    />
                  </FilterRowWrapper>

                  <FilterRowWrapper>
                    <Controller
                      render={({ field, fieldState }) => (
                        <Rangepicker
                          startDate={field.value.from
                            ? moment(field.value.from, "YYYY-MM-DD HH:mm:ss")
                            : null
                          }
                          endDate={field.value.to
                            ? moment(field.value.to, "YYYY-MM-DD HH:mm:ss")
                            : null
                          }
                          onChange={(from, to) =>
                            field.onChange({from, to})}
                          title="Дата собеседования"
                        />
                      )}
                      name="filters.interviewDate"
                      control={control}
                    />
                  </FilterRowWrapper>
                </Filters>
              )}
            </FiltersBtn>
          </RightActions>
        }
        title={
          window.location.pathname === privateRoutes.candidateFormsChk.path
            ? "Анкеты кандидатов ЧК"
            : "Анкеты кандидатов МС"
        }
      />

      <GridWrapper>
        <Wrapper>
          <>
            <Grid
              data={candidatesData}
              isLoading={isLoading}
              error={error}
              gridRow={isCallCenterOperator ? GridRowOperator : GridRowAdmin}
              onRowClick={(data) => {
                history.push({
                  pathname:
                    privateRoutes.candidateFormItem.alias + `${data.id}`,
                  state: {
                    isChk: window.location.pathname === privateRoutes.candidateFormsChk.path,
                    goBackPath: window.location.pathname
                  },
                });
              }}
              setPage={setPage}
              setLimit={setLimit}
            />
          </>
        </Wrapper>

        {statisticsOn &&
          <Statistics
            data={statisticsData}
            isLoading={statisticsIsLoading}
          />
        }
      </GridWrapper>
      <Toast/>
    </>
  );
});

const FiltersBtn = styled.div`
  position: relative;
`;

const SelectAgeWrapper = styled.div`
  display: flex;
  &:not(:last-child) {
    margin-bottom: 15px;
  }
`;

const SearchWrapper = styled.div`
  max-width: 200px;

  @media (max-width: 1122px) {
    max-width: 100%;
  }
`;

const RightActions = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 1;

  @media (max-width: 1122px) {
    & > div {
      &:first-child {
        flex-grow: 1;
      }
      &:nth-child(2),
      &:nth-child(3) {
        flex-shrink: 0;
      }
    }
  }

  @media (max-width: 700px) {
    flex-wrap: wrap;
    justify-content: flex-start;
    & > div {
      &:first-child {
        width: 100%;
      }
    }
  }
`;

const Wrapper = styled.div`
  width: 100%;

  @media (max-width: 700px) {
    order: 2;
  }
`;

const GridWrapper = styled.div`
  display: flex;
  height: calc(100% - 96px);

  @media (max-width: 1122px) {
    height: calc(100% - 160px);
  }

  @media (max-width: 700px) {
    height: calc(100% - 220px);
    flex-direction: column;
  }

  @media (max-width: 550px) {
    height: auto;
  }
`;

const HeaderSelectWrapper = styled.div`
  width: 180px;
`;

const FilterRowWrapper = styled.div`
  &:not(:last-child) {
    margin-bottom: 15px;
  }
`;

export default RowsSection;
