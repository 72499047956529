import React, { useCallback, useContext, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Router } from "react-router-dom";
import Loader from "./components/Loader";
import PrivateRoutes from "./routes/privateRoutes";
import { history } from "./routes/history";
import PublicRoutes from "./routes/publicRoutes";
import { AuthStoreContext } from "./stores/auth";
import { DirectoryStoreContext } from "./stores/directory";
import styled from "styled-components";
import { APP_VERSION } from "./config/consts";

const App = observer(() => {
  const { userData, isAuth, authPending, checkAuthKeycloak } = useContext(AuthStoreContext);
  const {
    setRestaurants,
    setVacancies,
    setTariffs,
    setCities,
    setCountries,
    setCitizenship,
    setRoles,
    setPartners,
    setCandidateProfileStatuses,
    setSources,
    setRejects,
    setStatusDetail,
  } = useContext(DirectoryStoreContext);

  useEffect(() => {
    checkAuthKeycloak();
  }, []);

  useEffect(() => {
    if (isAuth) {
      setVacancies();
      setTariffs();
      setCities();
      setCountries();
      setCitizenship();
      setPartners();
      setCandidateProfileStatuses();
      setSources();
      setRejects();
      setStatusDetail();

      if(userData.role?.name === "superadmin") {
        setRoles();
      }
    }
  }, [isAuth]);

  useEffect(() => {
    if(isAuth) {
      setRestaurants();
    }
  }, [isAuth, userData.role?.name])

  const getRoutes = useCallback(() => {
    if (isAuth) {
      return <PrivateRoutes />;
    } else {
      return <PublicRoutes />;
    }
  }, [isAuth]);

  return (
    <>
      {authPending ? (
        <Loader width={100} height={100} />
      ) : (
        <Router history={history}>{getRoutes()}</Router>
      )}
      <Version>{APP_VERSION}</Version>
    </>
  );
});

const Version = styled.div`
  position: fixed;
  bottom: 10px;
  right: 10px;
  color: #616161;
  font-size: 12px;
  font-family: "CeraPro-Black, sans-serif";
`;

export default App;
