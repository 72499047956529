const MATERIALS_VALUE = "1";
const TRANSPORT_VALUE = "2";
const POST_BOX_VALUE = "3";
const ENTRANCES_VALUE = "4";
const PROMOTER_VALUE = "5";
const MAGAZINE_VALUE = "6";
const ELEVATORS_VALUE = "7";
const BUS_STOP_VALUE = "8";
const OTHER_VALUE = "9";

const DEFAULT_RESULT_VALUE = {
    [MATERIALS_VALUE]: [],
    [TRANSPORT_VALUE]: [],
    [POST_BOX_VALUE]: [],
    [ENTRANCES_VALUE]: [],
    [BUS_STOP_VALUE]: [],
    [ELEVATORS_VALUE]: [],
    [PROMOTER_VALUE]: [],
    [MAGAZINE_VALUE]: [],
    [OTHER_VALUE]: [],
};

const DEFAULT_FROM_VALUE = {
    type: null,
    restaurant: null,
    month: null,
    [MATERIALS_VALUE]: {
        type: null,
        size: null,
        count: "",
        comment: "",
    },
    [BUS_STOP_VALUE]: {
        address: "",
        comment: "",
    },
    [ELEVATORS_VALUE]: {
        address: "",
        comment: "",
    },
    [MAGAZINE_VALUE]: {
        name: "",
        comment: "",
    },
    [TRANSPORT_VALUE]: {
        numbers: "",
        comment: "",
    },
    [POST_BOX_VALUE]: {
        address: "",
        comment: "",
    },
    [ENTRANCES_VALUE]: {
        address: "",
        comment: "",
    },
    [PROMOTER_VALUE]: {
        dotes: "",
        comment: "",
    },
    [OTHER_VALUE]: {
        comment: "",
    },
};

interface ItemOfflineAdv {
    id: string;
    restaurant?: {label: string, value: string};
    type?: {label: string, value: string};
    size?: {label: string, value: string};
    count?: string;
    comment?: string;
    address?: string;
    name?: string;
    numbers?: string;
    dotes?: string;
    advertisingMonth?: string;
}

interface ISelectOption {
    label: string;
    value: string;
}

export {
    OTHER_VALUE,
    MAGAZINE_VALUE,
    PROMOTER_VALUE,
    POST_BOX_VALUE,
    TRANSPORT_VALUE,
    MATERIALS_VALUE,
    DEFAULT_RESULT_VALUE,
    DEFAULT_FROM_VALUE,
    BUS_STOP_VALUE,
    ELEVATORS_VALUE,
    ENTRANCES_VALUE,
};

export type { ItemOfflineAdv, ISelectOption };

