import React, {useRef} from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from "highcharts-react-official";
import styled from "styled-components";
import moment from "moment";

const Chart = (props: HighchartsReact.Props) => {
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);

  const categories = Object.keys(props.data)
    .map(item => moment(item, 'YYYY-MM-DD HH:mm:ss').format('DD.MM'));

  const seriesData = Object.keys(props.data).map((key, index) => {
      return {
        name: categories[index],
        y: props.data[key],
        color: '#CED4DA'
      }
    })

  const chartOption: Highcharts.Options = {
    title: {
      text: 'Количество анкет за последние 7 дней',
      style: {
        fontFamily: 'CeraPro-Black',
        fontSize: '22px',
        color: '#272B30',
      },
    },

    legend: {
      enabled: false // отключает нижнюю подпись
    },

    credits: {
      enabled: false // отключает подпись в правом нижнем углу
    },

    tooltip: {
      useHTML: true,
      backgroundColor: '#4F575E',
      borderWidth: 0,
      shadow: false,
      followPointer: true,
      shared: true,
      style: {
        color: '#FFFFFF',
      },
      headerFormat: '',
      pointFormat: '<div style="color: #FFFFFF; font-size: 14px; line-height: 14px; padding: 0 8px;">{point.y}</div>',
      footerFormat: '',
    },

    xAxis: {
      // categories: ['25.01', '26.01', '27.01', '28.01', '29.01', '30.01', '31.01']
      categories: categories
    },

    yAxis: {
      title: {
        text: 'Кол-во анкет'
      },
      gridLineWidth: 1,
      gridLineColor: '#F1F3F5'
    },

    series: [
      {
        type: 'column',
        name: 'statistic',
        pointWidth: 51,
        states: {
          hover: {color: '#A0001E'}
        },
        data: seriesData
      },
    ],
  }

  return (
    <ChartWrapper>
      <ChartContent>
        <HighchartsReact
          highcharts={Highcharts}
          options={chartOption}
          ref={chartComponentRef}
          {...props}
        />
      </ChartContent>
    </ChartWrapper>
  );
};

const ChartContent = styled.div`
  width: 100%;
  
  @media (max-width: 500px) {
    overflow: auto;
  }
`;

const ChartWrapper = styled.div`
  width: 578px;
  background-color: #FFFFFF;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 8px 16px 8px 0;
  
  @media (max-width: 600px) {
    padding: 8px 0;
    width: 100%;
  }
`;

export default Chart;
