import React, {FC, memo, useCallback, useContext} from "react";
import styled from "styled-components";
import {
    Cell, GridRowWrapper, FieldLabelWrapper, FieldValueWrapper, FieldValueBoldWrapper,
    RowIdWrapper, Block, FieldValueGreenWrapper, FieldValueRedWrapper
} from "../../../../styles/gridRowDefaults";
import {theme} from "../../../../styles/theme";
import _get from "lodash/get";
import moment from "moment/moment";
import {formatPhoneNumber} from "../../../../utils/formatPhoneNumber";
import {AuthStoreContext} from "../../../../stores/auth";

interface Props {
    data: {
        id: string;
        first_name: string;
        second_name: string;
        candidate_profile_status: {
            name: string;
            is_reject: boolean
        };
        interview: {
            auto_dialing_status: string;
            interview_date: string
        }
        candidate_interview: {
            created_at: string;
            created_by: string;
            name: string;
            id: string;
        };
        is_auto_dialing: boolean;
        city: {
            name: string;
        };
        restaurant: {
            name: string;
            tariff_id: number;
        };
        age: string;
        condition: string;
        citizenship: {
            name: string;
        };
        reject_reason: string;
    };
    onRowClick?: (data: object) => void;
}

const GridRow: FC<Props> = memo(({
    data,
    onRowClick
}) => {
    const {userData} = useContext(AuthStoreContext);

    // ### получать цвет из данных анкеты
    const getColoredStatusValue = useCallback((data) => {
        if (data.reject_reason) {
            return (
                <FieldValueRedWrapper>
                    {data.candidate_profile_status.name}
                </FieldValueRedWrapper>
            );
        } else {
            return (
                <FieldValueGreenWrapper>
                    {data.candidate_profile_status.name}
                </FieldValueGreenWrapper>
            );
        }
    }, []);
    
    const getColoredStateValue = useCallback(() => {
        switch (data.condition) {
            case "free":
                return <StateValueWrapper state="free">Свободна</StateValueWrapper>;
            case "archived":
                return <StateValueWrapper state="archived">В архиве</StateValueWrapper>;
            case "in_work":
                return <StateValueWrapper state="in_work">В работе</StateValueWrapper>;
        }
    }, []);
    
    const getAutoCallText = (data) => {
        return !!data.auto_redial_status
          ? data.auto_redial_status
          : "-";
    };
    
    const convertDate = (date) => {
        if (date) {
            return moment(date, "YYYY-MM-DD HH:mm:ss").format("DD.MM.YYYY HH:mm");
        }
        return "-";
    };
    
    return (
        <GridRowWrapper
            hoverColor="rgba(228, 0, 43, 0.02)"
            onClick={() => onRowClick(data)}
        >
            <Block>
                <TopRowWrapper className="cell-row">
                    <TopRowLeftSide>
                        <RowIdWrapper>ID {data.id}</RowIdWrapper>
                        <FieldValueBoldWrapper>
                            {convertDate(_get(data, "created_at"))}
                        </FieldValueBoldWrapper>
                        
                        <FieldValueBoldWrapper>
                            {data.first_name + " " + (data.second_name || "")}
                        </FieldValueBoldWrapper>
                    </TopRowLeftSide>
                </TopRowWrapper>
                
                <div>{getColoredStateValue()}</div>
            </Block>
            
            <Block>
                <Cell setFlex={"0 0 30%"}>
                    <div className="cell-row">
                        <FieldLabelWrapper>Статус</FieldLabelWrapper>
                        {getColoredStatusValue(data)}
                    </div>

                    {(userData.is_access_to_auto_call_status ||
                        data.restaurant.tariff_id === 2 ||
                        data.restaurant.tariff_id === 3) &&
                        <div className="cell-row">
                            <FieldLabelWrapper>Статус авто-обзвона</FieldLabelWrapper>
                            <FieldValueWrapper>{getAutoCallText(data)}</FieldValueWrapper>
                        </div>
                    }
                </Cell>
                
                <Cell setFlex={"0 0 15%"}>
                    <div className="cell-row">
                        <FieldLabelWrapper>Город</FieldLabelWrapper>
                        <FieldValueWrapper>
                            {_get(data, "city.name_ru", "-")}
                        </FieldValueWrapper>
                    </div>
                    
                    <div className="cell-row">
                        <FieldLabelWrapper>Телефон</FieldLabelWrapper>
                        <FieldValueWrapper>{formatPhoneNumber(data, "phone")}</FieldValueWrapper>
                    </div>
                </Cell>
                
                <Cell setFlex={"0 0 20%"}>
                    <div className="cell-row">
                        <FieldLabelWrapper>Ресторан</FieldLabelWrapper>
                        <FieldValueWrapper>
                            {_get(data, "restaurant.name_ru", "-")}
                        </FieldValueWrapper>
                    </div>
                    
                    <div className="cell-row">
                        <FieldLabelWrapper>Дата интервью</FieldLabelWrapper>
                        <FieldValueWrapper>
                            {convertDate(_get(data, "interview.interview_date"))}
                        </FieldValueWrapper>
                    </div>
                </Cell>
                
                <Cell setFlex={"0 0 15%"}>
                    <div className="cell-row">
                        <FieldLabelWrapper>Возраст</FieldLabelWrapper>
                        <FieldValueWrapper>{data.age}</FieldValueWrapper>
                    </div>
                </Cell>
                
                <Cell>
                    <div className="cell-row">
                        <FieldLabelWrapper>Гражданство</FieldLabelWrapper>
                        <FieldValueWrapper>
                            {_get(data, "citizenship.name", "-")}
                        </FieldValueWrapper>
                    </div>
                </Cell>
            </Block>
        </GridRowWrapper>
    );
});

const TopRowWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TopRowLeftSide = styled.div`
  display: flex;
  gap: 15px;
`;

const StateValueWrapper = styled.div<{state: string}>`
  font-family: ${theme.fonts.mainRegular};
  color: ${props => props.state === "in_work" ? theme.colors.green :
          props.state === "archived" ? theme.colors.primary : theme.colors.neutral6};
  font-size: ${theme.fontSizes.body};
`;

export default GridRow;