import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import debounce from "lodash/debounce";
import React, {useContext, useEffect, useRef, useState, useCallback, useMemo} from "react";
import {observer} from "mobx-react-lite";
import {Controller, useForm} from "react-hook-form";
import styled from "styled-components";
import * as yup from "yup";
import FilterIcon from "../../assets/icons/FilterIcon";
import sortOrderSelectOptions from "../../assets/sortOrderSelectOptions.json";
import ActionIcon from "../../components/ActionIcon";
import Button from "../../components/Button";
import Filters from "../../components/Filters";
import Header from "../../components/Header";
import {Input} from "../../components/Input";
import MultiSelect from "../../components/MultiSelect";
import Select from "../../components/Select";
import {USER_MANAGEMENT_FILTER_ID as FILTER_ID} from "../../config/filters";
import {DirectoryStoreContext} from "../../stores/directory";
import {FiltersStoreContext} from "../../stores/filters";
import initializeMultiselectOptions from "../../utils/initializeMultiselectOptions";
import initializeMultiselectValue from "../../utils/initializeMultiselectValue";
import GridRow from "./GridRow";
import {getUsers} from "../../actions/users";
import {useFetching} from "../../hooks/useFetching";
import Grid from "../../components/Grid";
import {ModalsStore} from "../../stores/modal";
import UsersModal from "./UsersModal";
import sortBySelectOptions from "../../assets/UserManagement/sortBySelectOptions.json";
import {LIMIT_DEFAULT, PAGE_DEFAULT} from "../../config/consts";

const schema = yup.object().shape({
    search: yup.string(),
    sortBy: yup.string(),
    sortOrder: yup.string(),
    filters: yup.object().shape({
        role: yup.string(),
        partner: yup.array(),
    })
});

const UserManagement = observer(() => {
    const [usersData, setUsersData] = useState(null);
    const [limit, setLimit] = useState(LIMIT_DEFAULT);
    const [page, setPage] = useState(PAGE_DEFAULT);
    const [searchValue, setSearchValue] = useState("");
    const [sortByValue, setSortByValue] = useState("id");
    const [sortOrderValue, setSortOrderValue] = useState("descending");
    const [filtersValue, setFiltersValue] = useState(null);
    const filtersIconRef = useRef(null);
    const {filtersData, filtersIconClickHandle, closeFiltersHandle} = useContext(FiltersStoreContext);
    const {roles, partners} = useContext(DirectoryStoreContext);
    const partnersOptions = initializeMultiselectOptions(partners, "id", "name", false);
    const {control, getValues, reset, setValue} = useForm({
        mode: "onChange",
        resolver: yupResolver(schema),
        defaultValues: {
            search: "",
            sortBy: "id",
            sortOrder: "descending",
            filters: {
                role: "all",
                partner: [],
            }
        }
    });
    
    const [fetchUsers, isLoading, error] = useFetching(async () => {
        let sortBy;
        if (sortByValue === "id" || sortByValue === "created_at" || sortByValue === "full_name") {
            sortBy = sortByValue;
        } else {
            sortBy = sortByValue + ".name";
        }
        let order = sortOrderValue === "ascending" ? sortBy : "-" + sortBy;
        order = order === "-id" ? null : order;
        
        const response = await getUsers(limit, page, searchValue, order, filtersValue);
        setUsersData(response);
    });
    
    useEffect(() => {
        setValue("filters.partner", initializeMultiselectValue(partners, "id"));
    }, [partners]);
    
    useEffect(() => {
        fetchUsers();
    }, [limit, page, searchValue, sortByValue, sortOrderValue, filtersValue]);

    const rolesOption = useMemo(() => {
        const rolesOptions = [];
        rolesOptions.push({label: "Все", value: "all"});

        for(let role of roles)
            rolesOptions.push({label: role.role_name_ru, value: role.id});

        return rolesOptions;
    }, [roles]);
    
    const searchInputChangeHandle = debounce((search) => {
        setSearchValue(search);
    }, 400);
    
    const sortBySelectChangeHandle = useCallback((value) => {
        setSortByValue(value);
    }, []);
    
    const sortOrderSelectChangeHandle = useCallback((value) => {
        setSortOrderValue(value);
    }, []);
    
    const resetFilters = useCallback(() => {
        reset({
            search: getValues().search,
            sortBy: getValues().sortBy,
            sortOrder: getValues().sortOrder,
            filters: {
                role: "all",
                partner: initializeMultiselectValue(partners, "id"),
            }
        });
    }, [roles, partners]);
    
    const applyFilters = useCallback(() => {
        const filters = {...getValues().filters};
        
        if (filters.role === "all") {
            delete filters.role;
        }
        if (filters.partner.length === partners.length) {
            delete filters.partner;
        }
        
        setPage(1);
        setFiltersValue(filters);
    }, [partners, limit, page]);
    
    const openCreateUserModal = useCallback(() => {
        ModalsStore.showModal("user-management-modal", {row: null});
    }, []);
    
    const openEditUserModal = useCallback((row) => {
        ModalsStore.showModal("user-management-modal", {row});
    }, []);
    
    return (
        <>
            <Header
                rightActions={
                    <RightActions>
                        <Button title="Добавить пользователя" onClick={() => openCreateUserModal()}/>

                        <SearchWrapper>
                            <Controller
                                render={({field, fieldState}) =>
                                    <Input
                                        {...field}
                                        {...fieldState}
                                        onChange={(value) => {
                                            searchInputChangeHandle(value);
                                            field.onChange(value);
                                        }}
                                        placeholder="Поиск..."
                                        search={true}
                                        maxLength={200}
                                        ref={null}
                                    />
                                }
                                name="search"
                                control={control}
                            />
                        </SearchWrapper>
                        
                        <HeaderSelectWrapper>
                            <Controller
                                render={({field, fieldState}) =>
                                    <Select
                                        {...field}
                                        {...fieldState}
                                        onChange={(value) => {
                                            sortBySelectChangeHandle(value);
                                            field.onChange(value);
                                        }}
                                        title="Сортировать по..."
                                        options={sortBySelectOptions}
                                        ref={null}
                                    />
                                }
                                name="sortBy"
                                control={control}
                            />
                        </HeaderSelectWrapper>
                        
                        <HeaderSelectWrapper>
                            <Controller
                                render={({field, fieldState}) =>
                                    <Select
                                        {...field}
                                        {...fieldState}
                                        onChange={(value) => {
                                            sortOrderSelectChangeHandle(value);
                                            field.onChange(value);
                                        }}
                                        title="Порядок сортировки"
                                        options={sortOrderSelectOptions}
                                        ref={null}
                                    />
                                }
                                name="sortOrder"
                                control={control}
                            />
                        </HeaderSelectWrapper>
                        
                        <FiltersBtn ref={filtersIconRef}>
                            <ActionIcon
                                action={() => filtersIconClickHandle(FILTER_ID, filtersIconRef)}
                                active={filtersData[FILTER_ID]?.open}
                                tooltip="Фильтры"
                            >
                                <FilterIcon/>
                            </ActionIcon>
                            
                            {filtersData[FILTER_ID]?.open &&
                                <Filters
                                    onReset={resetFilters}
                                    btnRef={filtersIconRef}
                                    onApply={applyFilters}
                                    onClose={(event) => closeFiltersHandle(event, FILTER_ID, filtersIconRef)}
                                >
                                    <FilterRowWrapper>
                                        <Controller
                                            render={({field, fieldState}) =>
                                                <Select
                                                    {...field}
                                                    {...fieldState}
                                                    title="Роль"
                                                    options={rolesOption}
                                                    ref={null}
                                                />
                                            }
                                            name="filters.role"
                                            control={control}
                                        />
                                    </FilterRowWrapper>
                                    
                                    <FilterRowWrapper>
                                        <Controller
                                            render={({field, fieldState}) =>
                                                <MultiSelect
                                                    {...field}
                                                    {...fieldState}
                                                    title="Партнер"
                                                    options={partnersOptions}
                                                    withSubOptions={false}
                                                    ref={null}
                                                />
                                            }
                                            name="filters.partner"
                                            control={control}
                                        />
                                    </FilterRowWrapper>
                                </Filters>
                            }
                        </FiltersBtn>
                    </RightActions>
                }
                title="Список пользователей"
            />
            
            <Grid
                data={usersData}
                onRowClick={openEditUserModal}
                isLoading={isLoading}
                error={error}
                gridRow={GridRow}
                setPage={setPage}
                setLimit={setLimit}
            />

            <UsersModal
                onRequestFulfilled={() => fetchUsers()}
            />
        </>
    );
});

const FiltersBtn = styled.div`
  position: relative;
`;

const SearchWrapper = styled.div`
  max-width: 200px;

    @media (max-width: 1241px) {
        max-width: 100%;
    }
`;

const HeaderSelectWrapper = styled.div`
  width: 180px;
`;

const FilterRowWrapper = styled.div`
  &:not(:last-child) {
    margin-bottom: 15px;
  }
`;

const RightActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 1;
  gap: 16px;
    
  @media (max-width: 1241px) {
      &>div:nth-child(2) {
          flex-grow: 1;
      }
  }
    
  @media (max-width: 969px) {
    flex-wrap: wrap;
    justify-content: flex-start;
  }

    @media (max-width: 450px) {
        &>div,
        &>button {
            width: 100%;
        }
    }
`;

export default UserManagement;
