import React, {FC} from 'react';

interface Props {
    width?: string,
    height?: string,
    fill?: string
}

const ModeEditIcon: FC<Props> = ({
    width,
    height,
    fill
}) => {
    return (
        <svg
            width={width || "24"}
            height={height || "24"}
            fill="none"
        >
            <g
                clipPath="url(#a)"
                fill={fill || "#000"}
            >
                <path
                    d="M3 21h3.75L17.81 9.94l-3.75-3.75L3 17.25V21Zm2-2.92 9.06-9.06.92.92L5.92 19H5v-.92ZM18.37 3.29a.996.996 0 0 0-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83a.996.996 0 0 0 0-1.41l-2.34-2.34Z"
                />
            </g>
            <defs>
                <clipPath id="a">
                    <path fill="#fff" d="M0 0h24v24H0z" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default ModeEditIcon;