import moment from "moment";
import React, {memo, useCallback, useContext} from 'react';
import {useHistory} from "react-router-dom";
import styled from "styled-components";
import {privateRoutes} from "../../../config/routes";
import {AuthStoreContext} from "../../../stores/auth";
import {theme} from "../../../styles/theme";

const UpcomingInterviews = memo(() => {
    const {userData} = useContext(AuthStoreContext);
    const history = useHistory();
    
    const getInterviews = useCallback((dayOffset) => {
        if (userData.near_candidate_interview?.length > 0) {
            return userData.near_candidate_interview.filter(interview => {
                return moment(interview.interview_date, "YYYY-MM-DD")
                    .isSame(moment().add(dayOffset, "days").format("YYYY-MM-DD"));
            });
        } else {
            return [];
        }
    }, [userData]);
    
    return (
        <InterviewWrapper>
            <H2>Ближайшие собеседования</H2>
            
            <Columns>
                {["Сегодня", "Завтра", ""].map((day, index) =>
                    <Column key={day}>
                        <H3>{day} {moment().add(index, "days").format("DD.MM.YYYY")}</H3>
                        {getInterviews(index).length > 0 && (
                            <>
                                {getInterviews(index).map(interview =>
                                    <Interview
                                        key={interview.id}
                                        onClick={
                                            () => history.push({
                                                pathname: `${privateRoutes.candidateFormItem.alias}${interview.candidate_profile_id}`
                                            })
                                        }
                                    >
                                        <Time>
                                            {
                                                moment(interview.interview_date, "YYYY-MM-DD HH:mm:ss")
                                                    .format("HH:mm")
                                            }
                                        </Time>
                                        <Candidate>
                                            {interview.candidate_profile_first_name} {interview.candidate_profile_second_name}
                                        </Candidate>
                                    </Interview>
                                )}
                            </>
                        )}
                        {getInterviews(index).length === 0 && (
                            <TextWrapper>
                                Нет собеседований на этот день.
                            </TextWrapper>
                        )}
                    </Column>
                )}
            </Columns>
        </InterviewWrapper>
    );
});

const InterviewWrapper = styled.div`
  gap: 16px;
  padding: 24px 16px 32px;
  background-color: #ffffff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;

const Columns = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;

  @media (max-width: 690px) {
    flex-direction: column;
    gap: 16px;
  }
`;

const Column = styled.div`
  flex: 1 1 auto;

  @media (max-width: 690px) {
    flex: 1 1 100%;
  }
`;

const TextWrapper = styled.div`
  font-family: ${theme.fonts.mainRegular};
  font-size: ${theme.fontSizes.body};
`;

const H2 = styled.div`
  font-size: ${theme.fontSizes.h2};
  color: ${theme.colors.neutral10};
  font-family: ${theme.fonts.mainBlack};
  margin-bottom: 16px;
`;

const H3 = styled.div`
  font-size: ${theme.fontSizes.h3};
  color: ${theme.colors.neutral8};
  font-family: ${theme.fonts.mainBlack};
  margin-bottom: 10px;
`;

const Interview = styled.div<{onClick: () => void}>`
  padding: 8px;

  :not(:last-child) {
    margin-bottom: 10px;
  }
  
  &:hover {
    cursor: pointer;
    background: ${theme.colors.neutral2};
  }
`;

const Time = styled.div`
  font-size: ${theme.fontSizes.body};
  color: ${theme.colors.neutral7};
  font-family: ${theme.fonts.mainRegular};
  margin-bottom: 5px;
`;

const Candidate = styled.div`
  font-size: ${theme.fontSizes.body};
  color: ${theme.colors.bodyText};
  font-family: ${theme.fonts.mainRegular};
`;

export default UpcomingInterviews;