import * as yup from "yup";
import {errorsDescriptions} from "../../../config/errors";

const modalSchema = yup.object().shape({
    id: yup.number().nullable(),
    full_name: yup.string()
        .required(errorsDescriptions.required)
        .trim()
        .min(3, "Имя должно содержать не менее 3 символов")
        .max(255, "Имя может содержать не более 255 символов"),
    job_title: yup.string().required(errorsDescriptions.required),
    email: yup.string()
        .required(errorsDescriptions.required)
        .trim()
        .min(5, "Email должен содержать минимум 5 символов")
        .max(255, "Email может содержать максимум 255 символов")
        .matches(/^[_a-z\d-]+(\.[_a-z\d-]+)*@[a-z\d-]+(\.[a-z\d-]+)*(\.[a-z]{1,20})$/gi, errorsDescriptions.email),
    role_id: yup.number().required(),
    restaurant: yup.object().when("role_id", {
        is: 1, then: yup.object().shape({
            partner_id: yup.number().required(),
            restaurants_ids: yup.number().required(),
            is_access_to_auto_call_status: yup.boolean(),
            is_plr_access: yup.boolean(),
            is_candidate_profile_order_access: yup.boolean(),
            is_access_to_ordering_offline_advertising: yup.boolean()
        }), otherwise: yup.object().shape({
            partner_id: yup.number().nullable(),
            restaurants_ids: yup.number().nullable(),
            is_access_to_auto_call_status: yup.boolean(),
            is_plr_access: yup.boolean(),
            is_candidate_profile_order_access: yup.boolean(),
            is_access_to_ordering_offline_advertising: yup.boolean()
        })
    }),
    controller: yup.object().when("role_id", {
        is: 2, then: yup.object().shape({
            partner_id: yup.number().required(),
            restaurants_ids: yup.array().min(1),
            is_access_to_auto_call_status: yup.boolean(),
            is_plr_access: yup.boolean(),
            is_candidate_profile_order_access: yup.boolean(),
            is_access_to_ordering_offline_advertising: yup.boolean()
        }), otherwise: yup.object().shape({
            partner_id: yup.number().nullable(),
            restaurants_ids: yup.array(),
            is_access_to_auto_call_status: yup.boolean(),
            is_plr_access: yup.boolean(),
            is_candidate_profile_order_access: yup.boolean(),
            is_access_to_ordering_offline_advertising: yup.boolean()
        })
    }),
    hr_partner: yup.object().when("role_id", {
        is: 3, then: yup.object().shape({
            partner_id: yup.number().required(),
            is_access_to_auto_call_status: yup.boolean(),
            is_plr_access: yup.boolean(),
            is_candidate_profile_order_access: yup.boolean(),
            is_access_to_ordering_offline_advertising: yup.boolean()
        }), otherwise: yup.object().shape({
            partner_id: yup.number().nullable(),
            is_access_to_auto_call_status: yup.boolean(),
            is_plr_access: yup.boolean(),
            is_candidate_profile_order_access: yup.boolean(),
            is_access_to_ordering_offline_advertising: yup.boolean()
        })
    })
});

export default modalSchema;