import React, {FC, useCallback} from "react";
import {Input} from "../../components/Input";

interface Props {
    row: {
        [key: string]: string | number | boolean;
    };
    rowIndex: number;
    field: string;
    value: string | number;
    onChange: (value, field, rowIndex, row) => void;
}

const TableInput: FC<Props> = ({
    row,
    field,
    value,
    onChange,
    rowIndex
}) => {
    const inputChangeHandle = useCallback((newValue) => {
        onChange(newValue, field, rowIndex, row)
    }, [onChange, field, rowIndex, row]);

    return (
        <>
            <Input
                value={value}
                withCloseIcon={false}
                width={"110px"}
                height="24px"
                padding={"0 10px"}
                type={"number"}
                maxLength={10}
                onChange={(newValue) => inputChangeHandle(newValue)}
            />
        </>
    );
};

export default TableInput;
