import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import styled from "styled-components";
import {observer} from "mobx-react-lite";
import AnalyticFunnel from "./Funnel";
import AnalyticPieChart from "./AnalyticPieChart";
import AnalyticColumnChart from "./AnalyticColumnChart";
import Header from "../../components/Header";
import {ANALYTICS_FILTER_ID} from "../../config/filters";
import ActionIcon from "../../components/ActionIcon";
import FilterIcon from "../../assets/icons/FilterIcon";
import {FiltersStoreContext} from "../../stores/filters";
import Filters from "../../components/Filters";
import {Controller, useForm} from "react-hook-form";
import Rangepicker from "../../components/Rangepicker";
import moment from "moment/moment";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import * as yup from "yup";
import Select from "../../components/Select";
import {theme} from "../../styles/theme";
import TargetAudience from "./TargetAudience";
import {useFetching} from "../../hooks/useFetching";
import {getAnalyticReport, getAnalytics} from "../../actions/analytic";
import {DirectoryStoreContext} from "../../stores/directory";
import {Input} from "../../components/Input";
import _orderBy from "lodash/orderBy";
import Loader from "../../components/Loader";
import FileDownloadIcon from "../../assets/icons/FileDownloadIcon";
import {saveAs} from 'file-saver';
import {Gender} from "../../assets/CandidateForms/RowsSection/Enums";

const schema = yup.object().shape({
  filters: yup.object().shape({
    dateRange: yup.object(),
    country: yup.string(),
    city: yup.string(),
    restaurant: yup.string(),
    partner: yup.string(),
    tariff: yup.string(),
    vacancy: yup.string(),
    stage: yup.string(),
    status: yup.string(),
    source: yup.string(),
    sex: yup.string(),
    age: yup.string(),
    citizenship: yup.string()
  })
});

const Analytics = observer(() => {
  const [analyticsData, setAnalyticsData] = useState(null);
  const [citiesOptions, setCitiesOptions] = useState([]);
  const [restaurantOptions, setRestaurantOptions] = useState([]);
  const filtersIconRef = useRef(null);
  const {filtersData, filtersIconClickHandle, closeFiltersHandle} = useContext(FiltersStoreContext);

  const {
    control,
    getValues,
    setValue,
    reset,
    watch
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      filters: {
        dateRange: {
          from: moment().startOf('month').format("YYYY-MM-DD"),
          to: moment().format("YYYY-MM-DD")
        },
        country_id: null,
        city_id: null,
        restaurant_id: null,
        partner_id: null,
        tariff_id: null,
        vacancy_id: null,
        funnel_stage: null,
        candidate_profile_status_id: null,
        source_id: null,
        sex: null,
        age: null,
        citizenship_id: null,
      }
    }
  });

  const [fetchAnalytics, isLoading, error] = useFetching(async () => {
    const filters = getValues();
    const response = await getAnalytics(filters.filters);
    setAnalyticsData(response);
  });

  useEffect(() => {
    fetchAnalytics();
  }, []);

  const {
      citizenship,
      restaurants,
      candidateProfileStatuses,
      vacancies,
      countries,
      cities,
      partners,
      tariffs,
      sources,
  } = useContext(DirectoryStoreContext);

  const sourcesOptions = sources.map(i => ({value: i.id, label: i.name}));
  const tariffsOptions = tariffs.map(i => ({value: i.id, label: i.name}));
  const partnersOptions = partners.map(i => ({value: i.id, label: i.name}));
  const countriesOptions = countries.map(i => ({value: i.id, label: i.name_ru}));
  const vacanciesOptions = vacancies.map(i => ({value: i.id, label: i.name}));
  const citizenshipOptions = citizenship.map(i => ({value: i.id, label: i.name}));
  const candidateProfileStatusesOptions = candidateProfileStatuses.map(i => ({value: i.id, label: i.name}));

  const resetFilters = useCallback(() => {
    reset({
      filters: control._defaultValues.filters
    });
  }, []);

  const applyFilters = useCallback(fetchAnalytics, []);

  useEffect(() => {
    const list = cities.map(i => ({value: i.id, label: i.name_ru}));
    setCitiesOptions(_orderBy(list, i => i.label));
  }, [cities]);

  useEffect(() => {
    watch((value, { name, type }) => {
      if (name === "filters.country_id") {
        let _cities = cities
        .filter(i => i.country_id === value.filters.country_id)
        .map(i => ({value: i.id, label: i.name_ru}));
        setCitiesOptions(_orderBy(_cities, i => i.label));

        let _restaurants = restaurants
        .filter(i => i.country_id === value.filters.country_id)
        .map(i => ({value: i.id, label: i.name_ru}));
        setRestaurantOptions(_restaurants);
      }
      if (name === "filters.city_id") {
        let _restaurants = restaurants
        .filter(i => value.filters.country_id ? i.country_id === value.filters.country_id : true)
        .filter(i => i.city_id === value.filters.city_id)
        .map(i => ({value: i.id, label: i.name_ru}));
        setRestaurantOptions(_restaurants);
      }
    });
  }, [watch, cities, restaurants]);

  const exportIconClickHandle = async () => {
    const date = getValues("filters.dateRange");
    const requestDate = {
      date_from: date.from,
      date_to: date.to,
    }

    const filters = {...getValues("filters")};
    delete filters.dateRange

    const response = await getAnalyticReport({...filters, ...requestDate});
    const data = new Blob([response], {type: "xlsx"});
    saveAs(data, "analytic.xlsx");
  };

  return (
    <>
      <Header
        rightActions={
          <>
            <ActionIcon
              action={exportIconClickHandle}
              tooltip="Экспорт в файл"
            >
              <FileDownloadIcon/>
            </ActionIcon>
            <FiltersBtn ref={filtersIconRef}>
            <ActionIcon
              action={() => filtersIconClickHandle(ANALYTICS_FILTER_ID, filtersIconRef)}
              active={filtersData[ANALYTICS_FILTER_ID]?.open}
              tooltip="Фильтры"
            >
              <FilterIcon/>
            </ActionIcon>

            {filtersData[ANALYTICS_FILTER_ID]?.open && (
              <Filters
                onReset={resetFilters}
                btnRef={filtersIconRef}
                onApply={() => {
                  applyFilters();
                  closeFiltersHandle(null, ANALYTICS_FILTER_ID, filtersIconRef);
                }}
                onClose={(event) => closeFiltersHandle(event, ANALYTICS_FILTER_ID, filtersIconRef)}
              >
                <FilterRowWrapper>
                  <Controller
                    render={({field, fieldState}) =>
                      <Rangepicker
                        startDate={field.value.from
                          ? moment(field.value.from, "YYYY-MM-DD")
                          : null
                        }
                        endDate={field.value.to
                          ? moment(field.value.to, "YYYY-MM-DD")
                          : null
                        }
                        onChange={(from, to) =>
                          field.onChange({from, to})}
                        title="С - по"
                      />
                    }
                    name="filters.dateRange"
                    control={control}
                  />
                </FilterRowWrapper>

                <FilterRowWrapper>
                  <Controller
                    render={({field, fieldState}) =>
                      <Select
                        {...field}
                        {...fieldState}
                        title="Страна"
                        onChange={val => {
                          field.onChange(val);
                          setValue('filters.city_id', null);
                          setValue('filters.restaurant_id', null);
                        }}
                        options={countriesOptions}
                        ref={null}
                      />
                    }
                    name="filters.country_id"
                    control={control}
                  />
                </FilterRowWrapper>

                <FilterRowWrapper>
                  <Controller
                    render={({field, fieldState}) =>
                      <Select
                        {...field}
                        {...fieldState}
                        title="Город"
                        onChange={val => {
                          field.onChange(val);
                          setValue('filters.restaurant_id', null);
                        }}
                        withSearch
                        options={citiesOptions}
                        ref={null}
                      />
                    }
                    name="filters.city_id"
                    control={control}
                  />
                </FilterRowWrapper>

                <FilterRowWrapper>
                  <Controller
                    render={({field, fieldState}) =>
                      <Select
                        {...field}
                        {...fieldState}
                        title="Ресторан"
                        withSearch={true}
                        options={restaurantOptions}
                        ref={null}
                      />
                    }
                    name="filters.restaurant_id"
                    control={control}
                  />
                </FilterRowWrapper>

                <FilterRowWrapper>
                  <Controller
                    render={({field, fieldState}) =>
                      <Select
                        {...field}
                        {...fieldState}
                        title="Партнер"
                        options={partnersOptions}
                        ref={null}
                      />
                    }
                    name="filters.partner_id"
                    control={control}
                  />
                </FilterRowWrapper>

                <FilterRowWrapper>
                  <Controller
                    render={({field, fieldState}) =>
                      <Select
                        {...field}
                        {...fieldState}
                        title="Тариф"
                        options={tariffsOptions}
                        ref={null}
                      />
                    }
                    name="filters.tariff_id"
                    control={control}
                  />
                </FilterRowWrapper>

                <FilterRowWrapper>
                  <Controller
                    render={({field, fieldState}) =>
                      <Select
                        {...field}
                        {...fieldState}
                        title="Вакансия / Тип анкеты"
                        options={vacanciesOptions}
                        ref={null}
                      />
                    }
                    name="filters.vacancy_id"
                    control={control}
                  />
                </FilterRowWrapper>

                <FilterRowWrapper>
                  <Controller
                    render={({field, fieldState}) =>
                      <Select
                        {...field}
                        {...fieldState}
                        title="Источник анкеты"
                        options={sourcesOptions}
                        ref={null}
                      />
                    }
                    name="filters.source_id"
                    control={control}
                  />
                </FilterRowWrapper>

                <FilterRowWrapper>
                  <Controller
                    render={({field, fieldState}) =>
                      <Select
                        {...field}
                        {...fieldState}
                        title="Пол"
                        options={[
                          {label: Gender.male, value: Gender.male},
                          {label: Gender.female, value: Gender.female}
                        ]}
                        ref={null}
                      />
                    }
                    name="filters.sex"
                    control={control}
                  />
                </FilterRowWrapper>

                <FilterRowWrapper>
                  <Controller
                    render={({field, fieldState}) =>
                        <Input
                            {...field}
                            {...fieldState}
                            title="Возраст"
                            placeholder="Возраст"
                            type="number"
                            positive={true}
                            ref={null}
                        />
                    }
                    name="filters.age"
                    control={control}
                  />
                </FilterRowWrapper>

                <FilterRowWrapper>
                  <Controller
                    render={({field, fieldState}) =>
                      <Select
                        {...field}
                        {...fieldState}
                        title="Гражданство"
                        options={citizenshipOptions}
                        ref={null}
                      />
                    }
                    name="filters.citizenship_id"
                    control={control}
                  />
                </FilterRowWrapper>

                <FilterSubtitle>Источник подбора и целевая аудитория</FilterSubtitle>
                <FilterRowWrapper>
                  <Controller
                    render={({field, fieldState}) =>
                      <Select
                        {...field}
                        {...fieldState}
                        title="Этап воронки"
                        options={[
                          {label: "Первый", value: "first"},
                          {label: "Второй", value: "second"},
                          {label: "Третий", value: "third"},
                          {label: "Четвертый", value: "fourth"},
                          {label: "Пятый", value: "fifth"},
                          {label: "Шестой", value: "sixth"}
                        ]}
                        ref={null}
                      />
                    }
                    name="filters.funnel_stage"
                    control={control}
                  />
                </FilterRowWrapper>

                <FilterRowWrapper>
                  <Controller
                    render={({field, fieldState}) =>
                      <Select
                        {...field}
                        {...fieldState}
                        title="Статус анкеты"
                        options={candidateProfileStatusesOptions}
                        ref={null}
                      />
                    }
                    name="filters.candidate_profile_status_id"
                    control={control}
                  />
                </FilterRowWrapper>
              </Filters>
            )}
          </FiltersBtn>
          </>
        }
        title={"Аналитика"}
      />
      {isLoading
          ?
        <Loader width={70} height={70} />
          :
        <CardsWrapper>
          {!!analyticsData &&
            <>
              <Card>
                  <Title>Воронка подбора</Title>
                  <AnalyticFunnel total={analyticsData.data.total}
                                  data={analyticsData.data.funnel_stages}/>
              </Card>
              <Row>
                <Card>
                    <Title>Источники подбора</Title>
                    <AnalyticPieChart total={analyticsData.data.total}
                                      data={analyticsData.data.sources}/>
                </Card>
                <Card>
                    <Title>Целевая аудитория</Title>
                    <TargetAudience data={analyticsData.data}
                                    total={analyticsData.data.total}/>
                </Card>
              </Row>
              <Card>
                  <Title>Причины отказа</Title>
                  <AnalyticColumnChart data={analyticsData.data.failure}/>
              </Card>
            </>
          }
        </CardsWrapper>
      }
    </>
  );
});

const Card = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;


const Title = styled.div`
  font-family: ${theme.fonts.mainBlack};
  font-size: ${theme.fontSizes.h2};
  color: ${theme.colors.neutral10};
`;

const FilterSubtitle = styled.div`
  font-family: ${theme.fonts.mainBold};
  font-size: ${theme.fontSizes.body};
  color: ${theme.colors.neutral10};
  margin: 24px 0 16px 0;
`;

const Row = styled.div`
  display: flex;
  gap: 24px;

  &>div {
    width: calc(100% / 2);
  }

  @media (max-width: 1350px) {
    flex-direction: column;
    
    &>div {
      width: 100%;
    }
  }
  
  
`;

const CardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const FiltersBtn = styled.div`
  position: relative;
`;

const FilterRowWrapper = styled.div`
  &:not(:last-child) {
    margin-bottom: 15px;
  }
`;

export default Analytics;
