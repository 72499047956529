import React, {FC, memo} from "react";
import styled from "styled-components";
import {theme} from "../../styles/theme";

interface Props {
    left: {
        value: string,
        label: string
    };
    right: {
        value: string,
        label: string
    };
    value: string;
    ref?: any;
    onChange: (value: string) => void;
}

const Switcher: FC<Props> = memo(({
    left,
    right,
    value,
    onChange
}) => {
    return (
        <SwitcherWrapper>
            <SideWrapper
                active={left.value === value}
                onClick={() => onChange(left.value)}
            >
                {left.label}
            </SideWrapper>
            <SideWrapper
                active={right.value === value}
                onClick={() => onChange(right.value)}
            >
                {right.label}
            </SideWrapper>
        </SwitcherWrapper>
    );
});

const SwitcherWrapper = styled.div`
  width: 100%;
  display: flex;
  border: 1px solid ${theme.colors.neutral4};
  border-radius: 4px;
`;

const SideWrapper = styled.div<{active: boolean}>`
  width: 50%;
  padding: 16px;
  text-align: center;
  border-radius: 4px;
  background: ${props => props.active ? theme.colors.neutral10 : theme.colors.neutral1};
  color: ${props => props.active ? "white" : theme.colors.neutral10};
  cursor: pointer;
`;

export default Switcher;