const BASE_URL = process.env.REACT_APP_BASE_URL;

// auth
export const LOGOUT = `${BASE_URL}/api/v2.0/auth/logout-sso`;
export const GET_CAPTCHA = `${BASE_URL}/captcha/api`;
export const CAPTCHA_CHECK = `${BASE_URL}/api/v2.0/auth/captchaCheck`;
export const GET_USER_DATA = `${BASE_URL}/api/v2.0/auth/profile`;
export const REGISTRATION = `${BASE_URL}/api/v2.0/auth/registration`;

// restaurants
export const RESTAURANTS = `${BASE_URL}/api/v2.0/restaurants/search`;
export const RESTAURANT = `${BASE_URL}/api/v2.0/restaurants`;
export const RESTAURANT_NOTIFICATION = `${BASE_URL}/api/v2.0/restaurantNotificationSettingss/search`;
export const CREATE_NOTIFICATION = `${BASE_URL}/api/v2.0/restaurantNotificationSettingss`;

// ad companies
export const AD_COMPANIES = `${BASE_URL}/api/v2.0/advertisingCompanies`;

// offline advertising
export const OFFLINE_ADVERTISING = `${BASE_URL}/api/v2.0/offlineAdvertisingOrders`;
export const OFFLINE_ADVERTISING_TYPE = `${BASE_URL}/api/v2.0/offlineAdvertisingTypes/search`;
export const PRINTED_PRODUCT = `${BASE_URL}/api/v2.0/printedProducts/search`;
export const POSTER_SIZE = `${BASE_URL}/api/v2.0/posterSizes/search`;

// bloggers
export const BLOGGERS = `${BASE_URL}/api/v2.0/bloggers`;

//candidates
export const CANDIDATE_PROFILES = `${BASE_URL}/api/v2.0/candidateProfiles`;
export const CANDIDATE_PROFILES_HISTORY = `${BASE_URL}/api/v2.0/candidateProfileStatusHistories/search`;
export const CANDIDATE_STATISTICS = `${BASE_URL}/api/v2.0/candidateProfiles/list`;

//questionnaire
export const CANDIDATE_QUESTIONNAIRES = `${BASE_URL}/api/v2.0/questions/questionnaire`;
export const CANDIDATE_QUESTION_ANSWER = `${BASE_URL}/api/v2.0/candidateQuestionAnswers`;
export const QUESTION = `${BASE_URL}/api/v2.0/questions`;

// candidate profiles order
export const CANDIDATE_FORMS_ORDER = `${BASE_URL}/api/v2.0/candidateOrders`;
export const RESTAURANT_FORMS_ORDER = `${BASE_URL}/api/v2.0/restaurants/candidate-order`;
export const RESTAURANT_FORMS_ORDER_UPDATE = `${BASE_URL}/api/v2.0/restaurants`;

//comments
export const COMMENTS = `${BASE_URL}/api/v2.0/comments`;

//interviews
export const INTERVIEWS = `${BASE_URL}/api/v2.0/candidateInterviews`;

//plr
export const PLR_RATE = `${BASE_URL}/api/v2.0/plrRates`;
export const PLR_EFFECTIVE_PROPERTY = `${BASE_URL}/api/v2.0/plrEffectiveProperties`;
export const PLR_DEMANDS = `${BASE_URL}/api/v2.0/plrDemands`;

// users
export const USERS = `${BASE_URL}/api/v2.0/users`;

// directory
export const GET_CITIZENSHIP_LIST = `${BASE_URL}/api/v2.0/citizenships/search`;
export const GET_ALL_CITIES_LIST = `${BASE_URL}/api/v2.0/cities/selector`;
export const GET_ALL_RESTAURANTS_LIST = `${BASE_URL}/api/v2.0/restaurants/selector`;
export const GET_VACANCIES_LIST = `${BASE_URL}/api/v2.0/vacancies`;
export const GET_TARIFFS_LIST = `${BASE_URL}/api/v2.0/tariffs/search`;
export const GET_PARTNERS_LIST = `${BASE_URL}/api/v2.0/partners/search`;
export const GET_CANDIDATE_PROFILE_STATUSES_LIST = `${BASE_URL}/api/v2.0/candidateProfileStatuses/search`;
export const GET_SOURCES_LIST = `${BASE_URL}/api/v2.0/advertisingCompanies/search`;
export const GET_ROLES_LIST = `${BASE_URL}/api/v2.0/roles/search`;
export const GET_COUNTRIES_LIST = `${BASE_URL}/api/v2.0/countries`;
export const GET_REJECTS_LIST = `${BASE_URL}/api/v2.0/candidateProfileRejects/search`;
export const GET_STATUS_DETAILS_LIST = `${BASE_URL}/api/v2.0/candidateProfileStatusDetails/search`;
