import React, {FC} from 'react';
import {createPortal} from "react-dom";
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {theme} from "../../styles/theme";

interface Props {

}

const Toast: FC<Props> = ({}) => {
    return createPortal(
        <ToastContainer
            {...theme.toast.containerParams}
        />,
        document.getElementById("root")
    );
};

export default Toast;