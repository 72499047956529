import {useContext, useMemo} from "react";
import {AuthStoreContext} from "../stores/auth";
import {getRestaurantOptions} from "../utils/getRestaurantOptions";
import {DirectoryStoreContext} from "../stores/directory";


export const useMemoRestaurantOption = () => {
  const { userData } = useContext(AuthStoreContext);
  const { cities, restaurants } = useContext(DirectoryStoreContext);

  return useMemo(() => {
    let userRestaurants = [];
    if(userData.role?.name === "restaurant" || userData.role?.name === "controller") {
      userRestaurants = userData.restaurants;
    } else if(userData.role?.name === "call_center_operator") {
      userRestaurants = restaurants;
    } else if(userData.role?.name === "hr_partner") {
      userRestaurants = userData.partner_restaurants;
    }

    return getRestaurantOptions(userData.role?.name, userRestaurants, cities);
  }, [userData.role?.name, userData.partner_restaurants, userData.restaurants, cities, restaurants])
}