import React, {FC, MutableRefObject} from 'react';
import {Input} from "../Input";
import styled from "styled-components";
import {theme} from "../../styles/theme";
import {useOutsideClick} from "../../hooks/useOutsideClick";


interface Props {
  filterInputValue: string;
  selectWrapperRef: MutableRefObject<HTMLDivElement>;
  value?: string;
  optionsDirection?: "top" | "bottom";
  size?: "primary" | "small";
  withSearch?: boolean;
  filteredOptions?: any;
  onChangeInputHandle: (value: string) => void;
  optionSelectHandle: (value: string) => void;
  setOptionsOpen: (state: boolean) => void;
}

const OptionsBlock:FC<Props> = ({
  filterInputValue,
  selectWrapperRef,
  value,
  optionsDirection,
  size,
  withSearch,
  filteredOptions,
  onChangeInputHandle,
  optionSelectHandle,
  setOptionsOpen,
}) => {
  useOutsideClick(selectWrapperRef, () => {
    setOptionsOpen(false);
  });

  return (
    <SelectOptions direction={optionsDirection} size={size}>
      {withSearch &&
        <InputWrapper>
          <Input
            value={filterInputValue}
            onChange={onChangeInputHandle}
            placeholder="Поиск"
            maxLength={200}
          />
        </InputWrapper>
      }

      {filteredOptions.map((option, idx) => (
        <SelectOption
          key={idx}
          value={option.value}
          active={
            filteredOptions.find((item) => item.value === value) &&
            filteredOptions.find((item) => item.value === value).value ===
            option.value
          }
          disabled={option?.disabled}
          onClick={() => optionSelectHandle(option.value)}
        >
          <span>{option.label}</span>
        </SelectOption>
      ))}
    </SelectOptions>
  );
};

const SelectOptions = styled.div<{direction: string; size: string}>`
  position: absolute;
  width: 100%;
  box-sizing: border-box;
  margin-top: 3px;
  padding: 4px 0;
  border: 1px solid ${({theme}) => theme.colors.primaryDark};
  border-radius: 4px;
  background: #ffffff;
  z-index: 10;
  max-height: 300px;
  overflow: auto;
  ${({direction, size}) =>
  direction === "top" && size === "small" && "bottom: 35px;"}
  ${({direction, size}) =>
          direction === "top" && size === "primary" && "bottom: 48px;"}
`;

const SelectOption = styled.div<{
  value: string;
  active: boolean;
  disabled?: boolean;
}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 9px;
  font-size: ${theme.fontSizes.body};
  font-family: ${theme.fonts.mainRegular};
  color: ${({active}) => (!active ? theme.colors.bodyText : "#ffffff")};
  cursor: pointer;
  background-color: ${({active, disabled}) =>
  active ? theme.colors.primary : disabled ? theme.colors.neutral3 : "#ffffff"};
  pointer-events: ${props => props.disabled ? 'none' : 'auto'};

  &:hover {
    ${({active}) => !active && "background-color: " + theme.colors.neutral2};
  }
`;

const InputWrapper = styled.div`
    margin: 5px 10px;
`;

export default OptionsBlock;