import styled from "styled-components";
import React, { FC, ReactElement, useEffect } from "react";
import Icon from "../Icon";
import {theme} from "../../styles/theme";
import ReactTooltip from "react-tooltip";

interface Props {
  children: ReactElement;
  action: (event?: any) => void;
  size?: number;
  rotate?: number;
  opacity?: number;
  disabled?: boolean;
  tooltip?: string;
  tooltipFor?: string;
  active?: boolean;
  color?: string;
  svgSize?: number;
  className?: string;
  tooltipPlace?: string;
}

const ActionIcon: FC<Props> = ({
  children,
  action,
  size = 40,
  rotate = 0,
  opacity = 0.56,
  disabled = false,
  tooltip,
  tooltipFor,
  active = false,
  color,
  svgSize = 24,
  className,
  tooltipPlace = 'bottom',
}) => {

  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  const handleAction = (e) => {
    e.stopPropagation();
    action(e);
    ReactTooltip.hide();
  };

  return (
    <ActionIconWrapper
      data-tip={tooltip}
      data-for={tooltipFor}
      data-place={tooltipPlace}
      onClick={disabled ? () => null : handleAction}
      size={size}
      disabled={disabled}
      active={active && !disabled}
      className={className}
    >
      <Icon
        opacity={opacity}
        rotate={rotate}
        disabled={disabled}
        size={svgSize}
        color={color}
      >
        {children}
      </Icon>
    </ActionIconWrapper>
  );
};

const iconFill = (active, disabled) => {
  if(active) {
    return theme.colors.primary
  }
  if(disabled) {
    return theme.colors.neutral4
  }

  return theme.colors.neutral7
}

const ActionIconWrapper = styled.div<{
  size: number;
  disabled: boolean;
  active: boolean;
}>`
  cursor: ${({disabled}) => !disabled ? 'pointer' : 'auto'};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({active}) => active ? 'rgba(173, 181, 189, 0.15)' : 'transparent'};
  
  &:hover {
    background-color: ${({disabled}) => !disabled && 'rgba(173, 181, 189, 0.15)'};
  }
  
  &:active {
    path[fill]{
      fill: ${({disabled}) => !disabled && theme.colors.primary};
    }
  }
  
  path[fill] {
    fill-opacity: 1;
    fill: ${({active, disabled}) => iconFill(active, disabled)};
  }
  
  ${({size}) => `
    width: ${size}px;
    height: ${size}px;
  `}
`;

export default ActionIcon;
