import React, {useEffect} from "react";

const useResize = (callback) => {
    const listener = (event) => {
        callback(event);
    };
    
    useEffect(() => {
        window.addEventListener("resize", listener);
        
        return () => {
            window.removeEventListener("resize", listener);
        }
    }, [callback]);
};

export default useResize;