import React, {memo, FC} from 'react';
import styled from "styled-components";
import {theme} from "../../styles/theme";

interface Props {
    width: number,
    height: number
}

const LoaderComponent: FC<Props> = memo(({
    width,
    height
}) => {
    const elementOffsetTop = width * 0.4 + "px";
    const elementWidth = width * 0.15 + "px";
    const elementHeight = height * 0.15 + "px";
    const element1OffsetLeft = width * 0.1 + "px";
    const element2OffsetLeft = width * 0.1 + "px";
    const element3OffsetLeft = width * 0.4 + "px";
    const element4OffsetLeft = width * 0.7 + "px";
    const pointTranslate = width * 0.3 + "px";
    
    return (
        <LoaderWrapper>
            <Loader
                width={width + "px"}
                height={height + "px"}
            >
                {new Array(4).fill("").map((element, index) =>
                    <LoaderElement
                        key={index}
                        top={elementOffsetTop}
                        width={elementWidth}
                        height={elementHeight}
                        element1Left={element1OffsetLeft}
                        element2Left={element2OffsetLeft}
                        element3Left={element3OffsetLeft}
                        element4Left={element4OffsetLeft}
                        pointTranslate={pointTranslate}
                    />
                )}
            </Loader>
        </LoaderWrapper>
    );
});

const LoaderWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const Loader = styled.div<{
    width: string;
    height: string;
}>`
  display: inline-block;
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: ${props => props.width};
  height: ${props => props.height};
`;

const LoaderElement = styled.div<{
    top: string;
    width: string;
    height: string;
    element1Left: string;
    element2Left: string;
    element3Left: string;
    element4Left: string;
    pointTranslate: string;
}>`
  position: absolute;
  top: ${props => props.top};
  width: ${props => props.width};
  height: ${props => props.height};
  border-radius: 50%;
  background: ${theme.colors.primary};
  animation-timing-function: cubic-bezier(0, 1, 1, 0);

  &:nth-child(1) {
    left: ${props => props.element1Left};
    animation: point-move-1 0.6s infinite;
  }

  &:nth-child(2) {
    left: ${props => props.element2Left};
    animation: point-move-2 0.6s infinite;
  }

  &:nth-child(3) {
    left: ${props => props.element3Left};
    animation: point-move-2 0.6s infinite;
  }

  &:nth-child(4) {
    left: ${props => props.element4Left};
    animation: point-move-3 0.6s infinite;
  }

  @keyframes point-move-1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }

  @keyframes point-move-2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(${props => props.pointTranslate}, 0);
    }
  }

  @keyframes point-move-3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
`;

export default LoaderComponent;