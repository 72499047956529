import React, {FC, memo, useRef} from "react";
import styled from "styled-components";
import {useOutsideClick} from "../../../../../hooks/useOutsideClick";
import {theme} from "../../../../../styles/theme";
import {TIMES} from "../../const";

interface Props {
  pickedDay: any;
  candidateProfileId: number;
  currentDate: any;
  position: {
    left: number;
    top: number;
  };
  open: boolean;
  reservedSlots: any[];
  onClick: (data: string) => void;
  onOutsideClick: () => void;
}

const ContextMenu: FC<Props> = memo(({
  pickedDay,
  candidateProfileId,
  currentDate,
  position,
  open,
  reservedSlots,
  onClick,
  onOutsideClick
                                     }) => {
  const ref = useRef(null);

  useOutsideClick(ref, () => {
    onOutsideClick();
  });

  return (
    <div>
      {open && (
        <ContextMenuWrapper ref={ref} position={position}>
          {TIMES.map(time => {
            if (pickedDay === currentDate.date() && currentDate.format('HH:mm') > time) {
              return null;
            }
            return (
              <ContextMenuItemWrapper
                key={time}
                reserved={reservedSlots?.map(reservedTime => {
                  if(reservedTime.candidateProfileId !== candidateProfileId) {
                    return reservedTime.time
                  }
                }).includes(time)}
                onClick={() => onClick(time)}
              >
                {time}
              </ContextMenuItemWrapper>
            )
          })
          }
        </ContextMenuWrapper>
      )}
    </div>
  );
});

const ContextMenuWrapper = styled.div<{
  position: { left: number; top: number };
}>`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: ${(props) => props.position.top}px;
  left: ${(props) => props.position.left}px;
  padding: 4px 0;
  height: 158px;
  background: #ffffff;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  overflow-y: auto;
  overflow-x: hidden;
  transform: translate(-100%, -100%);
  font-size: ${theme.fontSizes.body};
`;

const ContextMenuItemWrapper = styled.div<{ reserved: boolean }>`
  background: ${props => props.reserved ? theme.colors.primary : 'white'};
  cursor: pointer;
  padding: 5px 10px;
  pointer-events: ${props => props.reserved ? 'none' : 'auto'};

  &:hover {
    background: ${theme.colors.neutral2};
  }
`;

export default ContextMenu;