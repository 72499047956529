import {
    CAPTCHA_CHECK,
    GET_USER_DATA,
    GET_CAPTCHA,
    LOGOUT
} from "../config/paths";
import axios from "../middlewares/axios";
import {getCookie} from "../utils/getCookie";

export async function loginKeycloak(email, password) {
    const url = new URLSearchParams();
    url.append('grant_type', 'password');
    url.append('username', email);
    url.append('password', password);
    url.append('client_id', process.env.REACT_APP_CLIENT_ID);
    url.append('client_secret', process.env.REACT_APP_CLIENT_SECRET);
    url.append('scope', 'openid');

    const response = await fetch(process.env.REACT_APP_KEYCLOAK_TOKEN, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: url,
        credentials: 'include'
    })

    return await response.json();
}

export async function refreshTokenRequest() {
    const refresh_token = getCookie("refresh_token");

    const url = new URLSearchParams();
    url.append('grant_type', 'refresh_token');
    url.append('client_id', process.env.REACT_APP_CLIENT_ID);
    url.append('refresh_token', refresh_token);
    url.append('client_secret', process.env.REACT_APP_CLIENT_SECRET);

    try {
        const response = await fetch(process.env.REACT_APP_KEYCLOAK_TOKEN, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: url,
            credentials: 'include'
        })

        return await response.json();
    } catch (e) {
        console.log(e);
        return e;
    }
}

export async function captchaCheck(value, key) {
    return axios.post(CAPTCHA_CHECK, {
        captcha_value: value,
        key
    })
}

export async function logoutKeycloak() {
    const refresh_token = getCookie("refresh_token");

    const url = new URLSearchParams();
    url.append('client_secret', process.env.REACT_APP_CLIENT_SECRET);
    url.append('client_id', process.env.REACT_APP_CLIENT_ID);
    url.append('refresh_token', refresh_token);

    return await fetch(process.env.REACT_APP_LOGOUT_KEYCLOAK, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: url
    })
}

export async function logoutSso() {
    return axios.get(LOGOUT);
}

export async function getCaptcha() {
    return axios.get(GET_CAPTCHA);
}

export async function getUserData() {
    return await axios.get(GET_USER_DATA);
}