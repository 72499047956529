const ADIcon = () => {
  return (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
          <g clipPath="url(#clip0_1204_41121)">
              <path d="M21 2H3C1.9 2 1 2.9 1 4V16C1 17.1 1.9 18 3 18H10V20H8V22H16V20H14V18H21C22.1 18 23 17.1 23 16V4C23 2.9 22.1 2 21 2ZM21 16H3V4H21V16Z" fill="black"/>
              <path d="M8.55469 7.38477L6.82129 13H4.98047L7.59277 5.89062H8.75977L8.55469 7.38477ZM9.99023 13L8.25195 7.38477L8.02734 5.89062H9.20898L11.8359 13H9.99023ZM9.92188 10.3486V11.6719H6.27441V10.3486H9.92188Z" fill="black"/>
              <path d="M14.668 13H13.0908L13.1006 11.6816H14.668C14.9935 11.6816 15.2686 11.6035 15.4932 11.4473C15.721 11.291 15.8936 11.0583 16.0107 10.749C16.1312 10.4365 16.1914 10.0524 16.1914 9.59668V9.28906C16.1914 8.94727 16.1572 8.64779 16.0889 8.39062C16.0238 8.13021 15.9261 7.91374 15.7959 7.74121C15.6657 7.56543 15.5029 7.43359 15.3076 7.3457C15.1156 7.25781 14.8942 7.21387 14.6436 7.21387H13.0615V5.89062H14.6436C15.1253 5.89062 15.5664 5.97363 15.9668 6.13965C16.3704 6.30241 16.7188 6.53678 17.0117 6.84277C17.3079 7.14551 17.5358 7.50521 17.6953 7.92188C17.8581 8.33529 17.9395 8.79427 17.9395 9.29883V9.59668C17.9395 10.098 17.8581 10.557 17.6953 10.9736C17.5358 11.3903 17.3096 11.75 17.0166 12.0527C16.7236 12.3555 16.377 12.5898 15.9766 12.7559C15.5794 12.9186 15.1432 13 14.668 13ZM14.0723 5.89062V13H12.3584V5.89062H14.0723Z" fill="black"/>
          </g>
          <defs>
              <clipPath id="clip0_1204_41121">
                  <rect width="24" height="24" fill="white"/>
              </clipPath>
          </defs>
      </svg>
  );
};

export default ADIcon;
