import React, { FC, memo } from "react";
import styled from "styled-components";
import { privateRoutes } from "../../../../config/routes";
import { history } from "../../../../routes/history";
import _get from "lodash/get";
import moment from "moment";
import {formatPhoneNumber} from "../../../../utils/formatPhoneNumber";

interface Interview {
  candidateProfile?: {
    age: number;
    condition: string;
    created_at: string;
    created_by: string;
    email: string;
    experience: string;
    first_name: string;
    id: string;
    is_auto_dialing: boolean;
    phone: string;
    recommendation?: string;
    reject_reason?: string;
    second_name: string;
    vacancy: string;
  };
  candidateProfileStatus?: {
    comment: string;
    created_at: string;
    created_by: string;
    id: string;
    is_reject: boolean;
    is_system: boolean;
    name: string;
    status_module?: string;
  };
  comments: [];
  vacancy: string;
  created_at: string;
  created_by: string;
  id: string;
  interview_date: string;
  auto_dialing_status: string | null;
}

interface Props {
  item: Interview;
}

const ListItem: FC<Props> = memo(({ item }) => {
  const created_date = item.created_at
    ? moment(item.created_at, "YYYY-MM-DD HH:mm:ss").format("DD.MM.YYYY HH:mm")
    : "";

  const convertDate = (date) => {
    if (date) {
      return moment(date, "YYYY-MM-DD HH:mm:ss").format("DD.MM.YYYY HH:mm");
    }
    return "-";
  };

  return (
    <Wrapper
      onClick={() =>
        history.push({
          pathname: `${privateRoutes.candidateFormItem.alias}${item.candidateProfile.id}`,
          state: {
            isChk: window.location.pathname === privateRoutes.candidateFormsChk.path,
            goBackPath: window.location.pathname
          }
        })
      }
    >
      <TitleWrapper>
        <Data>{created_date}</Data>
        <Name>
          {_get(item, "candidateProfile.first_name")}{" "}
          {_get(item, "candidateProfile.second_name")}
        </Name>
        <ID>ID{item.id}</ID>
      </TitleWrapper>
      <BodyWrapper>
          <AttributeWrapper width={"30%"}>
            <TitleAttribute>Статус</TitleAttribute>
            <BodyAttribute status={"phone_interview"}>
              {_get(item, "candidateProfileStatus.name", "")}
            </BodyAttribute>
          </AttributeWrapper>
          <AttributeWrapper width={"300px"}>
            <TitleAttribute>Ресторан</TitleAttribute>
            <BodyAttribute>{_get(item, "restaurant.name_ru")}</BodyAttribute>
          </AttributeWrapper>
          <AttributeWrapper width={"20%"}>
            <TitleAttribute>Вакансия</TitleAttribute>
            <BodyAttribute>{_get(item, "vacancy.name")}</BodyAttribute>
          </AttributeWrapper>
          <AttributeWrapper width={"20%"}>
            <TitleAttribute>Гражданство</TitleAttribute>
            <BodyAttribute>{_get(item, "citizenship.name")}</BodyAttribute>
          </AttributeWrapper>
          <AttributeWrapper width={"120px"}>
            <TitleAttribute>Возраст</TitleAttribute>
            <BodyAttribute>{_get(item, "candidateProfile.age")}</BodyAttribute>
          </AttributeWrapper>
          <AttributeWrapper width={"20%"}>
            <TitleAttribute>Телефон</TitleAttribute>
            <BodyAttribute>{formatPhoneNumber(item, "candidateProfile.phone")}</BodyAttribute>
          </AttributeWrapper>
          <AttributeWrapper width={"30%"}>
            <TitleAttribute>Email</TitleAttribute>
            <BodyAttribute>
              {_get(item, "candidateProfile.email")}
            </BodyAttribute>
          </AttributeWrapper>
        <AttributeWrapper width={"30%"}>
          <TitleAttribute>Дата собеседования</TitleAttribute>
          <BodyAttribute>{convertDate(_get(item, "interview_date"))}</BodyAttribute>
        </AttributeWrapper>
      </BodyWrapper>
    </Wrapper>
  );
});

const getColor = (status: string, theme: any) => {
  switch (status) {
    case "phone_interview":
      return theme.colors.green;
    case "not_phone":
      return theme.colors.primary;
    default:
      return theme.colors.bodyText;
  }
};

const BodyWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  
  &>div {
    @media (max-width: 1695px) {
      &:nth-child(1),
      &:nth-child(6) {
        width: 428px;
      }
      
      &:nth-child(3), 
      &:nth-child(4),
      &:nth-child(8) {
        width: 200px;
      }
    }
    
    @media (max-width: 1459px) {
      &:nth-child(6) {
        width: 308px;
      }
    }

    @media (max-width: 1175px) {
      &:nth-child(2),
      &:nth-child(7) {
        width: 200px;
      }
    }
    
    @media (max-width: 1155px) {
      &:nth-child(1) {
        width: 307px;
      }
      &:nth-child(6) {
        width: 388px;
      }
    }
    
    @media (max-width: 1034px) {
      &:nth-child(1) {
        width: 320px;
      }
    }

    @media (max-width: 955px) {
      &:nth-child(6) {
        width: 428px;
      }
    }
    
    @media (max-width: 847px) {
      &:nth-child(1),
      &:nth-child(6) {
        width: 400px;
      }
    }

    @media (max-width: 707px) {
      &:nth-child(5) {
        width: 200px;
      }
    }

    @media (max-width: 691px) {
      &:nth-child(6) {
        width: 200px;
      }
    }

    @media (max-width: 571px) {
      &:nth-child(4) {
        width: 200px;
      }
    }
  }
`;

const AttributeWrapper = styled.div<{ width: string }>`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => width};
  margin-bottom: 10px;
`;

const TitleAttribute = styled.div`
  width: 100%;
  line-height: 14px;
  margin-bottom: 5px;
  ${({ theme }) => `
    font-family: ${theme.fonts.mainRegular};
    font-size: ${theme.fontSizes.small};
    color: ${theme.colors.neutral7};
  `}
`;

const BodyAttribute = styled.div<{ status?: string }>`
  width: 100%;
  line-height: 20px;
  ${({ theme, status }) => `
    font-family: ${theme.fonts.mainRegular};
    font-size: ${theme.fontSizes.body};
    color: ${getColor(status, theme)};
  `}
`;

const ID = styled.p`
  display: flex;
  align-items: center;
  ${({ theme }) => `
    font-family: ${theme.fonts.mainRegular};
    font-size: ${theme.fontSizes.title};
    color: ${theme.colors.neutral5};
  `}
`;

const Data = styled.p`
  display: flex;
  align-items: center;
  margin-right: 5px;
  width: 140px;
  ${({ theme }) => `
    font-family: ${theme.fonts.mainBlack};
    font-size: ${theme.fontSizes.title};
  `}
`;

const Name = styled.p`
  display: flex;
  align-items: center;
  margin-right: 5px;
  ${({ theme }) => `
    font-family: ${theme.fonts.mainBlack};
    font-size: ${theme.fontSizes.title};
  `}
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 16px 16px 6px 16px;
  cursor: pointer;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral2};

  &:hover {
    ${({ theme }) => `background: ${theme.colors.neutral2};`}
  }
`;

const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 10px;
`;

export default ListItem;
