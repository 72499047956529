import {yupResolver} from "@hookform/resolvers/yup";
import React, {FC, useCallback, useContext} from "react";
import {Controller, useForm, useWatch} from "react-hook-form";
import styled from "styled-components";
import * as yup from "yup";
import {deleteAdCompany, postAdCompany, putAdCompany} from "../../actions/adCompany";
import typeSelectOptions from "../../assets/AdCompanies/typeSelectOptions.json";
import Button from "../../components/Button";
import {Input} from "../../components/Input";
import {Modal} from "../../components/Modal";
import MultiSelect from "../../components/MultiSelect";
import Select from "../../components/Select";
import {TextArea} from "../../components/TextArea";
import {errorsDescriptions} from "../../config/errors";
import {connectModal} from "../../hocs/Modal";
import {useFetching} from "../../hooks/useFetching";
import {DirectoryStoreContext} from "../../stores/directory";
import {ModalsStoreContext} from "../../stores/modal";
import initializeMultiselectOptions from "../../utils/initializeMultiselectOptions";
import initializeMultiselectValue from "../../utils/initializeMultiselectValue";
import {FOR_MANUAL_INPUT, FOR_UTM_SOURCE, SOURCE_TYPE} from "./const";

const schema = yup.object().shape({
  company_name: yup
    .string()
    .required(errorsDescriptions.required)
    .trim()
    .min(2, "Минимум 2 символа")
    .max(255, "Максимум 255 символов"),
  utm_source: yup
    .string()
    .when(
      "source_type",
      {
        is: value => value !== FOR_MANUAL_INPUT,
        then: yup.string()
          .trim()
          .min(2, "Минимум 2 символа")
          .max(255, "Максимум 255 символов"),
        otherwise: yup.string()
      }),
  company_type: yup.string().required(),
  source_type: yup.string().required(),
  restaurants_ids: yup
    .array()
    .when(
      ["company_type", "source_type"],
      {
        is: (...values) => values[0] === "for_restaurants" && values[1] !== FOR_MANUAL_INPUT,
        then: yup.array().min(1, "Минимум 1 ресторан"),
        otherwise: yup.array()
      }
    ),
  tariffs_ids: yup
    .array()
    .when(
      "company_type",
      {is: "for_restaurants", then: yup.array(), otherwise: yup.array().min(1, "Минимум 1 тариф")}
    ),
  description: yup
    .string()
    .trim()
    .max(1000, "Максимум 1000 символов")
});

interface Props {
  onRequestFulfilled: () => void;
}

const AdCompanyModal: FC<Props> = connectModal("ad-companies-modal")(({
  onRequestFulfilled,
  handleHide
}) => {
  const {row, rowId} = useContext(ModalsStoreContext).modals["ad-companies-modal"].props;
  const {cities, tariffs} = useContext(DirectoryStoreContext);
  const restaurantsOptions = initializeMultiselectOptions(
    cities, "id", "name_ru", true, "restaurants_minimal"
  );
  const tariffsOptions = initializeMultiselectOptions(
    tariffs, "id", "name", false
  );
  const {handleSubmit, control, formState: {isValid, errors}} = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      company_name: row?.name || "",
      utm_source: row?.utm_source || "",
      company_type: row?.company_type,
      source_type: row === null ? FOR_UTM_SOURCE : row?.source_type,
      restaurants_ids: row?.restaurants ? initializeMultiselectValue(row?.restaurants, "id") : [],
      tariffs_ids: row?.tariffs ? initializeMultiselectValue(row?.tariffs, "id") : [],
      description: row?.description || ""
    },
  });

  const typeSelectValue = useWatch({
    control,
    name: "company_type"
  });

  const sourceTypeValue = useWatch({
    control,
    name: "source_type"
  });

  const [requestHandle, isLoading] = useFetching(async (data) => {
    let response;
    if (row?.name) {
      response = await putAdCompany(rowId, data);
    } else {
      response = await postAdCompany(data);
    }

    if (response.status === 200) {
      handleHide();
      onRequestFulfilled();
    }
  });

  const [deleteAdCompanyHandle, isLoadingDelete] = useFetching(async (rowId) => {
    const response = await deleteAdCompany(rowId);
    if (response.message === "OK") {
      handleHide();
      onRequestFulfilled();
    }
  });
  const onSubmit = useCallback(async (formData) => {
    const requestData = {...formData};

    if (typeSelectValue === "for_restaurants") {
      delete requestData.tariffs_ids;
    }
    if (typeSelectValue === "for_tariffs" || sourceTypeValue === FOR_MANUAL_INPUT) {
      delete requestData.restaurants_ids;
    }
    if (sourceTypeValue !== FOR_MANUAL_INPUT) {
      requestData.utm_source = requestData.utm_source.trim();
    } else {
      delete requestData.utm_source;
    }
    requestData.name = requestData.company_name.trim();
    requestData.description = requestData.description.trim();
    if (!requestData.description) {
      delete requestData.description;
    }

    await requestHandle(requestData);
  }, [typeSelectValue, sourceTypeValue]);

  const deleteCompanyHandle = useCallback(async (event) => {
    event.preventDefault();

    await deleteAdCompanyHandle(rowId);
  }, []);

  return (
    <Modal
      title={row?.name ? "Редактирование рекламной кампании" : "Добавление рекламной кампании"}
      onClose={handleHide}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Content>
          <Controller
            render={({field, fieldState}) => {
              return (
                <Input
                  {...field}
                  {...fieldState}
                  placeholder="Название"
                  error={errors?.company_name?.message}
                  ref={null}
                />
              );
            }}
            name="company_name"
            control={control}
          />

          <Controller
            render={({field, fieldState}) => {
              return (
                <Select
                  title="Тип"
                  options={typeSelectOptions}
                  {...field}
                  {...fieldState}
                  ref={null}
                />
              );
            }}
            name="company_type"
            control={control}
          />

          <Controller
            render={({field, fieldState}) => {
              return (
                <Select
                  title="Тип метки"
                  options={SOURCE_TYPE}
                  {...field}
                  {...fieldState}
                  ref={null}
                />
              );
            }}
            name="source_type"
            control={control}
          />

          {sourceTypeValue !== FOR_MANUAL_INPUT &&
            <>
              <Controller
                render={({field, fieldState}) => {
                  return (
                    <Input
                      {...field}
                      {...fieldState}
                      placeholder="utm_source"
                      error={errors?.utm_source?.message}
                      ref={null}
                    />
                  );
                }}
                name="utm_source"
                control={control}
              />

              {typeSelectValue === "for_restaurants" &&
                <Controller
                  render={({field, fieldState}) => {
                    return (
                      <MultiSelect
                        title="Ресторан"
                        {...field}
                        {...fieldState}
                        options={restaurantsOptions}
                        withSubOptions={true}
                        subOptionsKey="restaurants_minimal"
                        ref={null}
                      />
                    );
                  }}
                  name="restaurants_ids"
                  control={control}
                />
              }
            </>
          }

          {typeSelectValue === "for_tariffs" &&
            <Controller
              render={({field, fieldState}) => {
                return (
                  <MultiSelect
                    title="Тариф"
                    {...field}
                    {...fieldState}
                    options={tariffsOptions}
                    withSubOptions={false}
                    ref={null}
                  />
                );
              }}
              name="tariffs_ids"
              control={control}
            />
          }

          <Controller
            render={({field, fieldState}) => {
              return (
                <TextArea
                  placeholder="Краткое описание"
                  {...field}
                  {...fieldState}
                  error={errors?.description?.message}
                  ref={null}
                />
              );
            }}
            name="description"
            control={control}
          />
        </Content>

        <ButtonsWrapper>
          {row?.name && (
            <>
              <Button
                onClick={deleteCompanyHandle}
                btnStyle='secondary'
                title="Удалить"
                disabled={isLoadingDelete || isLoading}
              />

              <ButtonsRightSide>
                <Button
                  onClick={handleHide}
                  btnStyle='cancel'
                  title="Отмена"
                />
                <Button
                  type="submit"
                  btnStyle='primary'
                  title="Сохранить"
                  disabled={!isValid || isLoading || isLoadingDelete}
                />
              </ButtonsRightSide>
            </>
          )}

          {!row?.name && (
            <>
              <div></div>

              <ButtonsRightSide>
                <Button
                  onClick={handleHide}
                  btnStyle='cancel'
                  title="Отмена"
                />

                <Button
                  type="submit"
                  btnStyle='primary'
                  title="Сохранить"
                  disabled={!isValid || isLoading}
                />
              </ButtonsRightSide>
            </>
          )}
        </ButtonsWrapper>
      </Form>
    </Modal>
  );
});

const Form = styled.form`
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 16px;
  overflow: auto;
  padding-top: 16px;
  margin-top: -16px;
`;

const ButtonsWrapper = styled.div`
  margin-top: 8px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 16px;
  flex-shrink: 0;
  background-color: #ffffff;
`;

const ButtonsRightSide = styled.div`
  display: flex;
  gap: 16px;
`;

const AddModalButtonsWrapper = styled.div`
  display: flex;
`;

export default AdCompanyModal;