import {FC} from 'react';

interface Props {
    width?: string,
    height?: string,
    fill?: string,
    onClick?: (event:any) => void
}

const CloseIcon: FC<Props> = ({
    width,
    height,
    fill,
    onClick
}) => {
    return (
      <svg
        width={width || "36"}
        height={height || "36"}
        viewBox="0 0 36 36"
        fill="none"
        onClick={onClick}
      >
          <g clipPath="url(#clip0_1216_31986)">
              <path
                  d="M28.5 9.615L26.385 7.5L18 15.885L9.615 7.5L7.5 9.615L15.885 18L7.5 26.385L9.615 28.5L18 20.115L26.385 28.5L28.5 26.385L20.115 18L28.5 9.615Z"
                  fill={fill || "black"}
              />
          </g>
          <defs>
              <clipPath id="clip0_1216_31986">
                  <rect width="36" height="36" fill="white"/>
              </clipPath>
          </defs>
      </svg>
    );
};

export default CloseIcon;