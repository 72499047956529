import React, {FC, memo, useRef, useEffect, useState} from 'react';
import styled from "styled-components";
import {useOutsideClick} from "../../hooks/useOutsideClick";
import {theme} from "../../styles/theme";
import Button from "../Button";

interface Props {
    children: JSX.Element | JSX.Element[],
    onReset: () => void,
    onApply: () => void,
    onClose: (event: any) => void,
    btnRef?: any,
}

const Filters: FC<Props> = memo(({
    children,
    onReset,
    onApply,
    onClose,
    btnRef,
}) => {
    const filtersRef = useRef(null);
    const [positions, setPositions] = useState({});

    const resizePositions = () => {
        wrapperPositions();
    }

    useEffect(() => {
        setPositions(wrapperPositions);

        window.addEventListener("resize", resizePositions);

        return () => {
            window.removeEventListener("resize", resizePositions);
        }
    }, [])

    const wrapperPositions = () => {
        const btnTarget = btnRef.current,
              filter = filtersRef.current;
        let left,
            documentWidth = document.getElementsByTagName('body')[0].getBoundingClientRect().width;
        if (documentWidth - btnTarget.offsetLeft + btnTarget.offsetWidth < filter.offsetWidth) {
            left = -(filter.offsetWidth - btnTarget.offsetWidth);
        } else {
            left = 0;
        }
        setPositions({left});

        return {left};
    };
    
    useOutsideClick(filtersRef, (event) => {
        onClose(event);
    });
    
    return (
        <FiltersWrapper
            ref={filtersRef}
            positions={positions}
        >
            <FiltersHeader>
                Фильтры
            </FiltersHeader>
            
            {children}
            
            <ButtonsWrapper>
                <ButtonWrapper>
                    <Button
                        title="Сбросить"
                        btnStyle={'cancel'}
                        onClick={onReset}
                    />
                </ButtonWrapper>
                <ButtonWrapper>
                    <Button
                        title="Применить"
                        btnStyle={'primary'}
                        onClick={onApply}
                    />
                </ButtonWrapper>
            </ButtonsWrapper>
        </FiltersWrapper>
    );
});



const FiltersWrapper = styled.div<{
    positions: any,
}>`
  position: absolute;
  margin-top: 3px;
  left: ${({positions}) => positions.left}px;
  width: 311px;
  padding: 16px;
  border-radius: 4px;
  background: #ffffff;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  z-index: 1;
`;

const FiltersHeader = styled.div`
  margin-bottom: 16px;
  font-size: ${theme.fontSizes.h3};
  font-family: ${theme.fonts.mainBlack};
  user-select: none;
`;

const ButtonsWrapper = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: flex-end;
  gap: 16px;
`;

const ButtonWrapper = styled.div``;

export default Filters;