export default function sortOptions(filteredOptions, key, withSubOptions, subOptionsKey) {
    const sortedOptions = [...filteredOptions];
    
    sortedOptions.sort((a, b) => {
        return sortHandle(a, b, key);
    });
    
    if (withSubOptions) {
        sortedOptions.map(option => {
            option[subOptionsKey].sort((a, b) => {
                return sortHandle(a, b, key);
            });
        });
    }
    
    return sortedOptions;
}

const sortHandle = (a, b, key) => {
    const labelA = a[key].toLowerCase();
    const labelB = b[key].toLowerCase();
    
    if (labelA < labelB) {
        return -1;
    }
    if (labelA > labelB) {
        return 1;
    }
    return 0;
};