import axios from "../middlewares/axios";
import {CREATE_NOTIFICATION, RESTAURANT, RESTAURANT_NOTIFICATION, RESTAURANTS} from "../config/paths";

export const getRestaurants = async function(limit, page, search, order, filters) {
    const data = {
        limit,
        page,
        with: {
            relationships: [
                "advertisingCompanies", "city", "partner", "tariff", "users", "blockInterviews"
            ],
            properties: ['blockingCandidateProfiles']
        }
    };
    
    if (order) {
        data.order = [order];
    }
    
    const filtersList = [];
    
    const filtersGroup = {
        type: "group",
        group: []
    };
    const orderObject = {
        type: "single",
        operator: "!=",
        value: ""
    };
    const searchGroup = {
        type: "group",
        group: []
    };
    
    if (filters) {
        if (filters.partner && filters.partner.length > 0) {
            filtersGroup.group.push({
                column: "partner_id",
                operator: "=",
                value: filters.partner
            });
        }
        if (filters.restaurant && filters.restaurant.length > 0) {
            filtersGroup.group.push({
                column: "id",
                operator: "=",
                value: filters.restaurant
            });
        }
        if (filters.city && filters.city.length > 0) {
            filtersGroup.group.push({
                column: "city_id",
                operator: "=",
                value: filters.city
            });
        }
        if (filters.tariff && filters.tariff.length > 0) {
            filtersGroup.group.push({
                column: "tariff_id",
                operator: "=",
                value: filters.tariff
            });
        }
        if (filters.forwarding) {
            if (filters.forwarding === "yes") {
                filtersGroup.group.push({
                    column: "forwarding_link",
                    operator: "!=",
                    boolean: "and",
                    value: ""
                });
            }
            if (filters.forwarding === "no") {
                filtersGroup.group.push({
                    column: "forwarding_link",
                    operator: "=",
                    boolean: "and",
                    value: ""
                });
            }
        }
        if (filters.minorsRecruitment) {
            if (filters.minorsRecruitment === "sixteen") {
                filtersGroup.group.push({
                    column: "is_sixteen_recruitment",
                    operator: "=",
                    boolean: "and",
                    value: true
                });
            }
            if (filters.minorsRecruitment === "seventeen") {
                filtersGroup.group.push({
                    column: "is_seventeen_recruitment",
                    operator: "=",
                    boolean: "and",
                    value: true
                });
            }
            if (filters.minorsRecruitment === "no") {
                filtersGroup.group.push({
                    column: "is_sixteen_recruitment",
                    operator: "=",
                    boolean: "and",
                    value: false
                });
                filtersGroup.group.push({
                    column: "is_seventeen_recruitment",
                    operator: "=",
                    boolean: "and",
                    value: false
                });
            }
        }

        if (filters.blocking) {
          filtersList.push(
            {
              column: "is_blocking",
              operator: "=",
              boolean: "and",
              value: filters.blocking
            }
          );
        }
    
        filtersGroup.group.map((item, index) => {
            if (index !== 0) {
                item.boolean = "and";
            }
        });
    
        if (filtersGroup.group.length !== 0) {
            filtersList.push(filtersGroup);
        }
    }
    
    if (order) {
        if (order.includes("partners.name")) {
            orderObject.column = "partners.name";
            filtersList.push(orderObject);
        }
    }
    
    if (search) {
        const searchColumns = ["name_ru"];
        searchColumns.map((column, index) => {
            const searchObject = {
                column,
                operator: "ilike",
                value: "%" + search + "%"
            };
            if (index !== 0) {
                searchObject.boolean = "or";
            }
            searchGroup.group.push(searchObject);
        });
        
        if (searchGroup.group.length !== 0) {
            filtersList.push(searchGroup);
        }
    }
    
    filtersList.map((item, index) => {
        if (index !== 0) {
            item.boolean = "and";
        }
    });
    
    if (filtersList.length !== 0) {
        data.filter = filtersList;
    }
    
    const response = await axios.post(RESTAURANTS, data);
    return response.data;
};

export const changeRestaurant = async (id, data) => {
    const response =
        await axios.put(`${RESTAURANT}/${id}`, {
            ...data
        });
    
    return response.data
}

export const getRestaurantNotification = async (id = null) => {
    const params = {
        pagination: "nolimit",
        with: {
            relationships: [
                "role", "restaurant"
            ]
        }
    };
    
    if (id) {
        params.filter = [
            {
                type: "single",
                column: "restaurant_id",
                operator: "=",
                boolean: "and",
                value: id.toString()
            }
        ];
    }
    
    const response = await axios.post(RESTAURANT_NOTIFICATION, params);
    
    return response.data;
};

export const createNotify = async (data) => {
    const response = await axios.post(CREATE_NOTIFICATION, data);
    
    return response.data;
};

export const changeNotify = async (id, data) => {
    const response = await axios.put(`${CREATE_NOTIFICATION}/${id}`, data);
    
    return response.data;
};