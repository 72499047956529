import {LIMIT_DEFAULT, PAGE_DEFAULT} from "../config/consts";
import {INTERVIEWS} from "../config/paths";
import axios from "../middlewares/axios";
import dayjs from "dayjs";
import moment from "moment/moment";

export const getInterviews = async (
    limit= LIMIT_DEFAULT,
    page= PAGE_DEFAULT,
    order = "",
    search = "",
    filters = null,
    filterOptionLengths = {},
    isChk = false,
    exportParams = null,
    pagination = "",
) => {

  let data = {};
  if(pagination === "nolimit") {
    data = {
      pagination: "nolimit",
      with: {
        relationships: [
          "candidateProfile",
        ]
      }
    }
  } else {
    data = {
      limit: limit,
      page: page,
      with: {
        relationships: [
          "comments",
          "vacancy",
          "candidateProfileStatus",
          "candidateProfile",
        ],
        properties: [
          "restaurant",
          "city",
          "citizenship",
        ],
      },
    };
  }

  if (order) {
    data.order = order;
  }

  const config = {};

  data.filter = [{
    column: "candidate_profiles.vacancy_id",
    operator: isChk ? "=" : "!=",
    value: 1,
  }];

  if (search) {
    data.filter.push({
      type: "group",
      group: [
        {
          column: "candidate_profiles.first_name",
          operator: "ilike",
          value: `%${search}%`,
        },
        {
          column: "candidate_profiles.second_name",
          operator: "ilike",
          value: `%${search}%`,
          boolean: "or"
        }
      ]
    });
  }
  let index = 1;
  let needBoolean = false;
  if (filters) {
    if (data.filter.length === 2) {
      index = 2;
    }

    data.filter.push({
      type: "group",
      group: []
    });

    if (filters?.partner?.length && filters?.partner?.length !== filterOptionLengths.partnerLength) {
      data.filter[index].group.push({
        column: "restaurants.partner_id",
        operator: "=",
        value: filters.partner,
      });
      needBoolean = true;
    }
    if (filters.restaurant_id?.length && filters?.restaurant_id?.length !== filterOptionLengths.restaurantLength) {
      data.filter[index].group.push({
        column: "restaurants.id",
        operator: "=",
        value: filters.restaurant_id
      });
      needBoolean = true;
    }
    if (filters.candidate_profile_status_id?.length && filters.candidate_profile_status_id?.length !== filterOptionLengths.statusLength) {
      const item = {
        column: "candidate_profile_statuses.id",
        operator: "=",
        value: filters.candidate_profile_status_id
      };
      if (needBoolean) {
        item.boolean = "and";
      }
      data.filter[index].group.push(item);
      needBoolean = true;
    }
    if (filters.dateRange.from) {
      const itemFrom = {
        column: "created_at",
        operator: ">=",
        value: dayjs(filters.dateRange.from).format("YYYY-MM-DD HH:mm:ss"),
      };
      if (needBoolean) {
        itemFrom.boolean = "and";
      }
      data.filter[index].group.push(itemFrom);
      needBoolean = true;
    }
    if (filters.dateRange.to) {
      const itemTo = {
        column: "created_at",
        operator: "<",
        value: dayjs(filters.dateRange.to).add(1, 'day').format("YYYY-MM-DD HH:mm:ss"),
      };
      if (needBoolean) {
        itemTo.boolean = "and";
      }
      data.filter[index].group.push(itemTo);
    }

    if (filters.ageFromTo.from) {
      const itemFrom = {
        column: "candidate_profiles.age",
        operator: ">=",
        value: filters.ageFromTo.from,
      };
      if (needBoolean) {
        itemFrom.boolean = "and";
      }
      data.filter[index].group.push(itemFrom);
      needBoolean = true;
    }

    if (filters.ageFromTo.to) {
      const itemTo = {
        column: "candidate_profiles.age",
        operator: "<=",
        value: filters.ageFromTo.to,
      };
      if (needBoolean) {
        itemTo.boolean = "and";
      }
      data.filter[index].group.push(itemTo);
    }

    if (filters.vacancy_id) {
      const item = {
        column: "vacancy_id",
        operator: "=",
        value: filters.vacancy_id,
      };
      if (needBoolean) {
        item.boolean = "and";
      }
      data.filter[index].group.push(item);
    }

    if (filters.citizenship_id?.length && filters.citizenship_id?.length !== filterOptionLengths.citizenshipLength) {
      const item = {
        column: "candidate_profiles.citizenship_id",
        operator: "=",
        value: filters.citizenship_id,
      };
      if (needBoolean) {
        item.boolean = "and";
      }
      data.filter[index].group.push(item);
    }

    if (filters.interviewDate?.from || filters.interviewDate?.to) {
      const itemFrom = {
        column: "interview_date",
        operator: ">=",
        value: dayjs(filters.interviewDate.from).format("YYYY-MM-DD HH:mm:ss"),
      };
      if (needBoolean) {
        itemFrom.boolean = "and";
      }
      data.filter[index].group.push(itemFrom);
      needBoolean = true;

      const itemTo = {
        column: "interview_date",
        operator: "<",
        value: dayjs(filters.interviewDate.to).add(1, 'day').format("YYYY-MM-DD HH:mm:ss"),
      };
      if (needBoolean) {
        itemTo.boolean = "and";
      }
      data.filter[index].group.push(itemTo);
    }

    if (data.filter[index].group.length === 0){
      data.filter = data.filter.filter((i, ii) => ii !== index);
    }
  }

  if (exportParams) {
    data.export = {
      export_type: "xlsx",
      file_name: `Собеседования ${moment().format("YYYY-MM-DD HH:mm:ss")}`,
      fields: exportParams
    };
    data.return_type = "export";
    config.responseType = "arraybuffer";

    if (!filters.dateRange.from || !filters.dateRange.to) {
      const itemFrom = {
        column: "created_at",
        operator: ">=",
        value: dayjs().add(-1, 'month').format("YYYY-MM-DD HH:mm:ss"),
      };
      if (needBoolean) {
        itemFrom.boolean = "and";
      }
      data.filter[index].group.push(itemFrom);
      needBoolean = true;

      const itemTo = {
        column: "created_at",
        operator: "<",
        value: dayjs().add(1, 'day').format("YYYY-MM-DD HH:mm:ss"),
      };
      if (needBoolean) {
        itemTo.boolean = "and";
      }
      data.filter[index].group.push(itemTo);
    }

  }

  const response = await axios.post(INTERVIEWS + "/search", data, config);

  return response.data;
};

export const createCandidateInterview = async (data) => {
  const response = await axios.post(INTERVIEWS, {
    ...data,
  });
  
  return response.data;
};

export const putCandidateInterview = async (id, data) => {
  const response = await axios.put(INTERVIEWS + "/" + id, {
    ...data,
  });
  
  return response.data;
};

export const deleteCandidateInterview = async (id) => {
  const response = await axios.delete(INTERVIEWS + "/" + id);

  return response.data;
};
