import React, {FC} from 'react';
import styled from "styled-components";

const Item = styled.div<{ isActive: boolean }>`
  background-color: ${props => props.isActive ? '#c7c7c7' : ''};
  padding: 20px;
  border: 1px solid #000;

  :hover {
    background-color: #e1e1e1;
  }
`;

interface Props {
  items: string[];
  activeItemIdx?: number;
  isLoading: boolean;
  error: string;
  onClick: (string) => void;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  onKeyUp: (event) => void;
}

const AutoCompleteList:FC<Props> = ({
  items,
  activeItemIdx,
  isLoading,
  error,
  onClick,
  onMouseEnter,
  onMouseLeave,
  onKeyUp
}) => {
  return (
    <div
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {isLoading
        ? <div>Wait</div>
        : items.map((item, idx) =>
          <Item
            key={idx}
            onClick={() => onClick(item)}
            onKeyUp={(e) => onKeyUp(e)}
            isActive={idx === activeItemIdx}
          >
            {item}
          </Item>
        )
      }

      {error && <div>ERROR!</div>}
    </div>
  );
};

export default AutoCompleteList;