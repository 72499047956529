import React, {Fragment} from "react";
import {Route, Switch} from "react-router-dom";
import {publicRoutes} from "../config/routes";

const PublicRoutes = () => {
    return (
        <Switch>
            {Object.keys(publicRoutes).map(routeKey => {
                const route = publicRoutes[routeKey];
                const Layout = route.layout || Fragment;
                const Component = route.component;
                
                return (
                    <Route
                        key={`route-${route.path}`}
                        path={route.path}
                        exact={route.exact}
                        render={(props) => (
                            <Layout>
                                <Component {...props} />
                            </Layout>
                        )}
                    />
                );
            })}
        </Switch>
    );
};

export default PublicRoutes;