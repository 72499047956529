import {FC} from "react";

interface Props {
    width?: string,
    height?: string,
    fill?: string
}

const ProblemIcon: FC<Props> = ({
    width,
    height,
    fill
}) => {
  return (
      <svg
          width={width || "24"}
          height={height || "24"}
          viewBox="0 0 24 24"
          fill="none"
      >
          <g clipPath="url(#clip0_1275_29314)">
              <path
                  d="M12 5.99L19.53 19H4.47L12 5.99ZM12 2L1 21H23L12 2ZM13 16H11V18H13V16ZM13 10H11V14H13V10Z"
                  fill={fill || "black"}
              />
          </g>
          <defs>
              <clipPath id="clip0_1275_29314">
                  <rect width="24" height="24" fill="white"/>
              </clipPath>
          </defs>
      </svg>
  );
};

export default ProblemIcon;
