import AnalyticsIcon from "../assets/icons/AnalyticsIcon";
import EditNoteIcon from "../assets/icons/EditNoteIcon";
import PeopleIcon from "../assets/icons/PeopleIcon";
import QuestionnaireIcon from "../assets/icons/QuestionnaireIcon";
import RestaurantIcon from "../assets/icons/RestaurantIcon";
import AuthLayout from "../layouts/AuthLayout";
import MainLayout from "../layouts/MainLayout";
import Auth from "../pages/Auth";
import Bloggers from "../pages/Bloggers";
import OrderSection from "../pages/CandidateForms/OrderSection";
import RowsSection from "../pages/CandidateForms/RowsSection";
import MainPage from "../pages/Main";
import DashboardIcon from "../assets/icons/DashboardIcon";
import AdCompanies from "../pages/AdCompanies";
import ADIcon from "../assets/icons/ADIcon";
import InterviewIcon from "../assets/icons/InterviewIcon";
import Interview from "../pages/Interview";
import Restaurants from "../pages/Restaurants";
import UserManagement from "../pages/UserManagement";
import OfflineAdvertising from "../pages/OfflineAdvertising";
import Analytics from "../pages/Analytics";
import TableChartIcon from "../assets/icons/TableChartIcon";
import Plr from "../pages/Plr";
import Playground from "../pages/Playground";
import CandidateForm from "../pages/CandidateForms/RowsSection/CandidateForm";

export const privateRoutes = {
  main: {
    exact: true,
    path: "/",
    component: MainPage,
    layout: MainLayout,
    title: "Рабочий стол",
    icon: DashboardIcon,
    roles: [],
  },
  userManagement: {
    exact: true,
    path: "/usermanagement",
    component: UserManagement,
    layout: MainLayout,
    title: "Управление пользователями",
    icon: PeopleIcon,
    roles: ["superadmin"],
  },
  adCompanies: {
    exact: true,
    path: "/adcompanies",
    component: AdCompanies,
    layout: MainLayout,
    title: "Рекламные кампании",
    icon: ADIcon,
    roles: ["superadmin"],
  },
  offline_advertising: {
    exact: true,
    path: "/offline_advertising",
    component: OfflineAdvertising,
    layout: MainLayout,
    title: "Заказ offline рекламы",
    icon: ADIcon,
    roles: ["superadmin"],
  },
  plr: {
    exact: true,
    path: "/plr",
    component: Plr,
    layout: MainLayout,
    title: "ПЛР",
    icon: TableChartIcon,
    roles: ["superadmin"],
  },
  analytics: {
    exact: false,
    path: "/analytics",
    component: Analytics,
    layout: MainLayout,
    title: "Аналитика",
    icon: AnalyticsIcon,
    roles: ["superadmin"],
  },
  interviewChk: {
    exact: true,
    path: "/interview/restaurant_employee",
    component: Interview,
    layout: MainLayout,
    roles: [
      "restaurant",
      "controller",
      "hr_partner",
      "superadmin",
      "call_center_operator",
    ],
  },
  interviewMc: {
    exact: true,
    path: "/interview/shift_manager",
    component: Interview,
    layout: MainLayout,
    roles: ["controller", "hr_partner", "superadmin", "call_center_operator"],
  },
  candidateFormsChk: {
    exact: true,
    path: "/candidateforms/restaurantemployee",
    component: RowsSection,
    layout: MainLayout,
    roles: [
      "restaurant",
      "controller",
      "hr_partner",
      "superadmin",
      "call_center_operator",
    ],
  },
  candidateFormsMs: {
    exact: true,
    path: "/candidateforms/shiftmanager",
    component: RowsSection,
    layout: MainLayout,
    roles: ["controller", "hr_partner", "superadmin", "call_center_operator"],
  },
  candidateFormItem: {
    exact: true,
    path: "/candidateforms/:id",
    alias: "/candidateforms/",
    component: CandidateForm,
    layout: MainLayout,
    roles: [
      "restaurant",
      "controller",
      "hr_partner",
      "superadmin",
      "call_center_operator",
    ],
  },
  candidateFormsOrders: {
    exact: true,
    path: "/candidateformsorder",
    component: OrderSection,
    layout: MainLayout,
    roles: ["superadmin"],
  },
  restaurants: {
    exact: true,
    path: "/restaurants",
    component: Restaurants,
    layout: MainLayout,
    title: "Рестораны",
    icon: RestaurantIcon,
    roles: ["superadmin"],
  },
  bloggers: {
    exact: true,
    path: "/bloggers",
    component: Bloggers,
    layout: MainLayout,
    title: "Блогеры",
    icon: EditNoteIcon,
    roles: ["superadmin"],
  },
  playground: {
    // ### temp
    exact: true,
    path: "/playground",
    component: Playground,
    layout: MainLayout,
    title: "Playground",
    icon: InterviewIcon,
    roles: ["superadmin"],
  },
};

const interviewForms = {
  path: "/interview",
  title: "Собеседования",
  icon: InterviewIcon,
  options: [
    {
      exact: privateRoutes.interviewChk.exact,
      path: privateRoutes.interviewChk.path,
      title: "Собеседования ЧК",
      roles: [
        "restaurant",
        "controller",
        "hr_partner",
        "superadmin",
        "call_center_operator",
      ],
    },
    {
      exact: privateRoutes.interviewMc.exact,
      path: privateRoutes.interviewMc.path,
      title: "Собеседования МС",
      roles: ["controller", "hr_partner", "superadmin", "call_center_operator"],
    },
  ],
  roles: [
    "restaurant",
    "controller",
    "hr_partner",
    "superadmin",
    "call_center_operator",
  ],
};

const candidateForms = {
  title: "Анкеты",
  path: "/candidateforms",
  icon: QuestionnaireIcon,
  options: [
    {
      exact: privateRoutes.candidateFormsChk.exact,
      path: privateRoutes.candidateFormsChk.path,
      title: "Анкеты ЧК",
      roles: [
        "restaurant",
        "controller",
        "hr_partner",
        "superadmin",
        "call_center_operator",
      ],
    },
    {
      exact: privateRoutes.candidateFormsMs.exact,
      path: privateRoutes.candidateFormsMs.path,
      title: "Анкеты МС",
      roles: ["controller", "hr_partner", "superadmin", "call_center_operator"],
    },
    {
      exact: privateRoutes.candidateFormsOrders.exact,
      path: privateRoutes.candidateFormsOrders.path,
      title: "Заказ анкет",
      roles: ["superadmin"],
    },
  ],
  roles: [
    "restaurant",
    "controller",
    "hr_partner",
    "superadmin",
    "call_center_operator",
  ],
};

export const publicRoutes = {
  auth: {
    exact: true,
    path: "*",
    component: Auth,
    layout: AuthLayout,
  },
};

export const menu = [
  privateRoutes.main,
  privateRoutes.userManagement,
  privateRoutes.adCompanies,
  privateRoutes.offline_advertising,
  privateRoutes.plr,
  privateRoutes.analytics,
  interviewForms,
  candidateForms,
  privateRoutes.restaurants,
  privateRoutes.bloggers,
];
