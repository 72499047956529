import React, {FC, memo} from "react";
import styled from "styled-components";
import {theme} from "../../../../styles/theme";

interface RestaurantsBlockProps {
  city: any;
  restaurants: any[];
  pickedRestaurant: {
    city_name: string;
    city_id: string;
    restaurant_name: string;
    restaurant_id: string;
  };
  onRestaurantPick:
    (value: { city_name: string; city_id: string; restaurant_name: string; restaurant_id: string }) => void;
}

const RestaurantsBlock: FC<RestaurantsBlockProps> = memo(({
  city,
  restaurants,
  pickedRestaurant,
  onRestaurantPick
}) => {
  return (
    <RestaurantsBlockWrapper>
      <CityWrapper>{city.name_ru}</CityWrapper>
      <RestaurantsWrapper>
        {restaurants.map((restaurant) => (
          <RestaurantWrapper
            key={restaurant.id}
            picked={
              city.id === pickedRestaurant.city_id &&
              restaurant.id === pickedRestaurant.restaurant_id
            }
            onClick={() => onRestaurantPick({
              city_name: city.name_ru,
              city_id: city.id,
              restaurant_name: restaurant.name_ru,
              restaurant_id: restaurant.id
            })}
          >
            {restaurant.name_ru}
            <AddressWrapper>
              {`${city.name_ru}, ${restaurant.address_ru || ""}`}
            </AddressWrapper>
          </RestaurantWrapper>
        ))}
      </RestaurantsWrapper>
    </RestaurantsBlockWrapper>
  );
});

const RestaurantsBlockWrapper = styled.div`
  margin-bottom: 15px;
`;

const CityWrapper = styled.div`
  color: ${theme.colors.neutral10};
  font-size: ${theme.fontSizes.h2};
  font-family: ${theme.fonts.mainBlack};
  margin-bottom: 10px;
`;

const RestaurantsWrapper = styled.div`
  margin-left: 20px;
`;

const RestaurantWrapper = styled.div<{ picked: boolean }>`
  cursor: pointer;
  font-size: ${theme.fontSizes.small};
  margin-bottom: 10px;
  background: ${(props) => (props.picked ? theme.colors.primary : "white")};
  color: ${(props) => (props.picked ? "white" : theme.colors.neutral7)};
  padding: 10px;
  border-radius: 4px;

  &:hover {
    background: ${(props) => (props.picked ? theme.colors.primary : theme.colors.neutral2)};
  }
`;

const AddressWrapper = styled.div`
  font-size: ${theme.fontSizes.body};
  color: ${theme.colors.neutral10};
`;

export default RestaurantsBlock;