export const Data = [
  {
    title: 'Необработанные',
    value: 'unprocessed',
    count: '573',
    active: true,
  },
  {
    title: 'Нужно перезвонить',
    value: 'recall',
    count: '20',
    active: false,
  },
  {
    title: 'В процессе трудоустройства',
    value: 'in_process_of_employment',
    count: '6',
    active: false,
  },

  {
    title: '',
    value: 'fake',
    count: '',
    active: false,
  },

  {
    title: 'Всего',
    value: 'total',
    count: '1000',
    active: false,
  },
  {
    title: 'Было приглашено',
    value: 'invited_to_interview',
    count: '164',
    active: false,
  },
  {
    title: 'Дошло на собеседование',
    value: 'passed_interview',
    count: '0',
    active: false,
  },
  {
    title: 'Трудоустроено',
    value: 'employed',
    count: '2',
    active: false,
  },

  {
    title: '',
    value: 'fake',
    count: '',
    active: false,
  },

  {
    title: 'Отказы',
    value: 'rejected',
    count: '2',
    active: false,
  },
]