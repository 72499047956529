import React, {FC, memo} from "react";
import styled from "styled-components";
import {theme} from "../../styles/theme";

interface Props {
    value: string;
    label: string;
    checked: boolean;
    ref?: any;
    onChange: (value: string, checked: boolean) => void;
    name?: string;
}

const Radio: FC<Props> = memo(({
    value,
    label,
    checked,
    onChange,
    name,
}) => {
    return (
        <RadioWrapper>
            <input
                hidden={true}
                type="radio"
                checked={checked}
                onChange={() => null}
                name={name}
            />
            
            <RadioCircleWrapper
                checked={checked}
                onClick={() => onChange(value, true)}
            />
            
            <span>{label}</span>
        </RadioWrapper>
    );
});

const RadioWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const RadioCircleWrapper = styled.div<{checked: boolean}>`
  width: 24px;
  height: 24px;
  aspect-ratio: 1;
  border: ${props => props.checked ? "none" : "3px solid " + theme.colors.neutral10};
  border-radius: 50%;
  background: ${props => props.checked ? theme.colors.primary : "none"};
  cursor: pointer;
`;

export default Radio;