import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import * as yup from "yup";
import React, {useCallback, useContext} from "react";
import Button from "../../../../components/Button";
import {Modal} from "../../../../components/Modal";
import {TextArea} from "../../../../components/TextArea";
import {Controller, useForm, useWatch} from "react-hook-form";
import styled from "styled-components";
import {connectModal} from "../../../../hocs/Modal";
import {ModalsStoreContext} from "../../../../stores/modal";
import {theme} from "../../../../styles/theme";
import CandidateFormCalendar from "../Operator/CandidateFormCalendar";
import {FieldLabelWrapper, FieldValueWrapper} from "../../../../styles/gridRowDefaults";

const schema = yup.object().shape({
    description: yup.string().nullable(),
    interview_date: yup.string().required()
});

const InterviewModal = connectModal("candidate-profile-interview-modal")(({
    handleHide,
    onChange
}) => {
    const {
        interviewData,
        candidateData
    } = useContext(ModalsStoreContext).modals["candidate-profile-interview-modal"].props;
    const {setValue, control, formState: {isValid}} = useForm({
        mode: "onChange",
        resolver: yupResolver(schema),
        defaultValues: {
            description: "",
            interview_date: interviewData.interview_date || ""
        },
    });
    
    const interviewDateValue = useWatch({
        control,
        name: "interview_date"
    });
    
    const onSubmit = useCallback(() => {
        onChange(control._formValues);
        handleHide();
    }, []);
    
    return (
        <Modal
          title="Собеседование"
          onClose={handleHide}
          width={"573px"}
          footer={
              <ButtonsWrapper>
                  <Button
                    btnStyle="cancel"
                    title="Отмена"
                    onClick={handleHide}
                  />

                  <Button
                    btnStyle="primary"
                    title="Назначить"
                    disabled={!isValid}
                    onClick={onSubmit}
                  />
              </ButtonsWrapper>
          }
        >
            <FormWrapper>
                {(candidateData.city || candidateData.restaurant) && (
                    <InfoRowWrapper>
                        <InfoBlockWrapper>
                            <FieldLabelWrapper>Город</FieldLabelWrapper>
                            <FieldValueWrapper>{candidateData?.city?.name_ru || "-"}</FieldValueWrapper>
                        </InfoBlockWrapper>
        
                        <InfoBlockWrapper>
                            <FieldLabelWrapper>Ресторан</FieldLabelWrapper>
                            <FieldValueWrapper>{candidateData?.restaurant?.name_ru || "-"}</FieldValueWrapper>
                        </InfoBlockWrapper>
                    </InfoRowWrapper>
                )}
                
                <FieldWrapper>
                    <Controller
                        render={({field, fieldState}) => {
                            return (
                                <TextArea
                                    {...field}
                                    {...fieldState}
                                    placeholder="Комментарий"
                                    ref={null}
                                />
                            );
                        }}
                        name="description"
                        control={control}
                    />
                </FieldWrapper>

                <CandidateFormCalendar
                    restaurantId={candidateData?.restaurant?.id}
                    candidateProfileId={candidateData?.candidateProfileId}
                    value={interviewDateValue}
                    onInterviewDatePick={(date) => {
                        setValue("interview_date", date);
                        control._updateValid();
                    }}
                />
                
                {interviewDateValue && (
                    <DateTextWrapper>
                        Дата и время: {interviewDateValue}
                    </DateTextWrapper>
                )}
            </FormWrapper>
        </Modal>
    );
});

const FormWrapper = styled.div`
  width: 100%;
  font-family: ${theme.fonts.mainRegular};
`;

const FieldWrapper = styled.div`
  margin-bottom: 15px;
`;

const ButtonsWrapper = styled.div`
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: end;
  gap: 10px;
`;

const DateTextWrapper = styled.div`
  font-size: ${theme.fontSizes.h2};
  color: ${theme.colors.green};
    font-family: ${theme.fonts.mainBlack};
  margin-top: 20px;
  display: flex;
  justify-content: end;
`;

const InfoRowWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`;

const InfoBlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export default InterviewModal;