export default function initializeMultiselectOptions(options, valueKey, labelKey, withSubOptions, subOptionsKey) {
    const newOptions = [];
    
    options.map(item => {
        let optionObject;
        
        if (withSubOptions) {
            optionObject = {
                value: item[valueKey],
                label: item[labelKey],
                [subOptionsKey]: item[subOptionsKey].map(subItem => {
                    return {
                        value: subItem[valueKey], label: subItem[labelKey]
                    };
                })
            };
        } else {
            optionObject = {
                value: item[valueKey],
                label: item[labelKey]
            };
        }
        
        newOptions.push(optionObject);
    });
    
    return newOptions;
}