import initializeMultiselectOptions from "./initializeMultiselectOptions";

export const getRestaurantOptions = (userRole, userRestaurants, cities) => {
  if(userRole === "controller" || userRole === "hr_partner" || userRole === "call_center_operator") {
    const cityNames = cities.reduce((acc, city) => (
      {...acc, [city.id]: city.name_ru}
    ), {})

    const groupRestaurantsByCity = {};
    for(let restaurant of userRestaurants) {
      restaurant.city_id in groupRestaurantsByCity ?
        groupRestaurantsByCity[restaurant.city_id].push({value: restaurant.id, label: restaurant.name_ru}) :
        groupRestaurantsByCity[restaurant.city_id] = [{value: restaurant.id, label: restaurant.name_ru}];
    }

    return Object.keys(groupRestaurantsByCity).map(cityKey => {
      return {
        value: +cityKey,
        restaurants_minimal: groupRestaurantsByCity[cityKey],
        label: cityNames[cityKey]
      };
    });
  } else {
    return initializeMultiselectOptions(
      cities, "id", "name_ru", true, "restaurants_minimal"
    );
  }
}