import React, {FC} from 'react';
import styled from 'styled-components';
import moment from 'moment';
import {theme} from "../../styles/theme";

const TD = styled.div<{hideBottomBorder?: boolean, hideLeftBorder?: boolean}>`
  width: calc(100% / 7);
  height: 134px;
  position: relative;
  background-color: white;
  border-bottom: 1px solid ${theme.colors.neutral2};
  border-left: 1px solid ${theme.colors.neutral2};
  
  box-sizing: border-box;
    
  @media (max-width: 900px) {
      width: calc(100% / 4);
      
      &:last-child {
          border-right: 1px solid ${theme.colors.neutral2};
      }
  }
    
  @media (max-width: 500px) {
      width: calc(100% / 2);
  }
`;

const DayNumber = styled.div<{isGray?: boolean, isToday?: boolean}>`
    font-family: ${theme.fonts.mainRegular};
    font-size: ${theme.fontSizes.body};
    color: ${({isGray, isToday}) => isToday ? theme.colors.primary : isGray ? theme.colors.neutral6 : theme.colors.bodyText};
    line-height: 20px;
`;

const DayOfWeek = styled.div`
    font-family: ${theme.fonts.mainRegular};
    font-size: ${theme.fontSizes.body};
    color: ${theme.colors.neutral6};
    line-height: 20px;
`;

const HeaderCellWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 10px 2px 10px;
`;

const CellWrapper = styled.div<{children?: React.ReactNode|React.ReactNode[]}>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const DAYS = ['ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ', 'ВС'];

interface Props {
    date: moment.Moment,
    renderDay: (string) => React.ReactNode,
}

const TableContent: FC<Props> = ({date, renderDay}) => {
    const _renderByWeek = currDayInMonth => {
        const currentMonth = currDayInMonth.month();

        let isFirstCell = true;
        let isFirstWeek = false;
        let isLastWeek = false;


        if (currDayInMonth.diff(moment(currDayInMonth).startOf('month'), 'days') === 0) {
            isFirstWeek = true;
        } else if (Math.abs(currDayInMonth.diff(moment(currDayInMonth).endOf('month'), 'days')) < 7) {
            isLastWeek = true;
        }

        let daysToRender = [];

        while (
            (daysToRender.length === 0 || (currDayInMonth.isoWeekday()-1) % 7 !== 0) &&
            currDayInMonth.month() <= currentMonth
            ) {
            const isToday = currDayInMonth.diff(moment().startOf('day'), 'days') === 0;
            let hideFirstWeekFirstCell = isFirstCell ? currDayInMonth.isoWeekday()-1 === 0 : false;

            daysToRender.push(
                <TD key={currDayInMonth.format('DDMMYY')}
                    hideBottomBorder={isLastWeek}
                    hideLeftBorder={isFirstWeek ? hideFirstWeekFirstCell : isFirstCell}>
                    <CellWrapper>
                        <HeaderCellWrapper>
                            <DayNumber isToday={isToday}>{currDayInMonth.format('D')}</DayNumber>
                            <DayOfWeek>{DAYS[currDayInMonth.isoWeekday()-1]}</DayOfWeek>
                        </HeaderCellWrapper>
                        {renderDay(currDayInMonth)}
                    </CellWrapper>
                </TD>
            );

            isFirstCell = false;
            currDayInMonth.add(1, 'days');
        }

        if (isFirstWeek) {
            for (let i = daysToRender.length; i < 7; i++) {
                const oldDate = moment(currDayInMonth).add(-1*(i+1), 'd');
                daysToRender.unshift(
                    <TD key={currDayInMonth.format('M') + i}
                        hideLeftBorder={i === 6}>
                        <CellWrapper>
                            <HeaderCellWrapper>
                                <DayNumber isGray={true}>{oldDate.format('D')}</DayNumber>
                                <DayOfWeek>{DAYS[oldDate.isoWeekday()-1]}</DayOfWeek>
                            </HeaderCellWrapper>
                            {renderDay(oldDate)}
                        </CellWrapper>
                    </TD>
                );
            }
        } else if (isLastWeek) {
            let j = 0;
            for (let i = daysToRender.length; i < 7; i++) {
                const nextDate = moment(currDayInMonth).add(j, 'd');
                j++;
                daysToRender.push(
                    <TD key={currDayInMonth.format('M') + i}
                        hideBottomBorder={true}>
                        <CellWrapper>
                            <HeaderCellWrapper>
                                <DayNumber isGray={true}>{nextDate.format('D')}</DayNumber>
                                <DayOfWeek>{DAYS[nextDate.isoWeekday()-1]}</DayOfWeek>
                            </HeaderCellWrapper>
                            {renderDay(nextDate)}
                        </CellWrapper>
                    </TD>
                );
            }
        }

        return daysToRender;
    };

    const _renderCalendar = () => {

        let currDayInMonth = moment(date).startOf('month');
        let endOfMonth = moment(date).endOf('month');

        const daysElementArray = [];

        while (currDayInMonth.isBefore(endOfMonth)) {
            daysElementArray.push(
              ..._renderByWeek(currDayInMonth)
            );
        }

        return daysElementArray;
    };

    return <>{_renderCalendar()}</>;
};

export default TableContent;
