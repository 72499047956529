import React, {FC, memo, useCallback} from 'react';
import styled from "styled-components";
import CloseIcon from "../../assets/icons/CloseIcon";
import DoneIcon from "../../assets/icons/DoneIcon";
import {theme} from "../../styles/theme";
import {
    Cell,
    GridRowWrapper,
    FieldLabelWrapper,
    FieldValueWrapper,
    FieldValueBoldWrapper,
    RowIdWrapper,
    Block
} from "../../styles/gridRowDefaults";
import {IRow} from "./types/types";

interface Props {
    data: IRow;
    onRowClick: (data: object) => void;
}

const GridRow: FC<Props> = memo(({
    data,
    onRowClick
}) => {
    const getIcon = useCallback((dataProperty) => {
        if (!dataProperty) {
            return (
                <CloseIcon
                    width="16"
                    height="16"
                    fill={theme.colors.primary}
                />
            );
        } else {
            return (
                <DoneIcon
                    width="16"
                    height="16"
                    fill={theme.colors.green}
                />
            );
        }
    }, []);

    return (
        <GridRowWrapper onClick={() => onRowClick(data)}>
            <Block>
                <FirstCellTopRowWrapper className="cell-row">
                    <RowIdWrapper>ID{data.id}</RowIdWrapper>
                    <FieldValueBoldWrapper>{data.name_ru}</FieldValueBoldWrapper>
                </FirstCellTopRowWrapper>
            </Block>
            
            <Row>
                <Cell setFlex={'0 0 20%'}>
                    <div className="cell-row">
                        <FieldLabelWrapper>Партнер</FieldLabelWrapper>
                        <FieldValueWrapper>{data.partner?.name}</FieldValueWrapper>
                    </div>
                </Cell>
                
                <Cell setFlex={'0 0 20%'}>
                    <div className="cell-row">
                        <FieldLabelWrapper>Город</FieldLabelWrapper>
                        <FieldValueWrapper>{data.city?.name_ru}</FieldValueWrapper>
                    </div>
                </Cell>
                
                <Cell setFlex={'1 1 auto'}>
                    <div className="cell-row">
                        <FieldLabelWrapper>Тариф</FieldLabelWrapper>
                        <FieldValueWrapper>{data.tariff?.name}</FieldValueWrapper>
                    </div>
                </Cell>

                <RightCell>
                    <Cell setFlex={'0 0 100px'}>
                        <IconCellRowWrapper className="cell-row">
                            <FieldLabelWrapper>Переадресация</FieldLabelWrapper>
                            <FieldValueWrapper>{getIcon(data.forwarding_link)}</FieldValueWrapper>
                        </IconCellRowWrapper>
                    </Cell>

                    <Cell setFlex={'0 0 180px'}>
                        <IconCellRowWrapper className="cell-row">
                            <FieldLabelWrapper>Набор несовершеннолетних</FieldLabelWrapper>
                            <FieldValueWrapper>{getIcon(data.is_sixteen_recruitment || data.is_seventeen_recruitment)}</FieldValueWrapper>
                        </IconCellRowWrapper>
                    </Cell>

                    <Cell setFlex={'0 0 100px'}>
                        <IconCellRowWrapper className="cell-row">
                            <FieldLabelWrapper>Блокировка</FieldLabelWrapper>
                            <FieldValueWrapper>{getIcon(data.is_blocking)}</FieldValueWrapper>
                        </IconCellRowWrapper>
                    </Cell>
                </RightCell>
            </Row>
        </GridRowWrapper>
    );
});

const RightCell = styled(Cell)`
  flex-direction: row;
    width: 380px;

    @media (max-width: 400px) {
        flex-wrap: wrap;
    }
`;

const Row = styled(Block)`
  @media (max-width: 1023px) {
      flex-wrap: wrap;
      
      &>div {
          min-width: 200px;
      }
  }
`;

const FirstCellTopRowWrapper = styled.div`
  display: flex;
  gap: 20px;
`;

const IconCellRowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
    
    @media (max-width: 1023px) {
        align-items: flex-start;
    }
`;

export default GridRow;