import React, {FC, memo, useCallback, useContext, useEffect} from "react";
import {Controller, useWatch} from "react-hook-form";
import styled from "styled-components";
import Checkbox from "../../../../components/Checkbox";
import Chip from "../../../../components/Chip";
import MultiSelect from "../../../../components/MultiSelect";
import Select from "../../../../components/Select";
import {DirectoryStoreContext} from "../../../../stores/directory";
import initializeMultiselectOptions from "../../../../utils/initializeMultiselectOptions";

interface Props {
    control: any;
    setValue: any;
    row: any;
}

const ControllerRoleFields: FC<Props> = memo(({
    control,
    setValue,
    row
}) => {
    const {partners, cities, restaurants} = useContext(DirectoryStoreContext);
    const partnersOptions = initializeMultiselectOptions(partners, "id", "name", false);

    const restaurantsSelectValue = useWatch({
        control,
        name: "controller.restaurants_ids"
    });
    const partnerSelectValue = useWatch({
        control,
        name: "controller.partner_id"
    });
    
    useEffect(() => {
        if (partnerSelectValue !== row?.partner?.id) {
            setValue("controller.restaurants_ids", []);
        }
    }, [partnerSelectValue]);
    
    const getRestaurantsOptions = useCallback(() => {
        const restaurantsOptions = initializeMultiselectOptions(
            cities, "id", "name_ru", true, "restaurants_minimal"
        );
        
        if (partnerSelectValue) {
            const filteredRestaurants = restaurants.filter(restaurant => {
                return restaurant.partner_id === partnerSelectValue;
            });
            const filteredRestaurantsIds = filteredRestaurants.map(restaurant => restaurant.id);
            return restaurantsOptions.filter(city => {
                let showCity = false;
    
                city.restaurants_minimal = city.restaurants_minimal.filter(restaurant => {
                    if (filteredRestaurantsIds.indexOf(restaurant.value) !== -1) {
                        showCity = true;
                        return true;
                    }
                });
                
                return showCity;
            });
        } else {
            return restaurantsOptions;
        }
    }, [partnerSelectValue, restaurants]);
    
    const chipClickHandle = useCallback((text, value) => {
        const newValue = restaurantsSelectValue.filter(item => item !== value);
        setValue("controller.restaurants_ids", newValue);
        control._updateValid();
    }, [restaurantsSelectValue, setValue]);
    
    return (
        <>
            <Controller
                render={({field, fieldState}) => {
                    return (
                        <Select
                            {...field}
                            {...fieldState}
                            title="Партнер"
                            options={partnersOptions}
                            ref={null}
                        />
                    );
                }}
                name="controller.partner_id"
                control={control}
            />
            
            <div>
                <Controller
                    render={({field, fieldState}) => {
                        return (
                            <MultiSelect
                                {...field}
                                {...fieldState}
                                title="Ресторан"
                                options={getRestaurantsOptions()}
                                withSubOptions={true}
                                subOptionsKey="restaurants_minimal"
                                disabled={!partnerSelectValue}
                                ref={null}
                            />
                        );
                    }}
                    name="controller.restaurants_ids"
                    control={control}
                />
                
                <ChipsWrapper>
                    {restaurantsSelectValue.map(restaurantId =>
                        <Chip
                            key={restaurantId}
                            text={restaurants.find(restaurant => restaurant.id === restaurantId)?.name_ru}
                            value={restaurantId}
                            withIcon={true}
                            onClick={chipClickHandle}
                        />
                    )}
                </ChipsWrapper>
            </div>

            <Controller
                render={({field, fieldState}) => {
                    return (
                        <Checkbox
                            {...field}
                            {...fieldState}
                            label="Доступ к статусу авто-обзвона"
                            onChange={(value) => field.onChange(value)}
                            ref={null}
                        />
                    );
                }}
                name="controller.is_access_to_auto_call_status"
                control={control}
            />
            
            <Controller
                render={({field, fieldState}) => {
                    return (
                        <Checkbox
                            {...field}
                            {...fieldState}
                            label="Доступ к ПЛР"
                            onChange={(value) => field.onChange(value)}
                            ref={null}
                        />
                    );
                }}
                name="controller.is_plr_access"
                control={control}
            />
            
            <Controller
                render={({field, fieldState}) => {
                    return (
                        <Checkbox
                            {...field}
                            {...fieldState}
                            label="Доступ к заказу анкет"
                            onChange={(value) => field.onChange(value)}
                            ref={null}
                        />
                    );
                }}
                name="controller.is_candidate_profile_order_access"
                control={control}
            />
            
            <Controller
                render={({field, fieldState}) => {
                    return (
                        <Checkbox
                            {...field}
                            {...fieldState}
                            label="Доступ к заказу offline рекламы"
                            onChange={(value) => field.onChange(value)}
                            ref={null}
                        />
                    );
                }}
                name="controller.is_access_to_ordering_offline_advertising"
                control={control}
            />
        </>
    );
});

const ChipsWrapper = styled.div`
  padding: 5px 0;
  display: flex;
  flex-flow: wrap;
  gap: 5px;
`;

export default ControllerRoleFields;