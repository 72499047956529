export const errorsDescriptions = {
  required: "Это поле обязательно для заполнения",
  name: "Имя может содержать от 3 символов и должно начинаться с заглавной буквы",
  surname:
    "Фамилия может содержать от 3 символов и должна начинаться с заглавной буквы",
  age: "Возраст может принимать значения от 0 до 99",
  phone: "Телефон должен соответствовать формату +7(xxx)xxx-xx-xx",
  email: "Введите корректный адрес электронной почты",
  city: "Поле города должно иметь длину от 2 до 30 символов и может содержать только буквы и знак -",
  password:
    "Пароль может содержать английские буквы, цифры и спецсимволы. Длина - от 10 до 30 символов",
};
