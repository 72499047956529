import {makeAutoObservable} from "mobx";
import {createContext} from "react";

class Modals {
  constructor() {
    makeAutoObservable(this);
  }

  modals = {};

  showModal = (modalName, props) => {
    this.modals[modalName] = {};
    this.modals[modalName].show = true;
    this.modals[modalName].props = props;
  };

  hideModal = (modalName) => {
    if (this.modals[modalName]) {
      this.modals[modalName].show = false;
    }
  };

  closeAllModals = () => {
    Object.keys(this.modals).forEach((modalName) => this.hideModal(modalName));
  };
}
export const ModalsStore = new Modals();
export const ModalsStoreContext = createContext(ModalsStore);
