import {observer} from "mobx-react-lite";
import styled from "styled-components";
import {theme} from "../../styles/theme";
import Table from "../../components/Table";
import React, {FC} from "react";
import _get from "lodash/get";

interface Props {
  data: any;
  total: number;
}

const TargetAudience: FC<Props> = observer(({data, total}) => {

  const citizenshipsData = [
    {
      title: "Всего",
      num: total,
      percent: '',
    }
  ];
  const otherCitizenships = _get(data, "citizenships.Другое.count");
  Object.keys(data.citizenships).forEach(key => {
    if (key !== "Другое") {
      citizenshipsData.push(
          {
            title: key,
            num: data.citizenships[key].count,
            percent: total ? `${(data.citizenships[key].count/total*100).toFixed(2)}%` : '0'
          }
      )
    }
  });
  citizenshipsData.push(
      {
        title: "Другое",
        num: otherCitizenships,
        percent: total ? `${(otherCitizenships/total*100).toFixed(2)}%` : "0"
      }
  );

  return (
    <Wrapper>
      <TableWrapper>
        <Table
          data={
            [
              {
                title: "Всего",
                num: total,
                percent: '',
              },
              {
                title: "мужской",
                num: _get(data, "sexes.male.count", 0),
                percent: total ? `${(_get(data, "sexes.male.count", 0)/total*100).toFixed(2)}%` : '0'
              },
              {
                title: "женский",
                num: _get(data, "sexes.woman.count", 0),
                percent: total ? `${(_get(data, "sexes.woman.count", 0)/total*100).toFixed(2)}%` : '0'
              },
            ]
          }
          fields={
            [
              {
                label: "",
                field: "title",
                type: "string",
              },
              {
                label: "",
                field: "num",
                type: "number",
              },
              {
                label: "",
                field: "percent",
                type: "string",
              },
            ]
          }
          headerSubtitles={
            [
              {
                title: "Пол",
                colSpan: 3
              }
            ]
          }
        />
        <Table
          data={
            [
              {
                title: "Всего",
                num: total,
                percent: '',
              },
              {
                title: "16-17",
                num: _get(data, "ages.16_17", 0),
                percent: total ? `${(_get(data, "ages.16_17", 0)/total*100).toFixed(2)}%` : '0'
              },
              {
                title: "18-25",
                num: _get(data, "ages.18_25", 0),
                percent: total ? `${(_get(data, "ages.18_25", 0)/total*100).toFixed(2)}%` : '0'
              },
              {
                title: "26-35",
                num: _get(data, "ages.26_35", 0),
                percent: total ? `${(_get(data, "ages.26_35", 0)/total*100).toFixed(2)}%` : '0'
              },
              {
                title: "36-45",
                num: _get(data, "ages.36_45", 0),
                percent: total ? `${(_get(data, "ages.36_45", 0)/total*100).toFixed(2)}%` : '0'
              },
              {
                title: "46-60",
                num: _get(data, "ages.46_60", 0),
                percent: total ? `${(_get(data, "ages.46_60", 0)/total*100).toFixed(2)}%` : '0'
              },
              {
                title: "61+",
                num: _get(data, "ages.61_", 0),
                percent: total ? `${(_get(data, "ages.61_", 0)/total*100).toFixed(2)}%` : '0'
              },
            ]
          }
          fields={
            [
              {
                label: "",
                field: "title",
                type: "string",
              },
              {
                label: "",
                field: "num",
                type: "number",
              },
              {
                label: "",
                field: "percent",
                type: "string",
              },
            ]
          }
          headerSubtitles={
            [
              {
                title: "Возраст",
                colSpan: 3
              }
            ]
          }
        />
      </TableWrapper>
      <TableWrapper>
        <Table
          data={citizenshipsData}
          fields={
            [
              {
                label: "",
                field: "title",
                type: "string",
              },
              {
                label: "",
                field: "num",
                type: "number",
              },
              {
                label: "",
                field: "percent",
                type: "string",
              },
            ]
          }
          headerSubtitles={
            [
              {
                title: "Гражданство",
                colSpan: 3
              }
            ]
          }
        />
      </TableWrapper>
    </Wrapper>
  )
});

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% / 2);
  height: 100%;

  @media (max-width: 450px) {
    width: 100%;
  }
  
  &:first-child {
    border-right: 1px solid ${theme.colors.neutral3};
    @media (max-width: 450px) {
      border-right: none;
    }
  }
  
  thead tr {
    &:last-child {
      td {
        padding: 0;
        &:first-child {
          width: 25%;
        }
        &:nth-child(2) {
          width: 28%;
        }
        &:nth-child(3) {
          width: 26%;
        }
      }
    }
  }
  
  tbody tr td {
    border-bottom: 0;
    &:nth-child(2),
    &:nth-child(3) {
      text-align: center;
    }
    
  }
`;

const Wrapper = styled.div`
  display: flex;
  height: 100%;
  
  @media (max-width: 450px) {
    flex-direction: column;
  }
`;

export default TargetAudience;
