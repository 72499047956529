import _get from "lodash/get";

export const formatPhoneNumber = (obj, key) => {
  if (_get(obj, key)) {
    const cleaned = ("" + _get(obj, key)).replace(/\D/g, "");
    const match = cleaned.match(/^([0-9]|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      const intlCode = match[1] ? "+7 " : "";
      return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join(
        ""
      );
    }
    return "-";
  }
  return "-";
};