import {useMemo} from "react";

export const useMemoRejectOptions = (rejects, statusClarificationValue = null) => {
  const rejectAllOptions = useMemo(() => {
    return rejects.map(reject => ({value: reject.id, label: reject.name}));
  }, [rejects])

  return useMemo(() => {
    if(statusClarificationValue === 9) // Отказ со стороны работодателя
      return rejectAllOptions.filter(reject =>
        [1, 2, 3, 4, 5, 6, 7, 8].includes(reject.value));

    if(statusClarificationValue === 10) // Отказ со стороны кандидата
      return rejectAllOptions.filter(reject =>
        [8, 9, 10, 11, 12, 13, 14, 15, 16, 17].includes(reject.value));

    return rejectAllOptions;

  }, [rejectAllOptions, statusClarificationValue]);
}