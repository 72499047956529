import React, { FC, memo } from "react";
import styled from "styled-components";
import { theme } from "../../../../styles/theme";
import dayjs from "dayjs";

interface Props {
  data: {
    created_at: string;
    name: string;
    comment: string;
    user: any
  };
}

const Comment: FC<Props> = memo(({ data }) => {
  return (
    <CommentWrapper>
      <CommentHeaderWrapper>
        <CommentHeaderElement>
          {data.created_at
            ? dayjs(data.created_at).format("DD.MM.YYYY HH:mm")
            : "-"}
        </CommentHeaderElement>
        <CommentHeaderElement>{data.user?.full_name}</CommentHeaderElement>
      </CommentHeaderWrapper>
      <CommentMessageWrapper>{data.comment}</CommentMessageWrapper>
    </CommentWrapper>
  );
});

const CommentWrapper = styled.div`
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 10px;
  }
`;

const CommentHeaderWrapper = styled.div`
  display: flex;
  gap: 5px;
`;

const CommentHeaderElement = styled.div`
  color: ${theme.colors.neutral7};
  font-size: ${theme.fontSizes.small};
`;

const CommentMessageWrapper = styled.div`
  color: ${theme.colors.neutral10};
  font-size: ${theme.fontSizes.body};
`;

export default Comment;
