import axios from "axios";
import {MAX_LIMIT} from "../config/consts";
import {OFFLINE_ADVERTISING, OFFLINE_ADVERTISING_TYPE, POSTER_SIZE, PRINTED_PRODUCT} from "../config/paths";
import moment from "moment/moment";

export const getOfflineAdvertising = async (filters, exportParams) => {
  const params = {
    limit: MAX_LIMIT,
    with: {
      relationships: [
        "restaurant", "printedProduct", "posterSize", "offlineAdvertisingType"
      ]
    }
  }
  
  const config = {};
  
  if (exportParams) {
    params.export = {
      export_type: "xlsx",
      file_name: `Заказ offline рекламы ${moment().format("YYYY-MM-DD HH:mm:ss")}`,
      fields: exportParams
    };
    params.return_type = "export";
    config.responseType = "arraybuffer";
  }

  if(filters) {
    params.filter = [
      {
        column: "advertising_month",
        value: filters
      }
    ]
  }

  const response = await axios.post(`${OFFLINE_ADVERTISING}/search`, params, config);

  return response.data;
}

export const saveOfflineAdvertising = async (params) => {
  const response = await axios.post(OFFLINE_ADVERTISING,
    params
  );

  return response.data;
}

export const removeAdvertisement = async (id) => {
  const response = await axios.delete(
    `${OFFLINE_ADVERTISING}/${id}`);

  return response.data;
}

export const getPrintedProduct = async () => {
  const response = await axios.post(PRINTED_PRODUCT, {limit: MAX_LIMIT});

  return response.data;
}

export const getPosterSize = async () => {
  const response = await axios.post(POSTER_SIZE, {limit: MAX_LIMIT});

  return response.data;
}

export const getOfflineAdvertisingType = async () => {
  const response = await axios.post(OFFLINE_ADVERTISING_TYPE, {limit: MAX_LIMIT});

  return response.data;
}
