import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import moment from "moment";
import React, {FC, useCallback, useContext, useEffect, useMemo, useState,} from "react";
import {Controller, useForm, useWatch} from "react-hook-form";
import styled from "styled-components";
import * as yup from "yup";
import {
  createCandidateInterview,
  deleteCandidateInterview,
  putCandidateInterview
} from "../../../../actions/interviews";
import ArrowBack from "../../../../assets/icons/ArrowBack";
import HistoryIcon from "../../../../assets/icons/HistoryIcon";
import ActionIcon from "../../../../components/ActionIcon";
import Button from "../../../../components/Button";
import {Input} from "../../../../components/Input";
import Select from "../../../../components/Select";
import {errorsDescriptions} from "../../../../config/errors";
import {ModalsStore} from "../../../../stores/modal";
import {theme} from "../../../../styles/theme";
import Comments from "../Comments";
import HandOverFormModal from "../HandOverFormModal";
import ReactTooltip from "react-tooltip";
import {DirectoryStoreContext} from "../../../../stores/directory";
import {observer} from "mobx-react-lite";
import {Gender, rejectCandidateProfileStatuses} from "../../../../assets/CandidateForms/RowsSection/Enums";
import {createCandidateProfile, getCandidateProfile, putCandidateProfile,} from "../../../../actions/candidates";
import Interview from "../Interrview";
import InterviewModal from "../InterviewModal";
import Testing from "../Testing";
import VacancyFormModal from "../VacancyFormModal";
import Operations from "../Operations";
import {AuthStoreContext} from "../../../../stores/auth";
import {useHistory, useParams} from "react-router-dom";
import HistoryFormModal from "../HistoryFormModal";
import Resume from "../Resume";
import Toast from "../../../../components/Toast";
import {INTERVIEW_REQUIRED_STATUSES, INTERVIEW_STATUSES, NOT_FOUND_TEXT} from "../const";
import {checkKeyDown} from "../chekKeyDown";
import {useFetching} from "../../../../hooks/useFetching";
import Loader from "../../../../components/Loader";
import {FOR_MANUAL_INPUT} from "../../../AdCompanies/const";
import {useMemoVacanciesOptions} from "../../../../hooks/useMemoVacanciesOptions";
import Page404 from "../../../Page404";
import Datepicker from "../../../../components/Datepicker";
import {
  getStatuses,
  showClarification,
  showInterview,
  showMedicalDate,
  showRefuseReason,
  showRegistrationDate,
  showRJP,
  showStatusComment
} from "../../../../utils/getStatuses";
import {createComment} from "../../../../actions/comments";
import {privateRoutes} from "../../../../config/routes";
import {useMemoStatusDetailOptions} from "../../../../hooks/useMemoStatusDetailOptions";
import {useMemoRejectOptions} from "../../../../hooks/useMemoRejectOptions";
import {onChangeOnlyLetters} from "../../../../utils/onChangeOnlyLetters";

interface Props {
  data?: any;
  changeViewMode: () => void;
  onRequestFulfilled: () => void;
}

const schema = yup.object().shape({
  status: yup.number().required(errorsDescriptions.required),
  rjpDate: yup
    .string()
    .when('status', (status, field) => status === 27
      ? field.required(errorsDescriptions.required)
      : field.nullable()),
  medicalDate: yup
    .string()
    .when('status', (status, field) => status === 28
      ? field.required(errorsDescriptions.required)
      : field.nullable()),
  registrationDate: yup
    .string()
    .when('status', (status, field) => status === 29
      ? field.required(errorsDescriptions.required)
      : field.nullable()),
  clarification: yup
    .number()
    .when('status', (status, field) => [2, 6, 8, 22].includes(status)
      ? field.required("")
      : field.nullable()),
  refuseReason: yup
    .number()
    .when(['clarification'], {
      is: (...value) => rejectCandidateProfileStatuses.includes(value[0]),
      then: yup.number().required(""),
      otherwise: yup.number().nullable()
    }),
  statusComment: yup
    .string()
    .when(
      ['refuseReason', 'clarification'],
      {
        is: (...values) => values[0] === 8 || values[1] === 6,
        then: yup.string().required(errorsDescriptions.required),
        otherwise: yup.string().nullable()
    }),
  name: yup
    .string()
    .required(errorsDescriptions.required)
    .min(2, "Это поле должно содержать не меньше 2 символов"),
  age: yup
    .string()
    .nullable()
    .required(errorsDescriptions.required)
    .min(0, errorsDescriptions.age)
    .max(2, errorsDescriptions.age),
  sex: yup.string().required(errorsDescriptions.required),
  citizenship: yup.string().required(errorsDescriptions.required),
  phone: yup
    .string()
    .required(errorsDescriptions.required)
    .matches(
      /^(7)?[0-9]{3}[0-9]{3}[0-9]{2}[0-9]{2}$/g,
      errorsDescriptions.phone
    ),
  email: yup
    .string()
    .matches(
      /^[_a-zA-Z\d-]+(\.[_a-zA-Z\d-]+)*@[a-zA-Z\d-]+(\.[a-z\d-]+)*(\.[a-z]{1,6})$|^$/g,
      errorsDescriptions.email
    ),
  city: yup.string().required(errorsDescriptions.required),
  vacancy_id: yup.string().required(errorsDescriptions.required),
  restaurant_id: yup.string().required(errorsDescriptions.required),
  source: yup.string().required(errorsDescriptions.required),
  interview: yup
    .object()
    .when('status', (status, field) => {
      return INTERVIEW_REQUIRED_STATUSES.includes(status) ?
        field
          .shape({
            interview_date: yup.string().required(errorsDescriptions.required)
          })
        : field.nullable()
    })
});

const CandidateForm: FC<Props> = observer(() => {
  const {
    handleSubmit,
    control,
    formState: {errors, isValid},
    setError,
    setValue,
    getValues,
    watch,
    resetField
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      status: null,
      clarification: null,
      refuseReason: null,
      statusComment: "",
      rjpDate: null,
      medicalDate: null,
      registrationDate: null,
      surname: "",
      name: "",
      age: null,
      sex: "",
      citizenship: null,
      phone: "",
      email: "",
      city: null,
      experience: "",
      vacancy_id: "",
      restaurant_id: null,
      source: null,
      resource: null,
      comments: "",
      interview: {
        description: "",
        interview_date: null,
      },
    },
  });

  const statusSelectValue = useWatch({
    control,
    name: "status",
  });
  const statusClarificationValue = useWatch({
    control,
    name: "clarification",
  });
  const statusRefuseValue = useWatch({
    control,
    name: "refuseReason",
  });
  const vacancySelectValue = useWatch({
    control,
    name: "vacancy_id"
  });
  const interviewObjectValue = useWatch({
    control,
    name: "interview",
  });
  const cityValue = useWatch({
    control,
    name: "city",
  });
  const restaurantValue = useWatch({
    control,
    name: "restaurant_id",
  });

  const [allDataIsDone, setAllDataIsDone] = useState(false);
  const [cityOptionsIsDone, setCityOptionsIsDone] = useState(false);
  const [restaurantOptionsIsDone, setRestaurantOptionsIsDone] = useState(false);

  const {
    candidateProfileStatuses,
    candidateProfileStatusesEndFetching,
    citizenship,
    citizenshipEndFetching,
    cities,
    citiesEndFetching,
    restaurants,
    restaurantsEndFetching,
    sources,
    sourcesEndFetching,
    vacancies,
    rejects,
    rejectsEndFetching,
    statusDetails,
    statusDetailEndFetching
  } = useContext(DirectoryStoreContext);
  const urlParams = useParams<{ id: string }>();
  const history = useHistory<any>();
  const {userData} = useContext(AuthStoreContext);
  const [candidateProfileData, setCandidateProfileData] = useState<Props["data"]>({});
  const [isInsufficientRights, setUsInsufficientRights] = useState<boolean>(false)
  const [fetchCandidateProfile, isLoading] = useFetching(async () => {
    const response = await getCandidateProfile(urlParams.id);

    if (response.code === 403 || response.code === 404) {
      setUsInsufficientRights(true);
    }
    if (response.message === "OK") {
      setCandidateProfileData(response.data);
      initFields(response.data);
    }
  });

  useEffect(() => {
    if (!candidateProfileStatusesEndFetching ||
      !citizenshipEndFetching ||
      !citiesEndFetching ||
      !restaurantsEndFetching ||
      !sourcesEndFetching ||
      !rejectsEndFetching ||
      !statusDetailEndFetching
    ) {
      return;
    }

    if (urlParams.id !== "newcandidate") {
      fetchCandidateProfile();
    } else if (userData.role.name === "restaurant") {
      setValue("city", userData.restaurants[0]?.city_id);
      setValue("restaurant_id", userData.restaurants[0]?.id);
    }
  }, [
    candidateProfileStatusesEndFetching,
    citizenshipEndFetching,
    citiesEndFetching,
    restaurantsEndFetching,
    sourcesEndFetching,
    rejectsEndFetching,
    statusDetailEndFetching
  ]);

  useEffect(() => {
    if (candidateProfileStatusesEndFetching &&
      citizenshipEndFetching &&
      citiesEndFetching &&
      restaurantsEndFetching &&
      sourcesEndFetching &&
      rejectsEndFetching &&
      statusDetailEndFetching &&
      cityOptionsIsDone &&
      restaurantOptionsIsDone
    ) {
      setAllDataIsDone(true);
    }
  }, [
    candidateProfileStatusesEndFetching,
    citizenshipEndFetching,
    citiesEndFetching,
    restaurantsEndFetching,
    sourcesEndFetching,
    rejectsEndFetching,
    statusDetailEndFetching,
    cityOptionsIsDone,
    restaurantOptionsIsDone
  ]);

  const getCityOptions = () => {
    if (!cities.length) return [];

    if (userData.role.name === "superadmin" || userData.role.name === "call_center_operator")
      return cities.map((item) => ({
        value: item.id,
        label: item.name_ru,
      }));

    let citiesId = [];
    if (userData.role.name === "hr_partner")
      citiesId = userData.partner_restaurants.map(restaurant => restaurant.city_id);
    if (userData.role.name === "controller")
      citiesId = userData.restaurants.map(restaurant => restaurant.city_id);
    if (userData.role.name === "restaurant")
      citiesId = restaurants.map(restaurant => restaurant.city_id);

    let cityOptions = [];
    for (let id of new Set(citiesId)) {
      cityOptions.push({
        value: id,
        label: cities.find(city => city.id === id).name_ru
      });
    }

    return cityOptions;
  }

  const getRestaurantsOptions = () => {
    if (userData.role.name === "restaurant" || userData.role.name === "controller") {
      return userData.restaurants.map(restaurant => (
        {
          label: restaurant.name_ru,
          value: restaurant.id,
          city_id: restaurant.city_id,
          address_ru: restaurant.address_ru,
        }
      ));
    }

    let restaurantsOptions = [];
    if (userData.role.name === "superadmin" || userData.role.name === "call_center_operator")
      restaurantsOptions = restaurants.map(restaurant => (
        {
          label: restaurant.name_ru,
          value: restaurant.id,
          city_id: restaurant.city_id,
          address_ru: restaurant.address_ru,
        }
      ));

    if (userData.role.name === "hr_partner")
      restaurantsOptions = userData.partner_restaurants.map(restaurant => (
        {
          label: restaurant.name_ru,
          value: restaurant.id,
          city_id: restaurant.city_id,
          address_ru: restaurant.address_ru,
        }
      ));

    return restaurantsOptions;
  };

  const vacanciesOptions = useMemoVacanciesOptions(candidateProfileData, urlParams.id, restaurantValue);

  const cityOptions = useMemo(
    () => {
      const filteredCityOptions = getCityOptions();
      if (citiesEndFetching) setCityOptionsIsDone(true);

      return filteredCityOptions;
    },
    [citiesEndFetching, userData.restaurants, userData.partner_restaurants]);

  const restaurantOptions = useMemo(
    () => {
      const filteredRestaurantOptions = getRestaurantsOptions();
      if(restaurantsEndFetching) setRestaurantOptionsIsDone(true);

      return filteredRestaurantOptions;
    },
    [cityValue, restaurantsEndFetching, userData.restaurants, userData.partner_restaurants]);

  const filteredRestaurantOptions = useMemo(
    () => {
      return restaurantOptions.filter(option => option.city_id === cityValue)
    },
    [restaurantOptions]);

  const statusDetailOptions = useMemoStatusDetailOptions(statusDetails, statusSelectValue);

  const rejectOptions = useMemoRejectOptions(rejects, statusClarificationValue)

  const changeCandidateProfileAfterSaveQuestionnaire = useCallback((type: string, value: string) => {
    const newObj = {...candidateProfileData};
    newObj[`count_${type}`] = value;
    setCandidateProfileData(newObj);
  }, [candidateProfileData, setCandidateProfileData]);

  const onOpenInterviewModal = useCallback(
    (event) => {
      event.preventDefault();

      ModalsStore.showModal("candidate-profile-interview-modal", {
        interviewData: interviewObjectValue,
        candidateData: {
          city: candidateProfileData.city || cities.find((item) => item.id === cityValue),
          restaurant: candidateProfileData.restaurant || restaurants.find(
            (item) => item.id === restaurantValue
          ),
          candidateProfileId: candidateProfileData.id
        },
        onChange: (value) => setValue("interview", value, {shouldValidate: true}),
      });
    },
    [interviewObjectValue, cities, restaurants, candidateProfileData, restaurantValue]
  );

  /**
   * Изменение рекомендации
   */
  const changeRecommendation = useCallback(async recommendation => {
    await putCandidateProfile(candidateProfileData.id, {recommendation});
    setCandidateProfileData({...candidateProfileData, recommendation})
  }, [candidateProfileData, setCandidateProfileData]);

  /**
   * Изменение резюме
   */
  const changeResumeFileName = useCallback(async filename => {
    let data = candidateProfileData;
    if (candidateProfileData.id) {
      await putCandidateProfile(candidateProfileData.id, {resume_file_name: filename});
    } else {
      const formData = getValues();
      data = {
        candidate_profile_status: {id: formData.status},
        first_name: formData.name,
        second_name: formData.surname,
        age: formData.age,
        sex: formData.sex,
        phone: formData.phone,
        email: formData.email,
        city: {id: formData.city},
        experience: formData.experience,
        vacancy: {id: parseInt(formData.vacancy_id)},
        restaurant: {id: parseInt(formData.restaurant_id)},
        source: {id: formData.source},
        citizenship: {id: formData.citizenship},
        reject_reason: formData.refuseReason,
      }
    }
    setCandidateProfileData({...data, resume_file_name: filename})
  }, [candidateProfileData, setCandidateProfileData]);

  const onOpenHandOverFormModal = useCallback((event) => {
    event.preventDefault();
    ModalsStore.showModal("hand-over-form-modal", {
      pickedCity: cityValue,
      cityOptions,
      restaurantOptions,
      onChange: (restaurant_id, city_id) => {
        setValue("restaurant_id", restaurant_id);
        setValue("city", city_id);
        setCandidateProfileData(
          {
            ...candidateProfileData,
            restaurant: restaurants.find(restaurant => restaurant.id === restaurant_id)
        })
        setValue('interview', {
          description: "",
          interview_date: null,
        }, {shouldValidate: true})
      }
    });
  }, [candidateProfileData, cityValue]);

  const onVacancyFormModal = useCallback(
    (event) => {
      event.preventDefault();
      ModalsStore.showModal("vacancy-form-modal", {
        data: candidateProfileData,
        vacanciesList: vacanciesOptions,
        onChange: (value) => setValue("vacancy_id", value.toString()),
      });
    },
    [candidateProfileData]
  );

  const onHistoryFormModal = useCallback(
    (event) => {
      event.preventDefault();
      ModalsStore.showModal("history-form-modal", {
        data: candidateProfileData,
      });
    },
    [candidateProfileData]
  );

  const onSubmit = useCallback(
    async (formData) => {
      const serverData = {
        candidate_profile_status_id: formData.status,
        first_name: formData.name,
        second_name: formData.surname || "",
        age: String(formData.age),
        email: formData.email,
        sex: formData.sex,
        phone: formData.phone,
        experience: formData.experience,
        vacancy_id: parseInt(formData.vacancy_id),
        restaurant_id: parseInt(formData.restaurant_id),
        source_id: formData.source,
        interview_date: formData.interview
          ? moment(formData.interview.interview_date, "DD.MM.YYYY HH:mm").format("YYYY-MM-DD HH:mm:ss")
          : "",
        citizenship_id: formData.citizenship,
        is_auto_dialing: false,
        is_interrogation: false,
        interview: formData.interview
      };

      if(formData.rjpDate) {
        serverData["rjp_date"] = formData.rjpDate;
      }
      if(formData.medicalDate) {
        serverData["medical_date"] = formData.medicalDate;
      }
      if(formData.registrationDate) {
        serverData["registration_date"] = formData.registrationDate;
      }
      if(formData.clarification) {
        serverData["status_detail_id"] = formData.clarification;
      }
      if(formData.refuseReason) {
        serverData["reject_id"] = formData.refuseReason;
      }
      if (candidateProfileData.resume_file_name) {
        serverData["resume_file_name"] = candidateProfileData.resume_file_name;
      }

      if(formData.resource){
        if(typeof formData.resource === "string") {
          serverData["source_resource_comment"] = formData.resource;
          serverData["source_resource_id"] = null;
        } else {
          serverData["source_resource_comment"] = null;
          serverData["source_resource_id"] = formData.resource;
        }
      }

      let candidateResponse;
      const interviewData = serverData.interview;
      delete serverData.interview;

      if (candidateProfileData.id) {
        const serverDataEmail = serverData["email"]
        if (candidateProfileData.email === serverDataEmail) {
          delete serverData["email"];
        }
        const response = await putCandidateProfile(candidateProfileData.id, serverData);

        if (response.data) {
          candidateResponse = response.data.data;
        } else {
          if (response?.response?.data?.error?.email) {
            setError('email', {type: 'errorEmail', message: 'Такой email уже зарегистрирован'});
          }
          return;
        }
      } else {
        serverData["source_marker"] = "added_manually";
        const response = await createCandidateProfile(serverData);

        if (response.data) {
          candidateResponse = response.data.data;
        } else {
          if (response?.response?.data?.error?.email) {
            setError('email', {type: 'errorEmail', message: 'Такой email уже зарегистрирован'});
          }
          return;
        }
      }

      if(formData.statusComment && candidateResponse.id) {
        const commentData = {
          model_id: candidateResponse.id,
          model_type: "App\\Models\\CandidateProfile",
          comment: formData.statusComment,
        };
        await createComment(commentData);
      }

      // TODO раскомментировать для возможности удаления собеседований
      // if(candidateProfileData.interview && !interviewData.interview_date) {
      //   await deleteCandidateInterview(
      //     candidateProfileData.interview.id
      //   );
      // }

      if (interviewData.interview_date && INTERVIEW_STATUSES.includes(statusSelectValue)) {
        interviewData.candidate_profile_id = candidateResponse.id;
        interviewData.interview_date =
          moment(interviewData.interview_date, "DD.MM.YYYY HH:mm").format("YYYY-MM-DD HH:mm:ss");

        if (!interviewData.description) {
          delete interviewData.description;
        }

        if (candidateProfileData.interview) {
          await putCandidateInterview(
            candidateProfileData.interview.id,
            interviewData
          );
        } else {
          await createCandidateInterview(interviewData);
        }
      }

      history.push(history.location.state?.goBackPath || privateRoutes.candidateFormsChk.path);
    },
    [candidateProfileData, statusSelectValue]
  );

  const getVacancy = (vacancyId) => {
    const vacancy = vacanciesOptions.find(el => el.value === vacancyId);

    if(vacancy) {
      return  vacancyId;
    }else {
      return  vacancies.find(el => el.id === vacancyId).name;
    }
  }

  const initFields = (fields) => {
    setValue("status", fields?.candidate_profile_status?.id);
    if(showClarification(fields?.candidate_profile_status?.id) && fields.status_detail_id){
      setValue("clarification", fields.status_detail_id)
    }
    if(showRJP(fields?.candidate_profile_status?.id) && fields.rjp_date)
      setValue("rjpDate", fields.rjp_date);
    if(showMedicalDate(fields?.candidate_profile_status?.id) && fields.medical_date)
      setValue("medicalDate", fields.medical_date);
    if(showRegistrationDate(fields?.candidate_profile_status?.id) && fields.registration_date)
      setValue("registrationDate", fields.registration_date)
    if(showRefuseReason(fields?.candidate_profile_status?.id, fields.status_detail_id)
      && fields.reject_id)
    {
      setValue("refuseReason", fields.reject_id);
    }
    setValue("surname", fields.second_name);
    setValue("name", fields.first_name);
    setValue("age", fields.age);
    setValue("sex", fields.sex);
    setValue("citizenship", fields.citizenship?.id);
    setValue("phone", fields.phone);
    setValue("email", fields.email ? fields.email : "");
    setValue("city", fields.city?.id);
    setValue("experience", fields.experience);
    setValue("vacancy_id", fields?.vacancy?.id
      ? getVacancy(fields?.vacancy?.id)
      : "");
    setValue("restaurant_id", fields.restaurant?.id);
    setValue("source", fields.source?.id);
    setValue("resource", fields.source_resource?.id || fields.source_resource_comment);
    setValue("comments", fields.comments);
    setValue("interview",
      {
        description: fields.interview?.description,
        interview_date: fields.interview ?
          moment(fields.interview?.interview_date, "YYYY-MM-DD HH:mm:ss")
            .format("DD.MM.YYYY HH:mm") :
          "",
      }
    );
  }

  const blockedSource = () => {
    return !!candidateProfileData?.source?.name && userData.role.name !== "superadmin";
  }

  const resourceRender = () => {
    const sourceId = watch("source")
    const resources = sources.find(source => source.id === sourceId)?.source_resources

    let disabled = false;
    if(candidateProfileData.id) {
      disabled = blockedSource()
    }

    return (
      <>
        {!!resources?.length &&
          <FieldsRowWrapper>
            <FieldElementWrapper fullWidth={true}>
              <Controller
                render={({field, fieldState}) => (
                  <Select
                    {...field}
                    {...fieldState}
                    title="Ресурс"
                    withSearch={true}
                    disabled={disabled}
                    options={resources.map(resource => ({value: resource.id, label: resource.name}))}
                    ref={null}
                  />
                )}
                name="resource"
                control={control}
              />
            </FieldElementWrapper>
          </FieldsRowWrapper>
        }
        {
          watch("source") === 51 && // источник "Другое"
          <FieldsRowWrapper>
            <FieldElementWrapper fullWidth={true}>
              <Controller
                render={({field, fieldState}) => (
                  <Input
                    {...field}
                    {...fieldState}
                    placeholder="Ресурс"
                    disabled={disabled}
                    ref={null}
                  />
                )}
                name="resource"
                control={control}
              />
            </FieldElementWrapper>
          </FieldsRowWrapper>
        }
    </>
  )}

  const onChangeSource = (value, onChange) => {
    onChange(value)
    setValue("resource", "")
  }

  return (
    <>
      {(isLoading || !allDataIsDone)
          ?
        <Loader width={100} height={100}/>
          :
        isInsufficientRights
            ?
        <Page404 text={NOT_FOUND_TEXT} />
            :
        <CandidateFormWrapper
          onSubmit={handleSubmit(onSubmit)}
          onKeyDown={checkKeyDown}
        >
          <FormHeader>
            <FlexBlock gap={24} alignItems={"center"}>
              <ActionIcon
                action={() => {
                  history.goBack();
                  ReactTooltip.hide();
                }}
                tooltip="Вернуться к списку"
              >
                <ArrowBack/>
              </ActionIcon>
              <H1>Анкета ID {candidateProfileData.id}</H1>
              {candidateProfileData.id && (
                <FlexBlock gap={16} alignItems={"center"}>
                  <DateWrapper>
                    <DateLabelWrapper>Анкета получена</DateLabelWrapper>
                    <DateValueWrapper>
                      {moment(candidateProfileData.created_at).format("DD.MM.YYYY HH:mm")}
                    </DateValueWrapper>
                  </DateWrapper>
                  <DateWrapper>
                    <DateLabelWrapper>Последнее изменение</DateLabelWrapper>
                    <DateValueWrapper>
                      {moment(candidateProfileData.updated_at).format("DD.MM.YYYY HH:mm")}
                    </DateValueWrapper>
                  </DateWrapper>
                  {userData.role.name === "superadmin" &&
                    <ActionIcon action={onHistoryFormModal}>
                      <HistoryIcon
                        width="29"
                        height="29"
                        fill={theme.colors.neutral7}
                      />
                    </ActionIcon>
                  }
                </FlexBlock>
              )}
            </FlexBlock>

            <HeaderRightSide>
              <Button
                title="Сохранить"
                type="submit"
                btnStyle="primary"
                disabled={!isValid}
              />
            </HeaderRightSide>
          </FormHeader>

          <FormRowWrapper>
            <BlockWrapper>
              <H2>Статус анкеты<StarWrapper> *</StarWrapper></H2>
              <StatusContentWrapper>
                <StatusSelectWrapper
                  // fullWidth={INTERVIEW_STATUSES.includes(statusSelectValue)}
                  fullWidth={true}
                >
                  <Controller
                    render={({field, fieldState}) => (
                      <Select
                        {...field}
                        {...fieldState}
                        title=""
                        onChange={(value) => {
                          setValue('clarification', null)
                          setValue('statusComment', null)
                          setValue('refuseReason', null)
                          setValue('rjpDate', null)
                          setValue('medicalDate', null)
                          setValue('registrationDate', null)
                          field.onChange(value);
                        }}
                        disabled={candidateProfileData?.candidate_profile_status?.id === 30}
                        options={getStatuses(candidateProfileData, candidateProfileStatuses, userData)}
                        ref={null}
                      />
                    )}
                    name="status"
                    control={control}
                  />
                </StatusSelectWrapper>

                {showRJP(statusSelectValue) &&
                  <StatusInputWrapper>
                    <Controller
                      render={({field, fieldState}) => (
                        <Datepicker
                          value={field.value
                            ? moment(field.value, "YYYY-MM-DD HH:mm:ss")
                            : null
                          }
                          timeInput={true}
                          title="Дата и время RJP"
                          required={true}
                          onChange={(date) => field.onChange(date.format("YYYY-MM-DD HH:mm:ss"))}
                        />
                      )}
                      name="rjpDate"
                      control={control}
                    />
                  </StatusInputWrapper>
                }

                {showMedicalDate(statusSelectValue) &&
                  <StatusInputWrapper>
                    <Controller
                      render={({field, fieldState}) => (
                        <Datepicker
                          value={field.value
                            ? moment(field.value, "YYYY-MM-DD HH:mm:ss")
                            : null
                          }
                          timeInput={true}
                          title="Дата и время медосмотра"
                          required={true}
                          onChange={(date) => field.onChange(date.format("YYYY-MM-DD HH:mm:ss"))}
                        />
                      )}
                      name="medicalDate"
                      control={control}
                    />
                  </StatusInputWrapper>
                }

                {showRegistrationDate(statusSelectValue) &&
                  <StatusInputWrapper>
                    <Controller
                      render={({field, fieldState}) => (
                        <Datepicker
                          value={field.value
                            ? moment(field.value, "YYYY-MM-DD HH:mm:ss")
                            : null
                          }
                          timeInput={true}
                          title="Дата и время оформления"
                          required={true}
                          onChange={(date) => field.onChange(date.format("YYYY-MM-DD HH:mm:ss"))}
                        />
                      )}
                      name="registrationDate"
                      control={control}
                    />
                  </StatusInputWrapper>
                }
                {showClarification(statusSelectValue) &&
                  <StatusInputWrapper>
                    <Controller
                      render={({field, fieldState}) => (
                        <Select
                          {...field}
                          {...fieldState}
                          title="Уточнение"
                          onChange={(value) => {
                            field.onChange(value);
                            setValue('refuseReason', null, { shouldValidate: true })
                            setValue('statusComment', null)
                          }}
                          options={statusDetailOptions}
                          error={errors?.clarification?.message}
                          required={true}
                          ref={null}
                        />
                      )}
                      name="clarification"
                      control={control}
                    />
                  </StatusInputWrapper>
                }
                {showRefuseReason(statusSelectValue, statusClarificationValue) && (
                  <StatusInputWrapper>
                    <Controller
                      render={({field, fieldState}) => (
                        <Select
                          {...field}
                          {...fieldState}
                          title="Причина отказа"
                          onChange={(value) => {
                            field.onChange(value);
                            setValue('statusComment', null)
                          }}
                          options={rejectOptions}
                          required={true}
                          ref={null}
                        />
                      )}
                      name="refuseReason"
                      control={control}
                    />
                  </StatusInputWrapper>
                )}
                {showStatusComment(statusSelectValue, statusRefuseValue, statusClarificationValue) && (
                  <StatusInputWrapper>
                    <Controller
                      render={({field, fieldState}) => (
                        <Input
                          {...field}
                          {...fieldState}
                          placeholder="Комментарий"
                          error={errors?.statusComment?.message}
                          required={true}
                          ref={null}
                        />
                      )}
                      name="statusComment"
                      control={control}
                    />
                  </StatusInputWrapper>
                )}
              </StatusContentWrapper>
            </BlockWrapper>

            {showInterview(candidateProfileData, statusSelectValue, restaurants, cities) && (
                <BlockWrapper>
                  <Interview
                    onOpenInterviewModal={onOpenInterviewModal}
                    candidateProfileData={candidateProfileData}
                    interviewObjectValue={interviewObjectValue}
                    statusSelectValue={statusSelectValue}
                    cityValue={cityValue}
                    restaurantValue={restaurantValue}
                    formType="admin"
                  />
                </BlockWrapper>
              )}
          </FormRowWrapper>

          <MainContentWrapper>
            <MainContentBlockWrapper>
              <H2>Информация о соискателе</H2>
              <MainContentFields>
                <FieldsRowWrapper>
                  <FieldElementWrapper fullWidth={false}>
                    <Controller
                      render={({field, fieldState}) => (
                        <Input
                          {...field}
                          {...fieldState}
                          placeholder="Фамилия"
                          onChange={value => onChangeOnlyLetters(value, field.onChange)}
                          error={errors?.surname?.message}
                          ref={null}
                        />
                      )}
                      name="surname"
                      control={control}
                    />
                  </FieldElementWrapper>
                  <FieldElementWrapper fullWidth={false}>
                    <Controller
                      render={({field, fieldState}) => (
                        <Input
                          {...field}
                          {...fieldState}
                          placeholder="Имя"
                          onChange={value => onChangeOnlyLetters(value, field.onChange)}
                          required={true}
                          error={errors?.name?.message}
                          ref={null}
                        />
                      )}
                      name="name"
                      control={control}
                    />
                  </FieldElementWrapper>
                  <FieldElementWrapper fullWidth={false}>
                    <Controller
                      render={({field, fieldState}) => (
                        <Input
                          {...field}
                          {...fieldState}
                          placeholder="Возраст"
                          required={true}
                          type="number"
                          positive={true}
                          error={errors?.age?.message}
                          ref={null}
                        />
                      )}
                      name="age"
                      control={control}
                    />
                  </FieldElementWrapper>
                  <FieldElementWrapper fullWidth={false}>
                    <Controller
                      render={({field, fieldState}) => (
                        <Select
                          {...field}
                          {...fieldState}
                          title="Пол"
                          required={true}
                          options={[
                            {label: Gender.male, value: Gender.male},
                            {label: Gender.female, value: Gender.female}
                          ]}
                          ref={null}
                        />
                      )}
                      name="sex"
                      control={control}
                    />
                  </FieldElementWrapper>
                  <FieldElementWrapper fullWidth={false}>
                    <Controller
                      render={({field, fieldState}) => (
                        <Select
                          {...field}
                          {...fieldState}
                          title="Гражданство"
                          required={true}
                          options={citizenship.map((item) => ({
                            value: item.id,
                            label: item.name,
                          }))}
                          ref={null}
                        />
                      )}
                      name="citizenship"
                      control={control}
                    />
                  </FieldElementWrapper>
                  <FieldElementWrapper fullWidth={false}>
                    <Controller
                      render={({field, fieldState}) => (
                        <Input
                          {...field}
                          {...fieldState}
                          value={field.value[0] === '8' ? field.value.slice(1) : field.value}
                          mask={"+{7}(000)000-00-00"}
                          placeholder="Номер телефона"
                          required={true}
                          error={errors?.phone?.message}
                          ref={null}
                        />
                      )}
                      name="phone"
                      control={control}
                    />
                  </FieldElementWrapper>
                  <FieldElementWrapper fullWidth={false}>
                    <Controller
                      render={({field, fieldState}) => (
                        <Input
                          {...field}
                          {...fieldState}
                          placeholder="Email"
                          error={errors?.email?.message}
                          ref={null}
                        />
                      )}
                      name="email"
                      control={control}
                    />
                  </FieldElementWrapper>
                  <FieldElementWrapper fullWidth={false}>
                    <Controller
                      render={({field, fieldState}) => (
                        <Select
                          {...field}
                          {...fieldState}
                          onChange={(value) => {
                            if (field.value === value) return;
                            field.onChange(value)
                            setValue("restaurant_id", null)
                            setValue("vacancy_id", "")
                          }}
                          title="Город"
                          required={true}
                          withSearch={true}
                          options={cityOptions}
                          disabled={candidateProfileData.id || userData.role.name === "restaurant"}
                          ref={null}
                        />
                      )}
                      name="city"
                      control={control}
                    />
                  </FieldElementWrapper>
                  <LastRowWrapper>
                    <FieldElementWrapper fullWidth={true}>
                      <Controller
                        render={({field, fieldState}) => (
                          <Input
                            {...field}
                            {...fieldState}
                            placeholder="Опыт"
                            type="number"
                            positive={true}
                            ref={null}
                          />
                        )}
                        name="experience"
                        control={control}
                      />
                    </FieldElementWrapper>
                  </LastRowWrapper>
                </FieldsRowWrapper>
              </MainContentFields>
            </MainContentBlockWrapper>

            <SecondContentBlockWrapper>
              {!candidateProfileData.id && (
                <MainContentBlockWrapper width={"calc((100% - 24px) / 2)"}>
                  <H2>Отклик</H2>
                  <MainContentFields>

                    <FieldsRowWrapper>
                      <FieldElementWrapper fullWidth={true}>
                        <Controller
                          render={({field, fieldState}) => (
                            <Select
                              {...field}
                              {...fieldState}
                              title="Ресторан"
                              required={true}
                              options={filteredRestaurantOptions}
                              disabled={!cityValue || userData.role.name === "restaurant"}
                              onChange={(value) => {
                                if (field.value === value) return;
                                field.onChange(value)
                                setValue("vacancy_id", "")
                              }}
                              withSorting={true}
                              withSearch={true}
                              ref={null}
                            />
                          )}
                          name="restaurant_id"
                          control={control}
                        />
                      </FieldElementWrapper>
                    </FieldsRowWrapper>

                    <FieldsRowWrapper>
                      <FieldElementWrapper fullWidth={true}>
                        <Controller
                          render={({field, fieldState}) => (
                            <Select
                              {...field}
                              {...fieldState}
                              title="Вакансия"
                              required={true}
                              options={vacanciesOptions}
                              disabled={!restaurantValue}
                              ref={null}
                            />
                          )}
                          name="vacancy_id"
                          control={control}
                        />
                      </FieldElementWrapper>
                    </FieldsRowWrapper>

                    <FieldsRowWrapper>
                      <FieldElementWrapper fullWidth={true}>
                        <Controller
                          render={({field, fieldState}) => (
                            <Select
                              {...field}
                              {...fieldState}
                              title="Источник"
                              onChange={value => onChangeSource(value, field.onChange)}
                              required={true}
                              withSearch={true}
                              optionsDirection={"top"}
                              options={sources.filter(item => item.source_type === FOR_MANUAL_INPUT)
                                .map(item => ({
                                    value: item.id,
                                    label: item.name,
                                  })
                                )}
                              ref={null}
                            />
                          )}
                          name="source"
                          control={control}
                        />
                      </FieldElementWrapper>
                    </FieldsRowWrapper>

                    {resourceRender()}
                  </MainContentFields>
                </MainContentBlockWrapper>
              )}

              {candidateProfileData.id && (
                <MainContentBlockWrapper width={"calc((100% - 24px) / 2)"}>
                  <H2>Отклик</H2>
                  <MainContentFields>
                    <ReviewContainer>
                      <ReviewInfo>
                        <span>Город</span>
                        <span>{cities.find(item => item.id === cityValue)?.name_ru}</span>
                      </ReviewInfo>
                    </ReviewContainer>

                    <ReviewContainer>
                      <FieldsRowWrapper>
                        <FieldElementWrapper fullWidth={true}>
                          <ReviewInfo>
                            <span>Ресторан</span>
                            <span>{candidateProfileData?.restaurant?.name_ru}</span>
                          </ReviewInfo>
                          {/*<Controller*/}
                          {/*  render={({ field, fieldState }) => (*/}
                          {/*    <Select*/}
                          {/*      {...field}*/}
                          {/*      {...fieldState}*/}
                          {/*      title="Ресторан"*/}
                          {/*      options={restaurants.map((item) => ({*/}
                          {/*        value: item.id,*/}
                          {/*        label: item.name_ru,*/}
                          {/*      }))}*/}
                          {/*      disabled={true}*/}
                          {/*      noBorder={true}*/}
                          {/*      ref={null}*/}
                          {/*    />*/}
                          {/*  )}*/}
                          {/*  name="restaurant_id"*/}
                          {/*  control={control}*/}
                          {/*/>*/}
                        </FieldElementWrapper>
                      </FieldsRowWrapper>
                      <Button
                        title="Передать другому ресторану"
                        btnStyle="secondary"
                        onClick={onOpenHandOverFormModal}
                        disabled={cities.length === 0}
                      />
                    </ReviewContainer>

                    <ReviewContainer>
                      <FieldsRowWrapper>
                        <FieldElementWrapper fullWidth={true}>
                          <ReviewInfo>
                            <span>Вакансия</span>
                            <span>{vacanciesOptions.find(item => item.value === Number(vacancySelectValue))?.label}</span>
                          </ReviewInfo>
                          {/*<Controller*/}
                          {/*  render={({ field, fieldState }) => (*/}
                          {/*    <Select*/}
                          {/*      {...field}*/}
                          {/*      {...fieldState}*/}
                          {/*      title="Вакансия"*/}
                          {/*      options={vacancyEnum}*/}
                          {/*      ref={null}*/}
                          {/*      disabled={true}*/}
                          {/*      noBorder={true}*/}
                          {/*    />*/}
                          {/*  )}*/}
                          {/*  name="vacancy_id"*/}
                          {/*  control={control}*/}
                          {/*/>*/}
                        </FieldElementWrapper>
                      </FieldsRowWrapper>
                      <Button
                        title="Изменить вакансию"
                        btnStyle="secondary"
                        onClick={onVacancyFormModal}
                      />
                    </ReviewContainer>

                    <FieldsRowWrapper>
                      <FieldElementWrapper fullWidth={true}>
                        <Controller
                          render={({field, fieldState}) => (
                            <Select
                              {...field}
                              {...fieldState}
                              title="Источник"
                              onChange={value => onChangeSource(value, field.onChange)}
                              required={true}
                              withSearch={true}
                              optionsDirection={"top"}
                              options={sources.filter(item => item.source_type === FOR_MANUAL_INPUT)
                                .map(item => ({
                                    value: item.id,
                                    label: item.name,
                                  })
                                )}
                              disabled={blockedSource()}
                              ref={null}
                            />
                          )}
                          name="source"
                          control={control}
                        />
                      </FieldElementWrapper>
                    </FieldsRowWrapper>

                    {resourceRender()}
                  </MainContentFields>
                </MainContentBlockWrapper>
              )}

              <SecondContentBlockWrapper
                width={"calc((100% - 24px) / 2)"}
                minWidth={"578px"}
              >
                {userData.role.name !== "restaurant" && (
                  <ShortContentBlockWrapper
                    width={"calc((100% - 24px) / 2)"}
                    minWidth={"277px"}
                  >
                    <H2>Резюме</H2>
                    <MainContentFields>
                      <Resume candidate_profile={candidateProfileData}
                              changeResumeFileName={changeResumeFileName}/>
                    </MainContentFields>
                  </ShortContentBlockWrapper>
                )}

                {candidateProfileData.id && (
                  <MainContentBlockWrapper
                    width={"calc((100% - 24px) / 2)"}
                    minWidth={"277px"}
                  >
                    <MainContentFields>
                      <Operations candidateProfile={candidateProfileData}
                                  changeRecommendation={changeRecommendation}
                                  updateAfterSave={changeCandidateProfileAfterSaveQuestionnaire}/>
                    </MainContentFields>
                  </MainContentBlockWrapper>
                )}
              </SecondContentBlockWrapper>
            </SecondContentBlockWrapper>

            {candidateProfileData.id && (
              <SecondContentBlockWrapper>
                <CommentsBlockWrapper width={"calc((100% - 24px) / 2)"}>
                  <Comments
                    data={candidateProfileData}
                    type={"App\\Models\\CandidateProfile"}
                    getValues={getValues}
                    resetField={resetField}
                  />
                </CommentsBlockWrapper>

                <MainContentBlockWrapper width={"calc((100% - 24px) / 2)"}>
                  <MainContentFields>
                    <Testing candidateProfile={candidateProfileData}/>
                  </MainContentFields>
                </MainContentBlockWrapper>
              </SecondContentBlockWrapper>
            )}
          </MainContentWrapper>
          <HandOverFormModal/>
          <VacancyFormModal/>
          <HistoryFormModal/>
          <InterviewModal/>
          <Toast/>
        </CandidateFormWrapper>
      }
    </>
  );
});

//region Styled
const H1 = styled.div`
  font-family: ${theme.fonts.condensedBlack};
  font-size: ${theme.fontSizes.h1};
  color: ${theme.colors.neutral10};
`;

const H2 = styled.div`
  font-size: ${theme.fontSizes.h2};
  color: ${theme.colors.neutral10};
  font-family: ${theme.fonts.mainBlack};
  margin-bottom: 16px;
`;

const CandidateFormWrapper = styled.form`
  font-family: ${theme.fonts.mainRegular};
  padding-bottom: 16px;
`;

const FormHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;

  @media (max-width: 820px) {
    flex-wrap: wrap;
    gap: 16px;
  }
`;

const FlexBlock = styled.div<{
  gap?: number;
  alignItems?: string;
  justifyContent?: string;
}>`
  display: flex;
  ${({alignItems}) => alignItems && "align-items: " + alignItems};
  ${({gap}) => gap && "gap: " + gap + "px"};
  ${({justifyContent}) =>
          justifyContent && "justify-content: " + justifyContent};
`;

const DateWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const DateLabelWrapper = styled.div`
  font-size: ${theme.fontSizes.small};
  color: ${theme.colors.neutral7};
  margin-bottom: 5px;
`;

const DateValueWrapper = styled.div`
  font-size: ${theme.fontSizes.body};
  color: ${theme.colors.neutral10};
`;

const HeaderRightSide = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const FormRowWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 24px;
  gap: 24px;

  @media (max-width: 1275px) {
    flex-direction: column;
  }
`;

const BlockWrapper = styled.div`
  border-radius: 4px;
  padding: 16px;
  background: #ffffff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
`;

const StatusContentWrapper = styled.div`
  display: flex;
  gap: 16px;

  @media (max-width: 740px) {
    flex-direction: column;

    & > div {
      width: 100%;
    }
  }
`;

const StatusSelectWrapper = styled.div<{ fullWidth: boolean }>`
  width: ${props => props.fullWidth ? "100%" : "50%"};
`;

const StatusInputWrapper = styled.div`
  width: 50%;
`;

const MainContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const ReviewContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const MainContentBlockWrapper = styled.div<{
  width?: string;
  minWidth?: string;
}>`
  background: #ffffff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 16px;
  flex-grow: 1;
  ${({width}) => width && "width: " + width};
  ${({minWidth}) => minWidth && "min-width: " + minWidth};

  @media (max-width: 630px) {
    flex-wrap: wrap;
    ${({minWidth}) => minWidth && "min-width: 100%; width: 100%"};
  }
`;

const ShortContentBlockWrapper = styled(MainContentBlockWrapper)`
  flex-grow: 0;

  @media (max-width: 630px) {
    flex-grow: 1;
  }
`;

const SecondContentBlockWrapper = styled.div<{
  width?: string;
  minWidth?: string;
}>`
  display: flex;
  gap: 24px;
  flex-grow: 1;
  flex-wrap: wrap;
  ${({width}) => width && "width: " + width};
  ${({minWidth}) => minWidth && "min-width: " + minWidth};

  @media (max-width: 630px) {
    ${({minWidth}) => minWidth && "min-width: 100%; width: 100%"};
  }
`;

const MainContentFields = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
`;

const LastRowWrapper = styled.div`
  display: flex;
  gap: 16px;
`;

// const ExpRowWrapper = styled.div`
//   & > div {
//     width: calc((100% - 48px) / 4);
//
//     @media (max-width: 877px) {
//       width: 100%;
//     }
//   }
// `;

const FieldsRowWrapper = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;

  & > div {
    min-width: 180px;
    width: calc((100% - 48px) / 4);
    flex-grow: 1;
  }
`;

const FieldElementWrapper = styled.div<{ fullWidth: boolean }>`
  width: ${(props) => (props.fullWidth ? "100%" : "25%")};
`;

const CommentsBlockWrapper = styled(MainContentBlockWrapper)`
  display: flex;
  flex-direction: column;
  height: 100%;

  @media (max-width: 720px) {
    min-width: 578px;
  }

  @media (max-width: 626px) {
    min-width: 100%;
  }
`;

const ReviewInfo = styled.div`
  width: 100%;

  span {
    display: block;
    font-family: ${theme.fonts.mainRegular};

    &:first-child {
      margin-bottom: 5px;
      font-size: ${theme.fontSizes.small};
      color: ${theme.colors.neutral7};
    }

    &:last-child {
      font-size: ${theme.fontSizes.body};
      color: ${theme.colors.neutral10};
    }
  }
`;

const StarWrapper = styled.span`
  color: ${theme.colors.primary};
`;

//endregion

export default CandidateForm;
