import React, {FC, forwardRef, useState} from "react";
import DatePicker, {setDefaultLocale, registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ru from 'date-fns/locale/ru';
import moment from "moment";
import styled from "styled-components";
import {theme} from "../../styles/theme";
import CalendarIcon from "../../assets/icons/CalendarIcon";

registerLocale('ru', ru);
setDefaultLocale('ru', ru);

const InputWrapper = styled.div<{calendarOpen: boolean, disabled: boolean,}>`
  width: 100%;
  position: relative;
  height: 40px;
  padding: 10px 34px 10px 10px;
  line-height: 20px;
  font-family: ${theme.fonts.mainRegular};
  font-size: ${theme.fontSizes.body};
  color: ${theme.colors.bodyText};
  ${({disabled}) => disabled && 'pointer-events: none;'}
  background-color: ${({disabled}) => disabled ? theme.colors.neutral1 : "#ffffff"};
    border: ${({calendarOpen, theme}) =>
      `1px solid ${
          !calendarOpen ? theme.colors.neutral7 : theme.colors.primaryDark
        } `};
  border-radius: 4px;
  cursor: ${({disabled}) => !disabled ? 'pointer' : 'auto'};

    &:hover {
        ${({disabled, theme, calendarOpen}) =>
          !disabled &&
          !calendarOpen &&
          `
        border: 1px solid` +
          theme.colors.neutral10 +
          `;`}
    }
`;

const Title = styled.div<{calendarOpen: boolean, disabled: boolean,}>`
  position: absolute;
  background: white;
  left: 10px;
  top: -6px;
  line-height: 12px;
  padding: 0 3px;
  font-family: ${theme.fonts.mainRegular};
  font-size: ${theme.fontSizes.small};
    color: ${({calendarOpen, theme}) =>
      !calendarOpen ? theme.colors.neutral6 : theme.colors.primaryDark};

    &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        bottom: 0;
        background-color: ${({disabled}) => disabled ? theme.colors.neutral1 : "#ffffff"};
    }
    span {
        position: relative;
    }
`;

const IconWrapper = styled.div`
    position: absolute;
    top: 8px;
    right: 5px;
    
    path {
        fill: ${theme.colors.neutral7}
    }
`;

interface Props {
    value: moment.Moment;
    onChange: (date: moment.Moment) => void;
    format?: string;
    title?: string;
    monthSelect?: boolean;
    minDate?: Date;
    timeInput?: boolean;
    required?: boolean;
    disabled?: boolean;
}

interface PropsCustomInput {
    value?: string,
    onClick?: () => void,
}

const Datepicker: FC<Props> = ({
  value,
  monthSelect,
  onChange,
  format="dd.MM.yyyy",
  title,
  minDate,
  timeInput = false,
  required = false,
  disabled = false,
}) => {
    const _onChange = (date:Date) => {
        const momentDate = moment(date);
        onChange(momentDate);
    };

    const [calendarOpen, setCalendarOpen] = useState(false);

    const CustomInput = forwardRef<HTMLDivElement, PropsCustomInput>(({
          value,
          onClick },
      ref) => {
        return (
            <InputWrapper
              onClick={onClick}
              ref={ref}
              calendarOpen={calendarOpen}
              disabled={disabled}
            >
                {
                    title &&
                    <Title calendarOpen={calendarOpen} disabled={disabled}>
                      <span>{title}{required && <StarWrapper> *</StarWrapper>}</span>
                    </Title>
                }
                {value}
                <IconWrapper>
                    <CalendarIcon/>
                </IconWrapper>
            </InputWrapper>
        )
    });

    let setting = {};
    if (monthSelect) {
        setting["showMonthYearPicker"] = true;
        setting["showFullMonthYearPicker"] = true;
        setting["showTwoColumnMonthYearPicker"] = true;
    }
    if(minDate) {
      setting["minDate"] = minDate;
    }

    if(timeInput) {
      setting["showTimeSelect"] = true;
      setting["timeFormat"] = "p";
      setting["timeCaption"] = "Время";
      setting["timeIntervals"] = 15;
      // setting["excludeTimes"] = [
      //   new Date(value?.toDate()).setHours(17, 30),
      //   // new Date(value?.toDate())
      //   // setHours(setMinutes(new Date(), 0), 17),
      // ]
      // OR
      // minTime={setHours(setMinutes(new Date(), 0), 17)}
      // maxTime={setHours(setMinutes(new Date(), 30), 20)}

      // setting["timeInputLabel"] = "Время:";
      // setting["showTimeInput"] = true;
    }

    return (
        <DatePicker
          selected={value?.toDate()}
          dateFormat={timeInput ? `${format} H:mm` : format}
          customInput={<CustomInput/>}
          onChange={_onChange}
          onCalendarOpen={() => setCalendarOpen(true)}
          onCalendarClose={() => setCalendarOpen(false)}
          {...setting}
        />
    );
};

const StarWrapper = styled.span`
  color: ${theme.colors.primary};
`;

export default Datepicker;
