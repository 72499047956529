import {observer} from "mobx-react-lite";
import React, {FC, useMemo} from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import addFunnel from "highcharts/modules/funnel";
import styled from "styled-components";
import {theme} from "../../styles/theme";

addFunnel(Highcharts);

interface Props {
    data: any;
    total: number;
}

const AnalyticFunnel: FC<Props> = observer(({data, total}) => {
    const first = Object.values(data.first).map((i:any) => i.count)
        .reduce((partialSum, a) => partialSum + a, 0);
    const second = Object.values(data.second).map((i:any) => i.count)
        .reduce((partialSum, a) => partialSum + a, 0);
    const third = Object.values(data.third).map((i:any) => i.count)
        .reduce((partialSum, a) => partialSum + a, 0);
    const fourth = Object.values(data.fourth).map((i:any) => i.count)
        .reduce((partialSum, a) => partialSum + a, 0);
    const fifth = Object.values(data.fifth).map((i:any) => i.count)
        .reduce((partialSum, a) => partialSum + a, 0);
    const sixth = Object.values(data.sixth).map((i:any) => i.count)
        .reduce((partialSum, a) => partialSum + a, 0);

    const chartOptions = useMemo(() => {
        return {
            chart: {
                type: "funnel",
                height: "500px",
            },
            title: {
                text: "",
            },
            plotOptions: {
                funnel: {
                    depth: 100,
                },
                series: {
                    dataLabels: {
                        enabled: true,
                        format: "<b>{point.name}</b> <br/>{point.value:,.0f} ({point.yPercentage:,.0f}%)",
                        softConnector: true,
                        inside: true,
                        color: '#ffffff',
                        className: "funnel-label",
                        useHTML: true
                    },
                    center: ["50%", "50%"],
                    neckWidth: "0%",
                    neckHeight: "0%",
                    width: "100%",
                    borderWidth: 0,
                },
            },
            legend: {
                enabled: false,
            },
            tooltip: {
                formatter: function() {
                    const { tooltipData, value } = this.point.options;
                    return Object.keys(tooltipData)
                        .map(key => {
                            const percent = tooltipData[key].count / value * 100;
                            return `${key} (${percent.toFixed(2)}%)`;
                        })
                        .join(`<br/>`);
                },
                followPointer: false,
                borderRadius: 4,
                backgroundColor: theme.colors.neutral8,
                borderColor: "transparent",
                padding: 10,
                distance: 30,
                shadow: false,
                hideDelay: 100,
                className: "chart-tooltip",
                useHTML: true,
                outside: true,
                style: {
                    color: "#ffffff",
                    fontSize: "14px",
                }
            },
            series: [
                {
                    name: "Анкет",
                    data: [
                        {
                            name: "Получено анкет всего",
                            y: 1000,
                            value: first,
                            color: "#D9022A",
                            yPercentage: first ? first / total * 100 : 0,
                            tooltipData: data.first
                        },
                        {
                            name: "Получено релевантных анкет",
                            y: 1000,
                            value: second,
                            color: "#BC0023",
                            yPercentage: second ? second / total * 100 : 0,
                            tooltipData: data.second
                        },
                        {
                            name: "Приглашено на собеседование",
                            y: 1000,
                            value: third,
                            color: "#E4002B",
                            yPercentage: third ? third / second * 100 : 0,
                            tooltipData: data.third
                        },
                        {
                            name: "Пришло на собеседование",
                            y: 1000,
                            value: fourth,
                            color: "#E2002A",
                            yPercentage: fourth ? fourth / third * 100 : 0,
                            tooltipData: data.fourth
                        },
                        {
                            name: "Отправлено на оформление",
                            y: 1000,
                            value: fifth,
                            color: "#F2002D",
                            yPercentage: fifth ? fifth / fourth * 100 : 0,
                            tooltipData: data.fifth
                        },
                        {
                            name: "Трудоустроено",
                            y: 1000,
                            value: sixth,
                            color: "#FF0130",
                            yPercentage: sixth ? sixth / fifth * 100 : 0,
                            tooltipData: data.sixth
                        },
                    ],
                },
            ],
        };
    }, [data.fifth, data.first, data.fourth, data.second, data.sixth, data.third, fifth, first, fourth, second, sixth, third, total]);

    return (
        <Wrapper>
            <HighchartsReact highcharts={Highcharts} options={chartOptions}/>
        </Wrapper>
    );
});

const Wrapper = styled.div`
  width: 100%;
    
  @media (max-width: 1175px) {
    overflow: auto;
    
    &>div {
        width: 1000px;
    }
  }
`;

export default AnalyticFunnel;
