import React, {FC, forwardRef} from "react";
import DatePicker, {setDefaultLocale, registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ru from 'date-fns/locale/ru';
import moment from "moment";
import styled from "styled-components";
import {theme} from "../../styles/theme";
import CalendarIcon from "../../assets/icons/CalendarIcon";

registerLocale('ru', ru);
setDefaultLocale('ru', ru);

const InputWrapper = styled.div<{calendarOpen?: boolean, disabled: boolean,}>`
  width: 100%;
  position: relative;
  height: 40px;
  padding: 10px 34px 10px 10px;
  line-height: 20px;
  font-family: ${theme.fonts.mainRegular};
  font-size: ${theme.fontSizes.body};
  color: ${theme.colors.bodyText};
  background-color: ${({disabled}) => disabled ? theme.colors.neutral1 : "#ffffff"};
    border: ${({calendarOpen, theme}) =>
  `1px solid ${
    !calendarOpen ? theme.colors.neutral7 : theme.colors.primaryDark
  } `};
  border-radius: 4px;
  cursor: ${({disabled}) => !disabled ? 'pointer' : 'auto'};

    &:hover {
        ${({disabled, theme, calendarOpen}) =>
  !disabled &&
  !calendarOpen &&
  `
        border: 1px solid` +
  theme.colors.neutral10 +
  `;`}
    }
`;

const Title = styled.div<{calendarOpen?: boolean, disabled: boolean,}>`
  position: absolute;
  background: white;
  left: 10px;
  top: -6px;
  line-height: 12px;
  padding: 0 3px;
  font-family: ${theme.fonts.mainRegular};
  font-size: ${theme.fontSizes.small};
    color: ${({calendarOpen, theme}) =>
  !calendarOpen ? theme.colors.neutral6 : theme.colors.primaryDark};

    &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        bottom: 0;
        background-color: ${({disabled}) => disabled ? theme.colors.neutral1 : "#ffffff"};
    }
    span {
      position: relative;
      width: fit-content;
      display: block;
    }
`;

const IconWrapper = styled.div`
  position: absolute;
  top: 8px;
  right: 5px;

  path {
    fill: ${theme.colors.neutral7}
  }
`;

interface Props {
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
  onChange: (startDate: string, endDate: string) => void,
  format?: string,
  title?: string,
  monthSelect?: boolean,
  disabled?: boolean,
}

interface PropsCustomInput {
  value?: string,
  onClick?: () => void,
}

const Datepicker: FC<Props> = ({
  startDate,
  endDate,
  monthSelect,
  onChange,
  format="dd.MM.yyyy",
  title,
disabled = false,
}) => {

  const _onChange = (dates) => {
    const [start, end] = dates;

    const from = start ? moment(start).format("YYYY-MM-DD") : null;
    const to = end ? moment(end).format("YYYY-MM-DD") : null;
    onChange(from, to);
  };


  const CustomInput = forwardRef<HTMLDivElement, PropsCustomInput>(({
    value,
    onClick },
  ref) => {
    return (
      <InputWrapper
        onClick={onClick}
        ref={ref}
        disabled={disabled}
      >
        {
          title &&
          <Title disabled={disabled}><span>{title}</span></Title>
        }
        {value}
        <IconWrapper>
          <CalendarIcon/>
        </IconWrapper>
      </InputWrapper>
    )
  });

  let setting = {};
  if (monthSelect) {
    setting["showMonthYearPicker"] = true;
    setting["showFullMonthYearPicker"] = true;
    setting["showTwoColumnMonthYearPicker"] = true;
  }

  return (
    <DatePicker
      selected={startDate?.toDate()}
      onChange={_onChange}
      startDate={startDate?.toDate()}
      endDate={endDate?.toDate()}
      dateFormat={format}
      customInput={<CustomInput/>}
      selectsRange
    />
  );
};

export default Datepicker;
