import React, {FC, memo} from "react";
import {Controller, useWatch} from "react-hook-form";
import styled from "styled-components";
import Select from "../../../../../components/Select";
import roleSelectOptions from "../../../../../assets/Restaurants/formNotifications/toUser/roleSelectOptions.json";
import HrPartnerRole from "./HrPartnerRole";
import RestaurantRole from "./RestaurantRole";
import ControllerRole from "./ControllerRole";

interface Props {
  setRestaurantNewCandidate: (val: boolean) => void;
  setRestaurantInterview: (val: boolean) => void;
  setRestaurantReminder: (val: boolean) => void;
  setControllerNewCandidate: (val: boolean) => void;
  setControllerInterview: (val: boolean) => void;
  setControllerReminder: (val: boolean) => void;
  setHRPartnerNewCandidate: (val: boolean) => void;
  setHRPartnerInterview: (val: boolean) => void;
  setHRPartnerReminder: (val: boolean) => void;
  control: any;
}

const ToUser: FC<Props> = memo(({
  setRestaurantNewCandidate,
  setRestaurantInterview,
  setRestaurantReminder,
  setControllerNewCandidate,
  setControllerInterview,
  setControllerReminder,
  setHRPartnerNewCandidate,
  setHRPartnerInterview,
  setHRPartnerReminder,
  control
}) => {
  const roleSelectValue = useWatch({
    control,
    name: "to_user.role"
  });

  return (
    <Wrapper>
      <FieldWrapper>
        <Controller
          render={({field, fieldState}) =>
            <Select
              {...field}
              {...fieldState}
              title="Роль"
              options={roleSelectOptions}
              ref={null}
            />
          }
          name="to_user.role"
          control={control}
        />
      </FieldWrapper>

      {roleSelectValue === "restaurant" &&
        <RestaurantRole
          setRestaurantNewCandidate={setRestaurantNewCandidate}
          setRestaurantInterview={setRestaurantInterview}
          setRestaurantReminder={setRestaurantReminder}
          control={control}
        />
      }

      {roleSelectValue === "controller" &&
        <ControllerRole
          setControllerNewCandidate={setControllerNewCandidate}
          setControllerInterview={setControllerInterview}
          setControllerReminder={setControllerReminder}
          control={control}
        />
      }

      {roleSelectValue === "hr_partner" &&
        <HrPartnerRole
          setHRPartnerNewCandidate={setHRPartnerNewCandidate}
          setHRPartnerInterview={setHRPartnerInterview}
          setHRPartnerReminder={setHRPartnerReminder}
          control={control}
        />
      }
    </Wrapper>
  );
});

const Wrapper = styled.div`
  width: 100%;
`;

const FieldWrapper = styled.div`
  &:not(:last-child) {
    margin-bottom: 15px;
  }
`;

export default ToUser;