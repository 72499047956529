import React, {FC, useCallback} from "react";
import {putRestaurantsFormsOrder} from "../../../actions/candidateFormsOrder";
import Checkbox from "../../../components/Checkbox";
import {useFetching} from "../../../hooks/useFetching";

interface Props {
    row: any;
    value: boolean;
    onRequestFulfilled?: () => void;
}

const TableCheckbox: FC<Props> = ({
    row,
    onRequestFulfilled
}) => {
    const [putRestaurantsFormsOrderHandle, isLoading, error] = useFetching(async (data) => {
        const {id, requestData} = data;
        await putRestaurantsFormsOrder(id, requestData);
        onRequestFulfilled();
    });
    
    const checkboxChangeHandle = useCallback(() => {
        putRestaurantsFormsOrderHandle({id: row.id, requestData: {is_order_autocomplete: !row.is_order_autocomplete}});
    }, []);
    
    return (
        <>
            <Checkbox
                disabled={isLoading}
                value={row.is_order_autocomplete}
                onChange={checkboxChangeHandle}
                size={'small'}
            />
        </>
    );
};

export default TableCheckbox;