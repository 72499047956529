import _get from "lodash/get";
import Highcharts from "highcharts";
import styled from "styled-components";
import {observer} from "mobx-react-lite";
import React, {FC, useMemo} from "react";
import HighchartsReact from "highcharts-react-official";
import {theme} from "../../styles/theme";

interface Props {
    data: any;
}

const AnalyticColumnChart: FC<Props> = observer(({data}) => {
    const totalFail = Object.values(data).map((i:any) => i.count)
        .reduce((partialSum, a) => partialSum + a, 0);
    const chartOptions = useMemo(() => {
        return {
            chart: {
                type: "column",
            },
            title: {
                text: "",
            },
            xAxis: {
                minPadding: 0,
                maxPadding: 0,
                title: {
                    text: "",
                },
            },
            yAxis: {
                title: {
                    useHTML: true,
                    text: "",
                },
            },
            tooltip: {
                formatter: function () {
                    const item = this.point;
                    return item.series.name + ' - ' + item.options.y + ' (' + item.options.percentage + '%)';
                },
                followPointer: false,
                borderRadius: 4,
                backgroundColor: theme.colors.neutral8,
                borderColor: "transparent",
                padding: 10,
                shadow: false,
                hideDelay: 100,
                className: "chart-tooltip",
                useHTML: true,
                outside: true,
                style: {
                    color: "#ffffff",
                    fontSize: "14px",
                }
            },
            plotOptions: {
                column: {groupPadding: 0},
                series: {
                    borderWidth: 0,
                    dataLabels: {
                        enabled: true,
                        align: "center",
                        formatter: function() {
                            return this.y + "<br/>" + "(" +this.percentage + "%)";
                        },
                        useHTML: true,
                        className: "column-label",
                    },
                },
            },
            legend: {
                enabled: true,
                layout: "horizontal",
                align: "center",
                verticalAlign: "bottom",
                itemWidth: 350,
                symbolRadius: 4,
                symbolHeight: 20,
                symbolWidth: 20,
                symbolPadding: 5,
                itemMarginTop: 14,
                useHTML: true,
            },
            series: [
                {
                    name: "Зарплата",
                    data: [
                        {
                            y: _get(data, "salary.count", 0),
                            percentage: totalFail ? (_get(data, "salary.count", 0) / totalFail * 100).toFixed(2) : 0,
                        },
                    ],
                    color: "#520102",
                },
                {
                    name: "Неудобное месторасположение",
                    data: [
                        {
                            y: _get(data, "inconvenient_location.count", 0),
                            percentage: totalFail ? (_get(data, "inconvenient_location.count", 0) / totalFail * 100).toFixed(2) : 0,
                        },
                    ],
                    color: "#63080A",
                },
                {
                    name: "Нет необходимых документов",
                    data: [
                        {
                            y: _get(data, "no_required_documents.count", 0),
                            percentage: totalFail ? (_get(data, "no_required_documents.count", 0) / totalFail * 100).toFixed(2) : 0,
                        },
                    ],
                    color: "#741312",
                },
                {
                    name: "Вакансия закрыта",
                    data: [
                        {
                            y: _get(data, "vacancy_closed.count", 0),
                            percentage: totalFail ? (_get(data, "vacancy_closed.count", 0) / totalFail * 100).toFixed(2) : 0,
                        },
                    ],
                    color: "#841F19",
                },
                {
                    name: "Не прошел медосмотр",
                    data: [
                        {
                            y: _get(data, "medical_examination_failed.count", 0),
                            percentage: totalFail ? (_get(data, "medical_examination_failed.count", 0) / totalFail * 100).toFixed(2) : 0,
                        },
                    ],
                    color: "#942B21",
                },
                {
                    name: "Возраст",
                    data: [
                        {
                            y: _get(data, "age.count", 0),
                            percentage: totalFail ? (_get(data, "age.count", 0) / totalFail * 100).toFixed(2) : 0,
                        },
                    ],
                    color: "#A4382B",
                },
                {
                    name: "Не интересна вакансия",
                    data: [
                        {
                            y: _get(data, "job_not_interested.count", 0),
                            percentage: totalFail ? (_get(data, "job_not_interested.count", 0) / totalFail * 100).toFixed(2) : 0,
                        },
                    ],
                    color: "#B24535",
                },
                {
                    name: "Не заполнял анкету",
                    data: [
                        {
                            y: _get(data, "did_not_complete_the_form.count", 0),
                            percentage: totalFail ? (_get(data, "did_not_complete_the_form.count", 0) / totalFail * 100).toFixed(2) : 0,
                        },
                    ],
                    color: "#C05340",
                },
                {
                    name: "Передумал трудоустраиваться",
                    data: [
                        {
                            y: _get(data, "changed_mind_about_getting_a_job_at_kfc.count", 0),
                            percentage: totalFail ? (_get(data, "changed_mind_about_getting_a_job_at_kfc.count", 0) / totalFail * 100).toFixed(2) : 0,
                        },
                    ],
                    color: "#CC624C",
                },
                {
                    name: "Гражданство",
                    data: [
                        {
                            y: _get(data, "citizenship.count", 0),
                            percentage: totalFail ? (_get(data, "citizenship.count", 0) / totalFail * 100).toFixed(2) : 0,
                        },
                    ],
                    color: "#D77259",
                },
                {
                    name: "Плохо говорит по-русски",
                    data: [
                        {
                            y: _get(data, "insufficient_level_of_russian.count", 0),
                            percentage: totalFail ? (_get(data, "insufficient_level_of_russian.count", 0) / totalFail * 100).toFixed(2) : 0,
                        },
                    ],
                    color: "#E28267",
                },
                {
                    name: "Нашел другую работу",
                    data: [
                        {
                            y: _get(data, "found_another_job.count", 0),
                            percentage: totalFail ? (_get(data, "found_another_job.count", 0) / totalFail * 100).toFixed(2) : 0,
                        },
                    ],
                    color: "#FDA78B",
                },
                {
                    name: "Не прошел тестовый день",
                    data: [
                        {
                            y: _get(data, "test_day_failed.count", 0),
                            percentage: totalFail ? (_get(data, "test_day_failed.count", 0) / totalFail * 100).toFixed(2) : 0,
                        },
                    ],
                    color: "#FFB79C",
                },
                {
                    name: "Другое",
                    data: [
                        {
                            y: _get(data, "other.count", 0),
                            percentage: totalFail ? (_get(data, "other.count", 0) / totalFail * 100).toFixed(2) : 0,
                        },
                    ],
                    color: "#FFC5AB",
                },
                {
                    name: "Всего",
                    data: [
                        {
                            y: totalFail,
                            percentage: (100).toFixed(2),
                        },
                    ],
                    color: "#FFEDE0",
                },
            ],
        };
    }, [data]);

    return (
        <Wrapper>
            <HighchartsReact highcharts={Highcharts} options={chartOptions}/>
        </Wrapper>
    );
});

const Wrapper = styled.div`
  width: 100%;

    @media (max-width: 890px) {
        overflow: auto;

        &>div {
            width: 762px;
        }
    }
`;

export default AnalyticColumnChart;
