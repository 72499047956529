import {
  CLARIFICATION_STATUSES,
  EMPLOYMENT_STAGE,
  INTERVIEW_STATUSES,
  QUESTIONNAIRE_PROCESSING_STAGE,
  SELECTION_STAGE
} from "../pages/CandidateForms/RowsSection/const";
import {rejectCandidateProfileStatuses} from "../assets/CandidateForms/RowsSection/Enums";

export const getStatuses = (candidateProfileData, candidateProfileStatuses, userData = null) => {
  let statuses = candidateProfileStatuses.map((item) => ({
    value: item.id,
    label: item.name,
    disabled: item.id === 13 || item.id === 25 || ( userData && item.id === 36 && (userData.role.name === "restaurant" || userData.role.name === "controller" || userData.role.name === "hr_partner"))  // 1 - Новая, 13 - Собеседование перенесено, 25 - Неявка на собеседование
  }))

  if(candidateProfileData.id) {
    if(SELECTION_STAGE.includes(candidateProfileData.candidate_profile_status?.id)){
      statuses.forEach(status => {
        if(candidateProfileData.candidate_profile_status?.id === 12 && status.value === 6) // Если анкете назначен статус «Приглашен на собеседование», пользователь не может изменить статус на «Отказ до собеседования»
          status.disabled = true;
        if(QUESTIONNAIRE_PROCESSING_STAGE.includes(status.value)) // После перевода анкеты на статус Этапа отбора пользователь не может поставить статус «В обработке»
          status.disabled = true
      })
    }

    if(candidateProfileData.candidate_profile_status?.id === 22) { // После статуса «Отказ после собеседования» пользователь может поставить статус «Неактивная» и «Приглашен на собеседование», либо статусы Этапа трудоустройства
      statuses.forEach(status => {
        if(status.value !== 2 && status.value !== 12 && !EMPLOYMENT_STAGE.includes(status.value)) status.disabled = true
      })
    }

    if(![...SELECTION_STAGE, ...EMPLOYMENT_STAGE].includes(candidateProfileData.candidate_profile_status?.id) &&
      candidateProfileData.candidate_profile_status?.id !== 35) { // Статус «На паузе» возможен на Этапе отбора и Этапе трудоустройства
      const pauseStatus = statuses.find(status => status.value === 35)
      pauseStatus.disabled = true
    }

    if(candidateProfileData.candidate_profile_status?.id === 35) {// после статуса На паузе пользователю доступны статусы Этапа отбора и Этапа трудоустройства
      statuses.forEach(status => {
        if(QUESTIONNAIRE_PROCESSING_STAGE.includes(status.value)) status.disabled = true
      })
    }

    if(candidateProfileData.candidate_profile_status?.id === 12) { // Собеседование перенесено(13) и Неявка на собеседование(25) можно поставить только после приглашен на собеседование(12)
      statuses.forEach(status => {
        if(status.value === 13 || status.value === 25)
          status.disabled = false
      })
    }

    if(candidateProfileData.candidate_profile_status?.id === 13) { // Неявка на собеседование(25) можно поставить и после Собеседование перенесено(13)
      statuses.forEach(status => {
        if(status.value === 25)
          status.disabled = false
      })
    }
  }

  return statuses;
}

export const showRJP = (statusSelectValue) => {
  // 27 - Статус Назначен пробный день (RJP)
  return statusSelectValue === 27
}

export const showMedicalDate = (statusSelectValue) => {
  // 28 - Статус Отправлен на медосмотр
  return statusSelectValue === 28
}

export const showRegistrationDate = (statusSelectValue) => {
  // 29 - Статус Отправлен на оформление
  return statusSelectValue === 29
}

export const showClarification = (statusSelectValue) => {
  return CLARIFICATION_STATUSES.includes(statusSelectValue)
}

export const showRefuseReason = (statusSelectValue, statusClarificationValue) => {
  return (rejectCandidateProfileStatuses.includes(statusClarificationValue) &&
    [6, 22].includes(statusSelectValue))
}

export const showStatusComment = (statusSelectValue, statusRefuseValue, statusClarificationValue) => {
  return ((statusRefuseValue === 8 &&
      rejectCandidateProfileStatuses.includes(statusClarificationValue) &&
      [6, 22].includes(statusSelectValue))
    ||
    (statusClarificationValue === 6 && statusSelectValue === 8))
}

export const showInterview = (candidateProfileData, statusSelectValue, restaurants, cities) => {
  return INTERVIEW_STATUSES.includes(statusSelectValue) ||
  (candidateProfileData.id && restaurants.length > 0 && cities.length > 0);
}
