import React, { FC, ReactElement, useCallback, useContext, useEffect } from 'react';
import styled from 'styled-components';
import Menu from './Menu';
import { AppStoreContext } from '../../stores/app';
import debounce from 'lodash/debounce';
import { observer } from 'mobx-react-lite';

const MainLayout: FC<{ children: ReactElement }> = observer(({ children }) => {
  const { menuIsOpen, setMenuIsOpen } = useContext(AppStoreContext);
  const toggleOpen = useCallback(() => {
    setMenuIsOpen(!menuIsOpen);
  }, [menuIsOpen]);

  const handleResizeOpen = debounce(() => {
    setMenuIsOpen(window.innerWidth > 1366);
  }, 100);

  useEffect(() => {
    window.addEventListener('resize', handleResizeOpen);
    return () => {
      window.removeEventListener('resize', handleResizeOpen);
    };
  }, []);

  return (
    <LayoutWrapper>
      <Menu isOpen={menuIsOpen} toggleOpen={toggleOpen} />
      <Content isOpen={menuIsOpen}>
        {children}
      </Content>
    </LayoutWrapper>
  );
});

const LayoutWrapper = styled.div`
  display: flex;
  height: 100%;
  @media (max-width: 820px) {
    flex-direction: column;
  }
`;
const Content = styled.div<{ isOpen: boolean }>`
  position: relative;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  width: calc(100% - 80px);
  padding-left: ${({ isOpen }) => (!isOpen ? '72px' : '236px')};
  padding-top: 16px;
  padding-right: 24px;
  padding-bottom: 22px;
  height: 100%;
  
  @media (max-height: 616px) {
    padding-left: ${({ isOpen }) => (!isOpen ? '94px' : '236px')};
  }
  
  @media (max-width: 820px) {
    padding: 56px 24px 24px;
    width: 100%;
  }
  
  @media (max-width: 600px) {
    padding: 56px 10px 16px;
  }
`;

export default MainLayout;
