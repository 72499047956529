import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import debounce from "lodash/debounce";
import {observer} from "mobx-react-lite";
import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import styled from "styled-components";
import * as yup from "yup";
import {getRestaurantFormsOrder} from "../../../actions/candidateFormsOrder";
import sortBySelectOptions from "../../../assets/CandidateForms/OrderSection/sortBySelectOptions.json";
import FilterIcon from "../../../assets/icons/FilterIcon";
import sortOrderSelectOptions from "../../../assets/sortOrderSelectOptions.json";
import ActionIcon from "../../../components/ActionIcon";
import ContentWrapper from "../../../components/ContentWrapper";
import Filters from "../../../components/Filters";
import Header from "../../../components/Header";
import {Input} from "../../../components/Input";
import Loader from "../../../components/Loader";
import MultiSelect from "../../../components/MultiSelect";
import PaginationGroup from "../../../components/PaginationGroup";
import Select from "../../../components/Select";
import tableFields from "../../../assets/CandidateForms/OrderSection/tableFields";
import Table from "../../../components/Table";
import tableSubtitles from "../../../assets/CandidateForms/OrderSection/tableSubtitles.json";
import {LIMIT_DEFAULT, PAGE_DEFAULT} from "../../../config/consts";
import {CANDIDATE_FORMS_ORDER_FILTER_ID as FILTER_ID} from "../../../config/filters";
import {useFetching} from "../../../hooks/useFetching";
import {DirectoryStoreContext} from "../../../stores/directory";
import {FiltersStoreContext} from "../../../stores/filters";
import initializeMultiselectOptions from "../../../utils/initializeMultiselectOptions";
import initializeMultiselectValue from "../../../utils/initializeMultiselectValue";
import orderAutorenewalSelectOptions from
        "../../../assets/CandidateForms/OrderSection/orderAutorenewalSelectOptions.json";
import Page404 from "../../Page404";
import {theme} from "../../../styles/theme";
import FileDownloadIcon from "../../../assets/icons/FileDownloadIcon";
import {saveAs} from 'file-saver';
import {useMemoRestaurantOption} from "../../../hooks/useMemoRestaurantOption";
import {AuthStoreContext} from "../../../stores/auth";

const schema = yup.object().shape({
    search: yup.string(),
    sortBy: yup.string(),
    sortOrder: yup.string(),
    filters: yup.object().shape({
        partner: yup.array(),
        city: yup.array(),
        restaurant: yup.array(),
        is_order_autocomplete: yup.string()
    })
});

const OrderSection = observer(() => {
    const { userData } = useContext(AuthStoreContext);
    const [candidateFormsOrderData, setCandidateFormsOrderData] = useState(null);
    const [limit, setLimit] = useState(LIMIT_DEFAULT);
    const [page, setPage] = useState(PAGE_DEFAULT);
    const [searchValue, setSearchValue] = useState("");
    const [sortByValue, setSortByValue] = useState("");
    const [sortOrderValue, setSortOrderValue] = useState("ascending");
    const filtersIconRef = useRef(null);
    const {filtersData, filtersIconClickHandle, closeFiltersHandle} = useContext(FiltersStoreContext);
    const {
        restaurants,
        cities,
        partners,
        restaurantsEndFetching,
        citiesEndFetching,
        partnersEndFetching
    } = useContext(DirectoryStoreContext);
    const citiesOptions = initializeMultiselectOptions(
        cities, "id", "name_ru", false
    );
    const restaurantsOptions = useMemoRestaurantOption();
    const partnersOptions = initializeMultiselectOptions(
        partners, "id", "name", false
    );
    const {
        control,
        getValues,
        reset,
        setValue
    } = useForm({
        mode: "onChange",
        resolver: yupResolver(schema),
        defaultValues: {
            search: "",
            sortBy: "",
            sortOrder: "ascending",
            filters: {
                partner: [],
                city: [],
                restaurant: [],
                is_order_autocomplete: "all"
            }
        }
    });

    const getSortBy = () => {
        switch (sortByValue) {
            case "restaurant_id": return  "id"
            case "partners": return "partners.name"
            case "cities": return "city_name"
            case "restaurants": return "name_ru"
        }
    }
    
    const [fetchCandidateFormsOrder, isLoading, error] = useFetching(async () => {
        const filters = getValues('filters');
        if(userData.role?.name === "restaurant") {
            filters.restaurant = [userData?.restaurants[0]?.id]
            filters.city = []
            filters.partner = []
        }
        if(userData.role?.name === "hr_partner" || userData.role?.name === "controller") filters.partner = []

        const sortBy = getSortBy();

        let order = null;
        if (sortBy) {
            order = sortOrderValue === "ascending" ? sortBy : "-" + sortBy;
        }
        const response = await getRestaurantFormsOrder(limit, page, searchValue, order, filters);

        setCandidateFormsOrderData(response);
    });

    const filterRestaurant = useMemo(() => {
        return initializeMultiselectValue(restaurants, "id");
    }, [restaurants])
    const filterCity = useMemo(() => {
        return initializeMultiselectValue(cities, "id");
    }, [cities])
    const filterPartner = useMemo(() => {
        return initializeMultiselectValue(partners, "id");
    }, [partners])

    useEffect(() => {
        setValue("filters.restaurant", filterRestaurant);
    }, [filterRestaurant])
    useEffect(() => {
        setValue("filters.city", filterCity);
    }, [filterCity])
    useEffect(() => {
        setValue("filters.partner", filterPartner);
    }, [filterPartner])
    
    useEffect(() => {
        if(!restaurantsEndFetching || !citiesEndFetching || !partnersEndFetching) return;
        fetchCandidateFormsOrder();
    }, [limit, page, searchValue, sortByValue, sortOrderValue, filterRestaurant, filterCity, filterPartner]);
    
    const searchInputChangeHandle = debounce((search) => {
        setSearchValue(search);
        setPage(PAGE_DEFAULT);
        resetFilters();
    }, 400);
    
    const sortBySelectChangeHandle = useCallback((value) => {
        setSortByValue(value);
    }, []);
    
    const sortOrderSelectChangeHandle = useCallback((value) => {
        setSortOrderValue(value);
    }, []);
    
    const resetFilters = useCallback(() => {
        reset({
            search: getValues().search,
            sortBy: getValues().sortBy,
            sortOrder: getValues().sortOrder,
            filters: {
                city: initializeMultiselectValue(cities, "id"),
                restaurant: initializeMultiselectValue(restaurants, "id"),
                partner: initializeMultiselectValue(partners, "id"),
                is_order_autocomplete: "all"
            }
        });
    }, [cities, restaurants, partners]);
    
    const applyFilters = useCallback(() => {
        setValue("search", "");
        setSearchValue("");

        const filters = {...getValues().filters};
        
        if (filters.city.length === cities.length) {
            delete filters.city;
        }
        if (filters.restaurant.length === restaurants.length) {
            delete filters.restaurant;
        }
        if (filters.partner.length === partners.length) {
            delete filters.partner;
        }
        if (filters.is_order_autocomplete === "all") {
            delete filters.is_order_autocomplete;
        }

        fetchCandidateFormsOrder();
        setPage(PAGE_DEFAULT);
    }, [cities, restaurants, partners, limit, page, searchValue]);

    const exportIconClickHandle = async () => {
        const exportParams = [
            {column: "id", alias: "Идентификатор ресторана"},
            {column: "partner.name", alias: "Партнер"},
            {column: "city_name", alias: "Город"},
            {column: "name_ru", alias: "Ресторан"},
            {column: "candidateOrdersCurrentMonth.requirement_la_re", alias: "Потребность ТР ЧК(текущий месяц)"},
            {column: "candidateOrdersCurrentMonth.count_re_candidate_profile", alias: "Кол-во анкет ЧК(текущий месяц)"},
            {column: "candidateOrdersCurrentMonth.requirement_la_sm", alias: "Потребность ТР МС(текущий месяц)"},
            {column: "candidateOrdersCurrentMonth.count_sm_candidate_profile", alias: "Кол-во анкет МС(текущий месяц)"},
            {column: "candidateOrdersNextMonth.requirement_la_re", alias: "Потребность ТР ЧК(следующий месяц)"},
            {column: "candidateOrdersNextMonth.count_re_candidate_profile", alias: "Кол-во анкет ЧК(следующий месяц)"},
            {column: "candidateOrdersNextMonth.requirement_la_sm", alias: "Потребность ТР МС(следующий месяц)"},
            {column: "candidateOrdersNextMonth.count_sm_candidate_profile", alias: "Кол-во анкет МС(следующий месяц)"},
            {column: "is_order_autocomplete", alias: "Автопродление заказа"},
        ];

        const filters = getValues("filters")
        const sortBy = getSortBy();
        const order = sortOrderValue === "ascending" ? sortBy : "-" + sortBy;
        const response = await getRestaurantFormsOrder(limit, page, searchValue, order, filters, exportParams);
        const data = new Blob([response], {type: "xlsx"});
        saveAs(data, "candidateOrder.xlsx");
    }
    
    return (
        <>
            <Header
                rightActions={
                    <RightActions>
                        <ActionIcon
                          action={exportIconClickHandle}
                          tooltip="Экспорт в файл"
                        >
                            <FileDownloadIcon/>
                        </ActionIcon>

                        <SearchWrapper>
                            <Controller
                                render={({field, fieldState}) =>
                                    <Input
                                        {...field}
                                        {...fieldState}
                                        onChange={(value) => {
                                            searchInputChangeHandle(value);
                                            field.onChange(value);
                                        }}
                                        placeholder="Поиск..."
                                        search={true}
                                        ref={null}
                                    />
                                }
                                name="search"
                                control={control}
                            />
                        </SearchWrapper>
                        
                        <HeaderSelectWrapper>
                            <Controller
                                render={({field, fieldState}) =>
                                    <Select
                                        {...field}
                                        {...fieldState}
                                        onChange={(value) => {
                                            sortBySelectChangeHandle(value);
                                            field.onChange(value);
                                        }}
                                        title="Сортировать по..."
                                        options={sortBySelectOptions}
                                        ref={null}
                                    />
                                }
                                name="sortBy"
                                control={control}
                            />
                        </HeaderSelectWrapper>
                        
                        <HeaderSelectWrapper>
                            <Controller
                                render={({field, fieldState}) =>
                                    <Select
                                        {...field}
                                        {...fieldState}
                                        onChange={(value) => {
                                            sortOrderSelectChangeHandle(value);
                                            field.onChange(value);
                                        }}
                                        title="Порядок сортировки"
                                        options={sortOrderSelectOptions}
                                        ref={null}
                                    />
                                }
                                name="sortOrder"
                                control={control}
                            />
                        </HeaderSelectWrapper>
                        
                        <FiltersBtn ref={filtersIconRef}>
                            <ActionIcon
                                action={() => filtersIconClickHandle(FILTER_ID, filtersIconRef)}
                                active={filtersData[FILTER_ID]?.open}
                                tooltip="Фильтры"
                            >
                                <FilterIcon/>
                            </ActionIcon>
                            
                            {filtersData[FILTER_ID]?.open &&
                                <Filters
                                    onReset={resetFilters}
                                    btnRef={filtersIconRef}
                                    onApply={() => {
                                        applyFilters();
                                        closeFiltersHandle(null, FILTER_ID, filtersIconRef);
                                    }}
                                    onClose={(event) => {
                                        closeFiltersHandle(event, FILTER_ID, filtersIconRef)
                                    }}
                                >
                                    {userData.role?.name !== "restaurant" &&
                                    <>
                                      {(userData.role?.name !== "hr_partner" && userData.role?.name !== "controller") &&
                                        <FilterRowWrapper>
                                            <Controller
                                                render={({field, fieldState}) =>
                                                    <MultiSelect
                                                        {...field}
                                                        {...fieldState}
                                                        title="Партнер"
                                                        options={partnersOptions}
                                                        withSubOptions={false}
                                                        ref={null}
                                                    />
                                                }
                                                name="filters.partner"
                                                control={control}
                                            />
                                        </FilterRowWrapper>
                                      }
                                    
                                        <FilterRowWrapper>
                                            <Controller
                                                render={({field, fieldState}) =>
                                                    <MultiSelect
                                                        {...field}
                                                        {...fieldState}
                                                        title="Город"
                                                        options={citiesOptions}
                                                        withSubOptions={false}
                                                        ref={null}
                                                    />
                                                }
                                                name="filters.city"
                                                control={control}
                                            />
                                        </FilterRowWrapper>


                                        <FilterRowWrapper>
                                          <Controller
                                            render={({field, fieldState}) =>
                                              <MultiSelect
                                                {...field}
                                                {...fieldState}
                                                title="Ресторан"
                                                options={restaurantsOptions}
                                                withSubOptions={true}
                                                subOptionsKey="restaurants_minimal"
                                                ref={null}
                                              />
                                            }
                                            name="filters.restaurant"
                                            control={control}
                                          />
                                        </FilterRowWrapper>
                                    </>
                                    }
                                    
                                    <FilterRowWrapper>
                                        <Controller
                                            render={({field, fieldState}) =>
                                                <Select
                                                    {...field}
                                                    {...fieldState}
                                                    title="Автопродление заказа"
                                                    options={orderAutorenewalSelectOptions}
                                                    ref={null}
                                                />
                                            }
                                            name="filters.is_order_autocomplete"
                                            control={control}
                                        />
                                    </FilterRowWrapper>
                                </Filters>
                            }
                        </FiltersBtn>
                    </RightActions>
                }
                title="Заказ анкет"
            />
    
            <>
                <ContentWrapper>
                    {isLoading ? (
                        <Loader width={70} height={70}/>
                    ) : (
                        <TableWrapper>
                            <Table
                                data={candidateFormsOrderData?.data}
                                fields={tableFields}
                                headerSubtitles={tableSubtitles}
                                onRequestFulfilled={() => fetchCandidateFormsOrder()}
                            />
                        </TableWrapper>
                    )}
                    {error && <Page404/>}
                </ContentWrapper>

                <PaginationGroup
                    meta={candidateFormsOrderData?.meta}
                    setLimit={setLimit}
                    setPage={setPage}
                />
            </>
        </>
    );
});

const RightActions = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
    justify-content: flex-end;
    flex-grow: 1;

    @media (max-width: 1241px) {
        &>div:nth-child(1) {
            flex-grow: 1;
        }
    }

    @media (max-width: 969px) {
        flex-wrap: wrap;
        justify-content: flex-start;
    }

    @media (max-width: 400px) {
        &>div {
            width: 100%;
        }
    }
`;

const TableWrapper = styled.div`
  overflow: auto;
  height: 100%;
    
    thead {
        td {
            &:nth-child(n+5):not(:last-child):not(:nth-child(13)) {
                width: 5%;
            }
            &:last-child,
            &:nth-child(13) {
                width: 7%;
            }
        }
    }

    td {
        &:first-child {
            color: ${theme.colors.neutral7};
        }

        &:nth-child(4),
        &:nth-child(8),
        &:nth-child(12) {
            border-right: 1px solid ${theme.colors.neutral3};
        }
        
        &:nth-child(n+5) {
            text-align: right;
        }

        &:nth-child(5),
        &:nth-child(7) {
            padding: 5px 10px;
        }

        &:last-child,
        &:nth-child(13) {
            padding: 8px 10px;
        }
        
        &>div {
            justify-content: flex-end;
            width: 100%;
        }
    }
        
    input {
      text-align: right;
      padding: 0 10px;
    }
    
`;

const FiltersBtn = styled.div`
  position: relative;
`;

const SearchWrapper = styled.div`
  max-width: 200px;

    @media (max-width: 1122px) {
        max-width: 100%;
    }
`;

const HeaderSelectWrapper = styled.div`
  width: 180px;
`;

const FilterRowWrapper = styled.div`
  &:not(:last-child) {
    margin-bottom: 15px;
  }
`;

export default OrderSection;
