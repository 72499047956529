import {observer} from "mobx-react-lite";
import ActionIcon from "../../../../components/ActionIcon";
import React, {FC} from "react";
import styled from "styled-components";
import ChevronIcon from "../../../../assets/icons/ChevronIcon";
import Button from "../../../../components/Button";
import moment from "moment";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  
  @media (max-width: 500px) {
    flex-wrap: wrap;
  }
`;

const Title = styled.div`
  margin: 0 6px 0 0;
  color: ${({theme}) => theme.colors.bodyText};
  font-size: ${({theme}) => theme.fontSizes.h2};
  font-family: ${({theme}) => theme.fonts.mainBlack};
  
  @media (max-width: 500px) {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
  }
  
  &::first-letter {
    text-transform: uppercase;
}
`;

const ActionWrapper = styled.div`
  
`;

interface Props {
  onChangeDate: (month: number) => void
  onClickToday: () => void
  calendarView: string
  currentDate: moment.Moment
}

const HeaderElement:FC<Props> = observer(({
                                              calendarView,
                                              onChangeDate,
                                              currentDate,
                                              onClickToday
                                          }) => {
    const getTitle = () => {
        if (calendarView === "month") {
            return currentDate.format("MMMM YYYY")
        }
        else {
            const date = moment(currentDate);
            return `${date.format("DD MMMM YYYY")} - ${date.add(6, "d").format("DD MMMM YYYY")}`
        }
    };

    const onChange = side => {
      onChangeDate(side)
    };

    return (
        <Wrapper>
            <Title>{getTitle()}</Title>
            <ActionWrapper>
                <ActionIcon action={() => onChange(-1)}>
                    <ChevronIcon direction={"left"}/>
                </ActionIcon>
            </ActionWrapper>
            <Button title="Сегодня"
                    btnStyle={"secondary"}
                    onClick={onClickToday}/>
            <ActionWrapper>
                <ActionIcon action={() => onChange(1)}>
                    <ChevronIcon direction={"right"}/>
                </ActionIcon>
            </ActionWrapper>
        </Wrapper>
    )
});

export {HeaderElement};
