import styled from "styled-components";
import MenuIcon from "../../../assets/icons/MenuIcon";
import MenuOpenIcon from "../../../assets/icons/MenuOpenIcon";
import { AuthStoreContext } from "../../../stores/auth";
import MenuItemDefault from "./MenuItemDefault";
import { FC, useContext, useMemo, useState, useEffect } from "react";
import ActionIcon from "../../../components/ActionIcon";
import { menu } from "../../../config/routes";
import { observer } from "mobx-react-lite";
import ExitToAppIcon from "../../../assets/icons/ExitToAppIcon";
import MenuItemOptions from "./MenuItemOptions";
import debounce from "lodash/debounce";

interface Props {
  isOpen: boolean;
  toggleOpen: () => void;
}

const Menu: FC<Props> = observer(({ isOpen, toggleOpen }) => {
  const { userData, logout } = useContext(AuthStoreContext);
  const [isAdaptive, setIsAdaptive] = useState(false);

  const handleIsAdaptive = debounce(() => {
    setIsAdaptive(window.innerWidth < 821);
  }, 100);

  useEffect(() => {
    if (window.innerWidth < 821) setIsAdaptive(true);
    window.addEventListener("resize", handleIsAdaptive);
    return () => {
      window.removeEventListener("resize", handleIsAdaptive);
    };
  }, []);

  const {
    is_access_to_ordering_offline_advertising,
    is_candidate_profile_order_access,
    is_plr_access,
    role,
  } = userData;

  const filteredMenu = useMemo(() => {
    let newMenu = menu.filter((item: any) => {
      if (item.roles.length === 0 || item.roles.includes(role.name)) {
        return true;
      } else {
        if (item.path === "/offline_advertising") {
          return is_access_to_ordering_offline_advertising;
        }
        if (item.path === "/plr") {
          return is_plr_access;
        }
      }
    });
    newMenu = newMenu.map((item: any) => {
      if (item.options) {
        if (
          item.roles.length === 0 ||
          item.options.filter((option) => option.roles.includes(role.name))
            .length === item.options.length
        ) {
          return item;
        } else {
          if (
            item.path === "/candidateforms" &&
            is_candidate_profile_order_access
          ) {
            if(role.name === "controller") return item;

            return {
              title: item.title,
              path: item.path,
              icon: item.icon,
              options: item.options.filter(
                (element) =>
                  element.roles.includes(role.name) ||
                  element.path === "/candidateformsorder"
              ),
            };
          }

          return {
            title: item.title,
            path: item.path,
            icon: item.icon,
            options: item.options.filter((element) =>
              element.roles.includes(role.name)
            ),
          };
        }
      } else {
        return item;
      }
    });

    return newMenu;
  }, [userData, menu]);

  const UserNameRender = () => {
    return (
      <Block>
        <SystemName>MARS</SystemName>
        <UserName>{userData.name}</UserName>
      </Block>
    );
  };

  return (
    <MenuWrapper isOpen={isOpen}>
      <MenuHeader isAdaptive={isAdaptive} isOpen={isOpen}>
        {!isAdaptive ? isOpen && UserNameRender() : UserNameRender()}
        <IconWrapper>
          <ActionIcon action={toggleOpen} color={"#fff"} opacity={1}>
            {isOpen ? <MenuOpenIcon /> : <MenuIcon />}
          </ActionIcon>
        </IconWrapper>
      </MenuHeader>
      <MenuContent isOpen={isOpen}>
        <Block>
          {filteredMenu.map((item: any) => {
            if (item.options) {
              return (
                <MenuItemOptions
                  key={`link-${item.path}`}
                  {...item}
                  menuOpen={isOpen}
                  isAdaptive={isAdaptive}
                  toggleOpen={toggleOpen}
                />
              );
            } else {
              return (
                <MenuItemDefault
                  key={`link-${item.path}`}
                  {...item}
                  menuOpen={isOpen}
                  onClick={() => isAdaptive && toggleOpen()}
                />
              );
            }
          })}
        </Block>
        <Block>
          <MenuItemDefault
            title={"Выход"}
            path={"/auth"}
            menuOpen={isOpen}
            icon={ExitToAppIcon}
            onClick={logout}
          />
        </Block>
      </MenuContent>
    </MenuWrapper>
  );
});

const UserName = styled.div`
  ${({ theme }) => `
    font-family: ${theme.fonts.mainRegular};
    font-size: ${theme.fontSizes.small};
    color: ${theme.colors.neutral7}
  `}
`;

const Block = styled.div``;

const SystemName = styled.div`
  line-height: 24px;
  ${({ theme }) => `
    font-family: ${theme.fonts.mainBlack};
    font-size: ${theme.fontSizes.h3};
  `}
`;

const MenuContent = styled.div<{ isOpen?: boolean }>`
  padding: 0 6px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  @media (max-width: 820px) {
    display: ${({ isOpen }) => (isOpen ? "flex" : "none")};
    position: fixed;
    top: 40px;
    left: 0;
    bottom: 0;
    background-color: #ffffff;
    width: 100%;
  }
`;

const IconWrapper = styled.div`
  svg path {
    fill: #000000;
  }
`;

const MenuWrapper = styled.div<{ isOpen: boolean }>`
  width: ${({ isOpen }) => (isOpen ? "220px" : "56px")};
  flex: 0 0 auto;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  
  @media (max-height: 616px) {
    width: ${({ isOpen }) => (isOpen ? "220px" : "70px")};
  }

  @media (max-width: 820px) {
    bottom: unset;
    right: 0;
    width: 100%;
    background-color: #ffffff;
    height: auto;
  }
`;

const MenuHeader = styled.div<{
  isOpen: boolean;
  isAdaptive: boolean;
}>`
  display: flex;
  justify-content: ${({ isAdaptive, isOpen }) =>
    !isAdaptive && isOpen ? "space-between" : "center"};
  padding: ${({ isAdaptive, isOpen }) =>
    !isAdaptive && !isOpen ? "16px 6px" : "16px"};
  width: 100%;
  min-height: 78px;

  @media (max-width: 820px) {
    padding: 0 16px;
    min-height: unset;
    height: 40px;
    justify-content: space-between;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    ${Block} {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }

  @media (max-width: 600px) {
    padding: 0 10px;
  }
`;

export default Menu;
