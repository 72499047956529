import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import debounce from "lodash/debounce";
import {observer} from "mobx-react-lite";
import React, {useContext, useEffect, useRef, useState, useCallback} from "react";
import {Controller, useForm} from "react-hook-form";
import styled from "styled-components";
import * as yup from "yup";
import FileDownloadIcon from "../../assets/icons/FileDownloadIcon";
import FilterIcon from "../../assets/icons/FilterIcon";
import sortOrderSelectOptions from "../../assets/sortOrderSelectOptions.json";
import ActionIcon from "../../components/ActionIcon";
import Filters from "../../components/Filters";
import Header from "../../components/Header";
import {Input} from "../../components/Input";
import MultiSelect from "../../components/MultiSelect";
import Rangepicker from "../../components/Rangepicker";
import Select from "../../components/Select";
import {BLOGGERS_FILTER_ID as FILTER_ID} from "../../config/filters";
import {DirectoryStoreContext} from "../../stores/directory";
import {FiltersStoreContext} from "../../stores/filters";
import {useFetching} from "../../hooks/useFetching";
import {getBloggers} from "../../actions/bloggers";
import Grid from "../../components/Grid";
import initializeMultiselectOptions from "../../utils/initializeMultiselectOptions";
import initializeMultiselectValue from "../../utils/initializeMultiselectValue";
import GridRow from "./GridRow";
import sortBySelectOptions from "../../assets/Bloggers/sortBySelectOptions.json";
import {LIMIT_DEFAULT, PAGE_DEFAULT} from "../../config/consts";
import {saveAs} from 'file-saver';
import moment from "moment";

const schema = yup.object().shape({
    search: yup.string(),
    sortBy: yup.string(),
    sortOrder: yup.string(),
    filters: yup.object().shape({
        dateRange: yup.object(),
        city: yup.array(),
        restaurant: yup.array(),
        partner: yup.array()
    })
});

const Bloggers = observer(() => {
    const [bloggersData, setBloggersData] = useState(null);
    const [limit, setLimit] = useState(LIMIT_DEFAULT);
    const [page, setPage] = useState(PAGE_DEFAULT);
    const [searchValue, setSearchValue] = useState("");
    const [sortByValue, setSortByValue] = useState("created_at");
    const [sortOrderValue, setSortOrderValue] = useState("descending");
    const [filtersValue, setFiltersValue] = useState(null);
    const filtersIconRef = useRef(null);
    const {filtersData, filtersIconClickHandle, closeFiltersHandle} = useContext(FiltersStoreContext);
    const {cities, restaurants, partners} = useContext(DirectoryStoreContext);
    const citiesOptions = initializeMultiselectOptions(cities, "id", "name_ru", false);
    const restaurantsOptions = initializeMultiselectOptions(
        cities, "id", "name_ru", true, "restaurants_minimal"
    );
    const partnersOptions = initializeMultiselectOptions(partners, "id", "name", false);
    const {control, getValues, reset, setValue} = useForm({
        mode: "onChange",
        resolver: yupResolver(schema),
        defaultValues: {
            search: "",
            sortBy: "",
            sortOrder: "descending",
            filters: {
                dateRange: {from: null, to: null},
                city: [],
                restaurant: [],
                partner: []
            }
        }
    });
    
    const [fetchBloggers, isLoading, error] = useFetching(async () => {
        const order = getOrder();
        const response = await getBloggers(limit, page, searchValue, order, filtersValue);
        setBloggersData(response);
    });
    
    useEffect(() => {
        setValue("filters.city", initializeMultiselectValue(cities, "id"));
        setValue("filters.restaurant", initializeMultiselectValue(restaurants, "id"));
        setValue("filters.partner", initializeMultiselectValue(partners, "id"));
    }, [cities, restaurants, partners]);
    
    useEffect(() => {
        fetchBloggers();
    }, [limit, page, searchValue, sortByValue, sortOrderValue, filtersValue]);
    
    const searchInputChangeHandle = debounce((search) => {
        setSearchValue(search);
    }, 400);
    
    const sortBySelectChangeHandle = useCallback((value) => {
        setSortByValue(value);
    }, []);
    
    const sortOrderSelectChangeHandle = useCallback((value) => {
        setSortOrderValue(value);
    }, []);
    
    const resetFilters = useCallback(() => {
        reset({
            search: getValues().search,
            sortBy: getValues().sortBy,
            sortOrder: getValues().sortOrder,
            filters: {
                dateRange: {from: null, to: null},
                city: initializeMultiselectValue(cities, "id"),
                restaurant: initializeMultiselectValue(restaurants, "id"),
                partner: initializeMultiselectValue(partners, "id")
            }
        });
    }, [cities, restaurants, partners]);
    
    const applyFilters = useCallback(() => {
        const filters = {...getValues().filters};
        
        if (filters.city.length === cities.length) {
            delete filters.city;
        }
        if (filters.restaurant.length === restaurants.length) {
            delete filters.restaurant;
        }
        if (filters.partner.length === partners.length) {
            delete filters.partner;
        }
        
        setPage(1);
        setFiltersValue(filters);
    }, [cities, restaurants, partners, limit, page]);
    
    const exportIconClickHandle = useCallback(async () => {
        const exportParams = [
            {column: "id", alias: "Идентификатор"},
            {column: "restaurant_id", alias: "Идентификатор ресторана"},
            {column: "partner_id", alias: "Идентификатор партнера"},
            {column: "name", alias: "Имя"},
            {column: "city.name_ru", alias: "Город"},
            {column: "restaurant.name_ru", alias: "Ресторан"},
            {column: "phone", alias: "Телефон"},
            {column: "email", alias: "Email"},
            {column: "partner.name", alias: "Партнер"},
            {column: "url", alias: "URL"},
            {column: "agreement", alias: "Согласие"},
            {column: "created_at", alias: "Дата создания"},
            {column: "updated_at", alias: "Дата обновления"},
            {column: "deleted_at", alias: "Дата удаления"},
        ];
        const order = getOrder();
        const response = await getBloggers(limit, page, searchValue, order, filtersValue, exportParams);
        const data = new Blob([response], {type: "xlsx"});
        saveAs(data, "bloggers.xlsx");
    }, [limit, page, searchValue, sortByValue, sortOrderValue, filtersValue]);
    
    const getOrder = () => {
        const postfix = sortByValue === "partners" ? ".name" : ".name_ru";
        const sortBy = sortByValue === "created_at" ? sortByValue : sortByValue + postfix;
        let order = sortOrderValue === "ascending" ? sortBy : "-" + sortBy;
        order = order === "-created_at" ? null : order;
        
        return order;
    };
    
    return (
        <>
            <Header
                rightActions={
                    <RightActions>
                        <ActionIcon
                            action={exportIconClickHandle}
                            tooltip="Экспорт в файл"
                        >
                            <FileDownloadIcon/>
                        </ActionIcon>

                        <SearchWrapper>
                            <Controller
                                render={({field, fieldState}) =>
                                    <Input
                                        {...field}
                                        {...fieldState}
                                        onChange={(value) => {
                                            searchInputChangeHandle(value);
                                            field.onChange(value);
                                        }}
                                        placeholder="Поиск..."
                                        search={true}
                                        maxLength={200}
                                        ref={null}
                                    />
                                }
                                name="search"
                                control={control}
                            />
                        </SearchWrapper>
                        
                        <HeaderSelectWrapper>
                            <Controller
                                render={({field, fieldState}) =>
                                    <Select
                                        {...field}
                                        {...fieldState}
                                        onChange={(value) => {
                                            sortBySelectChangeHandle(value);
                                            field.onChange(value);
                                        }}
                                        title="Сортировать по..."
                                        options={sortBySelectOptions}
                                        ref={null}
                                    />
                                }
                                name="sortBy"
                                control={control}
                            />
                        </HeaderSelectWrapper>
                        
                        <HeaderSelectWrapper>
                            <Controller
                                render={({field, fieldState}) =>
                                    <Select
                                        {...field}
                                        {...fieldState}
                                        onChange={(value) => {
                                            sortOrderSelectChangeHandle(value);
                                            field.onChange(value);
                                        }}
                                        title="Порядок сортировки"
                                        options={sortOrderSelectOptions}
                                        ref={null}
                                    />
                                }
                                name="sortOrder"
                                control={control}
                            />
                        </HeaderSelectWrapper>
                        
                        <FiltersBtn ref={filtersIconRef}>
                            <ActionIcon
                                action={() => filtersIconClickHandle(FILTER_ID, filtersIconRef)}
                                active={filtersData[FILTER_ID]?.open}
                                tooltip="Фильтры"
                            >
                                <FilterIcon/>
                            </ActionIcon>
                            
                            {filtersData[FILTER_ID]?.open &&
                                <Filters
                                    onReset={resetFilters}
                                    btnRef={filtersIconRef}
                                    onApply={applyFilters}
                                    onClose={(event) => closeFiltersHandle(event, FILTER_ID, filtersIconRef)}
                                >
                                    <FilterRowWrapper>
                                        <Controller
                                            render={({field, fieldState}) =>
                                              <Rangepicker
                                                startDate={field.value.from
                                                  ? moment(field.value.from, "YYYY-MM-DD HH:mm:ss")
                                                  : null
                                                }
                                                endDate={field.value.to
                                                  ? moment(field.value.to, "YYYY-MM-DD HH:mm:ss")
                                                  : null
                                                }
                                                onChange={(from, to) =>
                                                  field.onChange({from, to})}
                                                title="С - по"
                                              />
                                            }
                                            name="filters.dateRange"
                                            control={control}
                                        />
                                    </FilterRowWrapper>
                                    
                                    <FilterRowWrapper>
                                        <Controller
                                            render={({field, fieldState}) =>
                                                <MultiSelect
                                                    {...field}
                                                    {...fieldState}
                                                    title="Город"
                                                    options={citiesOptions}
                                                    withSubOptions={false}
                                                    ref={null}
                                                />
                                            }
                                            name="filters.city"
                                            control={control}
                                        />
                                    </FilterRowWrapper>
                                    
                                    <FilterRowWrapper>
                                        <Controller
                                            render={({field, fieldState}) =>
                                                <MultiSelect
                                                    {...field}
                                                    {...fieldState}
                                                    title="Ресторан"
                                                    options={restaurantsOptions}
                                                    withSubOptions={true}
                                                    subOptionsKey="restaurants_minimal"
                                                    ref={null}
                                                />
                                            }
                                            name="filters.restaurant"
                                            control={control}
                                        />
                                    </FilterRowWrapper>
                                    
                                    <FilterRowWrapper>
                                        <Controller
                                            render={({field, fieldState}) =>
                                                <MultiSelect
                                                    {...field}
                                                    {...fieldState}
                                                    title="Партнер"
                                                    options={partnersOptions}
                                                    withSubOptions={false}
                                                    ref={null}
                                                />
                                            }
                                            name="filters.partner"
                                            control={control}
                                        />
                                    </FilterRowWrapper>
                                </Filters>
                            }
                        </FiltersBtn>
                    </RightActions>
                }
                title="Блогеры"
            />
            
            <Grid
                data={bloggersData}
                isLoading={isLoading}
                error={error}
                gridRow={GridRow}
                setPage={setPage}
                setLimit={setLimit}
            />
        </>
    );
});

const RightActions = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: flex-end;
  flex-grow: 1;
    
    @media (max-width: 640px) {
        flex-wrap: wrap;
        justify-content: flex-start;
    }
    
    @media (max-width: 450px) {
        &>div{
            &:not(:first-child):not(:last-child) {
                width: 100%;
            }
            &:first-child {
                order: 1;
            }
        }
    }
`;

const FiltersBtn = styled.div`
  position: relative;
`;

const SearchWrapper = styled.div`
  max-width: 200px;

    @media (max-width: 1122px) {
        max-width: 100%;  
    }
    
    @media (max-width: 930px) {
        flex-grow: 1;
    }
`;

const HeaderSelectWrapper = styled.div`
  width: 180px;
`;

const FilterRowWrapper = styled.div`
  &:not(:last-child) {
    margin-bottom: 15px;
  }
`;

export default Bloggers;