import React, {FC, memo, useCallback, useContext, useEffect} from "react";
import {Controller, useWatch} from "react-hook-form";
import Checkbox from "../../../../components/Checkbox";
import Select from "../../../../components/Select";
import {DirectoryStoreContext} from "../../../../stores/directory";
import initializeMultiselectOptions from "../../../../utils/initializeMultiselectOptions";

interface Props {
    control: any;
    setValue: any;
    row: any;
}

const RestaurantRoleFields: FC<Props> = memo(({
    control,
    setValue,
    row
}) => {
    const {partners, restaurants} = useContext(DirectoryStoreContext);
    const partnersOptions = initializeMultiselectOptions(partners, "id", "name", false);

    const partnerSelectValue = useWatch({
        control,
        name: "restaurant.partner_id"
    });
    
    useEffect(() => {
        if (partnerSelectValue !== row?.partner?.id) {
            setValue("restaurant.restaurants_ids", null);
        }
    }, [partnerSelectValue]);
    
    const getRestaurantsOptions = useCallback(() => {
        const restaurantsOptions = restaurants.map(item => ({label: item.name_ru, value: item.id}));
        
        if (partnerSelectValue) {
            const filteredRestaurants = restaurants.filter(restaurant => {
                return restaurant.partner_id === partnerSelectValue;
            });
            const filteredRestaurantsIds = filteredRestaurants.map(restaurant => restaurant.id);
            return restaurantsOptions.filter(restaurant => {
                if (filteredRestaurantsIds.indexOf(restaurant.value) !== -1) {
                    return true;
                }
            });
        } else {
            return restaurantsOptions;
        }
    }, [partnerSelectValue, restaurants]);
    
    return (
        <>
            <Controller
                render={({field, fieldState}) => {
                    return (
                        <Select
                            {...field}
                            {...fieldState}
                            title="Партнер"
                            options={partnersOptions}
                            ref={null}
                        />
                    );
                }}
                name="restaurant.partner_id"
                control={control}
            />
    
            <Controller
                render={({field, fieldState}) => {
                    return (
                        <Select
                            {...field}
                            {...fieldState}
                            title="Ресторан"
                            options={getRestaurantsOptions()}
                            withSearch={true}
                            withSorting={true}
                            disabled={!partnerSelectValue}
                            ref={null}
                        />
                    );
                }}
                name="restaurant.restaurants_ids"
                control={control}
            />

            <Controller
                render={({field, fieldState}) => {
                    return (
                        <Checkbox
                            {...field}
                            {...fieldState}
                            label="Доступ к статусу авто-обзвона"
                            onChange={(value) => field.onChange(value)}
                            ref={null}
                        />
                    );
                }}
                name="restaurant.is_access_to_auto_call_status"
                control={control}
            />
    
            <Controller
                render={({field, fieldState}) => {
                    return (
                        <Checkbox
                            {...field}
                            {...fieldState}
                            label="Доступ к ПЛР"
                            onChange={(value) => field.onChange(value)}
                            ref={null}
                        />
                    );
                }}
                name="restaurant.is_plr_access"
                control={control}
            />
    
            <Controller
                render={({field, fieldState}) => {
                    return (
                        <Checkbox
                            {...field}
                            {...fieldState}
                            label="Доступ к заказу анкет"
                            onChange={(value) => field.onChange(value)}
                            ref={null}
                        />
                    );
                }}
                name="restaurant.is_candidate_profile_order_access"
                control={control}
            />
    
            <Controller
                render={({field, fieldState}) => {
                    return (
                        <Checkbox
                            {...field}
                            {...fieldState}
                            label="Доступ к заказу offline рекламы"
                            onChange={(value) => field.onChange(value)}
                            ref={null}
                        />
                    );
                }}
                name="restaurant.is_access_to_ordering_offline_advertising"
                control={control}
            />
        </>
    );
});

export default RestaurantRoleFields;