import {observer} from "mobx-react-lite";
import React, {FC, useMemo} from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import styled from "styled-components";
import {theme} from "../../styles/theme";

interface Props {
    data: any;
    total: number;
}

const AnalyticPieChart: FC<Props> = observer(({data, total}) => {

    const pieColors = (function () {
        let colors = [],
          base = Highcharts.getOptions().colors[8],
          i;

        for (i = 0; i < 10; i += 1) {
            colors.push(Highcharts.color(base).brighten((i - 5) / 7).get());
        }
        return colors;
    }());

    const chartOptions = useMemo(() => {
        return {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: "pie",
            },
            title: {
                text: "",
            },
            tooltip: {
                pointFormat: "{series.name}",
                followPointer: false,
                borderRadius: 4,
                backgroundColor: theme.colors.neutral8,
                borderColor: "transparent",
                padding: 10,
                shadow: false,
                hideDelay: 100,
                className: "funnel-tooltip",
                useHTML: true,
                outside: true,
                style: {
                    color: "#ffffff",
                    fontSize: "14px",
                }
            },
            accessibility: {
                point: {
                    valueSuffix: "%",
                },
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: "pointer",
                    colors: pieColors,
                    dataLabels: {
                        enabled: true,
                        backgroundColor: "#4F575E",
                        useHTML: true,
                        className: "pie-label",
                        color: "#FFFFFF",
                        borderRadius: 4,
                        format: "{point.y}|{point.percentage:.1f}%",
                    },
                    showInLegend: true,
                },
            },
            legend: {
                enabled: true,
                layout: "vertical",
                align: "right",
                y: 10,
                itemWidth: 150,
                symbolRadius: 4,
                symbolHeight: 20,
                symbolWidth: 20,
                symbolPadding: 5,
                itemMarginTop: 15,
                useHTML: true,
                navigation: {
                    activeColor: theme.colors.primary,
                }
            },
            series: [
                {
                    name: "Brands",
                    colorByPoint: true,
                    data: Object.keys(data).map(key => ({
                        name: key,
                        y: data[key].count
                    })),
                },
            ],
        };
    }, [data, total]);

    return (
        <Wrapper>
            <HighchartsReact highcharts={Highcharts} options={chartOptions}/>
        </Wrapper>
    );
});

const Wrapper = styled.div`
  width: 100%;  
    
  @media (max-width: 890px) {
      overflow: auto;

      &>div {
          width: 762px;
      }
  }
    
  .pie-label {
      span {
          font-family: ${theme.fonts.mainRegular} !important;
          font-weight: normal !important;
          font-size: 13px !important;
          left: 5px !important;
          top: 5px !important;
      }
  }
`;

export default AnalyticPieChart;
