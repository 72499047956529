import React, {FC} from "react";
import styled from "styled-components";
import {theme} from "../../../styles/theme";
import ActionIcon from "../../../components/ActionIcon";
import DeleteIcon from "../../../assets/icons/DeleteIcon";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 10px;
  cursor: pointer;
  border-radius: 4px;
  position: relative;
  gap: 10px;
  
  @media (max-width: 795px) {
    flex-wrap: wrap;
  }
  
  &:hover {
    background: ${theme.colors.neutral2};
    
    .btn-delete {
      display: block;
    }
  }
  
  .btn-delete {
    display: none;
    position: absolute;
    top: 5px;
    right: 5px;

    @media (hover: none) {
      display: block;
    }
    
    @media (max-width: 500px) {
      right: 0;
    }
  }
`;

const Column = styled.div<{title: string,}>`
  display: flex;
  flex-direction: column;
  width: 180px;
  ${({title}) => title === "Комментарий" && "flex-grow: 1; width: 100%;"};
  
  &:first-child {
    width: 300px;
  }
`;

const Title = styled.p`
  line-height: 14px;
  margin-bottom: 5px;
  font-family: ${theme.fonts.mainRegular};
  font-size: ${theme.fontSizes.small};
  color: ${theme.colors.neutral7};
`;

const Value = styled.p`
  line-height: 20px;
  font-family: ${theme.fonts.mainRegular};
  font-size: ${theme.fontSizes.body};
  color: ${theme.colors.bodyText};
`;

interface Props {
    values: Array<{title:string, value:string}>
    fetchDelete: () => void;
}

const MaterialItem : FC<Props> = ({values, fetchDelete}) => {
    return (
        <Wrapper>
            {
                values.map((item, i) => {
                    return (
                        <Column key={i} title={item.title}>
                            <Title>{item.title}</Title>
                            <Value>{item.value}</Value>
                        </Column>
                    )
                })
            }
            <ActionIcon
                className={"btn-delete"}
                action={fetchDelete}
                size={24}
                tooltip={"Удалить"}
            >
                <DeleteIcon/>
            </ActionIcon>
        </Wrapper>
    )
};

export default MaterialItem;
