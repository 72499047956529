import {useEffect} from "react";

export interface IFormData {
  searchValue: string;
  sortOrder: string;
  sortBy: string;
  filters: any;
}

export const useUpdateForm = (setValue, formData: IFormData) => {
  const {searchValue, sortBy, sortOrder, filters} = formData;

  useEffect(() => {
    setValue("search", searchValue);
  }, [setValue, searchValue]);

  useEffect(() => {
    setValue("sortBy", sortBy);
  }, [setValue, sortBy]);

  useEffect(() => {
    setValue("sortOrder", sortOrder);
  }, [setValue, sortOrder]);

  useEffect(() => {
    setValue("filters", filters);
  }, [setValue, filters, filters.restaurant_id, filters.candidate_profile_status_id, filters.citizenship_id]);
}