import React, { FC, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { Input } from "../../../../components/Input";
import { theme } from "../../../../styles/theme";
import Comment from "./Comment";
import SendIcon from "../../../../assets/icons/SendIcon";
import { useFetching } from "../../../../hooks/useFetching";
import { createComment, getComments } from "../../../../actions/comments";

interface Props {
  comments?: {
    date: string;
    name: string;
    comment: string;
  }[];
  data?: any;
  type?: string;
  disabled?: boolean;
  getValues?: (field: string) => number;
  resetField?: (field: string, option: object) => void;
}

const Comments: FC<Props> =({ comments = [], data, type, disabled, getValues, resetField }) => {
  const [commentInputValue, setCommentInputValue] = useState("");
  const [commentsData, setCommentsData] = useState(null);
  const [messageSend, setMessageSend] = useState(false);

  const [fetchComments, isLoading, error] = useFetching(async (data) => {
    const { filters } = data;
    const response = await getComments(filters);
    setCommentsData(response);
    if(response?.data.length &&
      (getValues("refuseReason") === 8 || getValues("clarification") === 6)
    ) {
      resetField("statusComment",  { defaultValue: response.data.at(-1)?.comment })
    }
  });

  useEffect(() => {
    applyComments();
  }, []);

  const applyComments = useCallback(() => {
    if(data) {
      fetchComments({
        filters: {
          model_id: data.id,
          model_type: type,
        },
      });
    }
  }, []);

  const onKeyUpHandler = (e) => {
    if(e.code === "Enter") onSend();
  }

  const onSend = useCallback(async () => {
    setMessageSend(true);
    const serverData = {
      model_id: data.id,
      model_type: type,
      comment: commentInputValue,
    };
    await createComment(serverData);
    setCommentInputValue("");
    applyComments();
    setMessageSend(false);
  }, [commentInputValue]);

  return (
    <>
      <H2>Комментарии</H2>

      <CommentsWrapper>
        {commentsData?.data.length > 0 && (
          <>
            {commentsData.data.map((comment, index) => (
              <Comment key={index} data={comment} />
            ))}
          </>
        )}
        {commentsData?.data.length === 0 && (
          <NoCommentsWrapper>Комментариев нет</NoCommentsWrapper>
        )}
      </CommentsWrapper>
      <Input
        inputId="comment"
        value={commentInputValue}
        placeholder="Введите комментарий"
        onChange={(value) => setCommentInputValue(value)}
        onKeyUp={onKeyUpHandler}
        rightIcon={<SendIcon />}
        rightActionDisabled={!commentInputValue || messageSend}
        rightIconAction={() => {
          onSend();
        }}
        disabled={messageSend || disabled}
      />
    </>
  );
};

const H2 = styled.div`
  font-size: ${theme.fontSizes.h2};
  color: ${theme.colors.neutral10};
  font-family: ${theme.fonts.mainBlack};
  margin-bottom: 16px;
`;

const CommentsWrapper = styled.div`
  margin-bottom: 30px;
  flex-grow: 1;
  height: 168px;
  overflow-x: hidden;
  overflow-y: auto;
`;

const NoCommentsWrapper = styled.div`
  font-size: ${theme.fontSizes.small};
  color: ${theme.colors.neutral7};
`;

export default Comments;
