import React, { FC } from "react";
import Select from "../../Select";
import styled from "styled-components";
import { theme } from "../../../styles/theme";
import { observer } from "mobx-react-lite";

const SELECT_OPTIONS = [
  { label: "2", value: "2" },
  { label: "5", value: "5" },
  { label: "10", value: "10" },
  { label: "20", value: "20" },
  { label: "50", value: "50" },
];

interface Props {
  limit: number;
  from: number;
  to: number;
  totalCount: number;
  changeLimit?: (string) => void;
}

const PaginationLimit: FC<Props> = observer(
  ({ limit, from, to, totalCount, changeLimit }) => {
    const selectChangeHandle = (value) => {
      changeLimit(value);
    };

    return (
      <PaginationLimitWrapper>
        <SelectText>Показывать по</SelectText>

        <Select
          title={""}
          options={SELECT_OPTIONS}
          value={limit?.toString()}
          onChange={(value) => selectChangeHandle(value)}
          size={"small"}
          optionsDirection={"top"}
          width={"65px"}
        />
        <TotalText>
          {from}-{to} из {totalCount}
        </TotalText>
      </PaginationLimitWrapper>
    );
  }
);

const Text = styled.div`
  font-family: ${theme.fonts.mainRegular};
  color: ${theme.colors.neutral7};
  font-size: ${theme.fontSizes.body};
  white-space: nowrap;
`;

const SelectText = styled(Text)`
  margin-right: 8px;
`;

const TotalText = styled(Text)`
  margin-left: 16px;
`;

const PaginationLimitWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export default PaginationLimit;
