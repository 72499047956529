import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import React, {FC, memo, useCallback, useContext, useState} from "react";
import {Controller, useForm, useWatch} from "react-hook-form";
import styled from "styled-components";
import * as yup from "yup";
import ArrowBack from "../../../../assets/icons/ArrowBack";
import ActionIcon from "../../../../components/ActionIcon";
import Button from "../../../../components/Button";
import Switcher from "../../../../components/Switcher";
import {errorsDescriptions} from "../../../../config/errors";
import {theme} from "../../../../styles/theme";
import ToCandidate from "./ToCandidate";
import ToUser from "./ToUser";
import {useFetching} from "../../../../hooks/useFetching";
import {changeNotify, createNotify} from "../../../../actions/restaurants";
import {DirectoryStoreContext} from "../../../../stores/directory";

interface Props {
  restaurant_id: number,
  notifications: {
    id: number;
    is_system: boolean;
    send_type: string;
    send_code: string;
    candidate_type: string;
    is_additional_email_sender: boolean;
    body: string;
    is_email_new_candidate_profile_employee: boolean;
    is_new_interview_profile_employee: boolean;
    is_one_hour_reminder_employee: boolean;
    is_email_new_candidate_profile_manager: boolean;
    is_new_interview_profile_manager: boolean;
    is_one_hour_reminder_manager: boolean;
    restaurant: {
      id: number;
      name_ru: string;
      email: string;
      address_ru: string;
      ignore_citizenship: number;
      forwarding_link: string;
      interview_format_for_shift_manager: string;
      location_for_shift_manager: string;
      call_bot: boolean;
      use_in_call_center: boolean;
      corporative: boolean;
      minor_recruitment_age: string;
      deleted_at: string;
    },
    role: {
      id: number;
      name: string;
      updated_at: string;
      deleted_at: string;
    },
    created_at: string;
    updated_at: string;
    deleted_at: string;
  }[];
  onChangeFormPart: () => void;
  onSubmit: (notifications: any) => void
}

const schema = yup.object().shape({
  role: yup.string().required(errorsDescriptions.required),
  to_candidate: yup.object().shape({
    candidateType: yup.string().required(errorsDescriptions.required),
    restaurant_employee: yup.object().shape({
      sms: yup.boolean().required(errorsDescriptions.required),
      email: yup.object().required(errorsDescriptions.required),
      message: yup.string(),
    }),
    shift_manager: yup.object().shape({
      sms: yup.boolean().required(errorsDescriptions.required),
      email: yup.object().required(errorsDescriptions.required),
      message: yup.string(),
    }),
  }),
  to_user: yup.object().shape({
    role: yup.object().required(errorsDescriptions.required),
    restaurant: yup.object().shape({
      new_forms_chk_email: yup.boolean().required(errorsDescriptions.required),
      new_forms_message: yup.string(),
      new_interviews_chk_email: yup.boolean().required(errorsDescriptions.required),
      new_interviews_message: yup.string(),
      email_reminder_chk: yup.boolean().required(errorsDescriptions.required)
    }),
    controller: yup.object().shape({
      new_forms_ms_email: yup.boolean().required(errorsDescriptions.required),
      new_forms_message: yup.string(),
      new_interviews_ms_email: yup.boolean().required(errorsDescriptions.required),
      new_interviews_message: yup.string(),
      email_reminder_ms: yup.boolean().required(errorsDescriptions.required)
    }),
    hr_partner: yup.object().shape({
      new_forms_chk_email: yup.boolean().required(errorsDescriptions.required),
      new_forms_ms_email: yup.boolean().required(errorsDescriptions.required),
      new_forms_message: yup.string(),
      new_interviews_chk_email: yup.boolean().required(errorsDescriptions.required),
      new_interviews_ms_email: yup.boolean().required(errorsDescriptions.required),
      new_interviews_message: yup.string(),
      email_reminder_chk: yup.boolean().required(errorsDescriptions.required),
      email_reminder_ms: yup.boolean().required(errorsDescriptions.required)
    })
  })
});

const Notifications: FC<Props> = memo(({
 restaurant_id,
 notifications,
 onChangeFormPart,
 onSubmit
}) => {
  const toRestaurantEmployee = notifications?.find(el =>
    el.send_code === "notification_about_interview" &&
    el.candidate_type === "restaurant_employee");
  const toShiftManager = notifications?.find(el =>
    el.send_code === "notification_about_interview" &&
    el.candidate_type === "shift_manager");

  const toRestaurantRoleCandidate = notifications?.find(el => el.role?.name === "restaurant" &&
    el.send_code === "notifications_about_new_candidate");
  const toRestaurantRoleInterview = notifications?.find(el => el.role?.name === "restaurant" &&
    el.send_code == "notifications_of_new_interviews_for_candidate");
  const toRestaurantRoleReminder = notifications?.find(el => el.role?.name === "restaurant" &&
    el.send_code == "one_hour_reminder_before_interview");

  const toControllerRoleCandidate = notifications?.find(el => el.role?.name === "controller" &&
    el.send_code === "notifications_about_new_candidate");
  const toControllerRoleInterview = notifications?.find(el => el.role?.name === "controller" &&
    el.send_code == "notifications_of_new_interviews_for_candidate");
  const toControllerRoleReminder = notifications?.find(el => el.role?.name === "controller" &&
    el.send_code == "one_hour_reminder_before_interview");

  const toHRPartnerRoleCandidate = notifications?.find(el => el.role?.name === "hr_partner" &&
    el.send_code === "notifications_about_new_candidate");
  const toHRPartnerRoleInterview = notifications?.find(el => el.role?.name === "hr_partner" &&
    el.send_code == "notifications_of_new_interviews_for_candidate");
  const toHRPartnerRoleReminder = notifications?.find(el => el.role?.name === "hr_partner" &&
    el.send_code == "one_hour_reminder_before_interview");

  const [changedEmployee, setChangedEmployee] = useState(false);
  const [changedManager, setChangedManager] = useState(false);

  const [changedRestaurantNewCandidate, setRestaurantNewCandidate] = useState(false);
  const [changedRestaurantInterview, setRestaurantInterview] = useState(false);
  const [changedRestaurantReminder, setRestaurantReminder] = useState(false);

  const [changedControllerNewCandidate, setControllerNewCandidate] = useState(false);
  const [changedControllerInterview, setControllerInterview] = useState(false);
  const [changedControllerReminder, setControllerReminder] = useState(false);

  const [changedHRPartnerNewCandidate, setHRPartnerNewCandidate] = useState(false);
  const [changedHRPartnerInterview, setHRPartnerInterview] = useState(false);
  const [changedHRPartnerReminder, setHRPartnerReminder] = useState(false);

  const [fetchNotify, isLoading, error] = useFetching(async (params, id = -1) => {
    (id === -1)
      ? await createNotify(params)
      : await changeNotify(id, params);
  });

  const {roles} = useContext(DirectoryStoreContext);

  const {
    control,
    getValues
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      role: "toCandidate",
      to_candidate: {
        candidateType: "restaurant_employee",
        restaurant_employee: {
          sms: toRestaurantEmployee?.send_type === "sms" || toRestaurantEmployee?.send_type === "sms_email",
          email: toRestaurantEmployee?.send_type === "email" || toRestaurantEmployee?.send_type === "sms_email",
          message: !!toRestaurantEmployee ? toRestaurantEmployee.body : "",
        },
        shift_manager: {
          sms: toShiftManager?.send_type === "sms" || toShiftManager?.send_type === "sms_email",
          email: toShiftManager?.send_type === "email" || toShiftManager?.send_type === "sms_email",
          message: !!toShiftManager ? toShiftManager.body : "",
        },
      },
      to_user: {
        role: "restaurant",
        restaurant: {
          role: "restaurant",
          new_forms_chk_email: toRestaurantRoleCandidate?.is_email_new_candidate_profile_employee || false,
          new_forms_message: toRestaurantRoleCandidate?.body || "",
          new_interviews_chk_email: toRestaurantRoleInterview?.is_new_interview_profile_employee || false,
          new_interviews_message: toRestaurantRoleInterview?.body || "",
          email_reminder_chk: toRestaurantRoleReminder?.is_one_hour_reminder_employee || false,
          reminder_message: toRestaurantRoleReminder?.body || "",
        },
        controller: {
          role: "controller",
          new_forms_ms_email: toControllerRoleCandidate?.is_email_new_candidate_profile_manager || false,
          new_forms_message: toControllerRoleCandidate?.body || "",
          new_interviews_ms_email: toControllerRoleInterview?.is_new_interview_profile_manager || false,
          new_interviews_message: toControllerRoleInterview?.body || "",
          email_reminder_ms: toControllerRoleReminder?.is_one_hour_reminder_manager || false,
          reminder_message: toControllerRoleReminder?.body || "",
        },
        hr_partner: {
          role: "hr_partner",
          new_forms_chk_email: toHRPartnerRoleCandidate?.is_email_new_candidate_profile_employee || false,
          new_forms_ms_email: toHRPartnerRoleCandidate?.is_email_new_candidate_profile_manager || false,
          new_forms_message: toHRPartnerRoleCandidate?.body || "",
          new_interviews_chk_email: toHRPartnerRoleInterview?.is_new_interview_profile_employee || false,
          new_interviews_ms_email: toHRPartnerRoleInterview?.is_new_interview_profile_manager || false,
          new_interviews_message: toHRPartnerRoleInterview?.body || "",
          email_reminder_chk: toHRPartnerRoleReminder?.is_one_hour_reminder_employee || false,
          email_reminder_ms: toHRPartnerRoleReminder?.is_one_hour_reminder_manager || false,
          reminder_message: toHRPartnerRoleReminder?.body || "",
        }
      }
    }
  });

  const switcherValue = useWatch({
    control,
    name: "role"
  });

  const fetchCandidate = async (values, candidateType) => {
    let candidateSendType = null;
    if (values.to_candidate[candidateType].email && values.to_candidate[candidateType].sms) {
      candidateSendType = "sms_email";
    } else if (values.to_candidate[candidateType].email) {
      candidateSendType = "email";
    } else if (values.to_candidate[candidateType].sms) {
      candidateSendType = "sms";
    }

    const params = {
      restaurant_id: restaurant_id,
      send_code: "notification_about_interview",
      candidate_type: candidateType,
      send_type: candidateSendType,
    };

    const candidateMessage = values.to_candidate[candidateType].message;
    if (candidateMessage) {
      params["body"] = candidateMessage;
    }

    if (candidateType === "shift_manager") {
      if (toShiftManager) {
        await fetchNotify(params, toShiftManager.id);
      } else {
        await fetchNotify(params);
      }
    }

    if (candidateType === "restaurant_employee") {
      if (toRestaurantEmployee) {
        await fetchNotify(params, toRestaurantEmployee.id);
      } else {
        await fetchNotify(params);
      }
    }
  }

  const fetchRestaurantRole = async (values, roleId, send_code) => {
    const params = {
      restaurant_id: restaurant_id,
      role_id: roleId,
      send_type: "email",
      send_code: send_code,
      candidate_type: "none",
    }

    if (send_code === "notifications_about_new_candidate") {
      params["is_email_new_candidate_profile_employee"] = values.to_user.restaurant.new_forms_chk_email;
      const candidateBody = values.to_user.restaurant.new_forms_message;
      if (candidateBody) params["body"] = candidateBody;

      if (toRestaurantRoleCandidate) {
        await fetchNotify(params, toRestaurantRoleCandidate.id);
      } else {
        await fetchNotify(params);
      }
    }

    if (send_code === "notifications_of_new_interviews_for_candidate") {
      params["is_new_interview_profile_employee"] = values.to_user.restaurant.new_interviews_chk_email;
      const interviewBody = values.to_user.restaurant.new_interviews_message;
      if (interviewBody) params["body"] = interviewBody;

      if (toRestaurantRoleInterview) {
        await fetchNotify(params, toRestaurantRoleInterview.id);
      } else {
        await fetchNotify(params);
      }
    }

    if (send_code === "one_hour_reminder_before_interview") {
      params["is_one_hour_reminder_employee"] = values.to_user.restaurant.email_reminder_chk;
      const reminderBody = values.to_user.restaurant.reminder_message;
      if (reminderBody) params["body"] = reminderBody;


      if (toRestaurantRoleReminder) {
        await fetchNotify(params, toRestaurantRoleReminder.id);
      } else {
        await fetchNotify(params);
      }
    }
  }

  const fetchControllerRole = async (values, roleId, send_code) => {
    const params = {
      restaurant_id: restaurant_id,
      role_id: roleId,
      send_type: "email",
      send_code: send_code,
      candidate_type: "none",
    }

    if (send_code === "notifications_about_new_candidate") {
      params["is_email_new_candidate_profile_manager"] = values.to_user.controller.new_forms_ms_email;
      const candidateBody = values.to_user.controller.new_forms_message;
      if (candidateBody) params["body"] = candidateBody;

      if (toControllerRoleCandidate) {
        await fetchNotify(params, toControllerRoleCandidate.id);
      } else {
        await fetchNotify(params);
      }
    }

    if (send_code === "notifications_of_new_interviews_for_candidate") {
      params["is_new_interview_profile_manager"] = values.to_user.controller.new_interviews_ms_email;
      const interviewBody = values.to_user.controller.new_interviews_message;
      if (interviewBody) params["body"] = interviewBody;

      if (toControllerRoleInterview) {
        await fetchNotify(params, toControllerRoleInterview.id);
      } else {
        await fetchNotify(params);
      }
    }

    if (send_code === "one_hour_reminder_before_interview") {
      params["is_one_hour_reminder_manager"] = values.to_user.controller.email_reminder_ms;
      const reminderBody = values.to_user.controller.reminder_message;
      if (reminderBody) params["body"] = reminderBody;

      if (toControllerRoleReminder) {
        await fetchNotify(params, toControllerRoleReminder.id);
      } else {
        await fetchNotify(params);
      }
    }
  }

  const fetchHRPartnerRole = async (values, roleId, send_code) => {
    const params = {
      restaurant_id: restaurant_id,
      role_id: roleId,
      send_type: "email",
      send_code: send_code,
      candidate_type: "none",
    }

    if (send_code === "notifications_about_new_candidate") {
      params["is_email_new_candidate_profile_employee"] = values.to_user.hr_partner.new_forms_chk_email;
      params["is_email_new_candidate_profile_manager"] = values.to_user.hr_partner.new_forms_ms_email;
      const candidateBody = values.to_user.hr_partner.new_forms_message;
      if (candidateBody) params["body"] = candidateBody;

      if (toHRPartnerRoleCandidate) {
        await fetchNotify(params, toHRPartnerRoleCandidate.id)
      } else {
        await fetchNotify(params);
      }
    }

    if (send_code === "notifications_of_new_interviews_for_candidate") {
      params["is_new_interview_profile_employee"] = values.to_user.hr_partner.new_interviews_chk_email;
      params["is_new_interview_profile_manager"] = values.to_user.hr_partner.new_interviews_ms_email;
      const interviewBody = values.to_user.hr_partner.new_interviews_message;
      if (interviewBody) params["body"] = interviewBody;

      if (toHRPartnerRoleInterview) {
        await fetchNotify(params, toHRPartnerRoleInterview.id)
      } else {
        await fetchNotify(params);
      }
    }

    if (send_code === "one_hour_reminder_before_interview") {
      params["is_one_hour_reminder_employee"] = values.to_user.hr_partner.email_reminder_chk;
      params["is_one_hour_reminder_manager"] = values.to_user.hr_partner.email_reminder_ms;
      const reminderBody = values.to_user.hr_partner.reminder_message;
      if (reminderBody) params["body"] = reminderBody;

      if (toHRPartnerRoleReminder) {
        await fetchNotify(params, toHRPartnerRoleReminder.id)
      } else {
        await fetchNotify(params);
      }
    }
  }

  const submitButtonClickHandle = useCallback(async () => {
    const values = getValues();

    const roleIdRestaurant = roles.find(role => role.name === "restaurant").id;
    const roleIdController = roles.find(role => role.name === "controller").id;
    const roleIdHRPartner = roles.find(role => role.name === "hr_partner").id;

    const promises = [];

    if(changedEmployee) promises.push(fetchCandidate(values, "restaurant_employee"));
    if(changedManager) promises.push(fetchCandidate(values, "shift_manager"));

    if(changedRestaurantNewCandidate) promises.push(fetchRestaurantRole(values, roleIdRestaurant, "notifications_about_new_candidate"));
    if(changedRestaurantInterview) promises.push(fetchRestaurantRole(values, roleIdRestaurant, "notifications_of_new_interviews_for_candidate"));
    if(changedRestaurantReminder) promises.push(fetchRestaurantRole(values, roleIdRestaurant, "one_hour_reminder_before_interview"));

    if(changedControllerNewCandidate) promises.push(fetchControllerRole(values, roleIdController, "notifications_about_new_candidate"));
    if(changedControllerInterview) promises.push(fetchControllerRole(values, roleIdController, "notifications_of_new_interviews_for_candidate"));
    if(changedControllerReminder) promises.push(fetchControllerRole(values, roleIdController, "one_hour_reminder_before_interview"));

    if(changedHRPartnerNewCandidate) promises.push(fetchHRPartnerRole(values, roleIdHRPartner, "notifications_about_new_candidate"));
    if(changedHRPartnerInterview) promises.push(fetchHRPartnerRole(values, roleIdHRPartner, "notifications_of_new_interviews_for_candidate"));
    if(changedHRPartnerReminder) promises.push(fetchHRPartnerRole(values, roleIdHRPartner, "one_hour_reminder_before_interview"));

    await Promise.all(promises);

    delete values.role;
    onChangeFormPart();
  }, [
    changedRestaurantNewCandidate,
    changedControllerNewCandidate,
    changedHRPartnerNewCandidate,
    changedRestaurantInterview,
    changedControllerInterview,
    changedHRPartnerInterview,
    changedRestaurantReminder,
    changedControllerReminder,
    changedHRPartnerReminder,
    changedEmployee,
    changedManager,
  ]);

  return (
    <NotificationsWrapper>
      <HeaderWrapper>
        <ActionIcon
          action={() => onChangeFormPart()}
        >
          <ArrowBack/>
        </ActionIcon>

        <H2>Уведомления</H2>
      </HeaderWrapper>

      <SwitcherWrapper>
        <Controller
          render={({field, fieldState}) =>
            <Switcher
              {...field}
              {...fieldState}
              left={{value: "toCandidate", label: "Кандидату"}}
              right={{value: "toUser", label: "Пользователю"}}
              ref={null}
            />
          }
          name="role"
          control={control}
        />
      </SwitcherWrapper>

      {switcherValue === "toCandidate" &&
        <ToCandidate
          setChangedEmployee={setChangedEmployee}
          setChangedManager={setChangedManager}
          control={control}
        />
      }

      {switcherValue === "toUser" &&
        <ToUser
          setRestaurantNewCandidate={setRestaurantNewCandidate}
          setControllerNewCandidate={setControllerNewCandidate}
          setHRPartnerNewCandidate={setHRPartnerNewCandidate}
          setRestaurantInterview={setRestaurantInterview}
          setControllerInterview={setControllerInterview}
          setHRPartnerInterview={setHRPartnerInterview}
          setRestaurantReminder={setRestaurantReminder}
          setControllerReminder={setControllerReminder}
          setHRPartnerReminder={setHRPartnerReminder}
          control={control}
        />
      }

      <ButtonsWrapper>
        <Buttons>
          <Button
            title="Назад"
            btnStyle="cancel"
            onClick={onChangeFormPart}
          />
          <Button
            type="submit"
            title="Сохранить"
            btnStyle="primary"
            disabled={isLoading}
            onClick={submitButtonClickHandle}
          />
        </Buttons>
      </ButtonsWrapper>
    </NotificationsWrapper>
  );
});

const NotificationsWrapper = styled.div`
  width: 100%;
  font-family: ${theme.fonts.mainRegular};
`;

const HeaderWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 15px;
`;

const H2 = styled.div`
  color: ${theme.colors.neutral10};
  font-size: ${theme.fontSizes.h2};
  font-family: ${theme.fonts.mainBlack};
`;

const SwitcherWrapper = styled.div`
  margin-bottom: 15px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 20px;
`;

const Buttons = styled.div`
  display: flex;
  gap: 20px;
`;

export default Notifications;