import {FC} from "react";

interface Props {
    onClick?: () => void
}

const LinkIcon: FC<Props> = ({onClick}) => {
    return (
        <svg
            width="24"
            height="24"
            fill="none"
            onClick={onClick}
        >
            <g clipPath="url(#a)">
                <path
                    d="M17 7h-4v2h4c1.65 0 3 1.35 3 3s-1.35 3-3 3h-4v2h4c2.76 0 5-2.24 5-5s-2.24-5-5-5Zm-6 8H7c-1.65 0-3-1.35-3-3s1.35-3 3-3h4V7H7c-2.76 0-5 2.24-5 5s2.24 5 5 5h4v-2Zm-3-4h8v2H8v-2Z"
                    fill="#000"
                />
            </g>
            <defs>
                <clipPath id="a">
                    <path fill="#fff" d="M0 0h24v24H0z" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default LinkIcon;