import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import debounce from "lodash/debounce";
import {Controller, useForm} from "react-hook-form";
import styled from "styled-components";
import * as yup from "yup";
import FilterIcon from "../../assets/icons/FilterIcon";
import Header from "../../components/Header";
import {AD_COMPANIES_FILTER_ID as FILTER_ID} from "../../config/filters";
import {DirectoryStoreContext} from "../../stores/directory";
import {FiltersStoreContext} from "../../stores/filters";
import initializeMultiselectOptions from "../../utils/initializeMultiselectOptions";
import initializeMultiselectValue from "../../utils/initializeMultiselectValue";
import {observer} from "mobx-react-lite";
import {ModalsStore} from "../../stores/modal";
import Button from "../../components/Button";
import {Input} from "../../components/Input";
import Table from "../../components/Table";
import Filters from "../../components/Filters";
import MultiSelect from "../../components/MultiSelect";
import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import tableFields from "../../assets/AdCompanies/tableFields";
import ActionIcon from "../../components/ActionIcon";
import ContentWrapper from "../../components/ContentWrapper";
import PaginationGroup from "../../components/PaginationGroup";
import {getAdCompanies} from "../../actions/adCompany";
import Loader from "../../components/Loader";
import Page404 from "../Page404";
import {useFetching} from "../../hooks/useFetching";
import AdCompanyModal from "./AdCompanyModal";
import {LIMIT_DEFAULT, PAGE_DEFAULT} from "../../config/consts";
import {useMemoRestaurantOption} from "../../hooks/useMemoRestaurantOption";

const schema = yup.object().shape({
    search: yup.string(),
    filters: yup.object().shape({
        restaurant: yup.array(),
        tariff: yup.array()
    })
});

const AdCompanies = observer(() => {
    const [adCompaniesData, setAdCompaniesData] = useState(null);
    const [limit, setLimit] = useState(LIMIT_DEFAULT);
    const [page, setPage] = useState(PAGE_DEFAULT);
    const [searchValue, setSearchValue] = useState("");
    const [filtersValue, setFiltersValue] = useState(null);
    const filtersIconRef = useRef(null);
    const {filtersData, filtersIconClickHandle, closeFiltersHandle} = useContext(FiltersStoreContext);
    const {
        restaurants,
        tariffs,
        tariffsEndFetching
    } = useContext(DirectoryStoreContext);
    const restaurantsOptions = useMemoRestaurantOption();
    const tariffsOptions = useMemo(() => {
        return initializeMultiselectOptions(
          tariffs, "id", "name", false
        )
    }, [tariffs]);
    const {control, getValues, setValue, reset} = useForm({
        mode: "onChange",
        resolver: yupResolver(schema),
        defaultValues: {
            search: "",
            filters: {
                restaurant: [],
                tariff: []
            }
        }
    });
    
    const [fetchAdCompanies, isLoading, error] = useFetching(async () => {
        const response = await getAdCompanies(limit, page, searchValue, null, filtersValue);
        setAdCompaniesData(response);
    });
    
    useEffect(() => {
        setValue("filters.restaurant", initializeMultiselectValue(restaurants, "id"));
        setValue("filters.tariff", initializeMultiselectValue(tariffs, "id"));
    }, [restaurants, tariffs]);
    
    useEffect(() => {
        fetchAdCompanies();
    }, [limit, page, searchValue, filtersValue]);
    
    const searchInputChangeHandle = debounce((search) => {
        setSearchValue(search);
        setPage(PAGE_DEFAULT);
    }, 400);
    
    const resetFilters = useCallback(() => {
        reset({
            search: getValues().search,
            filters: {
                restaurant: initializeMultiselectValue(restaurants, "id"),
                tariff: initializeMultiselectValue(tariffs, "id"),
            },
        });
    }, [restaurants, tariffs]);
    
    const applyFilters = useCallback(() => {
        const filters = {...getValues().filters};
        
        if (filters.restaurant.length === restaurants.length) {
            delete filters.restaurant;
        }
        if (filters.tariff.length === tariffs.length) {
            delete filters.tariff;
        }

        setPage(1);
        setFiltersValue(filters);
    }, [restaurants, tariffs, limit, page]);
    
    const onOpenAdModal = useCallback(() => {
        ModalsStore.showModal("ad-companies-modal", {row: null, rowId: null});
    }, []);
    
    const onOpenEditModal = useCallback((rowId) => {
        const row = adCompaniesData.data.find((row) => row.id === rowId);
        ModalsStore.showModal("ad-companies-modal", {row, rowId});
    }, [adCompaniesData]);
    
    return (
        <>
            <Header
                rightActions={
                    <RightActions>
                        <Button title="Добавить РК" onClick={onOpenAdModal}/>

                        <SearchWrapper>
                            <Controller
                                render={({field, fieldState}) => (
                                    <Input
                                        {...field}
                                        {...fieldState}
                                        onChange={(value) => {
                                            searchInputChangeHandle(value);
                                            field.onChange(value);
                                        }}
                                        placeholder="Поиск..."
                                        search={true}
                                        maxLength={200}
                                        ref={null}
                                    />
                                )}
                                name="search"
                                control={control}
                            />
                        </SearchWrapper>
                        
                        <FiltersBtn ref={filtersIconRef}>
                            <ActionIcon
                                action={() => filtersIconClickHandle(FILTER_ID, filtersIconRef)}
                                active={filtersData[FILTER_ID]?.open}
                                disabled={!tariffsEndFetching || !restaurantsOptions.length}
                                tooltip="Фильтры"
                            >
                                <FilterIcon/>
                            </ActionIcon>
                            
                            {filtersData[FILTER_ID]?.open && (
                                <Filters
                                    onReset={resetFilters}
                                    onApply={applyFilters}
                                    btnRef={filtersIconRef}
                                    onClose={(event) => closeFiltersHandle(event, FILTER_ID, filtersIconRef)}
                                >
                                    <FilterRowWrapper>
                                        <Controller
                                            render={({field, fieldState}) => (
                                                <MultiSelect
                                                    {...field}
                                                    {...fieldState}
                                                    title="Ресторан"
                                                    options={restaurantsOptions}
                                                    withSubOptions={true}
                                                    subOptionsKey="restaurants_minimal"
                                                    ref={null}
                                                />
                                            )}
                                            name="filters.restaurant"
                                            control={control}
                                        />
                                    </FilterRowWrapper>
                                    
                                    <FilterRowWrapper>
                                        <Controller
                                            render={({field, fieldState}) => (
                                                <MultiSelect
                                                    {...field}
                                                    {...fieldState}
                                                    title="Тариф"
                                                    options={tariffsOptions}
                                                    withSubOptions={false}
                                                    ref={null}
                                                />
                                            )}
                                            name="filters.tariff"
                                            control={control}
                                        />
                                    </FilterRowWrapper>
                                </Filters>
                            )}
                        </FiltersBtn>
                    </RightActions>
                }
                title={"Управление РК"}
            />
            
            <TableWrapper>
                <ContentWrapper>
                    {isLoading ? (
                        <Loader width={70} height={70}/>
                    ) : (
                        <Table
                            data={adCompaniesData?.data}
                            fields={tableFields}
                            onClick={onOpenEditModal}
                            onRequestFulfilled={() => fetchAdCompanies()}
                        />
                    )}
                    {error && <Page404/>}
                </ContentWrapper>
                <PaginationGroup
                    meta={adCompaniesData?.meta}
                    setLimit={setLimit}
                    setPage={setPage}
                />
            </TableWrapper>


            <AdCompanyModal onRequestFulfilled={() => fetchAdCompanies()}/>
        </>
    );
});

const FiltersBtn = styled.div`
  position: relative;
`;

const SearchWrapper = styled.div`
  max-width: 200px;

    @media (max-width: 1241px) {
        max-width: 100%;
    }
`;

const TableWrapper = styled.div`
  height: calc(100% - 96px);
    
    @media (max-height: 700px) {
        height: auto;
    }
    
    @media (max-width: 700px) {
        .grid-content-wrapper {
            overflow-x: auto;
            &>div {
                width: 651px;
            }
        }
    }
`;

const RightActions = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: flex-end;
  flex-grow: 1;

    @media (max-width: 1241px) {
        &>div:nth-child(2) {
            flex-grow: 1;
        }
    }

    @media (max-width: 969px) {
        flex-wrap: wrap;
        justify-content: flex-start;
    }

    @media (max-width: 400px) {
        &>div,
        &>button {
            width: 100%;
        }
    }
`;

const FilterRowWrapper = styled.div`
  &:not(:last-child) {
    margin-bottom: 15px;
  }
`;

export default AdCompanies;
