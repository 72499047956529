import React, { FC, memo, MouseEventHandler } from "react";
import styled from "styled-components";

interface Props {
  title?: string;
  mainWidth?: string;
  type?: "button" | "submit" | "reset";
  weight?: number;
  svg?: React.JSXElementConstructor<any>;
  btnStyle?: 'primary' | 'secondary' | 'cancel';
  onClick?: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  className?: string,
}

const Button: FC<Props> = memo(
  ({  title,
                type,
                svg,
                onClick,
                disabled = false,
                btnStyle = 'secondary',
                weight,
                mainWidth,
                className,
  }: Props) => {

    const Icon = svg;

    return (
      <ButtonWrapper
        onClick={onClick}
        disabled={disabled}
        type={type}
        btnStyle={btnStyle}
        weight={weight}
        mainWidth={mainWidth}
        className={className}
      >
        {svg && <Icon disabled={disabled}/>}
        {title}
      </ButtonWrapper>
    );
  }
);

const buttonCss = (btnStyle, theme) => {
  switch (btnStyle) {

    case 'secondary':
      return (
        'border: 1px solid ' + theme.colors.primary + '; ' +
        'background-color: #ffffff; ' +
        'color: ' + theme.colors.primary + '; '
      )

    case 'cancel':
      return (
        'border: 1px solid ' + theme.colors.neutral7 + '; ' +
        'background-color: #ffffff; ' +
        'color: ' + theme.colors.neutral7 + '; '
      )

    default:
      return (
        'border: 0; ' +
        'background-color: ' + theme.colors.primary + '; ' +
        'color: #ffffff;'
      )
  }
}

const buttonCssHover = (btnStyle, theme) => {
  switch (btnStyle) {
    case 'secondary':
      return (
        'background-color: rgba(228, 0, 43, 0.1);'
      )

    case 'cancel':
      return (
        'background-color: rgba(173, 181, 189, 0.15);'
      )

    default:
      return (
        'background-color: ' + theme.colors.primaryDark + '; '
      )
  }
}

const buttonCssDisabled = (btnStyle, theme) => {
  switch (btnStyle) {
    case 'secondary':
    case 'cancel':
      return (
        'border: 1px solid ' + theme.colors.neutral4 + '; ' +
        'background-color: #ffffff; ' +
        'color: ' + theme.colors.neutral4 + '; '
      )

    default:
      return (
        'border: 0;' +
        'color: #ffffff;' +
        'background-color: ' + theme.colors.neutral4 + '; '
      )
  }
}

const ButtonWrapper = styled.button<{ disabled: boolean, btnStyle: string, weight?: number, mainWidth?: string,}>`
  outline: none;
  box-shadow: none;
  cursor: ${({disabled}) => disabled ? 'auto' : 'pointer'};
  font-weight: ${({weight}) => weight ? weight : 'auto'};
  min-height: ${({mainWidth}) => mainWidth ? mainWidth : '40px'};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  border-radius: 4px;
  ${({mainWidth}) => mainWidth && 'width: ' + mainWidth + '; height: ' + mainWidth + ';'};
  
  &:hover {
    ${({disabled, btnStyle, theme}) => !disabled && buttonCssHover(btnStyle, theme)};
  }
  
  ${({theme}) => `
    font-family: ${theme.fonts.mainBold};
    font-size: ${theme.fontSizes.body};
  `}
  ${({disabled, btnStyle, theme}) => (
    !disabled ? buttonCss(btnStyle, theme) : buttonCssDisabled(btnStyle, theme)
  )};
`;

export default Button;
